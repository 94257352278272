import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import * as moment from 'moment';

@Component({
  selector: 'flrgl-calendar-month',
  templateUrl: './calendar-month.component.html',
  styleUrls: ['./calendar-month.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarMonthComponent implements OnInit {
  @Input()
  start: Date;
  @Input()
  monthOffset = 0;
  @Input()
  longWeekdays = false
  @Input()
  events: { earliestDate: Date, latestDate: Date, name: string, eventId: string }[];
  @Input()
  displayFullEvents = false;
  @Output()
  dateSelected = new EventEmitter();
  @Output()
  dateHover = new EventEmitter<Date>();
  @Output()
  clicked = new EventEmitter();
  @Input()
  printEvents = false;
  @Input()
  useLatestDate = false;
  @Input()
  chatTrackMap: Dictionary<any>;

  month: CalendarMonth;
  _weekDays: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  _longWeekDays: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  dayHover: CalendarDay;
  get weekDays() {
    return this.longWeekdays ? this._longWeekDays : this._weekDays;
  }
  constructor() { }
  ngOnInit(): void {

    const momentEventsMap: { [dayTime: string]: MomentEvent[] } = {};
    for (const e of this.events) {
      const mom = moment(this.useLatestDate ? e.latestDate : e.earliestDate).startOf('day');
      const dayId = mom.format('YYYY-MM-DD');
      const m = { ...e, moment: mom, dayId };
      let a = momentEventsMap[m.moment.toDate().getTime()];
      if (!a) {
        a = [];
        momentEventsMap[dayId] = a;
      }
      a.push(m);
    }
    const monthMoment = moment(this.start);
    monthMoment.add(this.monthOffset, 'months');
    const today = moment().startOf('day');
    this.month = {
      title: monthMoment.format('MMMM `YY'),
      expanded: false,
      id: '' + 12 * monthMoment.year() + monthMoment.month(),
      weeks: [],
    };
    // console.log('o', this.month.title, momentEventsMap);


    const startD = moment(monthMoment.toDate()).startOf('month');
    const endD = moment(
      moment(monthMoment.toDate()).add(1, 'months').startOf('month')
    );
    let week = { title: 'w', days: [] };
    this.month.weeks.push(week);
    for (let i = 0; i < 7; i++) {
      if (i < startD.weekday()) {
        week.days.push({ title: '', number: '', empty: true });
      }
    }
    // console.log(startD.weekday());
    for (const d = startD; d.diff(endD, 'days') < 0; d.add(1, 'days')) {
      const dayId = moment(d).startOf('day').format('YYYY-MM-DD');
      const momentEvents = momentEventsMap[dayId] || [];
      // if (momentEvents.length) {
      //   console.log('x', dayId, momentEvents);
      // }
      const day: CalendarDay = {
        active: false,
        number: d.format('D'),
        empty: false,
        date: d.toDate(),
        isEventDate: !!momentEvents.length,
        momentEvents,
        isToday: d.isSame(today),
      };
      week.days.push(day);
      if (d.day() % 7 === 6) {
        week = { title: 'w', days: [] };
        this.month.weeks.push(week);
      }
    }
    for (let i = 0; i <= 7; i++) {
      if (i > endD.weekday()) {
        week.days.push({ title: '', number: '', empty: true });
      }
    }

  }

  eventUnread(eventId: string) {
    return this.chatTrackMap?.[eventId]?.sumUnread;
  }

  enterDay(day: CalendarDay) {
    if (this.dayHover) {
      this.dayHover.active = false;
    }
    this.dayHover = day;
    this.dayHover.active = true;
    this.dateHover.next(day.date);
  }

  exitDay(day: CalendarDay) {
    day.active = false;
    this.dateHover.next(null);
  }

  selectedDate(
    day: CalendarDay,
    e: any
  ) {
    this.dateSelected.next({ day, e });
    day.active = false;
  }
}

interface CalendarMonth {
  title: string;
  id: string;
  expanded: boolean;
  weeks: {
    title: string;
    days: CalendarDay[];
  }[];
}
interface CalendarDay {
  number: string;
  active: boolean;
  date: Date;
  empty: boolean;
  isEventDate: boolean;
  isToday: boolean;
  momentEvents: MomentEvent[]
}

interface MomentEvent {
  earliestDate: Date, latestDate: Date,
  name: string,
  eventId: string,
  moment: moment.Moment,
  dayId: string
}