<mat-card>
    <div fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutAlign="end center">
        <div class="title" fxFlex="grow">
            {{item.name}}
            <!-- - {{item.id}} -->
        </div>
        <button mat-icon-button class="func m-r-15" (click)="editCalculation()" *auth1="['planner']">
            <mat-icon>
                functions
            </mat-icon>
        </button>
        <ng-container *ngIf="item.calcType=='none';then noCalculationTmpl;else calculationTmpl"></ng-container>
    </div>
</mat-card>


<ng-template #noCalculationTmpl>
    <mat-form-field [appearance]="editable?'fill':'outline'" fxFlexLayout="auto end" class="no-calc">
        <mat-label>Budget</mat-label>
        <input [readonly]="!editable" matInput type="number" class="right1" #input1 [formControl]="budgetControl"
            min="0" (focus)="editable && onFocus(input1)">
        <span matPrefix>€&nbsp;</span>
    </mat-form-field>
</ng-template>



<ng-template #calculationTmpl>
    <div fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutAlign="end" class="calculation">
        <mat-form-field appearance="outline" class="number1">
            <mat-label>{{getCalculationLabel()}}</mat-label>
            <input matInput class="right1" [ngModel]="getCalculationNumber()" readonly>
        </mat-form-field>
        <mat-icon fxFlexAlign="center" class="multiply">clear</mat-icon>
        <mat-form-field appearance="outline">
            <mat-label>Item Price</mat-label>
            <input matInput class="right1" [ngModel]="getCalculationItemPrice() | currency:'EUR':'symbol'"
                readonly>
        </mat-form-field>
        <span fxFlexAlign="center" class="equals">=</span>
        <mat-form-field appearance="outline" class="budget">
            <mat-label>Budget</mat-label>
            <input matInput class="right1" [ngModel]="item.budget | currency:'EUR':'symbol'" readonly>
        </mat-form-field>
    </div>
</ng-template>