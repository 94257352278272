<div class="container" fxLayout="column" fxLayoutAlign="start center" [ngClass]="{editMode:!!chatToEdit}">

    <div class="create-msg" fxLayout="column">
        <mat-form-field>
            <textarea matInput [placeholder]="msgPlaceHolder()" rows="1" [formControl]="msg" cdkTextareaAutosize
                (keyup)="keyup1($event)"></textarea>
        </mat-form-field>
        <div fxLayout="row" fxFlex="grow" class="buttons">
            <button mat-icon-button fxFlexAlign="start" [matMenuTriggerFor]="menu">
                <mat-icon>attach_file</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="uploadDoc(fileUploadDoc)">
                    <mat-icon class='doc1' fontSet="far" fontIcon="fa-file-alt"></mat-icon>
                    Upload Document
                </button>
                <button mat-menu-item (click)="uploadImg(fileUpload)">
                    <mat-icon class="img1" fontSet="far" fontIcon="fa-image"></mat-icon>
                    Upload Image
                </button>
            </mat-menu>

            <input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*" hidden
                (change)="attachFile($event)" #fileUpload>

            <input type="file" id="fileUploadDoc" name="fileUploadDoc" multiple="multiple"
                accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,image/*,.odt" hidden (change)="attachFile($event)"
                #fileUploadDoc>

            <div fxFlex="grow"></div>
            <button mat-icon-button fxFlexAlign="end" (click)="upsert()">
                <mat-icon>send</mat-icon>
            </button>
        </div>
    </div>
</div>