<ng-container *ngIf="auth$ | async as auth">
    <ng-container *ngIf="(!item.internal || auth.isPlanner)">

        <mat-card *ngIf=" !editMode;else editItem" (click)="auth.isPlanner && setEditMode(true)"
            [ngClass]="{flagged:item.flagged||item.highlight,bg2:bg2, done:item.done, overdue: item.overdue && !item.done, internal:item.internal}">
            <div *ngIf="showEvent" fxLayoutAlign='end center' (click)="toCategorizedChecklist(item);$event.stopPropagation()">
                <span class="event-label">
                    <span class="text1">
                        <span class="customer">{{item?.event?.customer?.name}}</span>
                        <span class="event">({{item?.event?.name}}) </span>
                    </span>

                    <mat-icon>launch</mat-icon>
                </span>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="inherit center">

                <div fxLayoutAlign="auto center" class="button">
                    <button mat-icon-button (click)="auth.isPlanner && toggleDone();$event.stopPropagation()">
                        <ng-container *ngIf="!item.done;else doneIcon">
                            <ng-container *ngIf="item.type==='payment';else nonPayment">
                                <div [ngClass]="{'with-highlight':item.highlight}" fxLayoutAlign="center center">
                                    <mat-icon class="euro">euro_symbol</mat-icon>
                                    <mat-icon *ngIf="item.highlight" class="highlight1">gps_not_fixed</mat-icon>
                                </div>
                            </ng-container>
                            <ng-template #nonPayment>
                                <ng-container *ngIf="item.internal;else publicItem">
                                    <mat-icon class="material-icons-outlined internal">spa</mat-icon>
                                </ng-container>
                                <ng-template #publicItem>
                                    <ng-container *ngIf="!item.highlight;else highlightIcon">
                                        <mat-icon *ngIf="!item.flagged;else flaggedIcon">radio_button_unchecked
                                        </mat-icon>
                                        <ng-template #flaggedIcon>
                                            <mat-icon>error_outline</mat-icon>
                                        </ng-template>
                                    </ng-container>
                                </ng-template>
                                <ng-template #highlightIcon>
                                    <mat-icon>gps_fixed</mat-icon>
                                </ng-template>
                            </ng-template>
                        </ng-container>
                        <ng-template #doneIcon>
                            <mat-icon>check_circle_outline </mat-icon>
                            <!-- <ng-container *ngIf="item.internal;">
                                <mat-icon class="material-icons-outlined">spa</mat-icon>
                            </ng-container> -->
                        </ng-template>
                    </button>
                </div>
                <div fxFlex="1 1 0">
                    <div class="top" readonly>
                        <ng-container *ngIf="showCat">
                            <span class="cat1">{{item?.checklistCat?.name}} </span>
                            <span class="separator1 m-x-4">|</span>
                        </ng-container>
                        <ng-container *ngIf="payment && payment?.vendor">
                            <span class="cat1"> {{payment?.vendor?.getControlledName(item.event?.customerId,auth)}}
                            </span>
                            <span class="separator1 m-x-4">|</span>
                        </ng-container>
                        <ng-container>
                            <ng-container *ngIf="item.assignee  && auth.isPlanner ">
                                <span class="assignee">
                                    <mat-icon>person_pin</mat-icon>
                                    <span class="assignee"> {{item?.assignee?.name}} </span>
                                    <span class="separator1 m-x-4">|</span>
                                </span>
                            </ng-container>
                        </ng-container>
                        <span class="date" (click)="auth.isPlanner && dueDateClick.next();$event.stopPropagation()">
                            <ng-container *ngIf="!item.done;else doneDate">
                                due {{item.dueDateString}}
                            </ng-container>
                            <ng-template #doneDate>done {{item.doneDateString}}</ng-template>
                        </span>
                    </div>
                    <div *ngIf="item.type==='checklist' || item.type==='eventItem';else paymentTitle" class="title">
                        <span>{{item.name}}
                            <ng-container *ngIf="item.type==='eventItem'"> [spec]</ng-container>
                        </span>
                    </div>
                    <ng-template #paymentTitle>
                        <div class="title" *ngIf="payment" fxLayout="row" fxFlexAlign="start center">
                            <span>
                                {{payment.amountString | currency:'EUR':'symbol'}}
                            </span>
                            <ng-container *ngIf="auth.isPlanner && payment.data.includesCommission">
                                <span class="m-x-8">=</span>
                                <span>{{(payment.amount - payment.commissionAmount) | currency:'EUR':'symbol'}}</span>
                                <span class="m-x-8"> (+</span>
                                <span>{{payment.commissionAmountString | currency:'EUR':'symbol'}} ) </span>
                            </ng-container>
                            <mat-icon *ngIf="!payment.isCash">account_balance
                            </mat-icon>

                        </div>
                    </ng-template> <!-- - {{payment.isCash?'Cash':'Bank'}} Payment -->
                    <div class="details" *ngIf="item.details">
                        {{item.details}}
                    </div>
                    <div *ngIf="item.type==='eventItem'">
                        <span class="details clickable1 underline1 hover1" [routerLink]="item.asEventItem.url">
                            <span>details</span>
                            <mat-icon>launch</mat-icon>
                        </span>
                    </div>
                </div>
            </div>
        </mat-card>
        <ng-template #editItem>
            <flrgl-checklist-item-edit [upsert]="upsert" (cancel)="setEditMode(false)" [vendors]="item.event.allVendors"
                [agency]="item?.event?.agency" (itemChange)="handleItemChange($event)" (deleted)="deletedItem()">
            </flrgl-checklist-item-edit>
        </ng-template>
    </ng-container>
</ng-container>