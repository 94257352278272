import { NgModule } from '@angular/core';
import { BudgetItemCalculationComponent } from './components/budget-item-calculation/budget-item-calculation.component';
import { BudgetItemComponent } from './components/budget-item/budget-item.component';
import { BudgetComponent } from './components/budget/budget.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { FlowergirlFeaturesBudgetModule } from '@skylitup/flowergirl/features/budget';
import { FlowergirlXplatWebUiModule } from '@skylitup/flowergirl/xplat/web/ui';
import { FlowergirlXplatWebFeaturesChecklistModule } from '@skylitup/flowergirl/xplat/web/features/checklist';

@NgModule({
  imports: [
    FlowergirlFeaturesBudgetModule,
    FlowergirlXplatWebUiModule,
    FlowergirlXplatWebFeaturesChecklistModule,
  ],
  declarations: [
    BudgetItemComponent,
    BudgetComponent,
    BudgetItemCalculationComponent,
    PaymentsComponent,
    PaymentsComponent,
    BudgetItemCalculationComponent,
    BudgetItemComponent,
  ],
  exports: [],
})
export class FlowergirlXplatWebFeaturesBudgetModule {}
