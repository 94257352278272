<form>
    <mat-form-field class="rate m-l-10">
        <mat-label>Amounts Received</mat-label>
        <input #amount1 type="number" min="0" matInput [formControl]="amount" required class="right1"
            (focus)="onFocus(amount1)">
        <mat-error *ngIf="amount.invalid">
            {{getErrorMessage(amount,'Amount')}}</mat-error>
        <span matPrefix>€</span>
    </mat-form-field>

    <mat-dialog-actions fxLayoutAlign="center">
        <button mat-button [mat-dialog-close]="saveCalculation()" type="submit">
            <mat-icon>save</mat-icon> Save
        </button>
        <button mat-button [mat-dialog-close] type="submit">
            <mat-icon>close</mat-icon> Cancel
        </button>

    </mat-dialog-actions>
</form>