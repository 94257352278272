import { Component, Input } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { ChatHeaderBaseComponent } from '@skylitup/flowergirl/features/chat';
import { AppState, ContextService, HasDialog, MediaLibraryService, uiMediaTabChanged, VendorResearchData } from '@skylitup/flowergirl/core';
import { filter, first, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'flrgl-chat-header',
  templateUrl: 'chat-header.component.html',
  styleUrls: ['chat-header.component.scss'],
})
export class ChatHeaderComponent extends ChatHeaderBaseComponent {
  @Input() dialogObj: HasDialog;
  @Input() inItemDetails = false;

  docsNum$: Observable<number>;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private context: ContextService,
    private store: Store,
    private mediaLibraryService: MediaLibraryService
  ) {
    super();
    this.docsNum$ = this.mediaLibraryService.docsInItemScope$.pipe(map(l => l?.length));
  }
  navigateToMedia(docs: boolean) {
    this.store.dispatch(uiMediaTabChanged({ index: docs ? 1 : 0 }));
    this.router.navigate(['media'], {
      relativeTo: this.route,
    });
  }
  navigateToLink() {
    if (this.inItemDetails) {
      if (this.dialogObj.dialog.type === 'vendorResearch') {
        this.context.contextPath$
          .pipe(first())
          .subscribe((path) => this.router.navigate([...path, 'chat', 'recent']));

      } else {
        this.context.contextPath$
          .pipe(first())
          .subscribe((path) => this.router.navigate([...path, 'chat']));
      }
    } else {
      if (this.dialogObj.dialog.type === 'eventItem') {
        this.router.navigateByUrl(this.dialogObj.url);
      } else if (this.dialogObj.dialog.type === 'vendorResearch') {
        const d = this.dialogObj.data as VendorResearchData;
        const catId = d.catIds?.[0] || '';
        const p = [...d.meta.urlPath, catId];
        // p.pop()
        this.router.navigate(p);
      } else {
        this.router.navigate(['overview'], {
          relativeTo: this.route.parent.parent.parent,
        });
      }
    }
  }
  text1() {
    if (!this.inItemDetails) {
      return `${this.dialogObj.name} details`;
    } else {
      return 'Chat';
    }
  }
}
