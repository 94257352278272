import { Observable } from 'rxjs';
import { BaseComponent } from '@skylitup/base/util';
import { Agency, AgencyService } from '@skylitup/flowergirl/core';

export abstract class AgencyListBaseComponent extends BaseComponent {
  agencies$: Observable<Agency[]>;
  constructor(private agencyService: AgencyService) {
    super();
    this.agencies$ = this.agencyService.agencies$;
  }
}
