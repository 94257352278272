import { Component, ChangeDetectionStrategy } from '@angular/core';
import { HeaderBaseComponent } from '@skylitup/flowergirl/ui';
 

@Component({
  selector: 'flrgl-header',
  templateUrl: 'header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent extends HeaderBaseComponent {}
