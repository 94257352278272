import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { first, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BudgetBaseComponent } from '@skylitup/flowergirl/features/budget';
import {
  EventItemService,
  Auth,
  ContextService,
  CustomerEventService,
  CustomerEvent,
  EventCategory,
  CommissionData,
  Category,
  uiFocusCat,
} from '@skylitup/flowergirl/core';
@Component({
  selector: 'flrgl-budget',
  templateUrl: 'budget.component.html',
  styleUrls: ['budget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetComponent extends BudgetBaseComponent
  implements AfterViewInit {
  viewInitialized = false;
  auth$: Observable<Auth>;
  constructor(
    context: ContextService,
    store: Store,
    itemService: EventItemService,
    customerEventService: CustomerEventService,
    cd: ChangeDetectorRef
  ) {
    super(context, store, itemService, customerEventService);
    this.auth$ = this.context.auth$;
    // context.customerEvent$
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(customerEvent => {
    //     cd.markForCheck(), console.log('7777', customerEvent?.vendorBudgetMap);
    //   });
  }
  goalChanged(val: number, customerEvent: CustomerEvent, cat: EventCategory) {
    const catGoalBudgets = {
      ...customerEvent.data.catGoalBudgets,
    };
    catGoalBudgets[cat.id] = val;
    this.customerEventService
      .update({
        catGoalBudgets,
        meta: customerEvent.data.meta,
      })
      .pipe(first())
      .subscribe();
  }
  commissionChange(
    customerEvent: CustomerEvent,
    vendorId: string,
    commissionData: CommissionData
  ) {
    const commissions = { ...(customerEvent.data.commissions || {}) };
    commissions[vendorId] = commissionData;
    const d = { ...customerEvent.data, commissions };
    this.customerEventService.update(d).pipe(first()).subscribe();
  }
  paymentsReceivedChange(
    customerEvent: CustomerEvent,
    vendorId: string,
    paymentsReceived: number
  ) {
    const commissions = { ...(customerEvent.data.commissions || {}) };
    const dd = {
      ...(commissions[vendorId] || { type: 'rate', amount: 0, rate: 0 }),
      paymentsReceived,
    };
    commissions[vendorId] = dd;
    const d = { ...customerEvent.data, commissions };
    this.customerEventService.update(d).pipe(first()).subscribe();
  }
  onFocus(input: any) {
    input.select();
  }

  digits2(a: number) {
    return Math.round(a * 100) / 100;
  }

  focusedOnCat(cat: Category) {
    if (this.viewInitialized) {
      this.store.dispatch(uiFocusCat({ cat: cat.id }));
    }
  }
  ngAfterViewInit(): void {
    this.viewInitialized = true;
  }
}
