<mat-dialog-content>
    <div class="container">
        <flrgl-media [selectMode]='true' (selectionChange)="selectedMedia=$event" [selectedMedia]="selectedMedia">
        </flrgl-media>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="center">
    <button mat-button mat-dialog-close autofocus>
        <mat-icon>close</mat-icon> Close
    </button>
    <button mat-button [mat-dialog-close]="selectedMedia">
        <mat-icon>save</mat-icon> Save
    </button>
</mat-dialog-actions>