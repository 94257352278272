import { Injectable } from '@angular/core';
import { LocalStorageService } from '@skylitup/base/ngrx-data-fire';
 import {
  Actions,
  createEffect,
  EffectNotification,
  ofType,
  OnInitEffects,
} from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { allUiActionCreators, uiInit } from './ui.actions';
import { NotificationService } from '../../../util/services/notification.service';
import { AppState } from '@skylitup/flowergirl/core';
import { genericCatch$$ } from '@skylitup/base/util';

@Injectable()
export class UiEffects implements OnInitEffects {
  uiStateChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(...allUiActionCreators),
        switchMap((_) =>
          this.store
            .select((s) => s.ui)
            .pipe(
              tap((uiState) =>
                this.localStorageService.setItem('ui', JSON.stringify(uiState))
              )
            )
        ),
        genericCatch$$('UiEffects-uiStateChange$')
      ),
    {
      dispatch: false,
    }
  );

  ngrxOnInitEffects(): Action {
    const initStateSerialized = this.localStorageService.getItem('ui');
    if (initStateSerialized) {
      return uiInit({ initState: JSON.parse(initStateSerialized) });
    } else {
      return uiInit(<any>{ initState: { showDemo: true } });
    }
  }
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    private notifications: NotificationService
  ) {}
  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.notifications.trackCompletion(resolvedEffects$);
  }
}
