// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  domainUrl: 'http://localhost:4200',
  
  firebase: {
    apiKey: 'AIzaSyAyapuI0bTf_CsCKmnGEuHYFBfWhc2Q_iA',
    authDomain: 'flowergirl-5230a.firebaseapp.com',
    databaseURL: 'https://flowergirl-5230a.firebaseio.com',
    projectId: 'flowergirl-5230a',
    storageBucket: 'flowergirl-5230a.appspot.com',
    messagingSenderId: '409007676371',
    appId: '1:409007676371:web:d596aed8eb812f9dd02d26',
    measurementId: 'G-P7J0WQPMZD',
    cloudfunctions: 'http://localhost:5001' 
  },
  routes: [
    {
      path: 'dev',
      // canActivate: [DevGuard],
      loadChildren: () =>
        import('@skylitup/flowergirl/xplat/web/features/dev').then(
          (m) => m.FlowergirlXplatWebFeaturesDevModule
        ),
    },
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
