import { createAction, props } from '@ngrx/store';

export const syncAgencyUserCards = createAction(
  '[user-cards - Sync]',
  props<{ sync: boolean }>()
);


export const syncCustomerUserCards = createAction(
  '[user-cards (for customer) - Sync]',
  props<{ sync: boolean }>()
);


