import { Dictionary } from '@ngrx/entity';
import { Loc, LocStore } from './location.model';
import { LocData } from './agency.model.data';

export const projectLocStore = (
  locs: LocData[],
  settings?: {
    filterFn?: (l: Loc) => boolean;
    unshiftSelfInTreeRoots?: boolean;
  }
): LocStore => {
  const { filterFn, unshiftSelfInTreeRoots } = settings || {};
  const locStore: LocStore = {
    locations: [],
    locationMap: {},
    locationTree: []
  };
  if (!locs) {
    return locStore;
  }

  const { locations, locationMap, locationTree } = locStore;
  // create Loc objects
  const ANYWHERE = 'anywhere';
  const anywhere: Loc = new Loc({
    id: ANYWHERE,
    parent: null,
    name: 'Anywhere',
    _del: null
  });

  const tmpLocations: Loc[] = [];
  const tmpLocationMap: Dictionary<Loc> = {};
  for (const l of locs) {
    const location = new Loc(l);
    tmpLocations.push(location);
    tmpLocationMap[location.id] = location;
  }

  if (!tmpLocationMap[ANYWHERE]) {
    tmpLocations.unshift(anywhere);
    tmpLocationMap[ANYWHERE] = anywhere;
  }
  // link parents children
  for (const location of tmpLocations) {
    const parent = tmpLocationMap[location?.data?.parent];
    if (parent) {
      parent.children.push(location);
      location.parent = parent;
      location.isRoot = false;
    } else {
      location.isRoot = true;
    }
  }
  // set roots before the filtering
  for (const location of tmpLocations) {
    let ancestor = location.parent;
    while (ancestor) {
      if (ancestor.isRoot) {
        location.root = ancestor;
      }
      ancestor = ancestor.parent;
    }
  }
  // link familytree

  const rootsThatPassFilter = [];
  for (const location of tmpLocations) {
    if (location && (!filterFn || filterFn(location))) {
      try {
        locations.push(location);
        locationMap[location.id] = location;
        if (location.id !== ANYWHERE) {
          location.ancestors.push(anywhere);
        }
        if (location.isRoot) {
          locationTree.push(location);
          rootsThatPassFilter.push(location);
        } else {
          let ancestor = location.parent;
          while (ancestor) {
            location.ancestors.push(ancestor);
            ancestor.descendants.push(location);
            ancestor = ancestor.parent;
          }
          if (!locationMap[location.root.id]) {
            locationMap[location.root.id] = location.root;
            locations.push(location.root);
            locationTree.push(location.root);
          }
        }
      } catch (e) {
        console.log(location);
        throw e;
      }
    }
  }

  // sort children alphabetically
  locationTree.sort((l1, l2) => {
    if (l1.id === ANYWHERE) {
      return -1;
    } else if (l2.id === ANYWHERE) {
      return 1;
    } else {
      return l1.name < l2.name ? -1 : 1;
    }
  });
  for (const location of locations) {
    location.descendants.sort((l1, l2) => (l1.name < l2.name ? -1 : 1));
  }

  if (unshiftSelfInTreeRoots) {
    for (const r of rootsThatPassFilter) {
      r.descendants.unshift(r);
    }
  }

  // console.log('locstore!!!!!!', locStore);
  // console.log('locstore ROOTS : ', locStore.locationTree.toString());
  return locStore;
};
