<div *ngIf="auth$ |async as auth">
    <ng-container *ngIf="chatTrack$ | async as chatTrack">
        <div *ngIf="!dialog.isEmpty; else noDialog" class="mat-elevation-z1  p-12 dialogObj clickable1 "
            [ngClass]="{active:active,'has-unread':!!chatTrack.getUnread(dialog.dialogId)}" fxFlex="grow">
            <div>
                <div class="name" fxFlex="grow">
                    <ng-container *ngIf="inOverview;else notInOverview">
                        <span class="inOverviewTitle" matBadgeSize='small' matBadgePosition="after" matBadge="!"
                            matBadgeColor='warn' matBadgeOverlap='true'
                            [matBadgeHidden]="!chatTrack.getUnread(dialog.dialogId)"> Chat
                            <mat-icon>forum</mat-icon>
                        </span>
                    </ng-container>
                    <ng-template #notInOverview>
                        <span *ngIf="!hideName">{{name(auth)}}</span>
                    </ng-template>
                </div>
                <div class="date" [matBadge]="chatTrack.getUnread(dialog.dialogId)" matBadgePosition="above after"
                    [matBadgeHidden]="!chatTrack.getUnread(dialog.dialogId)" matBadgeSize='small'
                    matBadgeOverlap='false'>
                    {{dialog?.lastContactDateString}}</div>
            </div>
            <ng-container *ngIf="chatTrackDebug">
                <div>
                    <ng-container *ngIf="chatTrack.auth">
                        {{chatTrack.getReadBy(dialog.dialogId)}} / {{chatTrack.getTotals(dialog.dialogId)}}
                        | {{dialog.dialogId}}
                    </ng-container>
                </div>
            </ng-container>
            <div class="excerpt" *ngIf="!dialog.isEmpty">
                {{dialog?.recentLatest?.user?.username}} :
                <span *ngIf="!inOverview;else longerExcerpt" [innerHTML]="dialog?.recentLatestExcerpt"></span>
                <ng-template #longerExcerpt>
                    <span [innerHTML]="dialog?.recentLatestExcerptLonger"></span>
                </ng-template>
            </div>
        </div>
        <ng-template #noDialog>
            <div class="mat-elevation-z1 dialogObj" fxLayout fxLayoutAlign="start center" fxFlex="grow"
                [ngClass]="{active:active}">
                <ng-container *ngIf="inOverview;else notInOverview">
                    <span class="inOverviewTitle"> Chat <mat-icon>forum</mat-icon></span>
                </ng-container>
                <ng-template #notInOverview>
                    <div class="name" fxFlex="grow"><span *ngIf="!hideName">{{name(auth)}}</span></div>
                </ng-template>
            </div>
        </ng-template>
    </ng-container>
</div>