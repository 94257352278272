import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { takeUntil } from 'rxjs/operators';
import { EntityAutocomplete } from './entity-autocomplete';
import { BaseComponent } from '@skylitup/base/util';

@Component({
  selector: 'flrgl-entity-autocomplete',
  templateUrl: 'entity-autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityAutocompleteComponent extends BaseComponent implements AfterViewInit  {
  @Input()
  control: EntityAutocomplete<any>;
  @Output()
  cleared = new EventEmitter<void>();
  @Input()
  openOnClear = true;
  @ViewChild('input1')
  input1: ElementRef;
  constructor() {
    super();
  }

  clear(autoTrigger?: MatAutocompleteTrigger) {
    this.control.formControl.setValue(null);
    this.cleared.next();
    if (autoTrigger && this.openOnClear) {
      setTimeout(() => autoTrigger.openPanel(), 0);
    }
  }
  ngAfterViewInit(): void {
    this.control.optionSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((optionSelected) => {
        setTimeout(() => this.input1.nativeElement.blur(), 0);
      });
  }
  // open(autoTrigger: MatAutocompleteTrigger) {
  //   setTimeout(() => autoTrigger.openPanel(), 0);
  // }
}
