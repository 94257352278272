import { Injectable } from '@angular/core';
import {
    EntityCollectionServiceElementsFactory,
    EntityDataService
} from '@ngrx/data';
import { EntityBaseSelectors, EntityBaseService, FirebaseStorageDataService } from '@skylitup/base/ngrx-data-fire';
import { forkJoin, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AGENCY_KEYS } from '../../agency/agency.model.data';
import { CUSTOMER_KEYS } from '../../customer/customer.model.data';
import { UploadData, UploadFilesRequest } from '../upload.model.data';
import { UploadService } from '../upload.service';
import { Doc } from './doc.model';
import { DocData, DOC_KEYS } from './doc.model.data';


interface SelectorsProjected extends EntityBaseSelectors<DocData, Doc> {
    //   selectDocIndex: MemoizedSelector<any, DocIndex>;
}

@Injectable({ providedIn: 'root' })
export class DocService extends EntityBaseService<DocData, Doc> {
    public selectorsProjected: SelectorsProjected;
    //   public mediaIndex$: Observable<DocIndex>;
    constructor(
        entityServiceFactory: EntityCollectionServiceElementsFactory,
        entityDataServices: EntityDataService,
        private uploadService: UploadService,
        private storageDataService: FirebaseStorageDataService
    ) {
        super(DOC_KEYS, entityServiceFactory, entityDataServices);
        this.init();
    }
    setupRxSelectors() {
        return this._selectPlainEntityTuple;
    }
    setupRx$() { }

    protected newProjectedEntity(d: DocData) {
        return new Doc(d);
    }

    upload(m: UploadFilesRequest): Observable<DocData[]> {
        return this.uploadService.upload(m).pipe(
            mergeMap(dd => this.createDocData(m.agency, m.customer, dd))
        );
    }
    createDocData(
        agencyId: string,
        customerId: string,
        dd: UploadData[]
    ): Observable<DocData[]> {
        const collectionPath = [
            AGENCY_KEYS.plural,
            agencyId,
            CUSTOMER_KEYS.plural,
            customerId,
            DOC_KEYS.plural
        ];
        const data = dd.map(d => ({
            meta: {
                collectionPath,
                id: null
            },
            ...d
        }));
        return forkJoin(data.map(d => this.upsert(d as DocData)));
    }
    syncEventDocs$(agencyId: string, customerId: string, eventId: string) {
        return this.syncQueryRealtime$(
            [
                AGENCY_KEYS.plural,
                agencyId,
                CUSTOMER_KEYS.plural,
                customerId,
                DOC_KEYS.plural
            ],
            {
                queryFn: query =>
                    query.where('links.events', 'array-contains', eventId),
                replaceState: true
            }
        );
    }
}
