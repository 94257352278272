import { Injectable } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";

import { last, map, mergeMap } from "rxjs/operators";
import {
  FirebaseStorageDataService,
  UploadTask,
} from "../../FirebaseStorageDataService";
@Injectable({ providedIn: "root" })
export class WebFirebaseStorageDataService extends FirebaseStorageDataService {
  constructor(private storage: AngularFireStorage) {
    super();
  }
  put(file: File, m: { path: string[]; fileName: string }): UploadTask {
    try {
      const filePath = [...m.path, m.fileName].join("/");
      const fileRef = this.storage.ref(filePath);
      const task = fileRef.put(file);
      return {
        snapshotChanges$: task.snapshotChanges().pipe(
          map((s) => ({
            bytesTransfered: s.bytesTransferred,
            totalBytes: s.totalBytes,
            state: s.state,
          }))
        ),
        percentageChanges$: task.percentageChanges(),
        downloadURL$: task.snapshotChanges().pipe(
          // tap(s => console.log('xxxx', s)),
          last(),
          mergeMap((s) => {
            return fileRef.getDownloadURL();
          })
        ),
      };
    } catch (er) {
      throw er;
    }
  }
}
