import {
  AfterViewInit,
  ChangeDetectionStrategy,

  ChangeDetectorRef, Component,

  Input
} from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { EntityData } from '@skylitup/base/ngrx-data-fire';
import {
  Agency, AppState, Auth,
  Category, ChecklistItem,
  ChecklistItemData,
  ChecklistItemImp, ChecklistItemService, ContextService, CustomerEvent,
  CustomerEventService,
  uiFocusCat,
  uiHideCompleted
} from '@skylitup/flowergirl/core';
import { ChecklistByCatBaseComponent } from '@skylitup/flowergirl/features/checklist';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'flrgl-checklist-by-cat',
  templateUrl: 'checklist-by-cat.component.html',
  styleUrls: ['checklist-by-cat.component.scss', '../checklist.common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistByCatComponent extends ChecklistByCatBaseComponent implements AfterViewInit {
  customerEvent$: Observable<CustomerEvent>;
  focusCat$: Observable<string>;
  hideCompletedItems: boolean;
  dateHover: Date;
  @Input()
  paymentsOnly = false;
  auth$: Observable<Auth>;
  agencyInContext$: Observable<Agency>;
  viewInitDone = false;

  constructor(
    private context: ContextService,
    private store: Store,
    private customerEventService: CustomerEventService,
    private checklistItemService: ChecklistItemService,
    private cd: ChangeDetectorRef
  ) {
    super();
    this.auth$ = this.context.auth$;
    this.agencyInContext$ = this.context.agency$;
    this.customerEvent$ = this.context.customerEvent$;
    this.focusCat$ = this.store.select(
      createSelector(
        (s: AppState) => s.ui,
        (s) => s.focusCat
      )
    );
    this.store
      .select((s: AppState) => s.ui.hideCompleted)
      .pipe(takeUntil(this.destroy$))
      .subscribe((b) => (this.hideCompletedItems = b));
  }

  selectedDate(
    { day, e },
    customerEvent: CustomerEvent,
  ) {
    this.customerEventService.updateChecklistItem(
      customerEvent,
      {
        ...e.item.data.data,
        dueDate: day.date,
      },
      e.item.data
    );
  }

  focusedOnCat(cat: Category) {
    this.store.dispatch(uiFocusCat({ cat: cat.id }));
  }
  trackByIdFn(i, o) {
    return o.id;
  }
  createItem(
    customerEvent: CustomerEvent,
    cat: Category,
    e: {
      data: ChecklistItemData;
      highlight: boolean;
      clear: () => void;
    }
  ) {
    this.customerEventService
      .createChecklistItem(
        customerEvent,
        { ...e.data, catId: cat.id, _del: null },
        e.highlight
      )
      .subscribe({ complete: () => e.clear() });
  }
  updateItem(
    customerEvent: CustomerEvent,
    e: { data: EntityData; highlight: boolean; done: () => void },
    checklistItem: ChecklistItem
  ) {
    this.customerEventService
      .updateChecklistItem(customerEvent, e.data, checklistItem, e.highlight)
      .subscribe({ complete: () => e.done() });
  }
  deleteItem(item: ChecklistItem) {
    this.checklistItemService
      .deleteEntity((item as ChecklistItemImp).data)
      .subscribe((_) => {
        this.cd.markForCheck();
      });
  }
  hideCompleted(hide: boolean) {
    this.store.dispatch(uiHideCompleted({ hide }));
  }
  digits2(a: number) {
    return Math.round(a * 100) / 100;
  }
  ngAfterViewInit(): void {
    this.viewInitDone = true;
  }
}
