import { Subject, BehaviorSubject, Observable, combineLatest } from 'rxjs';
 import { map, first, startWith } from 'rxjs/operators';
import { EntityData } from '@skylitup/base/ngrx-data-fire';
import { EntityProjected } from '@skylitup/base/ngrx-data-fire';
import { VendorParam } from '@skylitup/flowergirl/core';

export interface FocusedSort extends FocusSortClick {
  direction: 'asc' | 'desc';
  fn: (v1: EntityProjected<any>, v2: EntityProjected<any>) => number;
}
export interface FocusSortClick {
  label: string;
  fieldPath: string[];
  key: string;
  paramType: VendorParam['type'];
  config: VendorParam;
  value: any;
  entityData?: (entity: EntityProjected<any>) => EntityData;
}

export class VendorSort {
  constructor(
    public entityData: (entity: EntityProjected<any>) => EntityData
  ) {}

  public focusedSort$: Subject<FocusedSort> = new BehaviorSubject(null);
  get highlightKey$() {
    return this.focusedSort$.pipe(map(f => (f ? f.key : ' ')));
  }
  focusSort(e: FocusSortClick, direction?: 'asc' | 'desc') {
    const { fieldPath, key, paramType, value } = e;
    this.focusedSort$.pipe(first()).subscribe(currentSort => {
      if (!direction) {
        if (
          currentSort &&
          currentSort.key === key &&
          (paramType !== 'select' ||
            (paramType === 'select' && currentSort.value.code === value.code))
        ) {
          direction = currentSort.direction === 'desc' ? 'asc' : 'desc';
        } else {
          direction = 'desc';
        }
      }

      const entityData = e.entityData || this.entityData;
      this.focusedSort$.next({
        ...e,
        fn: (v1, v2) =>
          direction === 'asc'
            ? getVal(entityData(v1)) - getVal(entityData(v2))
            : getVal(entityData(v2)) - getVal(entityData(v1)),
        direction
      });
    });

    function getVal(v: EntityData): number {
      // console.log('___', v);
      let n: any = v;
      for (let i = 0; i < fieldPath.length; i++) {
        n = n[fieldPath[i]];
        if (n === undefined) {
          break;
        }
      }
      // console.log(value, v.name, n, fieldPath, v);
      if (
        n === undefined ||
        n == null ||
        (typeof n === 'string' && n.trim() === '')
      ) {
        if (paramType === 'bool' || paramType === 'select') {
          return -1;
        } else if (paramType === 'number') {
          if (direction === 'desc') {
            return -1;
          } else {
            return Number.MAX_VALUE;
          }
        }
      } else {
        if (paramType === 'number') {
          return n as number;
        } else if (paramType === 'bool') {
          return !!n ? 1 : -1;
        } else if (paramType === 'select') {
          const code = value.code as string;
          if (n === code) {
            return Number.MAX_VALUE;
          } else {
            return code.charCodeAt(0);
          }
        } else {
          return 0;
        }
      }
    }
  }
  removeFocusSort() {
    this.focusedSort$.next(null);
  }
  toggleOrderFocusSort(f: FocusedSort) {
    const direction = f.direction === 'desc' ? 'asc' : 'desc';
    this.focusSort({ ...f }, direction);
  }
  focusSortSelectChanged(e: FocusSortClick, value: string) {
    this.focusSort({ ...e, value: { code: value } }, 'desc');
  }
  sort$(entities$: Observable<EntityProjected<any>[]>) {
    return combineLatest([
      entities$,
      this.focusedSort$.pipe(startWith(null as FocusedSort))
    ]).pipe(
      map(([entities, focusedSort]) =>
        focusedSort ? [...entities].sort(focusedSort.fn) : entities
      )
    );
  }
}
