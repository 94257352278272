import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

import * as moment from 'moment';
import {
  CustomerEvent,
  ChecklistItem,
  Payment,
  ChecklistItemType,
} from '@skylitup/flowergirl/core';

export class ChecklistItemUpsertBehavior {
  form: FormGroup;
  clickedInside = false;
  datePickerOpen = false;
  highlight: boolean;
  constructor(
    private fb: FormBuilder,
    public customerEvent: CustomerEvent,
    public item?: ChecklistItem
  ) {
    if (item) {
      let g: any = {
        dueDate: [moment(item.dueDate)],
        details: [item.details],
        name: [item.name],
        flagged: [item.flagged],
        internal: [item.internal || false],
        type: [item.type],
        assigneeId: [item.assigneeId],
      };
      if (item.isPayment) {
        const payment: Payment = item as Payment;
        g = {
          ...g,
          amount: [payment.amount],
          paymentMethod: [payment.paymentMethod],
          extraRate: [payment.extraRate],
          vendorId: [payment.data.vendorId],
          includesCommission: [payment.data.includesCommission],
          commissionAmount: [payment.data.commissionAmount],
        };
      }
      this.form = this.fb.group(g);
      this.highlight = this.item.highlight;
    } else {
      this.form = this.fb.group({
        dueDate: [moment(this.customerEvent?.info.earliestDate)],
        details: [''],
        name: [''],
        flagged: [false],
        internal: [false],
        type: ['checklist'],
        amount: ['', Validators.required],
        paymentMethod: ['cash'],
        extraRate: [24],
        vendorId: [null],
        assigneeId: [null],
        includesCommission: [false],
        commissionAmount: [0],
      });
      this.highlight = false;
    }
    this.updateValidators();
  }
  patchForm(item: ChecklistItem) {
    this.form.patchValue(
      {
        dueDate: moment(item.dueDate),
        details: item.details || null,
        name: item.name ,
        flagged: item.flagged || false,
        internal: item.internal || false,
        type: item.type,
      },
      { emitEvent: false }
    );
  }

  reset(item: ChecklistItem) {
    this.item = item;
    this.patchForm(item);
    this.customerEvent = item.event;
    this.highlight = item.highlight;
  }
  flagChanged(flagged: boolean) {
    this.form.patchValue({ flagged });
  }
  internalChanged(internal: boolean) {
    // this.highlight=false;
    this.form.patchValue({ internal });
    if (internal) {
      this.form.patchValue({ flagged: false });
      this.highlight = false;
      this.typeChanged(false);
    }
  }

  typeChanged(payment: boolean) {
    this.form.patchValue({ type: payment ? 'payment' : 'checklist' });
    if (payment) {
      this.form.patchValue({ name: '' });
    }
    this.updateValidators();
  }
  updateValidators() {
    const amount = this.form.get('amount');
    const name = this.form.get('name');
    const vendorId = this.form.get('vendorId');
    const commissionAmount = this.form.get('commissionAmount');
    if (this.isPayment) {
      amount.setValidators([Validators.required]);
      vendorId.setValidators([Validators.required]);
      commissionAmount.setValidators([
        (control: AbstractControl): ValidationErrors | null => {
          if (+control.value > +amount.value) {
            return { bigger: 'bigger' };
          }
          return null;
        },
      ]);
      if (name) {
        name.setValidators([]);
      }
    } else {
      name.setValidators([Validators.required]);
      if (amount) {
        amount.setValidators([]);
      }
      if (vendorId) {
        vendorId.setValidators([]);
      }
      if (commissionAmount) {
        commissionAmount.setValidators([]);
      }
    }
    if (name) {
      name.updateValueAndValidity();
    }
    if (amount) {
      amount.updateValueAndValidity();
    }
    if (vendorId) {
      vendorId.updateValueAndValidity();
    }
    if (commissionAmount) {
      commissionAmount.updateValueAndValidity();
    }
  }
  highlightChanged(highlight: boolean) {
    this.highlight = highlight;
    // this.form.patchValue({});
  }
  get isPayment() {
    return (this.form.get('type').value as ChecklistItemType) === 'payment';
  }
  get isInternal() {
    return this.form.get('internal').value as boolean;
  }
  get paymentMethod() {
    return this.form.get('paymentMethod')?.value;
  }
  get name() {
    return this.form.get('name')?.value;
  }

  clickInside() {
    this.clickedInside = true;
  }
  clickDoc(clickedOutside: () => void) {
    if (!this.clickedInside && !this.datePickerOpen) {
      clickedOutside();
    }
    this.clickedInside = false;
  }
  clear = () => {
    this.form.patchValue({
      details: '',
      name: '',
      amount: '',
      vendorId: null,
      assigneeId: null,
      commissionAmount: 0,
      includesCommission: false,
      paymentMethod: 'cash',
      extraRate: 24,
    });
    this.form.markAsUntouched();

    this.highlight = false;
  };
  startAt(): moment.Moment {
    const a = this.customerEvent?.info.earliestCandiDate
      ? moment(this.customerEvent?.info.earliestDate)
      : null;
    return a;
  }
  setToEventDate(datePicker: MatDatepicker<moment.Moment>) {
    if (this.customerEvent?.info.earliestDate) {
      datePicker.select(moment(this.customerEvent?.info.earliestDate));
    }
  }
}
