import { Component, ElementRef } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { WindowService } from '@skylitup/base/util';
import {
  ContextService,
  createUiFocusChat,
  CustomerEvent,
  doHeartBeat,
  uiFocusChat,
  uiTrigger1,
  VendorParam,
  VendorResearch,
  VendorResearchService,
} from '@skylitup/flowergirl/core';
import { CompareVendorsBaseComponent } from '@skylitup/flowergirl/features/vendor';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FocusSortClick, VendorSort } from '../util/vendor-sort';

@Component({
  selector: 'flrgl-compare-vendors',
  templateUrl: 'compare-vendors.component.html',
  styleUrls: ['compare-vendors.component.scss'],
})
export class CompareVendorsComponent extends CompareVendorsBaseComponent {
  vendorResearchInContext$: Observable<VendorResearch[]>;
  vendorSort = new VendorSort((n: VendorResearch) => n.vendor.data);
  selectedVendorResearch: VendorResearch = null;
  fullCardOpen = false;
  showFeedback = false;
  customerEvent$: Observable<CustomerEvent>;
  constructor(
    private context: ContextService,
    private vendorResearchService: VendorResearchService,
    private eRef: ElementRef,
    private actions$: Actions,
    public windowService: WindowService,
    private store: Store
  ) {
    super();
    this.customerEvent$ = this.context.customerEvent$;
    const _vendorResearchInContext$ = this.vendorResearchService.vendorResearchInContext$.pipe(
      map((vv) => vv.filter((v) => !!v.vendor))
    );
    this.vendorResearchInContext$ = this.vendorSort
      .sort$(_vendorResearchInContext$)
      .pipe(map((aa) => aa as VendorResearch[]));
    this.vendorSort.focusedSort$
      .pipe(
        map((a) => a),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.store.dispatch(doHeartBeat());
        this.fullCardOpen = false;
      });
    this.vendorResearchService.entityMapProjected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((vrMap) => {
        if (this.selectedVendorResearch) {
          this.selectedVendorResearch = vrMap[this.selectedVendorResearch.id];
        }
      });
    this.actions$
      .pipe(takeUntil(this.destroy$), ofType(uiTrigger1))
      .subscribe((_) => (this.fullCardOpen = false));
    // this.vendorResearchInContext$
    //   .pipe(
    //     filter(v => !!v.length),
    //     first()
    //   )
    //   .subscribe(s => {
    //     // this.showMap(s[0]);
    //     this.showcase(true, true, s[0]);
    //   });
  }
  trackByIdFn(i, o) {
    return o.id;
  }

  showcase(
    show: boolean,
    showFeedback: boolean,
    vendorResearch?: VendorResearch
  ) {
    this.fullCardOpen = show;
    this.showFeedback = showFeedback;
    if (show && vendorResearch) {
      this.selectedVendorResearch = vendorResearch;
      this.store.dispatch(createUiFocusChat(vendorResearch));
    }
  }
  focusSort(e: FocusSortClick) {
    this.vendorSort.focusSort(e);
  }
  sortByHearts() {
    const fieldVal = {
      config: { label: 'Rating', type: 'number' as VendorParam['type'] },
      key: 'rating',
    };

    this.vendorSort.focusSort({
      label: fieldVal.config.label,
      key: fieldVal.key,
      paramType: 'number',
      config: fieldVal.config,
      fieldPath: ['rating'],
      value: null,
      entityData: (n: VendorResearch) => n.data,
    });

    // this.vendorSort.focusSort({
    //   entityData: (n: VendorResearch) => n.vendor.data,
    //   label: 'Rating',
    //   fieldPath: ['rating'],
    //   key: string,
    //   paramType: 'number'
    // });
  }
  // config: VendorParam
}
