import { NgModule } from '@angular/core';

import { ChatComponent } from './components/chat/chat.component';
import { ChatMessageComponent } from './components/chat-portal/chat-dialog/chat-message/chat-message.component';
import { ChatDialogComponent } from './components/chat-portal/chat-dialog/chat-dialog.component';
import { ChatMessageEditComponent } from './components/chat-portal/chat-dialog/chat-message/chat-message-edit/chat-message-edit.component';
import { ChatMessageCreateComponent, UploadDocPopupComponent } from './components/chat-portal/chat-dialog/chat-message/chat-message-create/chat-message-create.component';
import { ChatPortalComponent } from './components/chat-portal/chat-portal.component';
import { FlowergirlXplatWebUiModule } from '@skylitup/flowergirl/xplat/web/ui';
import { FlowergirlFeaturesChatModule } from '@skylitup/flowergirl/features/chat';
import { ChatRecentComponent } from './components/chat/chat-recent/chat-recent.component';
import { ChatByCatComponent } from './components/chat/chat-by-cat/chat-by-cat.component';
import { RouterModule } from '@angular/router';
import { ChatThumbComponent } from './components/chat/ui/chat-thumb/chat-thumb.component';
import { ChatHeaderComponent } from './components/chat/ui/chat-header/chat-header.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    FlowergirlFeaturesChatModule,
    FlowergirlXplatWebUiModule,
    RouterModule,
    CommonModule
  ],
  declarations: [
    ChatComponent,
    ChatMessageComponent,
    ChatDialogComponent,
    ChatPortalComponent,
    ChatMessageCreateComponent,
    ChatMessageEditComponent,
    ChatRecentComponent,
    ChatByCatComponent,
    ChatThumbComponent,
    ChatHeaderComponent,
    UploadDocPopupComponent
  ],
  exports: [ChatPortalComponent, ChatHeaderComponent, ChatThumbComponent],
})
export class FlowergirlXplatWebFeaturesChatModule { }
