import { Observable } from 'rxjs';
import { BaseComponent } from '@skylitup/base/util';
import { Agency, AgencyService } from '@skylitup/flowergirl/core';

export abstract class AgencyBaseComponent extends BaseComponent {
  public agency$: Observable<Agency>;
  constructor(agencyService: AgencyService) {
    super();
    this.agency$ = agencyService.agencyInContext$;
  }
}
