import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AgencyListBaseComponent } from '@skylitup/flowergirl/features/navigate';
import { AgencyService, ContextService } from '@skylitup/flowergirl/core';



@Component({
  selector: 'flrgl-agency-list',
  templateUrl: 'agency-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgencyListComponent extends AgencyListBaseComponent {
  constructor(agencyService: AgencyService, contextService: ContextService) {
    super(agencyService);
  }
}
