import { Component } from '@angular/core';

import { VendorResearchBaseComponent } from '@skylitup/flowergirl/features/vendor';
import { Observable, combineLatest, of } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import {
  map,
  filter,
  takeUntil,
  distinctUntilChanged,
  switchMap,
  first,
  tap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';
import {
  CustomerEvent,
  Category,
  ContextService,
  VendorResearchService,
  CustomerEventService,
  uiTrigger1,
} from '@skylitup/flowergirl/core';

@Component({
  selector: 'flrgl-vendor-research',
  templateUrl: 'vendor-research.component.html',
  styleUrls: ['vendor-research.component.scss'],
})
export class VendorResearchComponent extends VendorResearchBaseComponent {
  customerEvent$: Observable<CustomerEvent>;
  researchCategory$: Observable<Category>;
  isComparePrices$: Observable<boolean>;
  showToCustomerControl = new FormControl();

  constructor(
    public context: ContextService,
    public vendorResearchService: VendorResearchService,
    public router: Router,
    private store: Store,
    public customerEventService: CustomerEventService
  ) {
    super();
    this.customerEvent$ = context.customerEvent$;
    this.researchCategory$ = this.context.researchCategory$;
    combineLatest([
      this.researchCategory$.pipe(filter((o) => !!o)),
      this.customerEvent$.pipe(filter((o) => !!o)),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(
          ([cat, customerEvent]) =>
            customerEvent.hideVrMap[cat.id] === undefined ||
            !customerEvent.hideVrMap[cat.id]
        ),
        distinctUntilChanged()
      )
      .subscribe((show) =>
        this.showToCustomerControl.setValue(show, { emitEvent: false })
      );

    this.showToCustomerControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        switchMap((show: boolean) =>
          of(show).pipe(
            switchMap((_) =>
              combineLatest([
                this.researchCategory$.pipe(filter((o) => !!o)),
                this.customerEvent$.pipe(filter((o) => !!o)),
              ])
            ),
            first(),
            switchMap(([cat, customerEvent]) => {
              const data = { ...customerEvent.data };
              if (!data.hideVrMap) {
                data.hideVrMap = {};
              } else {
                data.hideVrMap = { ...data.hideVrMap };
              }
              data.hideVrMap[cat.id] = !show;
              return customerEventService.update(data);
            })
          )
        )
      )
      .subscribe();
  }
  get isComparePrices() {
    return this.router.url.includes('compare-prices');
  }
  trigger1() {
    this.store.dispatch(uiTrigger1({ trigger: 'closeShowcase' }));
  }
}
