<ng-container *ngIf="auth$ |async as auth">
    <mat-card fxLayout="column" *ngIf="vendor" class="pointer1"
        [ngClass]="{'in-showcase':inShowCase, 'social-names':socialNames}"
        (click)="showFullCard() || (!inShowCase && rippler.launch()) || true" mat-ripple #rippler="matRipple"
        [matRippleDisabled]="true" [matRippleCentered]="true">
        <mat-card-content fxFlex="grow">
            <div class="top">
                <div class="row1" fxLayout="row" fxLayoutAlign="space-between center">
                    <ng-container *ngIf="editable">
                        <button mat-icon-button [routerLink]="['vendor',vendor.id]" matTooltip="Edit Vendor"
                            *auth1="['planner']">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </ng-container>
                    <div *ngIf="!inShowCase;else inShowCaseName" class="name center1" mat-button>
                        {{ vendor.getControlledName(customerId,auth) }}
                    </div>
                    <ng-template #inShowCaseName>
                        <div class="showcasename">
                            {{ vendor.getControlledName(customerId,auth) }}
                        </div>
                    </ng-template>
                    <button *ngIf="!vendor.isMasked(customerId,auth)" mat-button
                        (click)="$event.stopPropagation() || showContactsInfo($event)" matTooltip="Show Contacts"
                        class="contacts-button" [ngClass]="{'in-showcase':inShowCase}">
                        <span *ngIf="inShowCase" class="m-r-4">Contacts</span>
                        <mat-icon class="m-r-2">email</mat-icon>
                        <mat-icon class="m-r-2">smartphone</mat-icon>
                        <mat-icon class="m-r-0">phone</mat-icon>
                    </button>
                </div>
                <div class="row2">
                    <a *ngIf="!vendor.isMasked(customerId,auth) && vendor.data?.links?.www"
                        (click)="clickExternal(vendor.data.links.www)" mat-button class="p-x-10 www"
                        matTooltip="Website">
                        <span *ngIf="inShowCase" class="m-r-6"> Website</span>
                        <mat-icon class="m-r-2">link</mat-icon>
                    </a>
                    <div *ngIf="!inShowCase;else emptySpace" class="name" fxFlex="grow" mat-button>
                    </div>
                    <ng-template #emptySpace>
                        <div fxFlex="grow"></div>
                    </ng-template>
                    <a *ngIf="!vendor.isMasked(customerId,auth) &&  vendor.data?.links?.instagram"
                        (click)="clickExternal(vendor.data.links.instagram)" mat-button matTooltip="Instagram">
                        <span *ngIf="socialNames" class="m-r-6"> Instagram</span>
                        <mat-icon fontSet="fab" fontIcon="fa-instagram"></mat-icon>
                    </a>
                    <a *ngIf="!vendor.isMasked(customerId,auth) && vendor.data?.links?.facebook"
                        (click)="clickExternal(vendor.data.links.facebook)" mat-button matTooltip="Facebook">
                        <span *ngIf="socialNames" class="m-r-6"> Facebook</span>
                        <mat-icon fontSet="fab" fontIcon="fa-facebook-square"> </mat-icon>
                    </a>
                    <a *ngIf="!vendor.isMasked(customerId,auth) && vendor.data?.links?.twitter"
                        (click)="clickExternal(vendor.data.links.twitter)" mat-button matTooltip="Twitter">
                        <span *ngIf="socialNames" class="m-r-6"> Twitter</span>
                        <mat-icon fontSet="fab" fontIcon="fa-twitter"></mat-icon>
                    </a>
                    <a *ngIf="!vendor.isMasked(customerId,auth) && vendor.data?.links?.pinterest"
                        (click)="clickExternal(vendor.data.links.pinterest)" mat-button matTooltip="Pinterest">
                        <span *ngIf="socialNames" class="m-r-6"> Pinterest</span>
                        <mat-icon fontSet="fab" fontIcon="fa-pinterest-square"></mat-icon>
                    </a>
                    <a *ngIf="!vendor.isMasked(customerId,auth) && vendor.data?.links?.youtube"
                        (click)="clickExternal(vendor.data.links.youtube)" mat-button matTooltip="Youtube">
                        <span *ngIf="socialNames" class="m-r-6"> Youtube</span>
                        <mat-icon fontSet="fab" fontIcon="fa-youtube-square"></mat-icon>
                    </a>
                </div>
                <div class="p-x-8" fxLayout="row" fxLayoutAlign="start end">
                    <div fxFlex="grow" *ngIf="withFilters;else catsNoFilters">#[
                        <span *ngFor="let cat of cats;let i=index"> <span
                                (click)="$event.stopPropagation() || catclick.next(cat)"
                                matTooltip="Filter by {{cat.name}} category"
                                class="cat1 clickable1 underline1">{{cat.name}}</span>
                            <span *ngIf="(i<(cats.length-1))">, </span></span>
                        ]
                    </div>
                    <ng-template #catsNoFilters>
                        <div fxFlex="grow">#[
                            <span *ngFor="let cat of cats;let i=index"> <span class="cat1">{{cat.name}}</span>
                                <span *ngIf="(i<(cats.length-1))">, </span></span>
                            ]
                        </div>
                    </ng-template>
                    <button *ngIf="priceLevel" [ngClass]="{highlight:highlightKey=='priceLevel'}"
                        (click)="$event.stopPropagation() ||  sort({config:{label:'Price Level',type:'number'},key:'priceLevel'},['priceLevel'])"
                        mat-button fxFlex="none" class="price-level param1" matTooltip="Price Level">
                        {{priceLevel}}
                    </button>
                </div>
                <div class="row2 locations">
                    <ng-container *ngIf="inShowCase;else mapLinkCompact">
                        <button *ngIf="!vendor.isMasked(customerId,auth)" mat-button
                            (click)="$event.stopPropagation() || showMap()" matTooltip="Map" class="p-x-4 address">
                            Map
                            <mat-icon class="m-r-2" [ngClass]="{hasMap:vendor.effectiveMapCenter}">location_on
                            </mat-icon>
                        </button>
                    </ng-container>
                    <ng-template #mapLinkCompact>
                        <button *ngIf="!vendor.isMasked(customerId,auth)" mat-icon-button
                            (click)="$event.stopPropagation() || showMap()" matTooltip="Map" class="p-x-4 address">
                            <mat-icon class="m-r-2" [ngClass]="{hasMap:vendor.effectiveMapCenter}">location_on
                            </mat-icon>
                        </button>
                    </ng-template>

                    <ng-container *ngIf="withFilters;else locsNoFilters">
                        <ng-container *ngFor="let loc of vendor.locations;let i=index">
                            <button mat-button (click)="$event.stopPropagation() ||  locationClick.next(loc)"
                                matTooltip="Filter by location: {{loc.name}} " class="loc">
                                {{loc.name}}
                            </button>
                            <span *ngIf="i<(vendor.locations.length-1)">, </span>
                        </ng-container>
                    </ng-container>
                    <ng-template #locsNoFilters>
                        <ng-container *ngFor="let loc of vendor.locations;let i=index">
                            <span class="loc">
                                {{loc.name}}
                            </span>
                            <span *ngIf="i<(vendor.locations.length-1)">, </span>
                        </ng-container>
                    </ng-template>

                    <ng-container *ngIf="withFilters;else coverageNoFilters">
                        <ng-container *ngIf="!!vendor.coverage.length">
                            <mat-icon class="m-l-10" class="coverage-area" fontSet="fas" fontIcon="fa-map"
                                matTooltip="Cover area"> </mat-icon>
                        </ng-container>
                        <ng-container *ngFor="let loc of vendor.coverage;let i=index">
                            <button mat-button (click)="$event.stopPropagation() ||  coverageClick.next(loc)"
                                matTooltip="Filter by coverage area: {{loc.name}}" class="loc">
                                {{loc.name}}
                            </button>
                            <span *ngIf="i<(vendor.coverage.length-1)">, </span>
                        </ng-container>
                    </ng-container>
                    <ng-template #coverageNoFilters>
                        <ng-container *ngFor="let loc of vendor.coverage;let i=index">
                            <span class="loc">
                                {{loc.name}}
                            </span>
                            <span *ngIf="i<(vendor.coverage.length-1)">, </span>
                        </ng-container>
                    </ng-template>
                </div>
                <mat-divider></mat-divider>
                <!-- (click)="sortByNumber('Price Level',['priceLevel'],'priceLevel')"  -->

                <div fxLayout="row wrap" class="specific-param" fxLayoutGap="4px">
                    <ng-container *ngFor="let val of dynamicFieldValues">
                        <ng-container *ngIf="val.type === 'bool' && val.value">
                            <button *ngIf="asBoolField(val) as bool" [ngClass]="{highlight:highlightKey==bool.key}"
                                mat-button fxFlex="none" class="param1" matTooltip="sort by {{bool.config.label}}"
                                (click)="$event.stopPropagation() || sort(bool,[bool.cat,bool.key])">
                                <mat-icon *ngIf="bool.config.icon" [fontSet]="bool.config.icon.fontSet"
                                    [fontIcon]="bool.config.icon.fontIcon" class="m-x-3">
                                </mat-icon> {{bool.config.label}}
                            </button>
                        </ng-container>
                        <ng-container *ngIf="val.type === 'number' && val.value">
                            <button *ngIf="asNumberField(val) as num" [ngClass]="{highlight:highlightKey==num.key}"
                                mat-button fxFlex="none" class="param1" matTooltip="sort by {{num.config.label}}"
                                (click)="$event.stopPropagation() || sort(num,[num.cat,num.key])">
                                <mat-icon *ngIf="num.config.icon" [fontSet]="num.config.icon.fontSet"
                                    [fontIcon]="num.config.icon.fontIcon" class="m-x-3">
                                </mat-icon> {{num.value}} {{numValueUnits(num)}} {{num.config.label}}
                            </button>
                        </ng-container>
                        <ng-container *ngIf="val.type === 'select' && val.value">
                            <button *ngIf="asSelectField(val) as select1"
                                [ngClass]="{highlight:highlightKey==select1.key}" mat-button fxFlex="none"
                                class="param1" matTooltip="sort by {{select1.config.label}}"
                                (click)="$event.stopPropagation() || sort(select1,[select1.cat,select1.key])">
                                <mat-icon *ngIf="select1.config.icon" [fontSet]="select1.config.icon.fontSet"
                                    [fontIcon]="select1.config.icon.fontIcon" class="m-x-3">
                                </mat-icon> {{select1.value.name}} <span *ngIf="!select1.config.hideLabelInVal">
                                    {{select1.config.label}}</span>
                            </button>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</ng-container>