import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '@skylitup/base/util';
import { CustomerEvent } from '@skylitup/flowergirl/core';
import * as moment from 'moment';

@Component({
  selector: 'flrgl-checklist-calendar',
  templateUrl: './checklist-calendar.component.html',
  styleUrls: ['./checklist-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistCalendarComponent extends BaseComponent implements OnInit, OnChanges {
  @Input()
  customerEvent: CustomerEvent;
  @Output()
  dateSelected = new EventEmitter();
  @Output()
  dateHover = new EventEmitter<Date>();
  @Output()
  closed = new EventEmitter();
  start = moment().startOf('month').toDate();
  months = [];
  eventInfo = [];
  constructor() {
    super();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    {
      if (changes.customerEvent && this.customerEvent?.info) {
        const eventDate = moment(this.customerEvent.info.earliestDate).startOf('day');
        const today = moment().startOf('day');
        let end = moment(eventDate);
        end.add(6, 'months');
        if (end.diff(today, 'days') < 0) {
          end = moment(today).add(6, 'months');
        }
        const n = end.diff(this.start, 'months');
        this.months = [...new Array(n)].map((_, i) => i);
        this.eventInfo = [{date:this.customerEvent.info.earliestDate,name:this.customerEvent.info.name,eventid:this.customerEvent.id}];
      }
    }
  }

  ngOnInit(): void {
  }

}
