<ng-container *ngIf="agencyInContext$ | async as agency">
    <ng-container *ngIf="auth$ | async as auth">
        <mat-drawer-container cdkDropListGroup *ngIf="customerEvent$|async as customerEvent">
            <mat-drawer-content>
                <div fxLayout="row" fxLayoutAlign="end center" class="hide-toggle">
                    <flrgl-go-back align="start" [path]="['..']" *ngIf="paymentsOnly">Back To Budget</flrgl-go-back>
                    <div fxFlex="grow" style="height:26px"></div>
                    <mat-slide-toggle class="example-margin" color="primary" [checked]="hideCompletedItems"
                        (change)="hideCompleted( $event.checked)">
                        <!-- [color]="color" [checked]="checked" [disabled]="disabled" -->
                        Hide Completed
                    </mat-slide-toggle>
                    <button mat-icon-button *auth1="['planner']" (click)="drawer.toggle()">
                        <mat-icon>date_range</mat-icon>
                    </button>
                </div>
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let cat of customerEvent.categories;trackBy:trackByIdFn" #panel1
                        (opened)="focusedOnCat(cat)" [expanded]="(focusCat$ |async)==cat.id">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <ng-container *ngIf="!paymentsOnly;else catHeaderWithMoney">
                                    {{ cat.name }}
                                </ng-container>
                                <ng-template #catHeaderWithMoney>
                                    <flrgl-budget-header [name]="cat.name" [showNumbers]="!panel1.expanded"
                                        [actualBudget]="customerEvent.getCatBudget(cat.id)"
                                        [paymentsPaid]="customerEvent.getCatPaid(cat.id)"
                                        [paymentsPending]="customerEvent.getCatPaymentPending(cat.id)">
                                    </flrgl-budget-header>
                                </ng-template>
                            </mat-panel-title>
                            <ng-container *ngIf="!paymentsOnly">
                                <ng-container *ngIf="customerEvent.getHighlightedChecklistItem(cat.id) as highlight">
                                    <mat-panel-description fxLayoutAlign="end center" *ngIf="!highlight.done">
                                        <div *ngIf="!highlight.isPayment;else paymentTitle" class="highlightedItem"
                                            fxLayoutAlign="end center">
                                            <span>{{highlight.name}}</span>
                                        </div>
                                        <ng-template #paymentTitle>
                                            <div class="highlightedItem"
                                                *ngIf="highlight.asPayment as highlightPayment">
                                                <span (click)="editMode=true">
                                                    {{highlightPayment.amount | currency:'EUR':'symbol'}} -
                                                    {{highlightPayment.paymentMethod=='cash'?'Cash':'Bank'}}
                                                    Payment
                                                </span>
                                            </div>
                                        </ng-template>
                                    </mat-panel-description>
                                </ng-container>
                            </ng-container>
                        </mat-expansion-panel-header>

                        <ng-template matExpansionPanelContent>

                            <ng-container *ngIf="paymentsOnly;else normalChecklist">
                                <ng-container
                                    *ngFor=" let vendorCat of customerEvent.getVendorCats(cat.id);trackBy:trackByIdFn">
                                    <div *ngIf="vendorCat.items.length" class="vendor-container mat-elevation-z2 ">

                                        <flrgl-budget-header-expanded
                                            [name]='vendorCat.getControlledName(customerEvent?.customerId,auth)'
                                            [actualBudget]=" vendorCat.money.budget"
                                            [paymentsPaid]="vendorCat.money.paid"
                                            [paymentsPending]="vendorCat.money.pendingPayment"
                                            [extraCost]="vendorCat.money.extraCost">
                                            {{vendorCat.getControlledName(customerEvent?.customerId,auth)||'(Unassigned)'}}
                                            <mat-icon *ngIf="vendorCat.hasVendor">store
                                            </mat-icon>
                                        </flrgl-budget-header-expanded>
                                        <mat-divider></mat-divider>

                                        <div *ngIf="vendorCat.payments.length" #itemsList="cdkDropList" cdkDropList>
                                            <div *ngFor="let item of vendorCat.payments; trackBy:trackByIdFn"
                                                [cdkDragDisabled]="!drawer.opened" (cdkDragEnded)="dateHover=null"
                                                [cdkDragData]="item" cdkDrag>

                                                <ng-container *ngIf="customerEvent.getVendors(cat.id) as vendors">
                                                    <flrgl-checklist-item *ngIf="!hideCompletedItems || !item.done"
                                                        [item]="item"
                                                        (itemChange)="updateItem(customerEvent,$event,item)"
                                                        (dueDateClick)="drawer.toggle()" (deleted)="deleteItem(item)"
                                                        [bg2]="item.dueDate.getTime()<dateHover?.getTime()">
                                                    </flrgl-checklist-item>
                                                </ng-container>

                                                <div *cdkDragPreview>
                                                    <mat-card class="drag-preview" fxLayout="row">
                                                        <div fxFlex="grow" fxLayoutAlign="center">...</div>
                                                        <mat-icon fxFlex="none">today</mat-icon>
                                                    </mat-card>
                                                </div>
                                                <div class="placeholder1" *cdkDragPlaceholder></div>
                                            </div>
                                        </div>
                                        <mat-card class="budget extra">
                                            <div fxLayout="row" fxLayoutAlign="end center">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Other Costs </mat-label>
                                                    <input matInput class="right1"
                                                        [ngModel]="vendorCat.money.extraCost | currency:'EUR':'symbol'"
                                                        readonly>
                                                </mat-form-field>
                                            </div>
                                        </mat-card>
                                        <ng-container *auth1="['planner']">
                                            <flrgl-commission-item [editable]="false" *ngIf="vendorCat.hasVendor"
                                                [commissionRate]="customerEvent.getCommissionRate(vendorCat.vendorId)"
                                                [commissionAmount]="customerEvent.getCommissionAmount(vendorCat.vendorId)"
                                                [commissionPaid]="customerEvent.getCommissionPaid(vendorCat.vendorId)"
                                                [commissionData]="customerEvent.getCommissionData(vendorCat.vendorId)">
                                            </flrgl-commission-item>
                                        </ng-container>

                                    </div>
                                </ng-container>
                            </ng-container>


                            <ng-template #normalChecklist>
                                <div *ngIf="customerEvent.checklistCatMap[cat.id] as checklist">
                                    <div *ngIf="checklist.items.length" #itemsList="cdkDropList" cdkDropList>
                                        <div *ngFor="let item of checklist.itemsOrPayments(paymentsOnly); trackBy:trackByIdFn"
                                            [cdkDragDisabled]="!drawer.opened" (cdkDragEnded)="dateHover=null"
                                            [cdkDragData]="item" cdkDrag>

                                            <ng-container *ngIf="customerEvent.getVendors(cat.id) as vendors">
                                                <flrgl-checklist-item *ngIf="!hideCompletedItems || !item.done"
                                                    [item]="item" (itemChange)="updateItem(customerEvent,$event,item)"
                                                    (dueDateClick)="drawer.toggle()"
                                                    [bg2]="item.dueDate.getTime()<dateHover?.getTime()"
                                                    (deleted)="deleteItem(item)">
                                                </flrgl-checklist-item>
                                            </ng-container>

                                            <div *cdkDragPreview>
                                                <mat-card class="drag-preview" fxLayout="row">
                                                    <div fxFlex="grow" fxLayoutAlign="center">...</div>
                                                    <mat-icon fxFlex="none">today</mat-icon>
                                                </mat-card>
                                            </div>
                                            <div class="placeholder1" *cdkDragPlaceholder></div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-container *auth1="['planner']">
                                <ng-container *ngIf="customerEvent.getVendors(cat.id) as vendors">
                                    <flrgl-checklist-item-create *ngIf="!paymentsOnly || vendors.length"
                                        [vendors]="vendors" [forcePayment]="paymentsOnly"
                                        [customerEvent]="customerEvent"
                                        (itemSubmitted)="createItem(customerEvent,cat,$event)" [agency]='agency'>
                                    </flrgl-checklist-item-create>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-drawer-content>
            <mat-drawer #drawer mode='side' position='end' [opened]="false">
                <flrgl-checklist-calendar *ngIf="viewInitDone && drawer.opened;else loadingCal"
                    [customerEvent]="customerEvent" (dateSelected)="selectedDate($event,customerEvent)"
                    #checklistCalendar (dateHover)="dateHover=$event" (closed)="drawer.close()">
                </flrgl-checklist-calendar>
                <ng-template #loadingCal>
                    <i class="fa fa-cog fa-spin fa-3x fa-fw" aria-hidden="true"></i>
                    <span class="sr-only"></span> Loading. Hang tight!
                </ng-template>
            </mat-drawer>
        </mat-drawer-container>
    </ng-container>
</ng-container>