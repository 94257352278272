import { APP_BASE_HREF, CommonModule } from '@angular/common';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
// libs
// app
import { RouterModule } from '@angular/router';
import { PersistenceResultHandler } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import {
  FirebaseStorageDataService, FirestoreEntityDataServiceProvider,
  LocalStorageService, PersistenceResultHandlerExtended,
  WebFirebaseStorageDataService, WebFirestoreEntityDataServiceProvider,
  WebLocalStorageService
} from '@skylitup/base/ngrx-data-fire';
import {
  BaseUtilModule, CustomRouterSerializer, FlrglRouterState,
  PlatformLanguageToken,
  PlatformWindowToken, throwIfAlreadyLoaded
} from '@skylitup/base/util';
import { AuthService } from './domain-model/auth/auth.service';
import { AuthEffects } from './domain-model/auth/state/auth.effects';
import { authReducer, AuthState } from './domain-model/auth/state/auth.reducer';
import { WebAuthService } from './domain-model/auth/web-auth.service';
import { ChatTrackEffects } from './domain-model/chat-track/chat-track.effect';
import { ChatEffects } from './domain-model/chat/chat.effects';
import { ChecklistItemEffects } from './domain-model/checklist-item/checklist-item.effects';
import { CustomerEventEffects } from './domain-model/customer-event/customer-event.effects';
import { CustomerEffects } from './domain-model/customer/customer.effects';
import { EventItemEffects } from './domain-model/event-item/event-item.effects';
import { MediaEffects } from './domain-model/upload/media/media.effects';
import { UiEffects } from './domain-model/ui/state/ui.effects';
import { uiReducer, UIState } from './domain-model/ui/state/ui.reducer';
import { UserCardEffects } from './domain-model/user-card/user-card.effects';
import { UserTrackEffects } from './domain-model/user-track/user-track-effects';
import { VendorResearchEffects } from './domain-model/vendor-research/vendor-research.effects';
import { VendorEffects } from './domain-model/vendor/vendor.effects';
import { FlrglEntityDataModule } from './entity-config';
import { InitEffects } from './init/state/init.effects';
import { NotificationService } from './util/services/notification.service';
import { WebNotificationService } from './util/services/web-notification.service';
import { clearState } from './util/state/clear-state';
import { DocEffects } from './domain-model/upload/doc/doc.effects';

export const BASE_PROVIDERS: any[] = [
  {
    provide: APP_BASE_HREF,
    useValue: '/',
  },
];

export interface AppState {
  auth: AuthState;
  ui: UIState;
  router: FlrglRouterState;
}
export function winFactory() {
  return window;
}

export function platformLangFactory() {
  const browserLang = window.navigator.language || 'en'; // fallback English
  // browser language has 2 codes, ex: 'en-US'
  return browserLang.split('-')[0];
}
@NgModule({
  imports: [
    CommonModule, BaseUtilModule,
    RouterModule.forRoot([], { initialNavigation: 'enabled' }),
    StoreModule.forRoot(
      { auth: authReducer, router: routerReducer, ui: uiReducer },
      { metaReducers: [clearState] }
    ),
    EffectsModule.forRoot([
      InitEffects,
      AuthEffects,
      UserCardEffects,
      CustomerEffects,
      CustomerEventEffects,
      EventItemEffects,
      VendorEffects,
      ChecklistItemEffects,
      ChatEffects,
      ChatTrackEffects,
      UiEffects,
      MediaEffects,
      VendorResearchEffects,
      UserTrackEffects,
      DocEffects
    ]),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterSerializer,
      // routerState: RouterState.Minimal
    }),
    FlrglEntityDataModule,
  ],
  providers: [
    {
      provide: PersistenceResultHandler,
      useClass: PersistenceResultHandlerExtended,
    },

    {
      provide: PlatformLanguageToken,
      useFactory: platformLangFactory,
    },
    {
      provide: PlatformWindowToken,
      useFactory: winFactory,
    },
    {
      provide: FirestoreEntityDataServiceProvider,
      useClass: WebFirestoreEntityDataServiceProvider,
    },
    {
      provide: FirebaseStorageDataService,
      useClass: WebFirebaseStorageDataService,
    },
    {
      provide: LocalStorageService,
      useClass: WebLocalStorageService,
    },
    {
      provide: AuthService,
      useClass: WebAuthService,
    },
    {
      provide: NotificationService,
      useClass: WebNotificationService,
    },
  ],
  exports: [CommonModule],
})
export class FlowergirlCoreModule {
  // configuredProviders: *required to configure WindowService and others per platform
  static forRoot(
    configuredProviders: Array<any>
  ): ModuleWithProviders<FlowergirlCoreModule> {
    return {
      ngModule: FlowergirlCoreModule,
      providers: [...configuredProviders],
    };
  }
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: FlowergirlCoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'BaseCoreModule'); // ensure default platform language is set
  }
}
