import { BaseComponent } from '@skylitup/base/util';
import { Directive } from '@angular/core';
@Directive()
export abstract class ChatThumbBaseComponent extends BaseComponent {
  public text = 'ChatThumb';

  constructor() {
    super();
  }
}
