<ng-container *ngIf="customerId$ | async as customerId">
    <ng-container *ngIf="auth$ | async as auth">
        <div class="outer" fxLayout="column">
            <div fxLayout="row">

                <flrgl-rating fxFlex="none" class="p-x-15" [rating]="vendorResearch.rating" matTooltip="Select a rating"
                    (ratingChange)="ratingChanged($event)"> </flrgl-rating>

                <button mat-icon-button class="clickable-icon1" (click)="ratingSortClick.next($event)">
                    <mat-icon fontSet="fas" fontIcon="fa-sort-amount-down">
                    </mat-icon>
                </button>
                <div fxFlex="grow" fxLayoutAlign="start center">{{vendorResearch.getControlledName(customerId,auth)}}
                </div>
                <!-- class="center1" -->

            </div>
            <flrgl-chat-header [dialogObj]="vendorResearch" inItemDetails="true"></flrgl-chat-header>
            <mat-divider></mat-divider>
            <ng-container *ngIf="chatTrack$ | async as chatTrack">

                <flrgl-chat-portal [dialog]="vendorResearch.dialog" [event]="vendorResearch.event" [auth]="auth"
                    [chatTrack]="chatTrack" fxFlex="grow">
                </flrgl-chat-portal>
            </ng-container>
        </div>
    </ng-container>
</ng-container>