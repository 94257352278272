<mat-card>
    <div fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutAlign="center center" class='m-x-10'>
        <div class="title " fxFlex="grow">
            A Part
            <!-- - {{item.id}} -->
        </div>
        <!-- <div fxFlex="grow"></div> -->

        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field appearance="outline" class="total"
                [ngClass]="{ 'pending':commissionAmount!==commissionPaid, 'paid':commissionAmount===commissionPaid, 'color-warn':(commissionAmount<commissionPaid)}">
                <mat-label> Pending</mat-label>
                <input matInput class="right1" readonly
                    [value]="(commissionAmount-commissionPaid) | currency:'EUR':'symbol'">
            </mat-form-field>
            <mat-form-field appearance="outline" class="total paid">
                <mat-label> Paid </mat-label>
                <input matInput class="right1" readonly [value]="(commissionPaid | currency:'EUR':'symbol')">
            </mat-form-field>
        </div>
        <button mat-icon-button class="func m-l-5" matTooltip="Payments Received" *ngIf="editable"
            [ngClass]="{'has-payments-received': !!commissionData?.paymentsReceived}" (click)="editPaymentsReceived()">
            <mat-icon>
                check
            </mat-icon>
        </button>
        <div fxFlex="grow"></div>

        <ng-template #func>
            <button mat-icon-button class="func m-r-15" (click)="editCalculation()" *ngIf="editable;else emptyFunc">
                <mat-icon>
                    functions
                </mat-icon>
            </button>
            <ng-template #emptyFunc>
                <div class='m-x-30'></div>
            </ng-template>
        </ng-template>

        <ng-container *ngIf="commissionData.type=='rate' && commissionRate ;else withoutRate">
            <ng-container *ngTemplateOutlet="func"></ng-container>
            <div fxLayout="row" class="calculation">
                <mat-form-field appearance="outline">
                    <mat-label>Rate</mat-label>
                    <input matInput class="right1" readonly [value]="commissionRate + '%'">
                </mat-form-field>
                <span fxFlexAlign="center" class="equals m-x-10">=</span>
            </div>
        </ng-container>
        <ng-template #withoutRate>
            <div fxLayout="row" fxLayoutGap="10px" class="calculation">
                <div class="empty1"></div>
            </div>
            <ng-container *ngTemplateOutlet="func"></ng-container>
        </ng-template>
        <mat-form-field appearance="outline" class="budget">
            <!-- <mat-label>Budget</mat-label> -->
            <input matInput class="right1" readonly [value]="commissionAmount | currency:'EUR':'symbol'">
        </mat-form-field>
    </div>
</mat-card>