import { EntityKeys } from "./entity.model.data";
import {
  EntityMetadataMap,
  EntityDataModuleConfig,
  EntityDataService,
  EntityDataModule,
} from "@ngrx/data";
import { InjectionToken, Provider, Injector } from "@angular/core";
import { FirestoreEntityDataServiceProvider } from "../db/firestore-entity-data-service-provider.service";
import { ENTITY_KEY_MAP_PLURAL } from "../db/xplat/web/firestore-entity-data.service";

export class EntityConfigModuleGenerator {
  providers: Provider[];
  entityConfig: EntityDataModuleConfig;
  TOKENS: [string, InjectionToken<any>][];

  constructor(keys: EntityKeys[]) {
    const ENTITY_KEY_MAP_PLURAL1 = keys.reduce(
      (r, n) => ((r[n.plural] = n) && r) || r,
      {}
    );
    const entityMetadata: EntityMetadataMap = keys.reduce((r, n) => {
      r[n.plural] = { selectId: (a) => a.meta.id };
      return r;
    }, {});
    const pluralNames = keys.reduce((r, n) => {
      r[n.plural] = n.plural;
      return r;
    }, {});
    this.entityConfig = {
      entityMetadata,
      pluralNames,
    };
    // console.log(JSON.stringify(entityConfig));
    this.TOKENS = keys.map((n) => [n.plural, new InjectionToken(n.plural)]);

    const providers1 = this.TOKENS.map((t) => ({
      provide: t[1],
      useFactory: (
        entityDataServiceProvider: FirestoreEntityDataServiceProvider
      ) => entityDataServiceProvider.getNewDataService(t[0]),
      deps: [FirestoreEntityDataServiceProvider],
    }));
    this.providers = [
      ...providers1,
      {
        provide: ENTITY_KEY_MAP_PLURAL,
        useValue: ENTITY_KEY_MAP_PLURAL1,
      },
    ];
  }
  registerServices(injector: Injector, entityDataService: EntityDataService) {
    const s = {};
    for (const t of this.TOKENS) {
      s[t[0]] = injector.get(t[1]);
    }
    entityDataService.registerServices(s);
  }
}
