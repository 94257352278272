import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceElementsFactory,
  EntityDataService,
} from '@ngrx/data';
import { createSelector } from '@ngrx/store';
import {
  EntityBaseSelectors,
  EntityBaseService,
} from '@skylitup/base/ngrx-data-fire';
import { Observable } from 'rxjs';
import { AGENCY_KEYS } from '../agency/agency.model.data';
import { CUSTOMER_EVENT_KEYS } from '../customer-event/customer-event.model.data';
import { VendorCat } from '../customer-event/vendor-cat.model';
import { CUSTOMER_KEYS } from '../customer/customer.model.data';
import { MediaService } from '../upload/media/media.service';
import { EventItem } from './event-item.model';
import { EventItemData, EVENT_ITEM_KEYS } from './event-item.model.data';
import { projectSpecMedia } from './event-item.model.fn';

interface SelectorsProjected
  extends EntityBaseSelectors<EventItemData, EventItem> { }

@Injectable({ providedIn: 'root' })
export class EventItemService extends EntityBaseService<
EventItemData,
EventItem
> {
  public selectorsProjected: SelectorsProjected;
  constructor(
    entityServiceFactory: EntityCollectionServiceElementsFactory,
    entityDataServices: EntityDataService,
    private mediaService: MediaService
  ) {
    super(EVENT_ITEM_KEYS, entityServiceFactory, entityDataServices);
    this.init();
  }
  setupRxSelectors() {
    return createSelector(
      this._selectPlainEntityTuple,
      this.mediaService.selectorsProjected.selectEntityMap,
      projectSpecMedia
    );
  }
  setupRx$() { }
  protected newProjectedEntity(d: EventItemData) {
    return new EventItem(d);
  }
  createEventItem(
    vendorCat: VendorCat,
    name: string,
    sortIndex: number
  ): Observable<EventItemData> {
    const event = vendorCat.event;

    const data: EventItemData = {
      name,
      meta: {
        collectionPath: [...event._fullPath, EVENT_ITEM_KEYS.plural],
        id: null,
      },
      agencyId: event.agencyId,
      specMediaIds: [],
      catId: vendorCat.id,
      vendorId: vendorCat.hasVendor ? vendorCat.vendorId : null,
      spec: null,
      calcCustomNumber: 0,
      calcType: 'none',
      calcItemPrice: 0,
      sortIndex,
      _del: null,
      customerType: event.data.customerType,
      //
      done: false,
      dueDate: vendorCat?.event?.info.earliestDate,
      doneDate: null,
      assigneeId: null,
      flagged: false,
      na: false
    };
    return this.upsert(data);
  }
  syncEventItems$(agencyId, customerId, customerEventId) {
    return this.syncQueryRealtime$([
      AGENCY_KEYS.plural,
      agencyId,
      CUSTOMER_KEYS.plural,
      customerId,
      CUSTOMER_EVENT_KEYS.plural,
      customerEventId,
      EVENT_ITEM_KEYS.plural,
    ]);
  }

  syncBigPlan$(agencyId: string) {
    return this.syncQueryRealtimeGroupCollection$(EVENT_ITEM_KEYS.plural, {
      queryFn: [
        (q) =>
          q
            .where('agencyId', '==', agencyId)
            .where('customerType', 'in', ['client', 'other']),
        (q) =>
          q
            .where('agencyId', '==', agencyId)
            .where('customerType', 'in', ['client', 'other']),
      ],
      replaceState: false,
    });
  }
}