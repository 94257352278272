import { Inject, Injectable } from '@angular/core';
import { EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { Dictionary } from '@ngrx/entity';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { EntityBaseSelectors, EntityBaseService, entityDate } from '@skylitup/base/ngrx-data-fire';
import { ENV, Environment } from '@skylitup/base/util';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AGENCY_KEYS } from '../data-index';
import { MailNotificationsTrack } from './mail-notifications-track.model';
import { MailNotificationsTrackData, MAIL_NOTIFICATION_KEYS } from './mail-notifications-track.model.data';


interface SelectorsProjected
    extends EntityBaseSelectors<MailNotificationsTrackData, MailNotificationsTrack> {
    selectUserTrackByUidMap: MemoizedSelector<any, Dictionary<MailNotificationsTrack>>;
}

@Injectable({ providedIn: 'root' })
export class MailNotificationsTrackService extends EntityBaseService<MailNotificationsTrackData, MailNotificationsTrack> {


    public selectorsProjected: SelectorsProjected;
    constructor(
        entityServiceFactory: EntityCollectionServiceElementsFactory,
        entityDataServices: EntityDataService,
        @Inject(ENV) private env: Environment) {
        super(MAIL_NOTIFICATION_KEYS, entityServiceFactory, entityDataServices);
        this.init();
    }
    setupRxSelectors() {
        return this._selectPlainEntityTuple;
    }
    setupRx$() {
        // this.userTrackByUidMap$ = this.store.select(this.selectorsProjected.selectUserTrackByUidMap);
    }

    protected newProjectedEntity(d: MailNotificationsTrackData) {
        return new MailNotificationsTrack(d);
    }

    sync$(agencyId: string) {

        return this.syncQueryRealtime$(
            [
                AGENCY_KEYS.plural,
                agencyId,
                MAIL_NOTIFICATION_KEYS.plural,
            ]
        );
        // return this.syncDocRealtime$(
        //     [
        //         AGENCY_KEYS.plural,
        //         agencyId,
        //         MAIL_NOTIFICATION_KEYS.plural,
        //     ],
        //     uid
        // );
    }
}
