import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Vendor, VendorService } from '@skylitup/flowergirl/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'flrgl-vendor-showcase-popup',
  templateUrl: './vendor-showcase-popup.component.html',
  styleUrls: ['./vendor-showcase-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class VendorShowcasePopupComponent implements OnInit {
  vendor$: Observable<Vendor>
  constructor(@Inject(MAT_DIALOG_DATA) data: { vendorId: string; },
    vendorService: VendorService
  ) {
    this.vendor$ = vendorService.entityMapProjected$.pipe(map(m => m[data.vendorId]));
  }

  ngOnInit(): void {
  }

}
