import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from './notification.service';

@Injectable({ providedIn: 'root' })
export class WebNotificationService extends NotificationService {
  constructor(private toastr: ToastrService) {
    super();
  }
  error(hint: string, msg: string): void {
    this.toastr.error(hint, msg);
  }
  warning(hint: string, msg: string): void {
    this.toastr.warning(hint, msg);
  }
}
