import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '@skylitup/base/ngrx-data-fire';
import { ENV, getErrorMessageHandler } from '@skylitup/base/util';
import {
  Auth,
  AuthService,
  loginFail,
  loginWithEmailAndPassword,
  saveNewPassword,
  sendResetPasswordEmail,
  sendResetPasswordEmailFail,
  sendResetPasswordEmailSuccess,
  sendSignInLinkToEmail,
  sendSignInLinkToEmailFail,
  sendSignInLinkToEmailSuccess,
  signInWithEmailLink,
} from '@skylitup/flowergirl/core';
import { LoginBaseComponent } from '@skylitup/flowergirl/features/navigate';
import { Observable } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'flrgl-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent extends LoginBaseComponent
  implements AfterViewInit, OnInit {
  // @ViewChild('ngxAuth')
  // ngxAuth: AuthComponent;
  // providers = AuthProvider;
  // ============
  auth$: Observable<Auth>;
  getErrorMessage = getErrorMessageHandler;
  // 'yo@skylitup.com' ||
  email = new FormControl(null, [Validators.required, Validators.email]);
  pwdLoginMode = new FormControl('login');
  password = new FormControl(null, [
    Validators.required,
    Validators.minLength(8),
  ]);
  passwordConfirm = new FormControl(null);
  //
  emailSent = false;
  signingIn = false;
  resettingPassword = false;
  resetPasswordEmailSent = false;
  emailSentError = false;
  oobCode: any;
  loginError: string;
  constructor(
    @Inject(ENV) public env,
    private authService: AuthService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private actions: Actions,
    private cd: ChangeDetectorRef
  ) {
    super();
    this.auth$ = this.authService.auth$;
    this.auth$.pipe(takeUntil(this.destroy$)).subscribe((auth) => {
      if (auth.data.emailInStorage && auth.data.emailInStorage !== 'null') {
        this.email.setValue(auth.data.emailInStorage);
      }
      if (auth.isLoggedIn && (auth.isCustomer || auth.isPlanner)) {
        this.router.navigateByUrl('/agencies');
      }
    });
  }

  sendSignInLinkToEmail() {
    this.store.dispatch(sendSignInLinkToEmail({ email: this.email.value }));
    this.actions
      .pipe(
        ofType(sendSignInLinkToEmailSuccess),
        takeUntil(this.destroy$),
        first()
      )
      .subscribe(() => {
        this.emailSent = true;
        this.emailSentError = false;
        this.loginError = null;

        this.cd.markForCheck();
      });
    this.actions
      .pipe(
        ofType(sendSignInLinkToEmailFail),
        takeUntil(this.destroy$),
        first()
      )
      .subscribe(() => {
        this.emailSent = false;
        this.emailSentError = true;
        this.loginError = null;

        this.cd.markForCheck();
      });
  }

  sendResetPasswordLink() {
    this.store.dispatch(sendResetPasswordEmail({ email: this.email.value }));
    this.actions
      .pipe(
        ofType(sendResetPasswordEmailSuccess),
        takeUntil(this.destroy$),
        first()
      )
      .subscribe(() => {
        this.resetPasswordEmailSent = true;
        this.emailSentError = false;
        this.loginError = null;

        this.cd.markForCheck();
      });
    this.actions
      .pipe(
        ofType(sendResetPasswordEmailFail),
        takeUntil(this.destroy$),
        first()
      )
      .subscribe(() => {
        this.cd.markForCheck();
        this.resetPasswordEmailSent = false;
        this.emailSentError = true;
        this.loginError = null;
      });
  }

  ngOnInit(): void {
    const url = this.router.url;
    this.authService.isSignInWithEmailLink(url).pipe(first()).subscribe(issignin => {
      if (issignin) {
        this.signingIn = true;
        // console.log('yo', this.signingIn)

        this.auth$
          .pipe(
            filter((auth) => auth?.data.localstorageRead),
            map((auth) => auth?.data.emailInStorage),
            first()
          )
          .subscribe((email) => {
            if (!email) {
              email = window.prompt('Please provide your email for confirmation');
            }
            this.store.dispatch(signInWithEmailLink({ email, url }));
            this.actions
              .pipe(ofType(loginFail), takeUntil(this.destroy$), first())
              .subscribe((a) => {
                this.cd.markForCheck();
                this.loginError = a.reason;
                this.signingIn = false;

              });
          });
      } else {
        const mode = this.activatedRoute.snapshot.queryParams.mode;
        this.oobCode = this.activatedRoute.snapshot.queryParams.oobCode;
        if (mode === 'resetPassword') {
          this.resettingPassword = true;
          this.loginError = null;
          this.pwdLoginMode.setValue('reset');
        }
      }
    })

  }
  json(j) {
    return JSON.stringify(j, null, 2);
  }
  ngAfterViewInit(): void { }
  saveNewPassword() {
    if (this.password.value !== this.passwordConfirm.value) {
      this.passwordConfirm.setErrors({ nomatch: true });
    } else {
      this.store.dispatch(
        saveNewPassword({
          code: this.oobCode,
          password: this.password.value,
        })
      );
      this.loginError = null;
      this.signingIn = true;

      this.actions
        .pipe(ofType(loginFail), takeUntil(this.destroy$), first())
        .subscribe((a) => {
          this.cd.markForCheck();
          this.loginError = a.reason;
          this.signingIn = false;
        });
    }
  }
  signInWithEmailPassword() {
    this.email.markAsTouched();
    this.password.markAsTouched();
    if (this.email.valid && this.password.valid) {
      this.store.dispatch(
        loginWithEmailAndPassword({
          email: this.email.value,
          password: this.password.value,
        })
      );
      this.signingIn = true;

      this.loginError = null;
      this.actions
        .pipe(ofType(loginFail), takeUntil(this.destroy$), first())
        .subscribe((a) => {
          this.cd.markForCheck();
          this.loginError = a.reason;
          this.signingIn = false;
        });
    }
  }
  tabIndex() {
    if (this.resettingPassword) {
      return 1;
    }
  }
}
