<ng-container *ngIf="customer$ | async as customer">
    <ng-container *ngIf="chatTrackMap$ | async as chatTrackMap">
        <ng-container *ngIf="auth$ | async as auth">

            <div *ngIf="events$ | async as events" class="m-t-10">
                <div class='m-t-5' fxLayout="row" fxLayoutAlign="start center">
                    <button class="hover1 add-event" [ngClass]="{'more':!events.length}" mat-button
                        matTooltip="Add Event" (click)="addCustomerEvent(customer)" *auth1="['planner']">
                        <mat-icon>add</mat-icon>
                        <ng-container *ngIf="!events.length">Create Event</ng-container>
                    </button>

                    <div fxFlex="grow"></div>
                    <button fxFlex="none" class="hover1" mat-stroked-button matTooltip="Edit Customer Account"
                        [routerLink]="customer.editUrl" *auth1="['planner']">
                        <mat-icon>edit</mat-icon> Edit {{customer.name}} account
                    </button>
                </div>
                <ng-container *ngIf="getEventsUpcoming(events) as upcomingEvents">
                    <mat-divider class="m-y-10"></mat-divider>
                    <div class="m-b-10" *ngIf="upcomingEvents.length">Upcoming Events</div>
                    <div class="container" fxLayout="row wrap" fxLayoutGap="10px">
                        <ng-container *ngFor="let customerEvent of upcomingEvents">
                            <ng-container *ngTemplateOutlet="customerEvent1;context:{customerEvent:customerEvent}">
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
                <mat-divider class="m-y-10"></mat-divider>
                <div *ngIf="!events.length">There are currently no events. Please create one</div>
                <ng-container *ngIf="getEventsPast(events) as pastEvents">
                    <div class="m-b-10" *ngIf="pastEvents.length">Past Events</div>
                    <div class="container" fxLayout="row wrap" fxLayoutGap="10px">
                        <ng-container *ngFor="let customerEvent of pastEvents">
                            <ng-container *ngTemplateOutlet="customerEvent1;context:{customerEvent:customerEvent}">
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-template let-customerEvent="customerEvent" #customerEvent1>
                    <ng-container *ngIf="getChatTrack(chatTrackMap,customerEvent) as chatTrack">
                        <mat-card class='event-container mat-elevation-z2  p-5 pointer1 m-b-10'
                            (click)="navigateToEvent(customerEvent,rippler)">
                            <div fxLayout="row" class="top" (click)="navigateToEvent(customerEvent,rippler)">
                                <button mat-icon-button matTooltip="Edit event"
                                    (click)=" $event.stopPropagation() || navigateToEditEvent(customerEvent)"
                                    *ngIf="auth.isPlanner;else editNoPlannerT">
                                    <mat-icon class="material-icons-outlined">edit_outline</mat-icon>
                                </button>
                                <ng-template #editNoPlannerT>
                                    <button mat-icon-button disabled *ngIf="chatTrack.sumUnread">
                                    </button>
                                </ng-template>
                                <div fxFlex="grow" class="center1 event-name">
                                    {{customerEvent.data.name}}</div>
                                <button mat-icon-button [color]="'primary'" matTooltip="Recent Chat"
                                    *ngIf="chatTrack.sumUnread;else noChatPlannerT" [matBadge]="chatTrack.sumUnread"
                                    matBadgePosition="above after" [matBadgeHidden]="!chatTrack.sumUnread"
                                    matBadgeSize='small' matBadgeOverlap='true'
                                    [routerLink]="chatRecentLink(customerEvent)">
                                    <mat-icon class="material-icons chat1">forum</mat-icon>
                                </button>
                                <ng-template #noChatPlannerT>
                                    <button mat-icon-button disabled *ngIf="auth.isPlanner">
                                    </button>
                                </ng-template>
                            </div>
                            <ng-container *ngIf="chatTrackService.DEBUG_MODE">
                                <div>
                                    <ng-container *ngIf="chatTrack.auth">
                                        {{chatTrack.sumReadBy}} / {{chatTrack.sumTotals}}
                                    </ng-container>
                                </div>
                            </ng-container>
                            <div class="center1">
                                <span> {{customerEvent.info.earliestDateDisplay}}</span>
                            </div>
                            <div class="bg" mat-ripple #rippler="matRipple" [matRippleDisabled]="true"
                                [matRippleCentered]="true"></div>
                        </mat-card>
                    </ng-container>
                </ng-template>
            </div>

        </ng-container>
    </ng-container>
</ng-container>