import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { firestoreRealtime, firestoreRealtimeNoSyncing } from '@skylitup/base/ngrx-data-fire';
import { genericCatch$$ } from '@skylitup/base/util';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ContextService } from '../../util/services/context.service';
import { NotificationService } from '../../util/services/notification.service';
import { CUSTOMER_KEYS } from '../customer/customer.model.data';
import { AGENCY_KEYS } from '../data-index';
import { ChatTrackService } from './chat-track.service';


@Injectable({ providedIn: 'root' })
export class ChatTrackEffects {
    chatTracksForCustomer$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(routerNavigatedAction),
                switchMap((_) => {
                    return combineLatest([
                        this.contextService.agencyId$,
                        this.contextService.customerId$,
                        this.contextService.auth$
                    ]);
                }),
                distinctUntilChanged(null, (a) => a[0] + a[1] + a[2]?.data?.uid),
                switchMap((a) =>
                    of(a).pipe(
                        switchMap(([agencyId, customerId, auth]) => {
                            if (agencyId && customerId) {
                                return this.chatTrackService
                                    .sync$(agencyId, customerId)
                                    .pipe(
                                        map((_) =>
                                            firestoreRealtime(
                                                `${AGENCY_KEYS.plural}/${agencyId}/${CUSTOMER_KEYS.plural}/${customerId}`
                                            )
                                        )
                                    )
                            }  else if(agencyId && auth.isPlanner){
                                return this.chatTrackService
                                    .syncAllCustomers$(agencyId)
                                    .pipe(
                                        map((_) =>
                                            firestoreRealtime(
                                                `${AGENCY_KEYS.plural}/${agencyId} @agency scope`
                                            )
                                        )
                                    )
                            }
                            
                            else {
                                return of(firestoreRealtimeNoSyncing('Chats-Track scoped by customer'))
                            }
                        }
                        ),
                        genericCatch$$('Chats-Track - customerContext')
                    )
                )
            ),
        { useEffectsErrorHandler: false }
    );


    constructor(
        private actions$: Actions,
        private chatTrackService: ChatTrackService,
        private notifications: NotificationService,
        private contextService: ContextService,
    ) { }

    ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
        return this.notifications.trackCompletion(resolvedEffects$);
    }

}