import { CustomerEvent } from '../customer-event/customer-event.model';
import { Customer } from './customer.model';
import { EntityTuple } from '@skylitup/base/ngrx-data-fire';
import { cloneEntityTuple } from '@skylitup/base/ngrx-data-fire';
import { Dictionary } from '@ngrx/entity';
import { UserCard } from '../user-card/user-card.model';
import { Agency } from '../agency/agency.model';

export const projectCustomerToAgency = (
  t: EntityTuple<Customer>,
  agencyMap: Dictionary<Agency>
) => {
  const result = cloneEntityTuple(t);
  const [customers] = result;
  for (const customer of customers) {
    customer.agency = agencyMap[customer.data.meta.parentId];
  }
  return result;
};

// export function projectCustomerAssignees(
//   t: EntityTuple<Customer>,
//   userCardByAuthUIDMap: Dictionary<UserCard>
// ): EntityTuple<Customer> {
//   const result = cloneEntityTuple(t, a => {
//     a.assignees = [];
//   });
//   const [customers, customerMap] = result;
//   for (const c of customers) {
//     for (const uId of c.data.assignees) {
//       const userCard = userCardByAuthUIDMap[uId];
//       if (userCard) {
//         c.assignees.push(userCard);
//       }
//     }
//   }
//   return result;
// }
// export function projectCustomerUserCards(
//   t: EntityTuple<Customer>,
//   userCards: UserCard[]
// ): EntityTuple<Customer> {
//   const result = cloneEntityTuple(t, a => {
//     a.customerUsers = [];
//   });
//   const [customers, customerMap] = result;
//   for (const u of userCards) {
//     if (u.data.access) {
//       // tslint:disable-next-line: forin
//       for (const customerId in u.data.access) {
//         const customer = customerMap[customerId];
//         if (customer) {
//           customer.customerUsers.push(u);
//         }
//       }
//     }
//   }

//   return result;
// }
