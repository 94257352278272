import { ActionReducer, on } from '@ngrx/store';
import { logoutSuccess } from '../../domain-model/auth/state/auth.actions';
 
export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === logoutSuccess.type) {
      state = undefined;
    }

    return reducer(state, action);
  };
}
