import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@skylitup/base/util';

@Component({
  selector: 'flrgl-lightbox',
  templateUrl: 'lightbox.component.html',
  styles: [
    'mat-dialog-container {padding: 0px !important;}',
    'img{max-height:80vh;max-width:80vw;}',
  ],
})
export class LightboxComponent extends BaseComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public imgUrl: string,
    private dialogRef: MatDialogRef<LightboxComponent>
  ) {
    super();
  }
  close() {
    this.dialogRef.close();
  }
}
