import { createAction, props } from '@ngrx/store';
import { LoginResponse } from '../auth.model';

export const loginWithEmailAndPassword = createAction(
  '[AUTH-Login-EmailPwd]',
  props<{ email: string; password: string }>()
);

export const sendSignInLinkToEmail = createAction(
  '[AUTH-Send-SignInLinkToEmail]',
  props<{ email: string }>()
);
export const sendSignInLinkToEmailSuccess = createAction(
  '[AUTH-Send-SignInLinkToEmail] Success'
);
export const sendSignInLinkToEmailFail = createAction(
  '[AUTH-Send-SignInLinkToEmail] Fail'
);
export const sendResetPasswordEmail = createAction(
  '[AUTH-Send-ResetPasswordEmail]',
  props<{ email: string }>()
);
export const sendResetPasswordEmailSuccess = createAction(
  '[AUTH-Send-ResetPasswordEmail] Success'
);
export const sendResetPasswordEmailFail = createAction(
  '[AUTH-Send-ResetPasswordEmail] Fail'
);
export const emailInStorage = createAction(
  '[AUTH-EmailInStorage]',
  props<{ email: string }>()
);

export const saveNewPassword = createAction(
  '[AUTH-SaveNewPassword]',
  props<{ code: string; password: string }>()
);

export const impersonateCustomer = createAction(
  '[AUTH-Impersonate Customer]',
  props<{ impersonatingCustomer: boolean }>()
);

export const timeTravel = createAction(
  '[AUTH-Time Travel]',
  props<{ timeTravelling: boolean }>()
);

export const authStorage = createAction(
  '[AUTH-STORAGE]',
  props<{
    emailInStorage: string;
    impersonatingCustomer: boolean;
    timeTravelling: boolean;
  }>()
);

export const signInWithEmailLink = createAction(
  '[AUTH-Login-SignInLinkToEmail]',
  props<{ email: string; url: string }>()
);

export const logoutThenloginWithEmailAndPassword = createAction(
  '[AUTH-Logout-Login-EmailPwd]',
  props<{ email: string; password: string }>()
);

export const refreshIdTokenResult = createAction(
  '[AUTH-ID Token] Refresh (force)',
  props<{ reason: string }>()
);
export const idTokenResult = createAction(
  '[AUTH-ID Token] Success',
  props<{ payload: LoginResponse }>()
);
export const idTokenResultGuest = createAction('[AUTH-ID Token] Guest');

export const loginSuccess = createAction('[AUTH-Login] Success');
export const loginFail = createAction(
  '[AUTH-Login] Fail',
  props<{ code: string; reason: string }>()
);

export const logout = createAction('[AUTH-Logout]');
export const logoutSuccess = createAction('[AUTH-Logout] Success');
