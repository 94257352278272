import {
  ChangeDetectionStrategy, Component,
  Input,
  OnChanges,
  OnInit, SimpleChanges
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Auth,
  Chat, ChatData,
  ChatEditService, ChatTrack, CustomerEvent, Dialog,
  doHeartBeat,
  UploadFilesRequest
} from '@skylitup/flowergirl/core';
import { ChatPortalBaseComponent } from '@skylitup/flowergirl/features/chat';
import { first } from 'rxjs/operators';
import { ChatMessageCreateComponent } from './chat-dialog/chat-message/chat-message-create/chat-message-create.component';

@Component({
  selector: 'flrgl-chat-portal',
  templateUrl: 'chat-portal.component.html',
  styles: ['.outer{height:100%;}', ' .dialog-container { min-height: 0%; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatPortalComponent extends ChatPortalBaseComponent
  implements OnChanges, OnInit {
  @Input()
  dialog: Dialog;
  @Input()
  canBeReadBy = true;
  @Input()
  forceRead = false;
  @Input()
  auth: Auth;
  @Input()
  chatTrack;
  @Input()
  event: CustomerEvent;
  constructor(private chatEditService: ChatEditService, private store: Store) {
    super();
  }

  msgSent(mm: { uploadFilesRequest: UploadFilesRequest; data: ChatData }, chatTrack: ChatTrack) {
    this.chatEditService
      .createChat(this.event, mm.data, mm.uploadFilesRequest)
      .pipe(first())
      .subscribe(() => { });
  }
  editSent(mm: { uploadFilesRequest: UploadFilesRequest; data: ChatData }) {
    this.chatEditService
      .updateChat(mm.data, mm.uploadFilesRequest)
      .pipe(first())
      .subscribe(() => { });
  }
  ngOnChanges(changes: SimpleChanges): void {
    const currentDialog = changes['dialog']?.currentValue as Dialog;
    const previousDialog = changes['dialog']?.previousValue as Dialog;
    const chatTrack = changes['chatTrack']?.currentValue as ChatTrack;

    if (currentDialog) {
      this.store.dispatch(doHeartBeat());
    }
    if (this.forceRead || ((this.canBeReadBy) && (
      currentDialog?.dialogId !== previousDialog?.dialogId
      || currentDialog?.history.length !== previousDialog?.history.length
      || currentDialog?.currentUserId !== previousDialog?.currentUserId
    ))) {
      if (this.dialog?.hasUnread && this.dialog.currentUserId) {
        this.chatEditService.markChatRead(this.dialog.unread, this.dialog.currentUserId).subscribe();
      }
    }

    const dialogId = this.dialog?.dialogId;


    if (this.forceRead
      || (this.canBeReadBy &&
        (chatTrack
          || currentDialog?.dialogId !== previousDialog?.dialogId
          || currentDialog?.currentUserId !== previousDialog?.currentUserId
        ))
    ) {
      if (this.chatTrack?.getReadBy(dialogId) !== this.chatTrack?.getTotals(dialogId)) {
        // console.log('chatTrackChange', this.chatTrack?.getReadBy(dialogId), this.chatTrack?.getTotals(dialogId));
        // console.log('WWWAAAATTTT-=== 2222222');
        this.chatEditService.markChatTrackRead(this.dialog, this.chatTrack).subscribe();
      }
    }
  }

  ngOnInit(): void { }
  msgEditStart(chat: Chat, chatCreateComponent: ChatMessageCreateComponent) {
    chatCreateComponent.editChat(chat);
  }

  msgDeleted(chat: Chat) {
    if (confirm(`Sure you want to delete this chat?`)) {
      this.chatEditService
        .deleteChat(chat)
        .subscribe(() => { });
    }
  }
}
