import { createFeatureSelector, createSelector } from "@ngrx/store";
import { RouterStateUrl, FlrglRouterState } from "./router-serializer";

const selectRouter = createFeatureSelector<FlrglRouterState>("router");
export const selectAppContext = createSelector(selectRouter, (s) =>
  s?.state?.paramsMapWithoutScope ? s.state.paramsMapWithoutScope : {}
);

export const selectRoutePathSegments = createSelector(selectRouter, (s) => s?.state?.segmentPaths || [])
// we haven't really used this yet
export const selectEntityIdInContext = createSelector(
  selectAppContext,
  (context: RouterStateUrl, props: { id: string }) => {
    return context[props.id];
  }
);
