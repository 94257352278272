import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { AuthService } from '../../domain-model/auth/auth.service';
 
@Injectable({
  providedIn: 'root',
})
export class AgencyGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isPlanner$.pipe(
      tap((p) => {
        if (!p) {
          this.toastr.error(
            'Access to this part of the application requires you to be part of the agency team ',
            'Access Denied',

            {
              positionClass: 'toast-bottom-right',
            }
          );
        }
      })
    );
  }
}
