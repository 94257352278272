<div fxLayout="column" *ngIf="vendor.effectiveMapCenter;else mapNotSet">
    <div fxLayout="row">
        <div *ngIf="vendor.data.address" class="address" fxFlex="grow">Address: {{vendor.data.address}}</div>
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="map-container" fxFlex="grow">
        <google-map height="100%" width="100%" [center]="vendor.effectiveMapCenter" [zoom]="vendor.data.mapZoom" #map1>
            <map-marker #marker [position]='vendor.effectiveMapCenter'></map-marker>
            <!-- <map-info-window>Info Window content</map-info-window> -->
        </google-map>
    </div>
</div>
<ng-template #mapNotSet>
    <div class="no-map" fxLayout="column" fxLayoutAlign="center center">
        <span>Map Not Set Yet</span>
        <mat-icon>location_off</mat-icon>
    </div>
</ng-template>