import { EntityData, entityDate, EntityKeys, EntityProjected, Timestamp } from '@skylitup/base/ngrx-data-fire';
import { UserTrackData, UserTrackSessionData } from './user-track-model.data';
import * as moment from 'moment';
import { UserTrackService } from './user-track.service';
import { Dictionary } from '@ngrx/entity';
export class UserTrack extends EntityProjected<UserTrackData> {
    readonly lastLogin: Date;
    readonly lastActive: Date;
    allSessions: UserTrackSession[] = [];
    sessionGroupDayMap: Dictionary<UserTrackGroupDaySession> = {};
    sessionGroupMonthMap: Dictionary<UserTrackGroupMonthSession> = {};
    sessionGroupDays: UserTrackGroupDaySession[];
    public sessionGroupMonths: UserTrackGroupMonthSession[];
    constructor(data: UserTrackData) {
        super(data);
        this.lastLogin = entityDate(this.data?.lastLogin);
        this.lastActive = entityDate(this.data?.lastActive);
    }

    buildAnalytics(): UserTrack {
        const ss = [];
        if (this.data.currentSession) {
            ss.push(this.data.currentSession)
        }
        ss.push(...(this.data.history || []));
        this.allSessions = ss.map(s => new UserTrackSession(s));

        for (const s of this.allSessions) {
            const d = this.sessionGroupDayMap[s.dayKey] = this.sessionGroupDayMap[s.dayKey] || new UserTrackGroupDaySession(s.day, s.dayKey, s.month, s.monthKey);
            d.sessions.push(s);
            d.totalSeconds += s.totalSeconds;
        }
        //
        this.sessionGroupDays = Object.values(this.sessionGroupDayMap);
        // this.sessionGroupDays.sort((s1, s2) => s1.day.getTime() - s2.day.getTime());
        //
        for (const d of this.sessionGroupDays) {
            const m = this.sessionGroupMonthMap[d.monthKey] = this.sessionGroupMonthMap[d.monthKey] || new UserTrackGroupMonthSession(d.month, d.monthKey);
            m.days.push(d);
            m.totalSeconds += d.totalSeconds;
        }
        this.sessionGroupMonths = Object.values(this.sessionGroupMonthMap);
        for (const g of this.sessionGroupMonths) {
            g.days.sort((g1, g2) => g2.day.getTime() - g1.day.getTime());
        }
        // this.sessionGroupMonths.sort((g1, g2) => g2.month.getTime() - g1.month.getTime());
        return this;
    }

    clone(): UserTrack {
        return new UserTrack(this.data).copy(this);
    }
    copy(from: UserTrack): UserTrack {
        return this;
    }
}

export class UserTrackSession {
    readonly dayKey: string;
    readonly monthKey: string;
    readonly start: Date;
    readonly end: Date;
    readonly month: Date;
    readonly day: Date;
    readonly startTimeDescription: string;
    readonly endTimeDescription: string;
    readonly totalSeconds: number;
    constructor(public data: UserTrackSessionData) {
        this.start = entityDate(data.start);
        this.end = entityDate(data.lastHeartBeat);
        const startMoment = moment(this.start);
        const endMoment = moment(this.end);
        const dayMoment = moment(this.start).startOf('day');
        this.day = dayMoment.toDate();
        this.dayKey = dayMoment.format("DDMMYYYY");
        const monthMoment = moment(this.start).startOf('month');
        this.month = monthMoment.toDate();
        this.monthKey = monthMoment.format("MMYYYY");
        this.startTimeDescription = startMoment.format('LTS')
        this.endTimeDescription = endMoment.format('LTS')
        this.totalSeconds = endMoment.diff(startMoment, 'seconds');
    }
    get durationDescription() {
        return moment.duration(this.totalSeconds, 'seconds').humanize();
    }
}

export class UserTrackGroupDaySession {
    totalSeconds = 0;
    sessions: UserTrackSession[] = [];
    readonly description: string;

    get durationDescription() {
        return moment.duration(this.totalSeconds, 'seconds').humanize();
    }
    constructor(public day: Date, public dayKey: string, public month: Date, public monthKey: string) {
        this.description = moment(day).format('dddd, D MMMM')
    }
}

export class UserTrackGroupMonthSession {
    totalSeconds = 0;
    days: UserTrackGroupDaySession[] = [];
    readonly description: string;
    get durationDescription() {
        return moment.duration(this.totalSeconds, 'seconds').humanize();
    }
    constructor(public month: Date, public monthKey: string) {
        this.description = moment(month).format('MMMM YYYY')
    }
}