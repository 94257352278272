<ng-container *ngIf="auth$ | async as auth">
    <ng-container *ngIf="customerEvent$ | async as customerEvent">
        <ng-container *ngIf="researchCategory$ | async as researchCategory">
            <ng-container *ngIf="unfold$ | async as unfold">
                <ng-container *ngLet="customerEvent.isUseDatesInResearch(researchCategory) as isUseDates">
                    <div class="container" [ngClass]="{
                        folded:unfold==-1,
                            innerHeightIncreased:windowService.innerHeightIncreased$|async
                        }">
                        <table mat-table class="mat-elevation-z1" [dataSource]="priceItemsInContext$ | async"
                            [trackBy]="trackById">
                            <!-- *** VERY FIRST ROW (HOLDS THE EXPAND BUTTON AND THE CANDIDATES WHEN VISIBLE ***  
                            LINKS TO THE FIRST *matHeaderRowDef OF THE TR tags (it's based on the ids) -->
                            <ng-container *ngFor="let dateCol of getCandiDateColumns(customerEvent,researchCategory)"
                                [matColumnDef]="dateCol.id">
                                <th mat-header-cell *matHeaderCellDef class="center1"
                                    [attr.colspan]="dateCol.id==='_name'?1:customerEvent.getResearchVersions(researchCategory.id).length"
                                    [attr.rowspan]="dateCol.id==='_name'?2:1">
                                    <div>
                                        <ng-container *ngIf="dateCol.id!=='_name';else unfold1">
                                            <ng-container *ngIf="unfold===-1">
                                                {{dateCol.v.description}}
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #unfold1>
                                            <div>
                                                <div>
                                                    <span *ngIf="unfold==1;else compactT">Full View</span>
                                                    <ng-template #compactT>Compact View</ng-template>
                                                </div>
                                                <button mat-icon-button class="clickable-icon1 unfold1"
                                                    (click)="unfold$.next(unfold==1?-1:1)"
                                                    [matTooltip]="unfold==1?'Collapse':'Expand'">
                                                    <mat-icon *ngIf="unfold==1;else unfoldMore1">unfold_more</mat-icon>
                                                    <ng-template #unfoldMore1>
                                                        <mat-icon>unfold_less</mat-icon>
                                                    </ng-template>
                                                </button>
                                            </div>
                                        </ng-template>
                                    </div>
                                </th>
                            </ng-container>

                            <!-- *** SECOND ROW (HOLDS THE VERSIONS WHEN VISIBLE ***  
                            LINKS TO THE SECOND *matHeaderRowDef OF THE TR tags (it's based on the ids) -->
                            <ng-container *ngFor="let col of getColumns(customerEvent,researchCategory)"
                                [matColumnDef]="col.id+'_th'">
                                <th mat-header-cell *matHeaderCellDef class='th-version'>
                                    <div>
                                        <ng-container *ngIf="unfold===-1">
                                            {{col.version.name}}
                                        </ng-container>
                                    </div>
                                </th>
                            </ng-container>

                            <!-- *** ALL THE OTHER ROWS ***  
                            LINKS TO *matRowDef OF THE TR tags (based on those ids)  
                            -->
                            <ng-container *ngFor="let col of getColumns(customerEvent,researchCategory)"
                                [matColumnDef]="col.id">
                                <ng-container *ngIf="col.id==='name';else priceCells">
                                    <!-- *** FIRST COLUMN VENDOR NAME ***-->
                                    <ng-container *matCellDef="let tr1;">
                                        <td *ngIf="tr1.i===0 " mat-cell
                                            [attr.rowspan]="getVendorNameRowSpan(customerEvent,researchCategory,unfold===1, isUseDates)">
                                            <div class="p-r-5">
                                                {{tr1.vr.getControlledName(customerEvent.customerId,auth)}}
                                            </div>
                                        </td>
                                    </ng-container>
                                </ng-container>
                                <ng-template #priceCells>
                                    <ng-container *matCellDef="let tr1;">
                                        <ng-container *ngIf="asIColumn(col) as col">
                                            <ng-container *ngIf="asVendorResearch(tr1.vr) as vr">
                                                <!-- *** Vendor Column CandiDate Header *** -->
                                                <th mat-header-cell class="center1 p-1"
                                                    [ngClass]="{'na':isCandiDateNA(vr, col.candiDate?.id)}"
                                                    *ngIf="isUseDates && tr1.i==0 && col.versionIndex===0 && unfold==1"
                                                    [attr.colspan]="col.id==='_name'?1:customerEvent.getResearchVersions(researchCategory.id).length">
                                                    {{col.candiDate.description}}
                                                    <button *ngIf="editMode && isCandiDateNA(vr, col.candiDate?.id)"
                                                        mat-icon-button class="func m-l-5" matTooltip="Add/Edit Note"
                                                        (click)="showEditNotesCandiDate(vr,col.candiDate.id)">
                                                        <mat-icon>description</mat-icon>
                                                    </button>
                                                    <button *ngIf="editMode" mat-icon-button class="func m-l-5 na-btn"
                                                        matTooltip="Not Applicable"
                                                        (click)="toggleCandiDateNA(vr,col.candiDate.id)">
                                                        <mat-icon
                                                            [ngClass]="{'color-primary':  isCandiDateNA(vr, col.candiDate?.id)}">
                                                            clear</mat-icon>
                                                    </button>
                                                </th>
                                                <!-- *** Vendor Column Version Header *** -->
                                                <th mat-header-cell class="p-1"
                                                    *ngIf="(isUseDates && tr1.i===1)|| (!isUseDates&&tr1.i===0) && unfold===1"
                                                    [ngClass]="{'na':isVersionNA(vr, col.version?.id)}">
                                                    <ng-container *ngIf="!isCandiDateNA(vr,col.candiDate.id)">
                                                        {{col.version.name}}
                                                        <button *ngIf="editMode && isVersionNA(vr, col.version?.id) "
                                                            mat-icon-button class="func m-l-5"
                                                            matTooltip="Add/Edit Note"
                                                            (click)="showEditNotesVersion(vr,col.version.id)">
                                                            <mat-icon>description</mat-icon>
                                                        </button>
                                                        <button *ngIf="editMode" mat-icon-button
                                                            class="func m-l-5 na-btn" matTooltip="Not Applicable"
                                                            (click)="toggleVersionNA(vr,col.version.id)">
                                                            <mat-icon
                                                                [ngClass]="{'color-primary':  isVersionNA(vr, col.version?.id)}">
                                                                clear</mat-icon>
                                                        </button>
                                                    </ng-container>
                                                </th>
                                                <!-- *** Vendor Column Totals *** -->
                                                <td mat-cell class="p-1" *ngIf="(
                                                            unfold===1 &&
                                                            ((isUseDates && tr1.i===2)|| (!isUseDates&&tr1.i===1))                              
                                                        )
                                                        ||(unfold===-1 && tr1.i==0)">
                                                    <ng-container
                                                        *ngIf="vr.getTotal(researchCategory.id, col.candiDate.id,col.version.id)">
                                                        <div class="total">
                                                            €{{ vr.getTotal(researchCategory.id, col.candiDate.id,col.version.id)}}
                                                            <mat-divider class="m-y-1"></mat-divider>
                                                        </div>
                                                    </ng-container>
                                                </td>
                                                <ng-container *ngIf="asPriceItem(tr1.priceItem) as priceItem">
                                                    <ng-container
                                                        *ngIf="!isVersionNA(vr,col.version.id) && !isCandiDateNA(vr,col.candiDate.id);else naT">
                                                        <!-- *** Price Items *** -->
                                                        <td mat-cell class="p-1"
                                                            *ngIf="unfold==1 && ((isUseDates && tr1.i>2)||(!isUseDates && tr1.i>1) )"
                                                            [ngClass]="{warn:isWarn(vr,col.candiDate?.id,col?.version?.id,priceItem?.id,it)}">
                                                            <flrgl-compare-prices-item [vr]="vr" [editable]="editMode"
                                                                #it [priceItem]="priceItem" [version]="col.version"
                                                                [candiDate]="col.candiDate"
                                                                [customerEvent]="customerEvent">
                                                            </flrgl-compare-prices-item>
                                                        </td>
                                                    </ng-container>
                                                    <ng-template #naT>
                                                        <ng-container
                                                            *ngIf="isCandiDateNA(vr,col.candiDate.id);else naVersionT">
                                                            <!-- *** N/A CandiDate Level *** -->
                                                            <td mat-cell class="p-1"
                                                                *ngIf=" ((isUseDates && tr1.i===3)|| (!isUseDates && tr1.i===2)) && col.versionIndex===0"
                                                                [ngClass]="{'na':isCandiDateNA(vr, col.candiDate?.id)}"
                                                                [attr.rowspan]="unfold==1?customerEvent.getResearchPriceItems(researchCategory.id).length:1"
                                                                [attr.colSpan]="unfold==1?customerEvent.getResearchVersions(researchCategory.id).length:1">
                                                                <div fxLayout="column" fxLayoutAlign="start center"
                                                                    (click)="showEditNotesCandiDate(vr,col.candiDate.id)">
                                                                    <span>
                                                                        N/A
                                                                    </span>
                                                                    <span class="notes"
                                                                        *ngIf="getCandiDateNA(vr,col.candiDate.id)?.notes?.length">
                                                                        {{getCandiDateNA(vr,col.candiDate.id)?.notes}}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-template #naVersionT>
                                                            <!-- *** N/A Version Level *** -->
                                                            <td mat-cell class="p-1"
                                                                *ngIf="((isUseDates && tr1.i===3)|| (!isUseDates && tr1.i===2))"
                                                                [ngClass]="{'na':isVersionNA(vr, col.version?.id)}"
                                                                [attr.rowspan]="unfold==1?customerEvent.getResearchPriceItems(researchCategory.id).length:1">

                                                                <div fxLayout="column" fxLayoutAlign="start center"
                                                                    (click)="showEditNotesVersion(vr,col.version.id)">
                                                                    <span>
                                                                        N/A
                                                                    </span>
                                                                    <span class="notes"
                                                                        *ngIf="getVersionNA(vr,col.version.id)?.notes?.length">
                                                                        {{getVersionNA(vr,col.version.id)?.notes}}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </ng-template>
                                                    </ng-template>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-template>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="getCandiDateColumnIds(customerEvent,researchCategory)">
                            </tr>
                            <tr mat-header-row *matHeaderRowDef="getColumnIds(customerEvent,researchCategory,true)">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: getColumnIds(customerEvent,researchCategory)">
                            </tr>
                        </table>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>