import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError } from 'rxjs';
import { AGENCY_KEYS } from '../agency/agency.model.data';
import { CUSTOMER_KEYS } from '../customer/customer.model.data';
import { UploadData, UploadFilesRequest } from './upload.model.data';
import * as moment from 'moment';
import { FirebaseStorageDataService } from '@skylitup/base/ngrx-data-fire';
import { first, map } from 'rxjs/operators';
import { DocData } from './doc/doc.model.data';

@Injectable({ providedIn: 'root' })
export class UploadService {
    constructor(
        private storageDataService: FirebaseStorageDataService
    ) { }

    upload(m: UploadFilesRequest): Observable<UploadData[]> {
        const path = m.customer
            ? [
                AGENCY_KEYS.plural,
                m.agency,
                CUSTOMER_KEYS.plural,
                m.customer,
            ]
            : [AGENCY_KEYS.plural, m.agency];
        if (m.type === 'doc') {
            path.push('docs')
        }
        path.push(moment().format('YYYY-MM-DD_HH-mm-ss'));

        const data = [...(<any>m.files)].map((file: File) => ({
            file,
            uploadData: {
                name: file.name,
                fileName: file.name,
                ext: file.name.substr(file.name.lastIndexOf('.') + 1),
                urlFull: '',
                processed: false,
                isImg: true,
                path,
                links: {
                    cats: m.cat ? [m.cat] : [],
                    vendors: m.vendor ? [m.vendor] : [],
                    eventItems: m.eventItem ? [m.eventItem] : [],
                    chats: m.chat ? [m.chat] : [],
                    events: m.event ? [m.event] : [],
                    originItem: m.originEventItem,
                    originVendor: m.originVendor,
                    originCat: m.originCat,
                    showcaseVendorId: m.showcaseVendorId || null
                },
                dateCreated: new Date(),
                _del: null
            }
        }));
        if (m.type === 'doc') {
            for (const d of data) {
                const doc = d.uploadData as Partial<DocData>;
                doc.name = m.docName;
                doc.type = m.docType;
            }
        }
        try {
            return forkJoin(
                data.map(d =>
                    this.storageDataService.put(d.file, d.uploadData).downloadURL$.pipe(
                        first(),
                        map(url => {
                            d.uploadData.urlFull = url;
                            return d.uploadData;
                        })
                    )
                )
            );
        } catch (error) {
            console.log(error);
            return throwError(error);
        }
    }
}