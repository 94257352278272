import {
  VendorData,
  VendorParam,
  VendorParamNumber,
  VendorParamSelect
} from './vendor.model.data';

export const VENDOR_DYNAMIC_DOMAIN_DEF: VendorDynamicDomain = {
  venueType: {
    label: 'Type',
    options: [
      { code: '5starHotel', name: '5 star hotel' },
      { code: 'weddingVenue', name: 'Wedding Venue' },
      { code: 'privateHouse', name: 'Private House' },
      { code: 'hotel', name: 'Hotel' },
      { code: 'villa', name: 'Villa' },
      { code: 'boutiqueHotel', name: 'Boutique Hotel' },
      { code: 'beachBar', name: 'Beach Bar' },
      { code: 'taverna', name: 'Taverna' },
      { code: 'restaurant', name: 'Restaurant' }
    ],
    required: true,
    type: 'select',
    icon: { fontSet: 'fas', fontIcon: 'fa-hotel' },
    hideLabelInVal: true
  },
  airportDistance: {
    label: 'Airport Distance',
    min: 0,
    units: 'mins',
    type: 'number',
    icon: { fontSet: 'fas', fontIcon: 'fa-plane-departure' }
  },
  bedrooms: {
    label: 'Beds',
    min: 0,
    max: 20,
    units: 'na',
    type: 'number',
    icon: { fontSet: 'fas', fontIcon: 'fa-bed' }
  },
  guests: {
    label: 'Guests Capacity',
    min: 0,
    max: 30,
    units: 'na',
    type: 'number',
    icon: { fontSet: 'fas', fontIcon: 'fa-users' }
  },

  beachFront: {
    label: 'Beachfront',
    type: 'bool',
    icon: { fontSet: 'fas', fontIcon: 'fa-umbrella-beach' }
  },
  sunsetView: {
    label: 'Sunset View',
    type: 'bool',
    icon: { fontSet: 'fas', fontIcon: 'fa-eye' }
  },
  hasChapel: {
    label: 'Has Chapel',
    type: 'bool',
    icon: { fontSet: 'fas', fontIcon: 'fa-church' }
  },
  hasPool: {
    label: 'Has Pool',
    type: 'bool',
    icon: { fontSet: 'fas', fontIcon: 'fa-swimming-pool' }
  },
  hasHistoricalView: {
    label: 'Has Historical View',
    type: 'bool',
    icon: { fontSet: 'fas', fontIcon: 'fa-landmark' }
  },
  hasRoof: {
    label: 'Has Roof',
    type: 'bool',
    icon: { fontSet: 'far', fontIcon: 'fa-building' }
  }
};

export const VENDOR_VENUE_DOMAIN_LIST = [
  'venueType',
  'airportDistance',
  'bedrooms',
  'guests',
  'beachFront',
  'sunsetView',
  'hasChapel',
  'hasPool',
  'hasHistoricalView',
  'hasRoof'
];
export const VENDOR_ACCOMODATION_DOMAIN_LIST = [
  'airportDistance',
  'bedrooms',
  'guests',
  'beachFront',
  'sunsetView',
  'hasPool',
  'hasHistoricalView',
];

export interface VendorDynamicDomain {
  venueType: VendorParamSelect;
  airportDistance: VendorParamNumber;
  bedrooms: VendorParamNumber;
  guests: VendorParamNumber;
  beachFront: VendorParam;
  sunsetView: VendorParam;
  hasChapel: VendorParam;
  hasPool: VendorParam;
  hasHistoricalView: VendorParam;
  hasRoof: VendorParam;
}

// export interface VenueData extends VendorData {
//   venue: {
//     venueType: string;
//     airportDistance: number;
//     bedrooms: number;
//     guests: number;
//     beachFront: boolean;
//     sunsetView: boolean;
//     hasChapel: boolean;
//     hasPool: boolean;
//     hasHistoricalView: boolean;
//     hasRoof: boolean;
//   };
// }
