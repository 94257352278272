import { ChecklistItem } from '../checklist-item/checklist-item.model';
import { CustomerEvent } from '../customer-event/customer-event.model';
import { CatData, Category } from '../system/system.model';
import { Payment } from './payment.model';
import { Vendor } from '../vendor/vendor.model';

export class ChecklistVendorCat extends Category {
  items: ChecklistItem[] = [];
  payments: Payment[] = [];

  highlightedChecklistItem: ChecklistItem;

  constructor(cat: Category | CatData, public event: CustomerEvent) {
    super(cat instanceof Category ? cat.data : cat);
  }
  clone() {
    return new ChecklistVendorCat(this.data, this.event).copy(this);
  }
  copy(from: ChecklistVendorCat): ChecklistVendorCat {
    this.items = [...from.items];
    this.payments = [...from.payments];
    return this;
  }
  itemsOrPayments(paymentsOnly: boolean) {
    return paymentsOnly ? this.payments : this.items;
  }
}
