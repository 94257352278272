<div class="empty"></div>
<div class="calendar-container">
    <button mat-stroked-button class="close-drawer" (click)="closed.next()">
        <mat-icon>close</mat-icon> Close
    </button>
    <ng-container>
        <div class="calendar">
            <cdk-virtual-scroll-viewport itemSize="185">
                <div *cdkVirtualFor="let month of months">
                    <flrgl-calendar-month [start]="start" [monthOffset]="month" (dateHover)="dateHover.next($event)"
                        (dateSelected)="dateSelected.next($event)" [events]="eventInfo">
                    </flrgl-calendar-month>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </ng-container>
</div>