import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { createSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CustomerEventService } from '../customer-event/customer-event.service';
import { HasDialog } from './chat.model';
import { projectDialogObjectsByEvent } from './chat.model.fn';
import { EntityTuple } from '@skylitup/base/ngrx-data-fire'
import { VendorResearchService } from '../vendor-research/vendor-research.service';

@Injectable({ providedIn: 'root' })
export class ChatAggregatorService {
  allDialogObjectsByEvent$: Observable<Dictionary<EntityTuple<HasDialog>>>;

  constructor(
    private customerEventService: CustomerEventService,
    private vendorResearchService: VendorResearchService,
    private store: Store
  ) {
    const selectDialogObjectsByEvent = createSelector(
      this.customerEventService.selectorsProjected.selectEntities,
      this.vendorResearchService.selectorsProjected.selectEntities,
      projectDialogObjectsByEvent
    );
    this.allDialogObjectsByEvent$ = this.store.select(
      selectDialogObjectsByEvent
    );
  }
}
