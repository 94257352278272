<ng-container *ngIf="agency$ | async as agency">
    <div class="top" *ngIf="auth$ | async as auth">
        <div fxLayout="row" fxLayoutAlign="start center">
            <flrgl-breadcrumb fxFlex="grow"></flrgl-breadcrumb>
            <flrgl-time-travel *ngIf="auth.data.timeTravelling && auth.isPlanner" class='m-r-10'> </flrgl-time-travel>
            <ng-container>
                <div class="settings1" fxLayoutAlign="end center"
                    *ngIf="customerEventEditPath$ | async as customerEventPath" fxLayout="row">
                    <a mat-icon-button *auth1="['planner']" [matMenuTriggerFor]="configMenu1">
                        <mat-icon>settings
                        </mat-icon>
                    </a>
                    <mat-menu #configMenu1="matMenu">
                        <ng-container *ngIf='isInVendorResearch'>
                            <ng-container *ngIf="customerEvent$ | async as customerEvent">
                                <ng-container *ngIf='researchCategory$ | async as researchCategory'>
                                    <a mat-menu-item class="color-accent"
                                        [routerLink]="[customerEvent.editUrl,'vendor-research',researchCategory.id]">Select
                                        Vendors (Research)</a>
                                    <a mat-menu-item class="color-accent"
                                        [routerLink]="[customerEvent.editUrl,'compare-prices',researchCategory.id]">Setup
                                        Prices (Research)</a>
                                    <mat-divider></mat-divider>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="customerEvent$ | async as customerEvent;else notInEvent">
                            <a mat-menu-item [routerLink]="customerEventPath + '/vendors'">Vendors</a>
                            <a mat-menu-item [routerLink]="[agency.url,'big-plan']">Big Plan</a>
                            <mat-divider></mat-divider>
                            <a mat-menu-item (click)="showSummary(customerEvent)">
                                <mat-icon class="m-r-5">info</mat-icon> Event Summary
                            </a>
                            <mat-divider></mat-divider>
                            <a mat-menu-item [routerLink]="customerEventPath + '/event-id'">Event Id</a>
                            <a mat-menu-item [routerLink]="customerEventPath">Event Items</a>
                            <a mat-menu-item [routerLink]="customerEventPath + '/event-categories'">Event Categories</a>
                            <a mat-menu-item [routerLink]="customerEventPath + '/import-from-event'">Import Data</a>
                        </ng-container>
                        <ng-template #notInEvent>
                            <a mat-menu-item [routerLink]="[agency.url,'edit', 'vendors']">Vendors</a>
                            <a mat-menu-item [routerLink]="[agency.url,'big-plan']">Big Plan</a>
                        </ng-template>
                        <ng-container *ngIf="customer$ | async as customer">
                            <mat-divider></mat-divider>
                            <a mat-menu-item [routerLink]="customer.editUrl">Customer Account</a>
                        </ng-container>
                        <mat-divider></mat-divider>
                        <a mat-menu-item [routerLink]="[agency.url,'edit','manage-users']">Manage Users</a>
                    </mat-menu>
                    <!-- (click)="navigateToChatRecents()" -->
                    <ng-container *ngIf="chatTrackInContext$ | async as chatTrack">
                        <button class="chat" mat-icon-button matBadgeSize='small' matBadgePosition="after" matBadge="!"
                            matBadgeColor='warn' matBadgeOverlap='false' *auth1="['planner']"
                            [matMenuTriggerFor]="notificationsControl">
                            <mat-icon class="chat">forum</mat-icon>
                            <mat-icon class="pause" *ngIf="chatTrack.isPaused">pause</mat-icon>
                            <mat-icon class="play" *ngIf="!chatTrack.isPaused">play_arrow</mat-icon>

                        </button>
                        <mat-menu #notificationsControl="matMenu">
                            <ng-container *ngIf="chatTrack.isPaused;else runningT ">
                                <!-- <button > -->
                                <span mat-menu-item disabled class="pause-msg">The Notifications for this event are
                                    paused for
                                    {{chatTrack.pauseDuration}}</span>
                                <!-- </button> -->
                                <button mat-menu-item (click)="toggleNotifications(chatTrack)">
                                    <mat-icon>play_arrow</mat-icon>
                                    <span>Resume Notifications</span>
                                </button>
                                <button mat-menu-item (click)="extendPauseNotifications(chatTrack)">
                                    <mat-icon>pause</mat-icon>
                                    <span>Extend Pause (4h)</span>
                                </button>
                            </ng-container>
                            <ng-template #runningT>
                                <button mat-menu-item (click)="toggleNotifications(chatTrack)">
                                    <mat-icon>pause</mat-icon>
                                    <span>Pause Notifications</span>
                                </button>
                            </ng-template>
                        </mat-menu>
                    </ng-container>
                </div>
            </ng-container>

            <a *ngIf="(edit$ | async)" mat-icon-button [routerLink]="overviewPath$ | async" fxFlex="none"
                matTooltip="Exit Back to the App">
                <mat-icon>exit_to_app</mat-icon>
            </a>
            <a mat-icon-button [matMenuTriggerFor]="menu" class="user1">
                <!-- {outlets:  { dev:''}} -->
                <mat-icon *ngIf="!auth.data.impersonatingCustomer;else impersonating1">person</mat-icon>
                <ng-template #impersonating1>
                    <mat-icon fontSet="fas" fontIcon="fa-user-secret" class="color-warn"></mat-icon>
                </ng-template>
            </a>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="showThemeSelector()">Themes</button>
                <button *auth1="['impersonating','planner']" mat-menu-item
                    (click)="impersonateCustomer(!auth.data.impersonatingCustomer)">
                    <ng-container *ngIf="!auth.data.impersonatingCustomer;else impersonating2">
                        Impersonate Customer
                    </ng-container>
                    <ng-template #impersonating2> Stop Customer Impersonating </ng-template>
                </button>
                <button *auth1="['planner']" mat-menu-item (click)="timeTravel(!auth.data.timeTravelling)">
                    <ng-container *ngIf="!auth.data.timeTravelling;else timeTravelling2">
                        Time-Travel
                    </ng-container>
                    <ng-template #timeTravelling2> Stop Time Travelling </ng-template>
                </button>
                <button *auth1="['dev']" mat-menu-item [routerLink]="['/dev']">DEV</button>
                <mat-divider></mat-divider>
                <div class="current-user">
                    {{auth.name}}
                    <!-- ({{auth.data.uid}}) -->
                </div>
                <button mat-menu-item (click)="logout()" fxLayoutAlign="start center">
                    Logout
                    <!-- <mat-icon class="m-l-5" fontSet="fas" fontIcon="fa-sign-out-alt"></mat-icon> -->
                </button>
            </mat-menu>
        </div>
        <mat-divider></mat-divider>
    </div>
    <div class="spacer"></div>
    <div class="outlet-container">
        <router-outlet></router-outlet>
    </div>
</ng-container>