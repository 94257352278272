import { Dictionary } from '@ngrx/entity';
import { EntityData, EntityKeys } from '@skylitup/base/ngrx-data-fire';
import {
  BudgetItemData
} from '../event-item/budget-item.model.data';

export const VENDOR_RESEARCH_KEYS: EntityKeys = {
  name: 'vendor-research',
  plural: 'vendors-research'
};
export interface VendorResearchData extends EntityData {
  name: string;
  vendorId: string;
  catIds: string[];
  rating: number;
  media: string[]; // used to track the order  only
  budgetResearch: Dictionary<BudgetResearchData>;
  linkedBudgetResearch: Dictionary<BudgetResearchItemData>;
  naCandiDates: Dictionary<{ notes: string, na: boolean }>;
  naVersions: Dictionary<{ notes: string, na: boolean }>;
}

export interface BudgetResearchData {
  candiDate: string;
  version: string; /// e.g. with boufet vs served etc (this is like the slug)
  items: Dictionary<BudgetResearchItemData>;
}

export interface BudgetResearchItemData extends BudgetItemData {
  priceItemId: string;
  srcVersion?: string;
  srcCandiDate?: string;
}

 