import { Component, Inject } from '@angular/core';

import { VendorContactsBaseComponent } from '@skylitup/flowergirl/features/vendor';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Vendor } from '@skylitup/flowergirl/core';

@Component({
  selector: 'flrgl-vendor-contacts',
  templateUrl: 'vendor-contacts.component.html',
  styleUrls: ['vendor-contacts.component.scss'],
})
export class VendorContactsComponent extends VendorContactsBaseComponent {
  vendor: Vendor;
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) data: Vendor) {
    super();
    this.vendor = data;
  }
}
