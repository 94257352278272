import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceElementsFactory,
  EntityDataService,
} from '@ngrx/data';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Agency } from '../agency/agency.model';
import { AGENCY_KEYS } from '../agency/agency.model.data';
import { AgencyService } from '../agency/agency.service';
import { Auth } from '../auth/auth.model';
// import { projectLCustomers } from './customer.model.fn';
import { CustomerEventService } from '../customer-event/customer-event.service';
import { UserCardService } from '../user-card/user-card.service';
import { Customer } from './customer.model';
import { CustomerData, CUSTOMER_KEYS } from './customer.model.data';
import {
  projectCustomerToAgency,
} from './customer.model.fn';
import {
  EntityBaseService,
  EntityBaseSelectors,
} from '@skylitup/base/ngrx-data-fire';

interface SelectorsProjected
  extends EntityBaseSelectors<CustomerData, Customer> { }
@Injectable({ providedIn: 'root' })
export class CustomerService extends EntityBaseService<CustomerData, Customer> {
  public selectorsProjected: SelectorsProjected;
  public customers$: Observable<Customer[]>;
  public customerMap$: Observable<Dictionary<Customer>>;
  public customerInContext$: Observable<Customer>;
  public customersInContext$: Observable<Customer[]>;

  constructor(
    entityServiceFactory: EntityCollectionServiceElementsFactory,
    entityDataServices: EntityDataService,
    private agencyService: AgencyService,
    private userCardService: UserCardService
  ) {
    super(CUSTOMER_KEYS, entityServiceFactory, entityDataServices);
    this.init();
  }
  setupRxSelectors() {
    const withAgency = createSelector(
      this._selectPlainEntityTuple,
      this.agencyService.selectorsProjected.selectEntityMap,
      projectCustomerToAgency
    );

    // const withAssignees = createSelector(
    //   withAgency,
    //   this.userCardService.selectorsProjected.selectUserCardByAuthUIDMap,
    //   projectCustomerAssignees
    // );
    // const withCustomerUsers = createSelector(
    //   withAssignees,
    //   this.userCardService.selectorsProjected.selectEntities,
    //   projectCustomerUserCards
    // );
    return withAgency;
  }

  protected setupRx$(): void {
    this.customers$ = this.entitiesProjected$;
    this.customerMap$ = this.entityMapProjected$;
    this.customerInContext$ = this.entityInContext$;
    this.customersInContext$ = combineLatest([
      this.customers$,
      this.agencyService.entityIdInContext$,
    ]).pipe(
      filter(([cc]) => !!cc),
      map(([customers, agencyId]) =>
        customers.filter((c) => c._parentId === agencyId)
      )
    );
  }
  public syncCustomersByCustomerUid$(customerUid: string) {
    return this.syncQueryRealtime$([CUSTOMER_KEYS.plural], {
      queryFn: (q) => q.where('customers', 'array-contains', customerUid),
    });
  }
  public syncCustomersByAgency$(agency: string): Observable<boolean> {
    return this.syncQueryRealtime$(
      [AGENCY_KEYS.plural, agency, CUSTOMER_KEYS.plural],
      { replaceState: true }
    );
  }

  public syncAssignedCustomersByAgency$(
    agency: string,
    uid: string
  ): Observable<boolean> {
    return this.syncQueryRealtime$(
      [AGENCY_KEYS.plural, agency, CUSTOMER_KEYS.plural],
      {
        queryFn: [
          (q) => q.where('assignees', 'array-contains', uid),
          (q) => q.where('customers', 'array-contains', uid),
          (q) => q.where('readOnlyUsers', 'array-contains', uid),
        ],
        replaceState: true,
      }
    );
  }
  public syncCustomer$(
    agency: string,
    customerUserId: string
  ): Observable<boolean> {
    return this.syncQueryRealtime$(
      [AGENCY_KEYS.plural, agency, CUSTOMER_KEYS.plural],
      {
        queryFn: [
          (q) => q.where('customers', 'array-contains', customerUserId),
          (q) => q.where('readOnlyUsers', 'array-contains', customerUserId),
        ],
        replaceState: true,
      }
    );
  }
  create({ agency, auth, name }: { agency: Agency; auth: Auth; name: string }) {
    return this.upsert({
      meta: {
        collectionPath: [...agency._fullPath, CUSTOMER_KEYS.plural],
      },
      name,
      agencyName: agency.name,
      assignees: [auth.userId],
      customers: [],
      readOnlyUsers: [],
      type: 'demo',
      _uid: auth.userId,
      _del: null,
      eventsInfo: {},
      usersInfo: {},
      timezone: agency.timezone
    });
  }
  protected newProjectedEntity(d: CustomerData) {
    return new Customer(d);
  }
}
