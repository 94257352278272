import { Injectable } from '@angular/core';
import { EntityBaseSelectors, EntityBaseService } from '@skylitup/base/ngrx-data-fire';
import {
  EntityCollectionServiceElementsFactory,
  EntityDataService,
} from '@ngrx/data';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserCardService } from '../user-card/user-card.service';
import { Agency } from './agency.model';
import { AgencyData, AGENCY_KEYS } from './agency.model.data';
import { Loc } from './location.model';
import { projectLocStore } from './location.model.fn';

interface SelectorsProjected extends EntityBaseSelectors<AgencyData, Agency> { }

@Injectable({ providedIn: 'root' })
export class AgencyService extends EntityBaseService<AgencyData, Agency> {
  selectorsProjected!: SelectorsProjected;
  agencyMap$!: Observable<Dictionary<Agency>>;
  agencies$!: Observable<Agency[]>;
  agencyInContext$!: Observable<Agency>;
  locationTreeInContext$!: Observable<Loc[]>;
  locationsInContext$!: Observable<Loc[]>;
  locationMap$!: Observable<Dictionary<Loc>>;
  constructor(
    entityServiceFactory: EntityCollectionServiceElementsFactory,
    entityDataServices: EntityDataService,
    private userCardService: UserCardService
  ) {
    super(AGENCY_KEYS, entityServiceFactory, entityDataServices);
    this.init();
  }

  protected setupRxSelectors() {
    // const withUserCards = createSelector(
    //   this._selectPlainEntityTuple,
    //   this.userCardService.selectorsProjected.selectUserCardByAuthUIDMap,
    //   projectAgencyUserCards
    // );
    // return withUserCards;
    return this._selectPlainEntityTuple;
  }

  protected setupRx$(): void {
    this.agencies$ = this.entitiesProjected$;
    // this.agencies$.subscribe((aa) => { console.log('77777777777', aa, JSON.stringify(aa[0]?.data.customers)); })
    this.agencyMap$ = this.entityMapProjected$;
    this.agencyInContext$ = this.entityInContext$;
    this.locationTreeInContext$ = this.agencyInContext$.pipe(
      map((agency) => agency?.locStore.locationTree || null)
    );
    this.locationsInContext$ = this.agencyInContext$.pipe(
      map((agency) => agency?.locStore.locations || null)
    );
    this.locationMap$ = this.agencyInContext$.pipe(
      map((agency) => agency?.locStore.locationMap || null)
    );
  }

  public syncAgencies$(userId: string) {
    // console.log('888888888888', userId);
    return this.syncQueryRealtime$([AGENCY_KEYS.plural], {
      queryFn: [
        (q) => q.where('members', 'array-contains', userId),
        (q) => q.where('membersRestricted', 'array-contains', userId),
        (q) => q.where('membersSales', 'array-contains', userId),
        (q) => q.where('customers', 'array-contains', userId),
      ],
      replaceState: true,
    });
  }
  protected newProjectedEntity(d: AgencyData) {
    const a = new Agency(d);
    a.locStore = projectLocStore(d.locations);
    return a;
  }
}
