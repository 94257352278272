import { Component, Input, EventEmitter, Output } from '@angular/core';

import { VendorResearchFeedbackBaseComponent } from '@skylitup/flowergirl/features/vendor';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import {
  VendorResearch,
  Auth,
  VendorResearchService,
  ContextService, ChatTrackService, ChatTrack
} from '@skylitup/flowergirl/core';

@Component({
  selector: 'flrgl-vendor-research-feedback',
  templateUrl: 'vendor-research-feedback.component.html',
  styleUrls: ['vendor-research-feedback.component.scss'],
})
export class VendorResearchFeedbackComponent extends VendorResearchFeedbackBaseComponent {
  @Input()
  vendorResearch: VendorResearch;
  auth$: Observable<Auth>;
  customerId$: Observable<string>;
  chatTrack$: Observable<ChatTrack>;

  @Output()
  ratingSortClick = new EventEmitter();

  constructor(
    context: ContextService,
    private vendorResearchService: VendorResearchService,
    chatTrackService: ChatTrackService

  ) {
    super();
    this.auth$ = context.auth$;
    this.customerId$ = context.customerId$;
    this.chatTrack$ = chatTrackService.chatTrackInContext$;

  }
  ratingChanged(rating: number) {
    this.vendorResearchService
      .update({ ...this.vendorResearch.data, rating })
      .pipe(first())
      .subscribe();
  }
}
