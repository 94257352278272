import { NgModule } from '@angular/core';
import { FlowergirlXplatWebUiModule } from '@skylitup/flowergirl/xplat/web/ui';

import { FlowergirlFeaturesChecklistModule } from '@skylitup/flowergirl/features/checklist';
import { ChecklistItemComponent } from './components/checklist/checklist-item/checklist-item/checklist-item.component';
import { ChecklistItemEditComponent } from './components/checklist/checklist-item/checklist-item-edit/checklist-item-edit.component';
import { ChecklistItemCreateComponent } from './components/checklist/checklist-item/checklist-item-create/checklist-item-create.component';
import { ChecklistByCatComponent } from './components/checklist/checklist-by-cat/checklist-by-cat.component';
import { ChecklistTimelineComponent } from './components/checklist/checklist-timeline/checklist-timeline.component';
import { ChecklistComponent } from './components/checklist/checklist.component';
import { ChecklistAgencyComponent } from './components/checklist-agency/checklist-agency.component';
import { ChecklistCalendarComponent } from './components/checklist/checklist-calendar/checklist-calendar.component';

@NgModule({
  imports: [FlowergirlFeaturesChecklistModule, FlowergirlXplatWebUiModule],
  declarations: [
    ChecklistItemComponent,
    ChecklistItemEditComponent,
    ChecklistItemCreateComponent,
    ChecklistByCatComponent,
    ChecklistTimelineComponent,
    ChecklistComponent,
    ChecklistAgencyComponent,
    ChecklistCalendarComponent,
  ],
  exports: [ChecklistComponent],
})
export class FlowergirlXplatWebFeaturesChecklistModule { }
