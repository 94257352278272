<mat-form-field *ngIf="theme$ |async as currentTheme">

    <mat-label>Theme</mat-label>
    <mat-select [formControl]="control">
        <mat-option *ngFor="let theme of themes" [value]="theme.code">
            {{theme.name}}
        </mat-option>
    </mat-select>
    <button mat-icon-button *ngIf="debug" mat-button [mat-dialog-close] type="submit" matSuffix>
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>