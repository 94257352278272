<div class="summary">
    <div fxLayout="row" fxLayoutAlign="center center">
        <div class="summary-title">
            <mat-icon>info</mat-icon> Event summary
        </div>
        <button mat-mini-fab matTooltip="Copy to clipboard" class="copy-clipboard"
            (click)="copySummaryToClipboard(summary1)">
            <mat-icon>file_copy</mat-icon>
        </button>
    </div>
    <div class="p-10">
        <textarea placeholder="Summary" matInput #summary1 cdkTextareaAutosize [innerHTML]="customerEvent.summaryText"
            readonly></textarea>
    </div>
    <!-- (focus)="summary1.select()" -->
</div>