import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceElementsFactory,
  EntityDataService
} from '@ngrx/data';
import {
  EntityBaseSelectors,
  EntityBaseService
} from '@skylitup/base/ngrx-data-fire';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AGENCY_KEYS } from '../agency/agency.model.data';
import { CustomerEvent } from '../customer-event/customer-event.model';
import { CUSTOMER_EVENT_KEYS } from '../customer-event/customer-event.model.data';
import { CUSTOMER_KEYS } from '../customer/customer.model.data';
import { ChecklistItemImp } from './checklist-item.model';
import {
  ChecklistItemData,
  CHECKLIST_ITEM_KEYS
} from './checklist-item.model.data';
import { Payment } from './payment.model';


interface SelectorsProjected
  extends EntityBaseSelectors<ChecklistItemData, ChecklistItemImp> {}

@Injectable({ providedIn: 'root' })
export class ChecklistItemService extends EntityBaseService<
  ChecklistItemData,
  ChecklistItemImp
> {
  public selectorsProjected: SelectorsProjected;
  constructor(
    entityServiceFactory: EntityCollectionServiceElementsFactory,
    entityDataServices: EntityDataService
  ) {
    super(CHECKLIST_ITEM_KEYS, entityServiceFactory, entityDataServices);
    this.init();
  }
  setupRxSelectors() {
    // createSelector(this._selectEntities, this.customerEventService.selectorsProjected.selectEntityMap)
    return this._selectPlainEntityTuple;
  }
  setupRx$() {}
  protected newProjectedEntity(d: ChecklistItemData) {
    return d.type === 'checklist' ? new ChecklistItemImp(d) : new Payment(d);
  }

  createChecklistItem(
    customerEvent: CustomerEvent,
    d: ChecklistItemData
  ): Observable<ChecklistItemData> {
    const data: ChecklistItemData = {
      meta: {
        collectionPath: [
          ...customerEvent._fullPath,
          CHECKLIST_ITEM_KEYS.plural,
        ],
        id: null,
      },
      ...d,
      agencyId: customerEvent.agencyId,
      customerType: customerEvent.data.customerType,
      done: false,
    };
    return this.upsert(data);
  }
  syncChecklistItems$(
    agencyId: string,
    customerId: string,
    customerEventId: string
  ) {
    return this.syncQueryRealtime$(
      [
        AGENCY_KEYS.plural,
        agencyId,
        CUSTOMER_KEYS.plural,
        customerId,
        CUSTOMER_EVENT_KEYS.plural,
        customerEventId,
        CHECKLIST_ITEM_KEYS.plural,
      ],
      { queryFn: (q) => q.orderBy('dueDate', 'asc'), replaceState: true }
    );
  }

  syncBigPlan$(agencyId: string) {
    return this.syncQueryRealtimeGroupCollection$(CHECKLIST_ITEM_KEYS.plural, {
      queryFn: [
        (q) =>
          q
            .where('agencyId', '==', agencyId)
            .where('done', '==', false)
            .where('customerType', 'in', ['client', 'other'])
            .orderBy('dueDate', 'asc'),
        (q) =>
          q
            .where('agencyId', '==', agencyId)
            .where('doneDate', '>', moment().subtract('15', 'days').toDate())
            .where('customerType', 'in', ['client', 'other'])
            .orderBy('doneDate', 'asc')
            .orderBy('dueDate', 'asc'),
      ],
      replaceState: false,
    });
  }
  //   syncBigPlanRecentlyDone$(agencyId: string) {
  //     return this.syncQueryRealtimeGroupCollection$(CHECKLIST_ITEM_KEYS.plural, {
  //       queryFn: (q) =>
  //         q
  //           .where('agencyId', '==', agencyId)
  //           .where('doneDate', '>', moment().subtract('15', 'days').toDate())
  //           .orderBy('doneDate', 'asc')
  //           .orderBy('dueDate', 'asc'),
  //       replaceState: true,
  //     });
  //   }
}
