import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@skylitup/base/util';
import { EntityAutocomplete } from '@skylitup/base/xplat/web/ui/material';
import {
  Agency,
  AppState,
  ChecklistItem,
  ChecklistItemData,
  ChecklistItemImp,
  ChecklistItemService,
  ChecklistMonth,
  compareChecklistItems,
  ContextService,
  Customer,
  CustomerEvent,
  CustomerEventService,
  CustomerService,
  syncChecklistBigPlan,
  uiBigPlanAssignee,
  uiBigPlanCustomer,
  uiBigPlanHideCompleted,
  UserCard,
  vendorsSyncAll
} from '@skylitup/flowergirl/core';
import { combineLatest, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  takeUntil
} from 'rxjs/operators';

@Component({
  selector: 'flrgl-checklist-agency',
  templateUrl: './checklist-agency.component.html',
  styleUrls: ['./checklist-agency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistAgencyComponent extends BaseComponent
  implements OnInit, OnDestroy {
  hideCompletedItems = new FormControl(true);

  assignee = new FormControl(null);
  assigneesAutocomplete: EntityAutocomplete<UserCard>;
  // +++++++++++++++++++++
  customer = new FormControl(null);
  customersAutocomplete: EntityAutocomplete<Customer>;
  // +++++++++++++++++++++

  expandAllMonths = false;
  checklistMonths$: Observable<ChecklistMonth[]>;
  agencyInContext$: Observable<Agency>;
  customersInContext$: Observable<Customer[]>;
  constructor(
    private store: Store,
    private checklistItemService: ChecklistItemService,
    private customerEventService: CustomerEventService,
    private customerService: CustomerService,
    private cd: ChangeDetectorRef,
    private contextService: ContextService,
    private router: Router
  ) {
    super();
    this.agencyInContext$ = this.contextService.agency$;
    this.customersInContext$ = this.customerService.customersInContext$.pipe(
      map((customers) =>
        customers
          .filter((c) => c.data.type === 'client' || c.data.type === 'other')
          .sort((c1, c2) => {
            if (c1.name > c2.name) return 1;
            if (c1.name < c2.name) return -1;
            return 0;
          })
      )
    );

    this.customersAutocomplete = new EntityAutocomplete<Customer>({
      label: 'Customer',
      options$: this.customersInContext$,
      formControl: this.customer,
    });

    this.assigneesAutocomplete = new EntityAutocomplete<any>({
      label: 'Assignee',
      options$: this.agencyInContext$.pipe(filter(a => !!a), map(a => a.members)),
      formControl: this.assignee,
    });

    this.store
      .select((s: AppState) => s.ui.bigPlanHideCompleted)
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((b) => this.hideCompletedItems.patchValue(b));

    this.store
      .select((s: AppState) => s.ui.bigPlanExpandAllMonths)
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((b) => {
        this.expandAllMonths = b;
      });

    combineLatest([
      this.store
        .select((s: AppState) => s.ui.bigPlanAssignee)
      , this.agencyInContext$.pipe(filter(a => !!a), map(a => a.memberMap))
    ]).pipe(
      takeUntil(this.destroy$), distinctUntilChanged(),
      map(([aId, map1]) => map1[aId])
    )
      .subscribe((a) => this.assignee.patchValue(a));


    combineLatest([
      this.store
        .select((s: AppState) => s.ui.bigPlanCustomer),
      this.customerService.customerMap$
    ]).pipe(
      takeUntil(this.destroy$), distinctUntilChanged(),
      map(([cId, map1]) => map1[cId])
    )
      .subscribe((b) => this.customer.patchValue(b));

    this.hideCompletedItems.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((b) =>
        this.store.dispatch(uiBigPlanHideCompleted({ bigPlanHideCompleted: b }))
      );

    this.assignee.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((b) =>
        this.store.dispatch(uiBigPlanAssignee({ bigPlanAssignee: b?.id }))
      );

    this.customer.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((b) =>
        this.store.dispatch(uiBigPlanCustomer({ bigPlanCustomer: b.id }))
      );
    const allChecklistItems = this.customerEventService.entitiesProjected$.pipe(
      map((customerEvents) => {
        const result: ChecklistItem[] = [];
        for (const customerEvent of customerEvents) {
          result.push(...customerEvent.checklistItems);
        }
        return result;
      })
    );
    const filteredItems = combineLatest([
      allChecklistItems,
      this.hideCompletedItems.valueChanges.pipe(
        startWith(this.hideCompletedItems.value)
      ),
      this.assignee.valueChanges.pipe(
        startWith(this.assignee.value as UserCard)
      ),
      this.customer.valueChanges.pipe(
        startWith(this.customer.value as Customer)
      ),
    ]).pipe(
      map(([items, hideDone, assignee, customer]) =>
        items
          .filter((i) => !assignee || i.assigneeId === assignee?.id)
          .filter(
            (i) =>
              !customer?.id ||
              !i.event?.customerId ||
              i.event?.customerId === customer?.id
          )
          .filter((i) => !hideDone || !i.done)
      )
    );
    this.checklistMonths$ = filteredItems.pipe(
      map((checklistItems) => {
        const checklistMonths: ChecklistMonth[] = [];
        const checklistMonthMap: Dictionary<ChecklistMonth> = {};

        for (const c of checklistItems) {
          ChecklistMonth.updateMonth(c, checklistMonthMap, checklistMonths);
        }
        return checklistMonths;
      }),
      map((cMs) => cMs.sort((c1, c2) => Number(c1.idNum) - Number(c2.idNum))),
      map((cMs) => {
        for (const cm of cMs) {
          cm.items.sort(compareChecklistItems);
        }
        return cMs;
      })
    );
  }
  ngOnDestroy(): void {
    this.store.dispatch(syncChecklistBigPlan({ sync: false }));
    this.store.dispatch(vendorsSyncAll({ sync: false }));
  }

  ngOnInit(): void {
    this.store.dispatch(syncChecklistBigPlan({ sync: true }));
    this.store.dispatch(vendorsSyncAll({ sync: true }));
  }

  trackByIdFn(i, o) {
    return o.id;
  }
  updateItem(
    customerEvent: CustomerEvent,
    e: { data: ChecklistItemData; highlight: boolean; done: () => void },
    checklistItem: ChecklistItem
  ) {
    this.customerEventService
      .updateChecklistItem(customerEvent, e.data, checklistItem, e.highlight)
      .subscribe({ complete: () => e.done() });
  }
  deleteItem(item: ChecklistItemImp) {
    this.checklistItemService.deleteEntity(item.data).subscribe((_) => {
      this.cd.markForCheck();
    });
  }
  expandAllMonthsChange(b) {
    console.log(b);
    // this.store.dispatch(
    //   uiBigPlanExpandAllMonths({ bigPlanExpandAllMonths: b })
    // );
  }
}
