import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';
import { AuthService } from '../../domain-model/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInRedirectGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url: string): Observable<boolean | UrlTree> {
    return this.authService.auth$.pipe(
      map((auth) => {
        if (auth.isLoggedIn && (auth.isCustomer || auth.isPlanner)) {
          return this.router.parseUrl('/agencies');
        } else {
          return true;
        }
      })
    );
  }
}
