import { Component, OnInit, Inject } from '@angular/core';

import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommissionPaymentsReceivedBaseComponent } from '@skylitup/flowergirl/ui';
import { getErrorMessageHandler } from '@skylitup/base/util';

@Component({
  selector: 'flrgl-commission-payments-received',
  templateUrl: 'commission-payments-received.component.html',
})
export class CommissionPaymentsReceivedComponent
  extends CommissionPaymentsReceivedBaseComponent
  implements OnInit {
  amount: FormControl;
  constructor(@Inject(MAT_DIALOG_DATA) public data: number) {
    super();
  }
  getErrorMessage = getErrorMessageHandler;
  ngOnInit(): void {
    this.amount = new FormControl(this.data, [Validators.required]);
  }
  saveCalculation() {
    return this.amount.value;
  }
  onFocus(input: any) {
    input.select();
  }
}
