import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityAutocompleteComponent } from './entity-autocomplete/entity-autocomplete.component';
import { EntityMultiAutocompleteComponent } from './entity-multi-autocomplete/entity-multi-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { CalendarMonthComponent } from './calendar-month/calendar-month.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BaseUtilModule } from '@skylitup/base/util';

@NgModule({
  imports: [
    CommonModule, BaseUtilModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    FlexLayoutModule,
    DragDropModule,
    MatBadgeModule,
  ],
  declarations: [EntityAutocompleteComponent, EntityMultiAutocompleteComponent, CalendarMonthComponent],
  exports: [EntityAutocompleteComponent, EntityMultiAutocompleteComponent, CalendarMonthComponent, BaseUtilModule],
})
export class BaseXplatWebUiMaterialModule { }
