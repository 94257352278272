import { FormControl } from '@angular/forms';
import { BaseComponent } from '@skylitup/base/util';

export abstract class TimeTravelBaseComponent extends BaseComponent {
  public text = 'TimeTravel';

  constructor() {
    super();
  }
}
