import {
  AfterViewInit,
  ChangeDetectionStrategy,

  ChangeDetectorRef, Component,

  Input,
  OnInit
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  AppState,
  ChecklistItem,
  ChecklistItemData,
  ChecklistItemImp, ChecklistItemService, ContextService, CustomerEvent,
  CustomerEventService,
  uiHideCompleted
} from '@skylitup/flowergirl/core';
import { ChecklistTimelineBaseComponent } from '@skylitup/flowergirl/features/checklist';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'flrgl-checklist-timeline',
  templateUrl: 'checklist-timeline.component.html',
  styleUrls: ['checklist-timeline.component.scss', '../checklist.common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistTimelineComponent extends ChecklistTimelineBaseComponent implements AfterViewInit {
  customerEvent$: Observable<CustomerEvent>;
  dateHover: Date;
  viewInitDone = false;
  checklistMonths$;
  hideCompletedControl = new FormControl(false);
  @Input()
  paymentsOnly = false;
  constructor(
    private context: ContextService,
    private customerEventService: CustomerEventService,
    private store: Store,
    private checklistItemService: ChecklistItemService,
    private cd: ChangeDetectorRef
  ) {
    super();
    this.customerEvent$ = this.context.customerEvent$;
    this.store
      .select((s: AppState) => s.ui.hideCompleted)
      .pipe(filter(o => o !== undefined), first())
      .subscribe((b) => (this.hideCompletedControl.setValue(b)));
    this.hideCompletedControl.valueChanges.pipe(filter(o => o !== undefined), distinctUntilChanged())
      .subscribe(hide => this.store.dispatch(uiHideCompleted({ hide })));
  }
  selectedDate(
    { day, e },
    customerEvent: CustomerEvent,
  ) {
    this.customerEventService.updateChecklistItem(
      customerEvent,
      {
        ...e.item.data.data,
        dueDate: day.date,
      },
      e.item.data
    );
  }

  deleteItem(item: ChecklistItem) {
    if (item.type !== 'eventItem') {
      this.checklistItemService
        .deleteEntity((item as ChecklistItemImp).data)
        .subscribe(() => this.cd.markForCheck());
    }
  }
  trackByIdFn(i, o) {
    return o.id;
  }

  updateItem(
    customerEvent: CustomerEvent,
    e: { data: ChecklistItemData; highlight: boolean; done: () => void },
    item: ChecklistItem
  ) {
    this.customerEventService
      .updateChecklistItem(customerEvent, e.data, item, e.highlight)
      .subscribe({ complete: () => e.done() });
  }


  ngAfterViewInit(): void {
    this.viewInitDone = true;
  }
}
