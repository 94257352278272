import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRipple } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { getErrorMessageHandler } from '@skylitup/base/util';
import {
  Agency,
  AppState, Auth,
  ChatTrack, ChatTrackService, ContextService, Customer,
  CustomerDataType, CustomerService,
  CUSTOMER_EVENT_KEYS, CUSTOMER_KEYS, uiCustomerListStyle,
  uiCustomerListType
} from '@skylitup/flowergirl/core';
import { CustomerListBaseComponent } from '@skylitup/flowergirl/features/navigate';
import * as moment from 'moment';
import { combineLatest, ConnectableObservable, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  publishBehavior,
  startWith,
  takeUntil,
  tap
} from 'rxjs/operators';

interface CustomersGroupedByMonth {
  id: string;
  year: string;
  month: string;
  date: Date;
  customers: Customer[];
  events: { earliestDate: Date, latestDate: Date, name: string, eventId: string, link: string[] }[];
}
interface YearStats {
  total: number;
  years: { y: string; n: number }[];
}
@Component({
  selector: 'flrgl-customer-list',
  templateUrl: 'customer-list.component.html',
  styleUrls: ['customer-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerListComponent extends CustomerListBaseComponent {
  // customers$: Observable<Customer[]>;
  customersPast$: Observable<Customer[]>;
  customersUpcoming$: Observable<Customer[]>;
  statsUpcoming$: Observable<YearStats>;
  statsPast$: Observable<YearStats>;
  agency$: Observable<Agency>;
  customerNameFilter = new FormControl();
  listStyle = new FormControl('cards');
  type = new FormControl('client');
  auth$: Observable<Auth>;
  creatingAccountFlag = false;
  customersPastGroupedByMonth$: Observable<CustomersGroupedByMonth[]>;
  customersUpcomingGroupedByMonth$: Observable<CustomersGroupedByMonth[]>;
  chatTrackMap$: Observable<Dictionary<ChatTrack>>;

  constructor(
    private customerService: CustomerService,
    private context: ContextService,
    private matDialog: MatDialog,
    private router: Router,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    public chatTrackService: ChatTrackService
  ) {
    super();
    this.agency$ = this.context.agency$;
    this.auth$ = this.context.auth$;
    this.chatTrackMap$ = this.chatTrackService.chatTrackByEventId$;
    this.store
      .select((s: AppState) => s.ui.uiCustomerListStyle)
      .pipe(
        filter((s) => s !== null),
        first()
      )
      .subscribe((style) => {
        if (this.listStyle.value !== style) {
          this.listStyle.setValue(style, { emitEvent: true });
        }
      });
    this.store
      .select((s: AppState) => s.ui.uiCustomerListType)
      .pipe(
        filter((s) => s !== null),
        first()
      )
      .subscribe((type) => {
        if (this.type.value !== type) {
          this.type.setValue(type, { emitEvent: true });
        }
      });

    this.listStyle.valueChanges
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((style) =>
        this.store.dispatch(uiCustomerListStyle({ style }))
      );

    this.type.valueChanges
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((listType: CustomerDataType | null) =>
        this.store.dispatch(uiCustomerListType({ listType }))
      );

    // this.showDemoSwitch.valueChanges
    //   .pipe(takeUntil(this.destroy$), distinctUntilChanged())
    //   .subscribe((show) => this.store.dispatch(uiShowDemo({ show })));

    this.customersPast$ = combineLatest([
      this.customerNameFilter.valueChanges.pipe(startWith(null as string)),
      this.type.valueChanges.pipe(
        startWith(this.type.value || 'client'),
        // tapDebug(a => ['111', a, this.showDemoSwitch.value])
      ),
      customerService.customersInContext$.pipe(
        map((a) => a.filter((o) => o.earliestEventDate?.getTime() < Date.now()))
      ),
      this.context.auth$.pipe(filter((o) => !!o)),
    ]).pipe(filterPipe$(true), publishBehavior([]));

    this.customersUpcoming$ = combineLatest([
      this.customerNameFilter.valueChanges.pipe(startWith(null as string)),
      this.type.valueChanges.pipe(
        startWith(this.type.value || 'client'),
      ),
      customerService.customersInContext$.pipe(
        map((a) => a.filter((o) => o.earliestEventDate?.getTime() >= Date.now()))
      ),
      this.context.auth$.pipe(filter((o) => !!o)),
    ]).pipe(filterPipe$(false), publishBehavior([]));

    (<ConnectableObservable<Customer[]>>this.customersUpcoming$).connect();
    (<ConnectableObservable<Customer[]>>this.customersPast$).connect();

    this.customersPastGroupedByMonth$ =
      combineLatest([this.customersPast$, this.chatTrackMap$])
        .pipe(groupedByMonth$);
    this.customersUpcomingGroupedByMonth$ =
      combineLatest([this.customersUpcoming$, this.chatTrackMap$])
        .pipe(groupedByMonth$);

    this.statsUpcoming$ = this.customersUpcoming$.pipe(yearStats);
    this.statsPast$ = this.customersPast$.pipe(yearStats);

    function yearStats(o: Observable<Customer[]>): Observable<YearStats> {
      return o.pipe(
        map((customers) => {
          const result = { total: customers.length, years: [] };
          const index = {};
          for (const c of customers) {
            const m = moment(c.earliestEventDate);
            const year = m.format('YYYY');
            if (!index[year]) {
              index[year] = { y: year, n: 0 };
            }
            index[year].n += 1;
          }
          result.years = Object.values(index);
          return result;
        })
      );
    }

    function groupedByMonth$(
      o: Observable<[Customer[], Dictionary<ChatTrack>]>
    ): Observable<CustomersGroupedByMonth[]> {
      return o.pipe(
        map(([customers, chatTrackMap]) => {
          const index: { [i: string]: CustomersGroupedByMonth } = {};
          for (const c of customers) {
            const m = moment(c.earliestEventDate);
            const month = m.format('MMMM');
            const year = m.format('YYYY');
            const id = year + month;
            const date = moment(m).startOf('month').toDate();
            if (!index[id]) {
              index[id] = { month, year, id, customers: [], date, events: [] };
            }

            index[id].customers.push(c);
            index[id].events.push(...c.eventsInfo.map(e => ({
              eventId: e.id, name: `${c.name} ${e.name}`,
              earliestDate: e.earliestDate, latestDate: e.latestDate,
              link: !!chatTrackMap?.[e.id]?.sumUnread ?
                [CUSTOMER_KEYS.name, c.id, CUSTOMER_EVENT_KEYS.name, e.id, 'chat', 'recent']
                : [CUSTOMER_KEYS.name, c.id, CUSTOMER_EVENT_KEYS.name, e.id]
            })));
          }
          return Object.values(index);
        })
      );
    }

    // this.customerNameFilter.valueChanges.pipe(startWith(null as string)),
    // this.type.valueChanges.pipe(
    //   startWith(this.type.value || 'client'),
    //   // tapDebug(a => ['111', a, this.showDemoSwitch.value])
    // ),
    // customerService.customersInContext$.pipe(
    //   map((a) => a.filter((o) => o.earliestEventDate?.getTime() < Date.now()))
    // ),
    // this.context.auth$.pipe(filter((o) => !!o)),


    function filterPipe$(
      reverse: boolean
    ): (o: Observable<[string, string, Customer[], Auth]>) => Observable<Customer[]> {
      return (o) =>
        o.pipe(
          // tapDebug(a => ['r', reverse, a]),
          map(([text, type, customers, auth]) =>
            customers.filter(
              (c: Customer) =>
                (
                  !auth.isPlanner ||
                  (type === 'client' && c.data.type === 'client') ||
                  (type === 'demo' && c.data.type === 'demo') ||
                  (type === 'archive' && c.data.type === 'archive') ||
                  (type === 'lead' && c.data.type === 'lead') ||
                  (type === 'other' && c.data.type === 'other')
                ) &&
                (text == null ||
                  c.name.toLowerCase().includes(text.toLowerCase()))
            )
          ),
          map((a) =>
            a.sort((c1, c2) => {
              // return first demo accounts and of those first those that have no users at all
              if (c1.isDemo && c2.isDemo) {
                const r = demoSortVal(c1) - demoSortVal(c2);
                if (r === 0) {
                  return (
                    c1.earliestEventDate.getTime() - c2.earliestEventDate.getTime()
                  );
                } else {
                  return r;
                }
              } else {
                let d = +c2.isDemo - +c1.isDemo;
                if (d === 0) {
                  d =
                    c1.earliestEventDate.getTime() - c2.earliestEventDate.getTime();
                  if (reverse) {
                    d = -d;
                  }
                }
                return d;
              }
              function demoSortVal(c: Customer) {
                if (c.isDemo) {
                  if (
                    c.data?.customers?.length +
                    c.data?.readOnlyUsers?.length ===
                    0
                  ) {
                    return 0;
                  } else if (c.data.type === 'demo') {
                    return 1;
                  }
                }
                return 2;
              }
            })
          )
        );
    }
  }

  getSumUnreadForCustomer(customer: Customer, chatTrackMap: Dictionary<ChatTrack>) {
    let unread = 0;
    for (const info of customer.eventsInfo) {
      unread += (chatTrackMap?.[info.id]?.sumUnread) || 0;
    }
    return unread;
  }
  navigateToCustomer(customer: Customer, rippler: MatRipple) {
    rippler.launch(null);
    setTimeout(() => {
      this.router.navigate(['customer', customer.id], { relativeTo: this.activatedRoute })
    }, 300)

  }
  navigateToEditCustomer(customer: Customer) {
    this.router.navigateByUrl(customer.editUrl);
  }
  asYearStats(a): YearStats {
    return a as YearStats;
  }
  clickedEvent(e: any) {
    this.router.navigate(e.link, { relativeTo: this.activatedRoute });
  }

  asCustomer(c): Customer {
    return c as Customer;
  }
  json(j) {
    return JSON.stringify(j, null, 2);
  }
  addCustomer(agency: Agency, auth: Auth) {
    this.matDialog
      .open(CreateCustomerPopupComponent)
      .afterClosed()
      .pipe(
        first(),
        filter((n) => !!n),
        mergeMap((name) => this.customerService.create({ agency, auth, name })),
        mergeMap((c) =>
          of(c).pipe(
            tap((_) => (this.creatingAccountFlag = true)),
            // mergeMap((_) => this.auth$),
            // filter((auth1) => auth1.data?.access?.assignee.includes(c.meta.id)),
            // first(),
            tap((_) => (this.creatingAccountFlag = false)),
            map((_) => c)
          )
        )
      )
      .subscribe((c) => this.router.navigateByUrl(Customer.getEditUrl(c)));
  }
}
@Component({
  templateUrl: 'create-customer-popup.component.html',
  styles: [''],
})
export class CreateCustomerPopupComponent {
  getErrorMessage = getErrorMessageHandler;
  form = new FormGroup({ name: new FormControl('', Validators.required) });
  get name() {
    return this.form.get('name');
  }
  constructor(private dialogRef: MatDialogRef<CreateCustomerPopupComponent>) { }
  keyUp(code: number) {
    // console.log(code);
    if (code === 13) {
      this.dialogRef.close(this.name.value);
    }
  }
}
