import { Component } from '@angular/core';

 import { Router } from '@angular/router';
import { VerifyEmailBaseComponent } from '@skylitup/flowergirl/features/navigate';
import { AuthService } from '@skylitup/flowergirl/core';
 
@Component({
  selector: 'flrgl-verify-email',
  templateUrl: 'verify-email.component.html',
  styles: ['.title{ font-size:2em;height:30vh}']
})
export class VerifyEmailComponent extends VerifyEmailBaseComponent {
  constructor(router: Router, authService: AuthService) {
    super();
  }
}
