import { Component, Input } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ChatThumbBaseComponent } from '@skylitup/flowergirl/features/chat';
import {
  Dialog,
  Auth,
  ContextService,
  VendorCat,
  VendorResearch, ChatTrackService, ChatTrack
} from '@skylitup/flowergirl/core';

@Component({
  selector: 'flrgl-chat-thumb',
  templateUrl: 'chat-thumb.component.html',
  styleUrls: ['chat-thumb.component.scss'],
})
export class ChatThumbComponent extends ChatThumbBaseComponent {
  @Input()
  dialog: Dialog;
  @Input()
  active: boolean;
  @Input()
  hideName = false;
  @Input()
  inOverview = false;
  auth$: Observable<Auth>;
  customerId: string;
  chatTrack$: Observable<ChatTrack>;
  chatTrackDebug = false;

  constructor(private context: ContextService,
    private chatTrackService: ChatTrackService
  ) {
    super();
    this.auth$ = this.context.auth$;

    this.context.customerId$
      .pipe(takeUntil(this.destroy$))
      .subscribe((customerId) => (this.customerId = customerId));

    this.chatTrack$ = this.chatTrackService.chatTrackInContext$;
    this.chatTrackDebug = this.chatTrackService.DEBUG_MODE;

  }
  name(auth) {
    if (this.dialog.type === 'general') {
      return 'General Chat';
    } else if (this.dialog.type === 'vendorCat') {
      const vc = this.dialog.dialogObj as VendorCat;
      return vc.getControlledName(this.customerId, auth);
    } else if (this.dialog.type === 'vendorResearch') {
      const vr = this.dialog.dialogObj as VendorResearch;
      return vr.getControlledName(this.customerId, auth);
    } else {
      return this.dialog.dialogObj.name;
    }
  }
}
