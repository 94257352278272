import { BaseComponent } from '@skylitup/base/util';

 
export abstract class ChecklistByCatBaseComponent extends BaseComponent {
  public text = 'ChecklistByCat';

  constructor() {
    super();
  }
}
