import { Injectable } from '@angular/core';
import {
  firestoreRealtime,
  firestoreRealtimeNoSyncing,
} from '@skylitup/base/ngrx-data-fire';
import {
  Actions,
  createEffect,
  ofType,
  EffectNotification,
} from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { combineLatest, of, Observable } from 'rxjs';
import { distinct, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { AgencyService } from '../agency/agency.service';
import { CustomerEventService } from '../customer-event/customer-event.service';
import { CustomerService } from '../customer/customer.service';
import { EVENT_ITEM_KEYS } from './event-item.model.data';
import { EventItemService } from './event-item.service';
import { AGENCY_KEYS } from '../agency/agency.model.data';
import { CUSTOMER_KEYS } from '../customer/customer.model.data';
import { CUSTOMER_EVENT_KEYS } from '../customer-event/customer-event.model.data';
import { NotificationService } from '../../util/services/notification.service';
import { genericCatch$$ } from '@skylitup/base/util';
import { syncChecklistBigPlan } from '../checklist-item/checklist-item.actions';

@Injectable({ providedIn: 'root' })
export class EventItemEffects {
  eventItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      switchMap((_) =>
        combineLatest([
          this.agencyService.entityIdInContext$,
          this.customerService.entityIdInContext$,
          this.customerEventService.entityIdInContext$,
        ])
      ),
      distinctUntilChanged(null, (a) => a.join('')),
      switchMap((a) =>
        of(a).pipe(
          switchMap(([agencyId, customerId, customerEventId]) =>
            agencyId && customerId && customerEventId
              ? this.eventItemService
                  .syncEventItems$(agencyId, customerId, customerEventId)
                  .pipe(
                    map((_) =>
                      firestoreRealtime(
                        `${AGENCY_KEYS.plural}/${agencyId}/${CUSTOMER_KEYS.plural}/${customerId}/${CUSTOMER_EVENT_KEYS.plural}/${customerEventId}/${EVENT_ITEM_KEYS.plural}`
                      )
                    )
                  )
              : of(firestoreRealtimeNoSyncing('event-items'))
          ),
          genericCatch$$('EventItemEffects - customerEventContext')
        )
      )
    )
  );

  bigPlanCurrent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncChecklistBigPlan),
      distinctUntilChanged(null, (a) => a.sync),
      switchMap((action) =>
        this.agencyService.entityIdInContext$.pipe(
          switchMap((agencyId) =>
            action.sync && agencyId
              ? this.eventItemService
                  .syncBigPlan$(agencyId)
                  .pipe(
                    map((_) =>
                      firestoreRealtime(
                        `/**/${EVENT_ITEM_KEYS.plural} | scoped by agency: ${agencyId}`
                      )
                    )
                  )
              : of(
                  firestoreRealtimeNoSyncing(`**/${EVENT_ITEM_KEYS.plural}`)
                )
          ),
          genericCatch$$('EventItemEffects - agencyContext')
        )
      )
    )
  );
  constructor(
    private actions$: Actions,
    private agencyService: AgencyService,
    private customerService: CustomerService,
    private customerEventService: CustomerEventService,
    private eventItemService: EventItemService,
    private notifications: NotificationService
  ) {}
  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.notifications.trackCompletion(resolvedEffects$);
  }
}
