import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  EntityAction,
  EntityActionDataServiceError,
  EntityOp,
  ofEntityOp,
} from '@ngrx/data';
import {
  Actions,
  createEffect,
  EffectNotification,
  ofType,
  OnRunEffects,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  firestoreRealtime,
  firestoreRealtimeNoSyncing,
  EntityOpsExtra,
} from '@skylitup/base/ngrx-data-fire';
import {
  genericCatch$$,
  genericError,
  WindowService,
} from '@skylitup/base/util';
import { merge, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { AgencyService } from '../../domain-model/agency/agency.service';
import { AuthService } from '../../domain-model/auth/auth.service';
import {
  idTokenResult,
  loginSuccess,
  logoutSuccess,
} from '../../domain-model/auth/state/auth.actions';
import { SYSTEM_KEYS } from '../../domain-model/system/system.model';
import { SystemService } from '../../domain-model/system/system.service';
import { uiFocusChat } from '../../domain-model/ui/state/ui.actions';
import { NotificationService } from '../../util/services/notification.service';

@Injectable()
export class InitEffects implements OnRunEffects {
  idTokenResultSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(idTokenResult),
      // tap(_ => this.clearStore()),
      switchMap(() =>
        this.authService.auth$.pipe(
          // filter(auth => auth.isLoggedIn && !auth.isLoggingOut),
          // first(),
          distinctUntilChanged(
            null,
            (auth) => auth.userId + auth.isLoggedIn + auth.isLoggingOut
          ),
          switchMap((auth) =>
            of(auth).pipe(
              switchMap((__) =>
                auth.userId && auth.isLoggedIn && !auth.isLoggingOut
                  ? merge(
                    this.systemService
                      .syncDocRealtime$([SYSTEM_KEYS.name], 'data')
                      .pipe(map((_) => firestoreRealtime(`system.data`))),
                    of(auth).pipe(
                      switchMap(
                        () =>
                          // auth.isPlanner?
                          this.agencyService
                            .syncAgencies$(auth.userId)
                            .pipe(
                              map((_) =>
                                firestoreRealtime(`agencies | by planner`)
                              )
                            )
                        // : of(
                        //     firestoreRealtimeNoSyncing(
                        //       'agencies | by planner'
                        //     )
                        //   )
                      )
                    )
                    , of(uiFocusChat(null))
                    // of(auth).pipe(
                    //   switchMap(
                    //     () =>
                    //       // auth.isCustomer?
                    //       this.agencyService
                    //         .syncAgenciesByCustomer$(auth.userId)
                    //         .pipe(
                    //           map(_ =>
                    //             firestoreRealtime(`agencies | by customer`)
                    //           )
                    //         )
                    //     // : of(
                    //     //     firestoreRealtimeNoSyncing(
                    //     //       'agencies | by customer'
                    //     //     )
                    //     //   )
                    //   )
                    // )
                  ).pipe(genericCatch$$('InitEffects-loginSuccess$'))
                  : of(
                    firestoreRealtimeNoSyncing(
                      'systemData or agencies (-loggedout)'
                    )
                  )
              )
            )
          )
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccess),
        tap((_) => this.reloadRoute())
      ),
    { dispatch: false }
  );

  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutSuccess),
        tap((_) => this.clearStore()),
        tap((_) => this.reloadRoute())
      ),
    { dispatch: false }
  );

  genericError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(genericError),
        tap((error) => this.notifications.error(error.hint, error.msg))
      ),
    { dispatch: false }
  );

  ngrxDataErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofEntityOp(
          EntityOp.QUERY_ALL_ERROR,
          EntityOp.QUERY_ALL_ERROR,
          EntityOp.QUERY_LOAD_ERROR,
          EntityOp.QUERY_MANY_ERROR,
          EntityOp.QUERY_BY_KEY_ERROR,
          EntityOp.SAVE_ADD_MANY_ERROR,
          EntityOp.SAVE_ADD_ONE_ERROR,
          EntityOp.SAVE_DELETE_MANY_ERROR,
          EntityOp.SAVE_DELETE_ONE_ERROR,
          EntityOp.SAVE_UPDATE_MANY_ERROR,
          EntityOp.SAVE_UPDATE_ONE_ERROR,
          EntityOp.SAVE_UPSERT_MANY_ERROR,
          EntityOp.SAVE_UPSERT_ONE_ERROR,
          EntityOpsExtra.SAVE_BATCH_UPDATE_ERROR
        ),
        map((action: EntityAction<EntityActionDataServiceError>) => ({
          hint: action.type,
          msg: action.payload.data.error.message,
        })),
        tap((error) => this.notifications.error(error.hint, error.msg))
      ),
    { dispatch: false }
  );

  clearStore() {
    this.systemService.clearCache();
    this.agencyService.clearCache();
  }

  constructor(
    private actions$: Actions,
    private systemService: SystemService,
    private authService: AuthService,
    private agencyService: AgencyService,
    store: Store,
    private notifications: NotificationService,
    windowService: WindowService,
    private router: Router
  ) {
    windowService.onerror((message, file, line, col, error) => {
      store.dispatch(genericError({ msg: message, hint: error }));
    });
  }

  reloadRoute() {
    const currentUrl = this.router.url;
    this.router
      .navigateByUrl('/login', { skipLocationChange: true })
      .then(() => this.router.navigate([currentUrl]));
  }
  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.notifications.trackCompletion(resolvedEffects$);
  }
}
