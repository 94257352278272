import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  EffectNotification,
  ofType,
} from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import {
  firestoreRealtime,
  firestoreRealtimeNoSyncing,
} from '@skylitup/base/ngrx-data-fire';
import { combineLatest, Observable, of } from 'rxjs';
import { distinct, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { AGENCY_KEYS } from '../agency/agency.model.data';
import { AgencyService } from '../agency/agency.service';
import { CustomerService } from '../customer/customer.service';
import { vendorsSyncAll } from './vendor.actions';
import { VENDOR_KEYS } from './vendor.model.data';
import { VendorService } from './vendor.service';
import { NotificationService } from '../../util/services/notification.service';
import { genericCatch$$ } from '@skylitup/base/util';

// keep the previous vendorid syncs ;) (use distinct instead of distinctuntilchanged and merge instead of switch ;))
@Injectable({ providedIn: 'root' })
export class VendorEffects {
  vendorsForAgency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsSyncAll),
      distinctUntilChanged(null, (a) => a.sync),
      switchMap((action) =>
        this.agencyService.entityIdInContext$.pipe(
          switchMap((agencyId) =>
            agencyId && action.sync
              ? this.vendorService
                  .syncVendorsForAgency$(agencyId)
                  .pipe(
                    map((_) =>
                      firestoreRealtime(
                        `${AGENCY_KEYS.plural}/${agencyId}/${VENDOR_KEYS.plural} - scoped by agency: ${agencyId}`
                      )
                    )
                  )
              : of(firestoreRealtimeNoSyncing('vendors scoped by agency'))
          ),
          genericCatch$$('vendorsForAgency - agencyContext')
        )
      )
    )
  );

  vendorsForCustomer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction, vendorsSyncAll),
        // takeWhile(action => action.type !== vendorsSyncAll.type),
        switchMap((_) => {
          return combineLatest([
            this.agencyService.entityIdInContext$,
            this.customerService.entityIdInContext$,
          ]);
        }),
        distinctUntilChanged(null, (a) => a.join('')),
        switchMap((a) =>
          of(a).pipe(
            switchMap(([agencyId, customerId]) =>
              agencyId && customerId
                ? this.vendorService
                    .syncVendorsForCustomer$(agencyId, customerId)
                    .pipe(
                      map((_) =>
                        firestoreRealtime(
                          `${AGENCY_KEYS.plural}/${agencyId}/${VENDOR_KEYS.plural} - scoped by customer: ${customerId}`
                        )
                      )
                    )
                : of(firestoreRealtimeNoSyncing('vendors scoped by customer'))
            ),
            genericCatch$$('vendorsForCustomer - customerContext')
          )
        )
      ),
    { useEffectsErrorHandler: false }
  );

  
  constructor(
    private actions$: Actions,
    private agencyService: AgencyService,
    private customerService: CustomerService,
    private vendorService: VendorService,
    private notifications: NotificationService
  ) {}
  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.notifications.trackCompletion(resolvedEffects$);
  }
}
