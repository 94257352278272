import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { OverviewEventItemBaseComponent } from '@skylitup/flowergirl/features/overview';
import { ContextService } from '@skylitup/flowergirl/core';

@Component({
  selector: 'flrgl-overview-event-item',
  templateUrl: 'overview-event-item.component.html',
  styleUrls: ['overview-event-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewEventItemComponent extends OverviewEventItemBaseComponent {
  constructor(private context: ContextService, cd: ChangeDetectorRef) {
    super();
    context.customerEvent$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => cd.markForCheck());
  }
}
