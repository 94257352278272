import { EntityProjected } from '@skylitup/base/ngrx-data-fire';
import { Loc } from '../agency/location.model';
import { Auth } from '../auth/auth.model';
import { Media } from '../upload/media/media.model';
import { Category } from '../system/system.model';
import { VENDOR_ACCOMODATION_DOMAIN_LIST, VENDOR_VENUE_DOMAIN_LIST } from './vendor-dynamic.model';
import { VendorData } from './vendor.model.data';


export const VENDOR_DYNAMIC_DOMAIN = {
  venue: VENDOR_VENUE_DOMAIN_LIST,
  accommodation: VENDOR_ACCOMODATION_DOMAIN_LIST
};

export class Vendor extends EntityProjected<VendorData> {
  cats: Category[] = [];
  media: Media[] = [];
  locations: Loc[] = [];
  coverage: Loc[] = [];
  codeName: string;
  constructor(data) {
    super(data);
    this.codeName = EntityProjected.hashCode2(this.id);
  }
  getControlledName(customerId: string, auth: Auth) {
    if (this.isMasked(customerId, auth)) {
      return 'Vendor [' + this.codeName + ']';
    } else {
      return this.data.name;
    }
  }
  isMasked(customerId: string, auth: Auth) {
    return auth &&
      (auth.data.impersonatingCustomer ||
        (customerId &&
          // auth.data.access?.readonly &&
          // auth.data.access?.readonly.includes(customerId)
          auth.userCard &&
          auth.userCard.isVendorsMasked(customerId)
        )
      );
  }
  clone(): Vendor {
    return new Vendor(this.data).copy(this);
  }

  copy(from: Vendor): Vendor {
    this.cats = from.cats;
    this.media = from.media;
    this.locations = from.locations;
    this.coverage = from.coverage;
    return this;
  }
  get effectiveMapCenter() {
    return this.data.mapCenter || this.data.addressGeoCenter;
  }

  public get instagramOrWebText() {
    if (this.data?.links?.instagram) {
      return `(${this.data?.links?.instagram})`
    } else if (this.data?.links?.www) {
      return `( ${this.data?.links?.www})`
    } else if (this.data?.links?.facebook) {
      return `(${this.data?.links?.facebook})`
    } return '';
  }
}
