import { EntityKeys } from '@skylitup/base/ngrx-data-fire';
import { UploadData } from '../upload.model.data';

export const MEDIA_KEYS: EntityKeys = {
  name: 'media',
  plural: 'media'
};
export interface MediaData extends UploadData {
  isImg: boolean;
  urlSmall?: string;
  urlMedium?: string;
  urlLarge?: string;
}

