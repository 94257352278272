import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FocusedSort, FocusSortClick, VendorSort } from './vendor-sort';
import { VendorParam, VendorParamSelect } from '@skylitup/flowergirl/core';
 

@Component({
  selector: 'flrgl-vendor-sort',
  templateUrl: 'vendor-sort.component.html',
  styleUrls: ['vendor-sort.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VendorSortComponent {
  @Input()
  vendorSort: VendorSort;
  removeFocusSort() {
    this.vendorSort.removeFocusSort();
  }
  toggleOrderFocusSort(f: FocusedSort) {
    this.vendorSort.toggleOrderFocusSort(f);
  }
  asVendorSelectParam(config: VendorParam): VendorParamSelect {
    return config as VendorParamSelect;
  }

  focusSortSelectChanged(e: FocusSortClick, value: string) {
    this.vendorSort.focusSortSelectChanged(e, value);
  }
}
