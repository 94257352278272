import { Dictionary } from '@ngrx/entity';
import { EntityProjected } from '@skylitup/base/ngrx-data-fire';
import { Auth } from '../auth/auth.model';
import { Dialog, HasDialog } from '../chat/chat.model';
import { CustomerEvent } from '../customer-event/customer-event.model';
import {
  calcBudget,
  calculationTypeLabel,
  getCalcNumber
} from '../event-item/budget-item.model.data';
import { Vendor } from '../vendor/vendor.model';
import { BudgetResearchItemData, VendorResearchData } from './vendor-research.model.data';

 
export class VendorResearch extends EntityProjected<VendorResearchData>
  implements HasDialog {
  dialog: Dialog;
  vendor: Vendor;
  customerEvent: CustomerEvent;
  _itemMap: Dictionary<BudgetResearchItem> = {};
  linkedItemMap: Dictionary<BudgetResearchItem> = {};
  constructor(data: VendorResearchData) {
    super(data);
    this.dialog = new Dialog(this, 'vendorResearch');
    this.setupLinkedItemMap();
    this.setupItemMap();
  }
  getControlledName(customerId: string, auth: Auth) {
    return this.vendor?.getControlledName(customerId, auth);
  }
  private setupItemMap() {
    const brs = this.data.budgetResearch || {};
    this._itemMap = Object.keys(brs).reduce((r, k) => {
      if (brs[k]) {
        const br = brs[k];
        const items = Object.values(brs[k].items || {});
        items.forEach(item => {
          r[
            br.candiDate + br.version + item.priceItemId
          ] = new BudgetResearchItem(item, br.candiDate, br.version, this);
        });
      }
      return r;
    }, {});
  }

  private setupLinkedItemMap() {
    const l = this.data.linkedBudgetResearch || {};
    this.linkedItemMap = Object.keys(l).reduce((r, k) => {
      if (l[k]) {
        r[k] = new BudgetResearchItem(l[k], null, null, this);
      }
      return r;
    }, {});
  }

  get name() {
    return this.data.name || this?.vendor?.name || null;
  }
  get event() {
    return this.customerEvent;
  }
  // // cats: EventCategory[];
  clone(): VendorResearch {
    return new VendorResearch(this.data).copy(this);
  }
  get rating() {
    return this.data.rating || -1;
  }
  getItem(
    candiDateId: string,
    versionId: string,
    priceItemId: string
  ): BudgetResearchItem {
    return this._itemMap[candiDateId + versionId + priceItemId];
  }
  copy(from: VendorResearch): VendorResearch {
    this.vendor = from.vendor;
    this.customerEvent = from.customerEvent;
    this.dialog = from.dialog;
    // this.cats = [...from.cats];
    return this;
  }

  getTotal(researchCatId: string, candiDateId: string, versionId: string) {
    let total = 0;
    if (this.isVersionNA(versionId) || this.isCandiDateNA(candiDateId)) {
      return 0;
    } else {
      for (const priceItem of this.customerEvent.getResearchPriceItems(
        researchCatId
      )) {
        const calc = this.getCalculation(candiDateId, versionId, priceItem.id);
        if (!calc?.data?.na) {
          total +=
            calc?.calcBudget ||
            0;
        }
      }
      return total;
    }
  }

  getVersionNA(versionId: string) {
    return this.data?.naVersions?.[versionId];
  }
  getCandiDateNA(candiDateId: string) {
    return this.data?.naCandiDates?.[candiDateId];
  }

  isVersionNA(versionId: string) {
    return !!this.getVersionNA(versionId)?.na;
  }
  isCandiDateNA(candiDateId: string) {
    return !!this.getCandiDateNA(candiDateId)?.na;
  }

  getCalculation(
    candiDateId: string,
    versionId: string,
    priceItemId: string
  ): BudgetResearchItem {
    return (
      (this.linkedItemMap || {})[priceItemId] ||
      this.getItem(candiDateId, versionId, priceItemId) ||
      null
    );
  }
  getNotes(
    candiDateId: string,
    versionId: string,
    priceItemId: string
  ): string {
    return this.getCalculation(candiDateId, versionId, priceItemId)?.data?.notes || null;
  }

  isPriceSet(candiDateId: string, versionId: string, priceItemId: string) {
    const calc = this.getCalculation(candiDateId, versionId, priceItemId);
    if (!calc) {
      return false;
    }
    return (
      calc.data.calcType !== 'none' ||
      (calc.data.budget !== null && calc.data.budget !== undefined)
    );
  }

  isLinked(priceItemId: string) {
    return !!(this.linkedItemMap || {})[priceItemId];
  }
  isLinkedSource(priceItemId: string, versionId: string, candidateId: string) {
    const d = (this.data.linkedBudgetResearch || {})[priceItemId];
    return d && d.srcVersion === versionId && d.srcCandiDate === candidateId;
  }
}
export class BudgetResearchItem {
  constructor(
    public data: BudgetResearchItemData,
    public candiDateId,
    public versionId,
    public vr: VendorResearch
  ) { }
  get calculationLabel() {
    return calculationTypeLabel(this.data);
  }
  get calculationNumber() {
    return getCalcNumber(this.vr?.customerEvent.data, this.data) || 0;
  }
  get calculationItemPrice() {
    return this.data?.calcItemPrice || 0;
  }
  get calcBudget() {
    return calcBudget(this.vr?.customerEvent.data, this.data) || 0;
  }
  get notes() {
    return (this.data.notes || "").trim();
  }
}

// export class BudgetResearch {
//   constructor(
//     public data: BudgetResearchData,
//     public vendorResearch: VendorResearch
//   ) {}
// }
// export interface PriceItemDefData extends VendorResearchItemDefData {
//   linked: boolean;
//   val?: BudgetItemData;
// }
