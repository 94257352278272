<ng-container *ngIf="agencyInContext$ |async as agency; else loading1">
    <ng-container *ngIf="customersInContext$ | async as customers; else loading1">
        <ng-container *ngIf="checklistMonths$ | async as checklistMonths; else loading1">
            <div fxLayout="row wrap" fxLayoutAlign="start center" class="hide-toggle" fxLayoutGap="10px">
                <!-- <flrgl-go-back align="start" [path]="['..']" *ngIf="paymentsOnly">Back To Budget</flrgl-go-back> -->

                <div class="assignee" fxFlex="none">
                    <flrgl-entity-autocomplete [control]="assigneesAutocomplete" class="items-filter">
                    </flrgl-entity-autocomplete>
                </div>
                <div class="customer" fxFlex="none">
                    <flrgl-entity-autocomplete [control]="customersAutocomplete" class="items-filter">
                    </flrgl-entity-autocomplete>
                </div>
                <mat-slide-toggle color="primary" [formControl]="hideCompletedItems" fxFlex="none">
                    <!-- [color]="color" [checked]="checked" [disabled]="disabled" -->
                    Hide Completed
                </mat-slide-toggle>
                <!-- <flrgl-go-back align="start" [path]="['..']" *ngIf="paymentsOnly">Back To Budget</flrgl-go-back> -->
                <!-- <div fxFlex="grow"></div> -->
                <mat-slide-toggle (change)="expandAllMonthsChange($event)" color="primary" [(ngModel)]="expandAllMonths"
                    fxFlex="none">
                    <!-- [color]="color" [checked]="checked" [disabled]="disabled" -->
                    Expand All Months </mat-slide-toggle>

            </div>

            <mat-accordion multi="true">
                <mat-expansion-panel *ngFor="let checklist of checklistMonths;trackBy:trackByIdFn" #panel1
                    [expanded]="expandAllMonths">
                    <mat-expansion-panel-header *ngIf="checklist.items.length">
                        <mat-panel-title>
                            <div class="title">{{checklist.title}}</div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent *ngIf="checklist.items.length">
                        <cdk-virtual-scroll-viewport itemSize="90" [style]="'height:'+ checklist.items.length*90 +'px'">

                            <div *cdkVirtualFor="let item of checklist.items; trackBy:trackByIdFn">
                                <flrgl-checklist-item [item]="item" [showCat]="true" [showEvent]="true"
                                    (deleted)="deleteItem(item)" (itemChange)="updateItem(item.event,$event,item)">
                                </flrgl-checklist-item>
                            </div>
                        </cdk-virtual-scroll-viewport>
                    </ng-template>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #loading1>
    <div class="loading-msg">
        <i class="fa fa-cog fa-spin fa-3x fa-fw" aria-hidden="true"></i>
        <span class="sr-only"></span> Loading. Hang tight!
    </div>

</ng-template>