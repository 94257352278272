import { BaseComponent } from '@skylitup/base/util';


export abstract class LoginBaseComponent extends BaseComponent {
  public text = 'Login';

  constructor() {
    super();
  }
}
