import { Component, Inject, SecurityContext } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BaseComponent } from '@skylitup/base/util';

@Component({
  selector: 'flrgl-pdfbox',
  templateUrl: 'pdfbox.component.html',
  // styles: [
  //   'mat-dialog-container {padding: 0px !important;}',
  //   'img{max-height:80vh;max-width:80vw;}',
  // ],
})
export class PdfboxComponent extends BaseComponent {
  sanitizedUrl: SafeUrl;
  constructor(
    @Inject(MAT_DIALOG_DATA) public fileUrl: string,
    private dialogRef: MatDialogRef<PdfboxComponent>,
    private sanitizer: DomSanitizer
  ) {
    super();
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl)
  }
  close() {
    this.dialogRef.close();
  }
}
