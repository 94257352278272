import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { AppState } from '@skylitup/flowergirl/core';

@Injectable({ providedIn: 'root' })
export class TimeTravelService {
  fakeTime = null;
  active = false;
  get fakeTimeMoment() {
    return moment(this.fakeTime || new Date());
  }
  constructor(store: Store) {
    store
      .select((s: AppState) => s.auth)
      .subscribe((s) => (this.active = s.timeTravelling));
  }
}
