import {
  Component,
  ViewChild,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Store, createSelector } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ChecklistBaseComponent } from '@skylitup/flowergirl/features/checklist';
import { AppState, uiChecklistTabChanged } from '@skylitup/flowergirl/core';

@Component({
  selector: 'flrgl-checklist',
  templateUrl: 'checklist.component.html',
  styleUrls: ['checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistComponent extends ChecklistBaseComponent
  implements OnInit {
  checklistTabIndex$: Observable<number>;

  @Input()
  paymentsOnly = false;

  @ViewChild('tabGroup1') tabGroup: MatTabGroup;
  constructor(private store: Store) {
    super();
    this.checklistTabIndex$ = this.store.select(
      createSelector(
        (s: AppState) => s.ui,
        (s) => s.checklistTabIndex
      )
    );
  }
  tabChanged($event: MatTabChangeEvent) {
    this.store.dispatch(uiChecklistTabChanged({ index: $event.index }));
  }

  ngOnInit(): void {
    // this.checklistTabIndex$.subscribe(i => {
    //   this.tabGroup.selectedIndex = i;
    // });
    //
  }
}
