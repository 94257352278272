import { Injector, NgModule, Optional, SkipSelf } from '@angular/core';
import { EntityDataModule, EntityDataService } from '@ngrx/data';
import { EntityConfigModuleGenerator } from '@skylitup/base/ngrx-data-fire';
import { throwIfAlreadyLoaded } from '@skylitup/base/util';
import { AGENCY_KEYS } from './domain-model/agency/agency.model.data';
import { CHAT_TRACK_KEYS } from './domain-model/chat-track/chat-track.model.data';
import { CHAT_KEYS } from './domain-model/chat/chat.model.data';
import { CHECKLIST_ITEM_KEYS } from './domain-model/checklist-item/checklist-item.model.data';
import { CUSTOMER_EVENT_KEYS } from './domain-model/customer-event/customer-event.model.data';
import { CUSTOMER_KEYS } from './domain-model/customer/customer.model.data';
import { EVENT_ITEM_KEYS } from './domain-model/event-item/event-item.model.data';
import { MAIL_NOTIFICATION_KEYS } from './domain-model/mail-notifications-track/mail-notifications-track.model.data';
import { MEDIA_KEYS } from './domain-model/upload/media/media.model.data';
import { SYSTEM_KEYS } from './domain-model/system/system.model';
import { USER_CARD_KEYS } from './domain-model/user-card/user-card.model.data';
import { USER_TRACK_KEYS } from './domain-model/user-track/user-track-model.data';
import { VENDOR_RESEARCH_KEYS } from './domain-model/vendor-research/vendor-research.model.data';
import { VENDOR_KEYS } from './domain-model/vendor/vendor.model.data';
import { DOC_KEYS } from './domain-model/upload/doc/doc.model.data';

//
export const ENTITY_KEYS = [
  EVENT_ITEM_KEYS,
  SYSTEM_KEYS,
  AGENCY_KEYS,
  CUSTOMER_KEYS,
  CUSTOMER_EVENT_KEYS,
  VENDOR_KEYS,
  CHECKLIST_ITEM_KEYS,
  CHAT_KEYS,
  USER_CARD_KEYS,
  MEDIA_KEYS,
  DOC_KEYS,
  VENDOR_RESEARCH_KEYS,
  CHAT_TRACK_KEYS,
  USER_TRACK_KEYS,
  MAIL_NOTIFICATION_KEYS
];
// console.log('ha');
const gen = new EntityConfigModuleGenerator(ENTITY_KEYS);
@NgModule({
  providers: gen.providers,
  imports: [EntityDataModule.forRoot(gen.entityConfig)],
})
export class FlrglEntityDataModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: FlrglEntityDataModule,
    entityDataService: EntityDataService,
    injector: Injector
  ) {
    throwIfAlreadyLoaded(parentModule, 'FlrglEntityDataModule');
    gen.registerServices(injector, entityDataService);
  }
}