import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceElementsFactory,
  EntityDataService,
} from '@ngrx/data';

import {
  VendorResearch,
} from './vendor-research.model';

import {
  VendorResearchData,
  VENDOR_RESEARCH_KEYS,
} from './vendor-research.model.data';

import {
  EntityBaseSelectors,
  EntityBaseService,
} from '@skylitup/base/ngrx-data-fire';
import { SystemService } from '../system/system.service';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Observable } from 'rxjs';

import { VendorService } from '../vendor/vendor.service';
import {
  projectVendor,
  projectVendorResearchMapByEventVendor,
  projectVRToCustomerEvent,
  projectVendorResearchToChat,
  projectVendorResearchMapByCat,
} from './vendor-research.model.fn';
import { Dictionary } from '@ngrx/entity';
import { CustomerEventService } from '../customer-event/customer-event.service';
import { Vendor } from '../vendor/vendor.model';
import { AuthService } from '../auth/auth.service';
import { ChatService } from '../chat/chat.service';
import { AGENCY_KEYS } from '../agency/agency.model.data';
import { CustomerEvent } from '../customer-event/customer-event.model';
import { CUSTOMER_EVENT_KEYS } from '../customer-event/customer-event.model.data';
import { CUSTOMER_KEYS } from '../customer/customer.model.data';
import { selectAppContext } from '@skylitup/base/util';

interface SelectorsProjected
  extends EntityBaseSelectors<VendorResearchData, VendorResearch> {
  selectCategoryIdInContext: MemoizedSelector<any, string>;
  selectVendorResearchInContext: MemoizedSelector<any, VendorResearch[]>;
  selectVendorResearchMapByEventVendor: MemoizedSelector<
    any,
    Dictionary<VendorResearch>
  >;
  selectVendorResearchMapByCat: MemoizedSelector<
    any,
    Dictionary<VendorResearch[]>
  >;
}

@Injectable({ providedIn: 'root' })
export class VendorResearchService extends EntityBaseService<
VendorResearchData,
VendorResearch
> {
  selectorsProjected: SelectorsProjected;
  categoryIdInContext$: Observable<string>;
  vendorResearchMapByEventVendor$: Observable<Dictionary<VendorResearch>>;
  vendorResearchInContext$: Observable<VendorResearch[]>;
  vendorResearchMapByCat$: Observable<Dictionary<VendorResearch[]>>;

  constructor(
    entityServiceFactory: EntityCollectionServiceElementsFactory,
    entityDataServices: EntityDataService,
    private vendorService: VendorService,
    private customerEventService: CustomerEventService,
    private authService: AuthService,
    private chatService: ChatService
  ) {
    super(VENDOR_RESEARCH_KEYS, entityServiceFactory, entityDataServices);
    this.init();
  }
  setupRxSelectors() {
    const selectCategoryIdInContext = createSelector(
      selectAppContext,
      (context) => {
        return context['researchCatId'];
      }
    );
    const withVendor = createSelector(
      this._selectPlainEntityTuple,
      this.vendorService.selectorsProjected.selectEntityMap,
      projectVendor
    );
    const withCustomerEvent = createSelector(
      withVendor,
      this.customerEventService.selectorsProjected.selectEntityMap,
      projectVRToCustomerEvent
    );
    const withChats = createSelector(
      withCustomerEvent,
      this.authService.selectUserId,
      this.chatService.selectorsProjected.selectEntities,
      projectVendorResearchToChat
    );

    const result = withChats;
    const selectVendorResearchInContext = createSelector(
      selectCategoryIdInContext,
      this.customerEventService.selectorsProjected.selectEntityIdInContext,
      result,
      (catId: string, customerEventId: string, [vrs]) => {
        const rr = [
          ...vrs.filter(
            (v) =>
              v.data.catIds?.includes(catId) &&
              v.data.meta.parentId === customerEventId
          ),
        ];
        return rr;
      }
    );
    const selectVendorResearchMapByEventVendor = createSelector(
      result,
      projectVendorResearchMapByEventVendor
    );
    const selectVendorResearchMapByCat = createSelector(
      result,
      projectVendorResearchMapByCat
    );

    Object.assign(this.selectorsProjected, {
      selectCategoryIdInContext,
      selectVendorResearchInContext,
      selectVendorResearchMapByEventVendor,
      selectVendorResearchMapByCat,
    });
    return result;
  }

  setupRx$() {
    this.categoryIdInContext$ = this.store.select(
      this.selectorsProjected.selectCategoryIdInContext
    );
    this.vendorResearchMapByEventVendor$ = this.store.select(
      this.selectorsProjected.selectVendorResearchMapByEventVendor
    );
    this.vendorResearchInContext$ = this.store.select(
      this.selectorsProjected.selectVendorResearchInContext
    );
    this.vendorResearchMapByCat$ = this.store.select(
      this.selectorsProjected.selectVendorResearchMapByCat
    );
  }

  syncVendorResearchsForCustomer$(agencyId: string, customerId: string) {
    return this.syncQueryRealtime$(
      [AGENCY_KEYS.plural, agencyId, VENDOR_RESEARCH_KEYS.plural],
      {
        queryFn: (query) =>
          query.where('customers', 'array-contains', customerId),
      }
    );
  }

  syncVendorResearchs$(agencyId: string, customerId: string, eventId: string) {
    return this.syncQueryRealtime$([
      AGENCY_KEYS.plural,
      agencyId,
      CUSTOMER_KEYS.plural,
      customerId,
      CUSTOMER_EVENT_KEYS.plural,
      eventId,
      VENDOR_RESEARCH_KEYS.plural,
    ]);
  }

  protected newProjectedEntity(d: VendorResearchData) {
    return new VendorResearch(d);
  }

  createVendorResearch(
    customerEvent: CustomerEvent,
    vendor: Vendor,
    catId: string
  ) {
    const data: VendorResearchData = {
      meta: {
        collectionPath: [
          ...customerEvent._fullPath,
          VENDOR_RESEARCH_KEYS.plural,
        ],
        id: null,
      },
      name: vendor.name,
      catIds: [catId],
      vendorId: vendor.id,

      media: [],
      budgetResearch: {},
      linkedBudgetResearch: {},
      rating: 0,
      _del: null,
      naVersions: {},
      naCandiDates: {}
    };
    return this.upsert(data);
  }
}
