import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { Observable, of } from 'rxjs';
import { filter, map, mergeMap, tap, timeoutWith } from 'rxjs/operators';
import { ContextService } from '../services/context.service';
import { CustomerService } from '../../domain-model/customer/customer.service';
import { AuthService } from '../../domain-model/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerGuard implements CanActivate {
  constructor(
    private customerService: CustomerService,
    private contextService: ContextService,
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.authService.auth$.pipe(
      mergeMap((auth) =>
        auth.isPlanner
          ? of(true)
          : this.customerService.customersInContext$.pipe(
            filter((o) => !!o && !!o.length),
            map((customers) =>
              customers?.length === 1
                ? this.router.parseUrl(customers[0].url)
                : true
            ),
            timeoutWith(5000, of(true))
          )
      )
    );
  }
}
