import { Category, CatData } from '../system/system.model';

import { Vendor } from '../vendor/vendor.model';

import { EventItem } from '../event-item/event-item.model';

import { CustomerEvent } from './customer-event.model';
import { Dialog, HasDialog } from '../chat/chat.model';
import { Payment } from '../checklist-item/payment.model';
import { Auth } from '../auth/auth.model';

export class EventCategory extends Category implements HasDialog {
  dialog: Dialog;
  done: boolean;
  constructor(data: CatData, public event: CustomerEvent) {
    super(data);
    this.dialog = new Dialog(this, 'category');
  }
  clone(): EventCategory {
    return new EventCategory(this.data, this.event).copy(this);
  }
  copy(from: EventCategory): EventCategory {
    super.copy(from);
    this.dialog = from.dialog;
    return this;
  }
}

export class VendorCat extends EventCategory {
  public items: EventItem[] = [];
  public payments: Payment[] = [];
  public money: {
    budget: number;
    paid: number;
    pendingPayment: number;
    extraCost: number;
  } = {
      budget: 0,
      paid: 0,
      pendingPayment: 0,
      extraCost: 0,
    };
  get paymentsTotal() {
    return this.money.paid + this.money.pendingPayment;
  }
  get catName() {
    return this.data.name;
  }
  public get vendorId() {
    return this.vendor?.id || 'void';
  }
  public get name() {
    return this.vendor?.name || '';
  }


  getControlledName(customerId: string, auth: Auth) {
    return this.vendor?.getControlledName(customerId, auth);
  }
  constructor(
    cat: Category | CatData,
    public vendor: Vendor,
    event: CustomerEvent
  ) {
    super(cat instanceof Category ? cat.data : cat, event);
    this.dialog.type = 'vendorCat';
  }
  clone() {
    return new VendorCat(this.data, this.vendor, this.event).copy(this);
  }
  copy(from: VendorCat): VendorCat {
    super.copy(from);
    this.items = [...from.items];
    return this;
  }
  get hasVendor() {
    return !!this.vendor;
  }
}
