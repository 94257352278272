import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceElementsFactory,
  EntityDataService
} from '@ngrx/data';

import { Vendor } from './vendor.model';
import { VendorData, VENDOR_KEYS } from './vendor.model.data';
import {
  EntityBaseSelectors,
  EntityBaseService
} from '@skylitup/base/ngrx-data-fire';
import { SystemService } from '../system/system.service';
import {
  projectVendortoCats,
  projectVendorShowcaseMedia,
  projectVendorLocations
} from './vendor.model.fn';
import { createSelector } from '@ngrx/store';
import { Customer } from '../customer/customer.model';
import { Observable } from 'rxjs';
import { MediaService } from '../upload/media/media.service';
import { AgencyService } from '../agency/agency.service';
import { AuthService } from '../auth/auth.service';
import { AGENCY_KEYS } from '../agency/agency.model.data';
import { Agency } from '../agency/agency.model';
interface SelectorsProjected extends EntityBaseSelectors<VendorData, Vendor> { }

@Injectable({ providedIn: 'root' })
export class VendorService extends EntityBaseService<VendorData, Vendor> {
  public selectorsProjected: SelectorsProjected;
  constructor(
    entityServiceFactory: EntityCollectionServiceElementsFactory,
    entityDataServices: EntityDataService,
    private systemService: SystemService,
    private mediaService: MediaService,
    private agencyService: AgencyService,
    private authService: AuthService
  ) {
    super(VENDOR_KEYS, entityServiceFactory, entityDataServices);
    this.init();
  }
  setupRxSelectors() {
    // const withReducedNames = createSelector(
    //   this._selectPlainEntityTuple,
    //   this.authService.selectorsProjected.selectAuth,
    //   projectVendorToReducedNames
    // );
    const withCats = createSelector(
      this._selectPlainEntityTuple,
      this.systemService.selectorsProjected.selectCategoryMap,
      projectVendortoCats
    );
    const withMedia = createSelector(
      withCats,
      this.mediaService.selectorsProjected.selectEntityMap,
      projectVendorShowcaseMedia
    );
    const withLocations = createSelector(
      withMedia,
      this.agencyService.selectorsProjected.selectEntityInContext,
      projectVendorLocations
    );
    return withLocations;
  }
  setupRx$() { }

  syncVendorsForCustomer$(agencyId: string, customerId: string) {
    return this.syncQueryRealtime$(
      [AGENCY_KEYS.plural, agencyId, VENDOR_KEYS.plural],
      {
        queryFn: query => query.where('customers', 'array-contains', customerId)
      }
    );
  }

  syncVendorsForAgency$(agencyId: string) {
    return this.syncQueryRealtime$([
      AGENCY_KEYS.plural,
      agencyId,
      VENDOR_KEYS.plural
    ]);
  }

  getAllForAgency(agencyId: string) {
    return this.getAll([AGENCY_KEYS.plural, agencyId, VENDOR_KEYS.plural]);
  }

  protected newProjectedEntity(d: VendorData) {
    return new Vendor(d);
  }

  addVendorToCustomerScope(
    vendor: Vendor,
    customer: Customer
  ): Observable<VendorData> {
    const customers = vendor.data.customers ? [...vendor.data.customers] : [];
    if (!customers.includes(customer.id)) {
      customers.push(customer.id);
    }
    const vendorData = { ...vendor.data, customers };
    // console.log('addVendorToCustomerScope!!!!!!');
    // console.log(vendorData);
    return this.upsert(vendorData);
  }
  createVendor(vendorData: VendorData, agency: Agency, customer?: Customer) {
    const meta = {
      collectionPath: [...agency._fullPath, VENDOR_KEYS.plural]
    };
    if (customer) {
      vendorData.customers = [customer.id];
    }
    return this.upsert({ ...vendorData, meta });
  }
}
