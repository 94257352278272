import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EntityOp, ofEntityOp, ofEntityType } from '@ngrx/data';
import {
  Actions,
  createEffect,
  EffectNotification,
  ofType,

  ROOT_EFFECTS_INIT
} from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '@skylitup/base/ngrx-data-fire';
import { genericCatch$$, genericError } from '@skylitup/base/util';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, interval, merge, Observable, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  first, map,
  pairwise,
  retry,
  switchMap,
  take, tap
} from 'rxjs/operators';
import { NotificationService } from '../../../util/services/notification.service';
import { AGENCY_KEYS } from '../../agency/agency.model.data';
import { AgencyService } from '../../agency/agency.service';
import { CUSTOMER_KEYS } from '../../customer/customer.model.data';
import { CustomerService } from '../../customer/customer.service';
import { allUiActionCreators } from '../../ui/state/ui.actions';
import { UserCardService } from '../../user-card/user-card.service';
import { AuthService } from '../auth.service';
import {
  authStorage,
  impersonateCustomer, loginFail,
  loginSuccess,
  loginWithEmailAndPassword,
  logout,
  logoutSuccess,
  logoutThenloginWithEmailAndPassword,
  refreshIdTokenResult,
  saveNewPassword, sendResetPasswordEmail,
  sendResetPasswordEmailFail,
  sendResetPasswordEmailSuccess, sendSignInLinkToEmail,
  sendSignInLinkToEmailFail,
  sendSignInLinkToEmailSuccess,
  signInWithEmailLink,


  timeTravel
} from './auth.actions';

const AUTH_EMAIL1 = 'auth_email1';
const AUTH_IMPERSONATE_CUSTOMER = 'auth_impersonating_customer';
const AUTH_TIME_TRAVEL = 'auth_time_travel';
@Injectable()
export class AuthEffects {
  // implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private notifications: NotificationService,
    private store: Store,
    private localStorageService: LocalStorageService,
    private agencyService: AgencyService,
    private customerService: CustomerService,
    private userCardService: UserCardService,
    private toastrService: ToastrService
  ) {
    // this is the subscription to the AUTH event stream
    this.authService.authResultEvents$
      .pipe(retry())
      .subscribe((a) => store.dispatch(a));
  }

  agencyChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType(AGENCY_KEYS.plural),
      ofEntityOp(EntityOp.QUERY_LOAD_SUCCESS),
      pairwise(),
      // filter(([a1, a2]) => a2?.payload?.data?.length),
      // tap(a => console.log('^^^^^^', a)),
      switchMap((_) =>
        combineLatest([
          this.authService.auth$,
          this.agencyService.agencies$,
        ]).pipe(
          switchMap(([auth, agencies]) => {
            for (const agency of agencies) {
              if (
                agency.data?.members?.includes(auth?.userId) &&
                !auth.data?.access?.member?.includes(agency.id)
              ) {
                return interval(1000).pipe(
                  take(60),
                  map((__) => `member of agency [${agency.id}] missing from claims`)
                );
              }
            }

            const claimIds = auth.data?.access?.member || [];

            for (const claimId of claimIds) {
              const agency = agencies.find((a) => a.id === claimId);
              if (!agency || !agency.data?.members?.includes(auth?.userId)) {
                return interval(1000).pipe(
                  take(60),
                  map(
                    (__) =>
                      `member of [${claimId}] in claims not in agency[members]`
                  )
                );
              }
            }
            return of(null);
          }),
          genericCatch$$('AuthEffects-agencyChanged$')
        )
      ),
      filter((refresh) => !!refresh),
      map((reason) => refreshIdTokenResult({ reason }))
    )
  );



  customerChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType(CUSTOMER_KEYS.plural),
      ofEntityOp(EntityOp.QUERY_LOAD_SUCCESS),
      pairwise(),
      switchMap((_) =>
        combineLatest([
          this.authService.auth$,
          this.customerService.customers$,
        ]).pipe(
          switchMap(([auth, customers]) => {
            for (const a of customers) {
              if (
                a.data?.customers?.includes(auth?.userId) &&
                !auth.data?.access?.customer?.includes(a.id)
              ) {
                return interval(1000).pipe(
                  take(60),
                  map((__) => `customer [${a.id}] missing from claims`)
                );
              }
              // --- assignee limit hit in the claims
              // if (
              //   a.data?.assignees?.includes(auth?.userId) &&
              //   !auth.data?.access?.assignee?.includes(a.id)
              // ) {
              //   return interval(1000).pipe(
              //     take(60),
              //     map((__) => `assignee of [${a.id}] missing from claims`)
              //   );f
              // }
            }
            const customerClaimIds = auth.data?.access?.customer || [];
            for (const claimId of customerClaimIds) {
              const customer = customers.find((a) => a.id === claimId);
              if (
                !customer ||
                !customer.data?.customers?.includes(auth?.userId)
              ) {
                return of(null);
                // return interval(1000).pipe(
                //   take(60),
                //   map(
                //     (__) =>
                //       `customer [${claimId}] in claims not in customer[customers]`
                //   )
                // );
              }
            }
            return of(null);
          }),
          genericCatch$$('AuthEffects-customerChanged$')
        )
      ),
      filter((refresh) => !!refresh),
      map((reason) => refreshIdTokenResult({ reason }))
    )
  );

  loginWithEmailAndPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginWithEmailAndPassword),
      switchMap((o) =>
        of(o).pipe(
          switchMap((login) =>
            this.authService.login(login.email, login.password)
          ),
          // tap((wat) => console.log('w', wat)),
          map((errorMessage) =>
            errorMessage
              ? loginFail({ code: '', reason: errorMessage })
              : loginSuccess()
          ),
          catchError((err) => {
            console.error(err);
            return of(loginFail({ code: err.code, reason: err.message }));
          })
        )
      ),
      genericCatch$$('AuthEffects-loginWithEmailAndPassword$')
    )
  );
  sendSignInLinkToEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendSignInLinkToEmail),
      switchMap((a) =>
        of(a.email).pipe(
          tap((email) => this.localStorageService.setItem(AUTH_EMAIL1, email)),
          switchMap((email) => this.authService.sendSignInLinkToEmail(email)),
          tap((_) =>
            this.toastrService.success(
              'Invitation to ' + a.email + ' sent',
              '',
              {
                positionClass: 'toast-bottom-right',
                disableTimeOut: false,
                timeOut: 5000,
                // autoDismiss: true
              }
            )
          ),
          map((l) =>
            l ? sendSignInLinkToEmailSuccess() : sendSignInLinkToEmailFail()
          ),
          catchError((err) => {
            let hint = '';
            if (typeof err === 'object') {
              hint = err.toString();
            }
            return merge(
              of(sendResetPasswordEmailFail()),
              of(genericError({ hint, msg: 'Email Failed' }))
            );
          })
        )
      )
    )
  );
  // genericCatch$$('AuthEffects-sendSignInLinkToEmail$')

  sendResetPasswordEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendResetPasswordEmail),
      switchMap((a) =>
        of(a.email).pipe(
          tap((email) => this.localStorageService.setItem(AUTH_EMAIL1, email)),
          switchMap((email) => this.authService.sendResetPasswordEmail(email)),
          tap((_) =>
            this.toastrService.success(
              'Reset-Password Email sent to ' + a.email + '',
              '',
              {
                positionClass: 'toast-bottom-right',
                disableTimeOut: false,
                timeOut: 5000,
                // autoDismiss: true
              }
            )
          ),
          map((l) =>
            l ? sendResetPasswordEmailSuccess() : sendResetPasswordEmailFail()
          ),
          catchError((err) => {
            let hint = '';
            if (typeof err === 'object') {
              hint = err.toString();
            }
            return merge(
              of(sendResetPasswordEmailFail()),
              of(genericError({ hint, msg: 'Email Failed' }))
            );
          })
        )
      )
    )
  );

  saveNewPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveNewPassword),
      switchMap((o) =>
        of(o).pipe(
          switchMap((a) =>
            this.authService.confirmPasswordReset(a.code, a.password).pipe(
              map((email) =>
                loginWithEmailAndPassword({ email, password: a.password })
              ),
              catchError((err) => {
                console.error(err);
                return of(loginFail({ code: err.code, reason: err.message }));
              }),
              genericCatch$$('AuthEffects-signInLinkToEmail$')
            )
          ),
          genericCatch$$('AuthEffects-signInLinkToEmail$')
        )
      )
    )
  );

  signInLinkToEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signInWithEmailLink),
      tap((a) => this.localStorageService.setItem(AUTH_EMAIL1, a.email)),
      exhaustMap((a) =>
        this.authService.signInWithEmailLink(a.email, a.url).pipe(
          map((_) => loginSuccess()),
          catchError((err) => {
            console.error(err);
            return of(loginFail({ code: err.code, reason: err.message }));
          }),
          genericCatch$$('AuthEffects-signInLinkToEmail$')
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccess),
        tap((success) => {
          if (success && this.authService.redirectUrl) {
            setTimeout(
              () => this.router.navigateByUrl(this.authService.redirectUrl),
              0
            );
          }
        }),
        genericCatch$$('AuthEffects-loginSuccess$')
      ),
    { dispatch: false }
  );
  logoutThenloginWithEmailAndPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutThenloginWithEmailAndPassword),
      switchMap((a) =>
        of(a).pipe(
          first(),
          tap((_) => this.store.dispatch(logout())),
          switchMap((_) =>
            this.actions$.pipe(
              ofType(logoutSuccess),
              first(),
              map((__) =>
                loginWithEmailAndPassword({
                  email: a.email,
                  password: a.password,
                })
              )
            )
          )
        )
      )
    )
  );

  impersonateCustomer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(impersonateCustomer),
        tap((a) =>
          this.localStorageService.setItem(
            AUTH_IMPERSONATE_CUSTOMER,
            '' + a.impersonatingCustomer
          )
        )
      ),
    { dispatch: false }
  );

  timeTravel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(timeTravel),
        tap((a) =>
          this.localStorageService.setItem(
            AUTH_TIME_TRAVEL,
            '' + a.timeTravelling
          )
        )
      ),
    { dispatch: false }
  );

  authStorage$ = createEffect(() =>
    this.actions$.pipe(
      // ofType(idTokenResult),
      // distinctUntilChanged(null, a => a.payload.uid),
      ofType(ROOT_EFFECTS_INIT),
      map((_) => {
        const emailInStorage = this.localStorageService.getItem(AUTH_EMAIL1);
        const impersonatingCustomer =
          this.localStorageService.getItem(AUTH_IMPERSONATE_CUSTOMER) ===
          'true';
        const timeTravelling =
          this.localStorageService.getItem(AUTH_TIME_TRAVEL) === 'true';

        return authStorage({
          emailInStorage,
          impersonatingCustomer,
          timeTravelling,
        });
      }),
      genericCatch$$('AuthEffects-authStorage$')
    )
  );
  //
  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      exhaustMap(() => this.authService.logout()),
      map((_) => logoutSuccess()),
      genericCatch$$('AuthEffects-logout$')
    )
  );

  loginFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginFail),
        tap((a) => this.notifications.error('Login Fail', a.reason)),
        genericCatch$$('AuthEffects-loginFail$')
      ),
    { dispatch: false }
  );

  // ngrxOnInitEffects(): Action {
  //   const emailInStorage = this.localStorageService.getItem(AUTH_EMAIL1);
  //   const impersonatingCustomer = !!this.localStorageService.getItem(
  //     AUTH_IMPERSONATE_CUSTOMER
  //   );
  //   return authStorage({ emailInStorage, impersonatingCustomer });
  // }

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.notifications.trackCompletion(resolvedEffects$);
  }
}
