<ng-container *ngIf="auth$ |async as auth">
    <ng-container *ngIf="customerEvent$ |async as event">
        <div class="outer" fxLayout="row"
            [ngClass]="{'mobile-mode':this.mobileQuery.matches,'side-open':drawer1.opened}">
            <mat-drawer-container class="example-container">
                <mat-drawer mode="side" [opened]="this.mobileQuery.matches?mobileSideToggle:sideToggle" #drawer1>
                    <div class="left">
                        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl"  >
                            <!-- [trackBy]="trackByFn" -->
                            <!-- This is the tree node template for leaf nodes -->
                            <mat-tree-node *matTreeNodeDef="let flatNode" matTreeNodePadding
                                matTreeNodePaddingIndent="10">
                                <!-- use a disabled button to provide padding for tree leaf -->
                                <div fxFlex="grow">
                                    <mat-divider class="m-t-6" *ngIf="flatNode.dialogObj.dialog.type=='general'">
                                    </mat-divider>
                                    <div fxLayout="row">
                                        <button mat-icon-button disabled
                                            *ngIf="flatNode.dialogObj.dialog.type!='general'">
                                        </button>
                                        <div [ngClass]="{active:flatNode.dialogId==flatNode.dialogId}"
                                            (click)="selectDialog(flatNode.dialogObj)" fxLayout fxFlex="grow">
                                            <div class="p-l-45"></div>
                                            <flrgl-chat-thumb [dialog]="flatNode.dialogObj?.dialog"
                                                [active]="dialogObj?.dialog?.dialogId==flatNode.dialogObj?.dialog.dialogId">
                                            </flrgl-chat-thumb>
                                        </div>
                                    </div>
                                    <mat-divider class="m-y-2" *ngIf="flatNode.dialogObj.dialog.type=='general'">
                                    </mat-divider>
                                </div>
                            </mat-tree-node>
                            <!-- This is the tree node template for expandable nodes -->
                            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding
                                matTreeNodeToggle matTreeNodePaddingIndent="10"
                                [ngClass]="{'unread-desc':node.dialogObj.dialog.hasUnreadDescendants}">
                                <button mat-icon-button [attr.aria-label]="'toggle ' + node.name">
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                    </mat-icon>
                                </button>
                                <flrgl-chat-thumb [dialog]="node.dialogObj.dialog"
                                    [active]="dialogObj?.dialog?.dialogId==node.dialogObj?.dialog.dialogId"
                                    (click)="selectDialog(node.dialogObj)">
                                </flrgl-chat-thumb>
                            </mat-tree-node>
                        </mat-tree>
                    </div>
                </mat-drawer>
                <mat-drawer-content>
                    <button mat-button (click)="toggle()" class="drawer-button mat-elevation-z3">
                        <mat-icon *ngIf="drawer1.opened;else rightb">chevron_left</mat-icon>
                        <ng-template #rightb>
                            <mat-icon>chevron_right</mat-icon>
                        </ng-template>
                    </button>
                    <mat-divider vertical="true" fxFlex="none" class='m-l-3' *ngIf="!drawer1.opened"></mat-divider>
                    <div class="right" fxLayout="column">
                        <ng-container *ngIf="dialogObj!=null">
                            <flrgl-chat-header [dialogObj]="dialogObj"></flrgl-chat-header>
                            <mat-divider></mat-divider>
                            <ng-container *ngIf="chatTrack$ | async as chatTrack">
                                <flrgl-chat-portal [dialog]="dialogObj.dialog" [chatTrack]="chatTrack" [auth]="auth"
                                    [event]="event" fxFlex="grow">
                                </flrgl-chat-portal>
                            </ng-container>
                        </ng-container>
                    </div>
                </mat-drawer-content>
            </mat-drawer-container>
        </div>
    </ng-container>
</ng-container>