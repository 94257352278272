import {
  Component,
  Input,
  HostListener,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ApplicationRef,
} from '@angular/core';
import { ChecklistItemUpsertBehavior } from '../checklist-item-upsert-behavior';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { ChecklistItemBaseComponent } from '@skylitup/flowergirl/features/checklist';
import {
  ChecklistItem,
  Vendor,
  ChecklistItemData,
  Auth,
  ContextService,
  Payment,
  Agency,
  uiFocusCat,
  uiChecklistTabChanged,
} from '@skylitup/flowergirl/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'flrgl-checklist-item',
  templateUrl: 'checklist-item.component.html',
  styleUrls: ['checklist-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistItemComponent extends ChecklistItemBaseComponent
  implements OnInit, OnChanges {
  @Input()
  item: ChecklistItem;
  @Input()
  bg2 = false;
  @Input()
  showCat = false;
  @Input()
  showEvent = false;
  @Output()
  itemChange: EventEmitter<{
    data: any;
    done: () => void;
  }> = new EventEmitter();
  am: number;
  @Output()
  dueDateClick: EventEmitter<void> = new EventEmitter();
  @Output()
  deleted: EventEmitter<void> = new EventEmitter();
  upsert: ChecklistItemUpsertBehavior;
  editMode = false;
  auth$: Observable<Auth>;
  @HostListener('click')
  clickInside() {
    this.upsert.clickInside();
  }
  @HostListener('document:click', ['$event.target'])
  clickDoc = () => {
    this.upsert.form.markAllAsTouched();
    if (this.upsert.form.valid) {
      this.upsert.clickDoc(() => {
        this.editMode = false;
      });
    }
  };
  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private af: ApplicationRef,
    private context: ContextService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store
  ) {
    super();
    this.auth$ = this.context.auth$;
  }
  handleItemChange($event) {
    this.itemChange.next($event);
    this.am = $event.amount;
  }
  deletedItem() {
    this.deleted.next();
    this.setEditMode(false);
  }
  setEditMode(b: boolean) {
    this.editMode = b;
    // console.log('ha');

    // if (!b) {
    //   this.item = this.item.clone();
    //   this.af.tick();
    //   this.cd.markForCheck();
    //   this.cd.detectChanges();
    // }
  }
  ngOnInit(): void {
    this.upsert = new ChecklistItemUpsertBehavior(
      this.fb,
      this.item.event,
      this.item
    );
  }
  toggleDone() {
    if (!this.item.done) {
      this.itemChange.next({
        data: {  done: true, doneDate: new Date() },
        done: () => {},
      });
    } else {
      this.itemChange.next({
        data: { done: false, doneDate: null },
        done: () => {},
      });
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.upsert && this.item && this.upsert.item !== this.item) {
      this.upsert.reset(this.item);
    }
  }
  get payment(): Payment {
    return this.item.asPayment;
  }
  toCategorizedChecklist(item: ChecklistItem) {

    const path = [...item.urlPath];
    path.pop();
    path.push('plan');
    this.router.navigate(path);
    if (item.catId) {
      this.store.dispatch(uiFocusCat({ cat: item.catId }));
    }
    this.store.dispatch(uiChecklistTabChanged({ index: 1 }));
  }
}
