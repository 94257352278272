import { Component, ElementRef, HostListener, Inject, Input, OnInit, Query, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getErrorMessageHandler } from '@skylitup/base/util';
import {
  BudgetItemData,
  BudgetResearchItem, CandiDate,

  CustomerEvent, VendorResearch,





  VendorResearchData, VendorResearchItemDefData,

  VendorResearchService
} from '@skylitup/flowergirl/core';
import { ComparePricesItemBaseComponent } from '@skylitup/flowergirl/features/vendor';
import { BudgetItemCalculationComponent } from '@skylitup/flowergirl/xplat/web/features/budget';
import { Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { BudgetResearchItemNotesEdit } from '../budget-research-item-notes-edit/budget-research-item-notes-edit.component';


@Component({
  selector: 'flrgl-compare-prices-item',
  templateUrl: 'compare-prices-item.component.html',
  styleUrls: ['compare-prices-item.component.scss'],
})
export class ComparePricesItemComponent extends ComparePricesItemBaseComponent
  implements OnInit {
  @Input()
  editable = false;
  @Input()
  vr: VendorResearch;
  @Input()
  candiDate: CandiDate;
  @Input()
  version: VendorResearchItemDefData;
  @Input()
  priceItem: VendorResearchItemDefData;
  @Input()
  customerEvent: CustomerEvent;
  @ViewChildren('input1') input1: QueryList<ElementRef>;
  // input1: ElementRef;
  clickedInside = true;
  editMode = false;

  budgetControl = new FormControl();

  get calcItem(): BudgetResearchItem {
    return this.vr?.getCalculation(
      this.candiDate?.id,
      this.version?.id,
      this.priceItem?.id
    );
  }

  constructor(
    private vendorResearchService: VendorResearchService,
    public dialog: MatDialog
  ) {
    super();
    // this.budgetControl.valueChanges
    //   .pipe(
    //     takeUntil(this.destroy$),
    //     // filter(ο => !!ο),
    //     debounceTime(1200),
    //     distinctUntilChanged()
    //   )
    //   .subscribe((v) => {
    //     this.upsert({ budget: v, calcType: 'none' });
    //   });
  }

  showEditNotes() {
    if (this.editable && (!this.isLinked() || this.isLinkedSource())) {
      let item: BudgetItemData = this.vr.getItem(
        this.candiDate.id,
        this.version.id,
        this.priceItem.id
      )?.data;
      if (!item) {
        item = {
          calcItemPrice: 0,
          calcCustomNumber: 0,
          na: false,
          calcType: "none"
        };
      }
      this.dialog
        .open(BudgetResearchItemNotesEdit, {
          data: { notes: item.notes },
          disableClose: true,
        })
        .afterClosed().pipe(
          switchMap(notes => {
            if (notes !== undefined && notes !== null) {
              return this.upsert({ ...item, notes })
            } else {
              return of(null);
            }
          }))
        .subscribe();
    }
  }

  toggleNA() {
    if (!this.isNA()) {
      this.upsert({ na: true }).subscribe(() => {
        this.editMode = false;
        // this.showEditNotes();
      });
    } else {
      this.upsert({ na: false }).subscribe(() => {
        this.editMode = false;
      });
    }
  }

  toggleLink() {
    const item = this.vr.getCalculation(
      this.candiDate.id,
      this.version.id,
      this.priceItem.id
    );
    const linkedBudgetResearch = item?.vr.data.linkedBudgetResearch
      ? { ...item.vr.data.linkedBudgetResearch }
      : {};
    if (linkedBudgetResearch[item.data.priceItemId]) {
      linkedBudgetResearch[item.data.priceItemId] = null;
    } else {
      linkedBudgetResearch[item.data.priceItemId] = { ...item.data, srcCandiDate: this.candiDate.id, srcVersion: this.version.id };
    }
    const data = {
      ...item.vr.data,
      linkedBudgetResearch,
      name: item.vr.vendor.name,
    };

    this.vendorResearchService.update(data).subscribe();
  }

  syncLinkData(data1: VendorResearchData) {


    // const linkedBudgetResearch = item?.vr.data.linkedBudgetResearch
    //   ? { ...item.vr.data.linkedBudgetResearch }
    //   : {};
    // if (linkedBudgetResearch[item.data.priceItemId]) {
    //   linkedBudgetResearch[item.data.priceItemId] = null;
    // } else {
    //   linkedBudgetResearch[item.data.priceItemId] = { ...item.data, srcCandiDate: this.candiDate.id, srcVersion: this.version.id };
    // }
    // const data = {
    //   ...item.vr.data,
    //   linkedBudgetResearch,
    //   name: item.vr.vendor.name,
    // };
    // return data;
  }

  editCalculation() {
    let item: BudgetItemData = this.vr.getItem(
      this.candiDate.id,
      this.version.id,
      this.priceItem.id
    )?.data;
    if (!item) {
      item = {
        calcItemPrice: 0,
        calcCustomNumber: 0,
        na: false
      };
    }
    this.dialog
      .open(BudgetItemCalculationComponent, {
        data: { item, customerEvent: this.customerEvent },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((d) => this.upsert(d));
  }

  upsert(ddd: BudgetItemData): Observable<boolean> {
    if (ddd) {
      const b = this.vr.data.budgetResearch || {};
      const data: VendorResearchData = {
        ...this.vr.data,
        name: this.vr.vendor.name,
      };

      data.budgetResearch = data.budgetResearch
        ? { ...data.budgetResearch }
        : {};

      const budgetResearchItem = this.vr.getItem(
        this.candiDate.id,
        this.version.id,
        this.priceItem.id
      );

      if (budgetResearchItem) {
        data.budgetResearch[budgetResearchItem.candiDateId + budgetResearchItem.versionId] = {
          ...data.budgetResearch[budgetResearchItem.candiDateId + budgetResearchItem.versionId],
        };
        const budgetResearchData =
          data.budgetResearch[budgetResearchItem.candiDateId + budgetResearchItem.versionId];
        budgetResearchData.items = {
          ...budgetResearchData.items,
        };

        const itemData = {
          ...budgetResearchData.items[budgetResearchItem.data.priceItemId],
          ...ddd,
        };
        budgetResearchData.items[itemData.priceItemId] = itemData;
      } else {
        data.budgetResearch[this.candiDate.id + this.version.id] = data
          .budgetResearch[this.candiDate.id + this.version.id]
          ? { ...data.budgetResearch[this.candiDate.id + this.version.id] }
          : {
            candiDate: this.candiDate.id,
            version: this.version.id,
            items: {},
          };
        data.budgetResearch[this.candiDate.id + this.version.id].items = data
          .budgetResearch[this.candiDate.id + this.version.id].items
          ? {
            ...data.budgetResearch[this.candiDate.id + this.version.id].items,
          }
          : {};

        data.budgetResearch[this.candiDate.id + this.version.id].items[
          this.priceItem.id
        ] = {
          ...ddd,
          priceItemId: this.priceItem.id,
        };
      }

      if (this.isLinked() && this.isLinkedSource()) {
        const item = data.budgetResearch[this.candiDate.id + this.version.id].items[
          this.priceItem.id
        ];
        const linkedBudgetResearch = data.linkedBudgetResearch
          ? { ...this.vr.data.linkedBudgetResearch }
          : {};
        linkedBudgetResearch[this.priceItem?.id] = { ...item, srcCandiDate: this.candiDate.id, srcVersion: this.version.id };
        data.linkedBudgetResearch = linkedBudgetResearch;
      }

      return this.vendorResearchService.update(data).pipe(first(), map(_ => true));
    } else {
      return of(false);
    }
  }


  keyUp1($event) {
    if ($event.keyCode === 13) {
      this.upsert({ budget: this.budgetControl.value, calcType: 'none', na: false }).subscribe(() => { this.editMode = false; });
    }
  }

  onFocus(input: any) {
    input.select();
  }

  ngOnInit(): void {
    const item = this.vr.getItem(
      this.candiDate.id,
      this.version.id,
      this.priceItem.id
    )?.data;
    if (item) {
      this.budgetControl.patchValue(item.budget, { emitEvent: false });
    }
  }

  @HostListener('document:click', ['$event.target'])
  clickedAnywhere = () => {
    if (!this.clickedInside) {
      this.edit(false);
    }
    this.clickedInside = false;
  };

  clickedSomewhereInside() {
    this.clickedInside = true;
    // this.candiDateEditMode.next(true);
    return true;
  }

  isNA() {
    return this.calcItem?.data?.na;
  }
  isLinked() {
    return this.vr?.isLinked(this.priceItem?.id);
  }

  isLinkedSource() {
    return this.vr?.isLinkedSource(this.priceItem?.id, this.version?.id, this.candiDate?.id);
  }

  edit(b: boolean) {
    if (b && this.editable && !this.isNA() && (!this.isLinked() || this.isLinkedSource())) {
      if (this.calcItem && this.calcItem.data.calcType !== 'none') {
        this.editCalculation();
      } else {
        setTimeout(() => {
          this.input1?.last?.nativeElement?.focus();
        }, 0);
        this.editMode = true;
      }
    } else if (this.editable && this.editMode && !b) {
      this.upsert({ budget: this.budgetControl.value || null, calcType: 'none', na: false }).subscribe(() => { this.editMode = false; });
    } else {
    }
  }
}




