<ng-container *ngIf="auth$ | async as auth">
    <ng-container *ngIf="vendorResearchMapByCat$ | async as vendorResearchMapByCat">
        <mat-accordion *ngIf="customerEvent$|async as customerEvent">
            <mat-expansion-panel *ngFor="let cat of customerEvent.categories;trackBy:trackByIdFn"
                (opened)="focusedOnCat(cat)" #panel11 [expanded]="(focusCat$ |async)==cat.id">
                <mat-expansion-panel-header [ngClass]="{'unread-desc':cat.dialog.hasUnreadDescendants}">
                    <mat-panel-title>
                        <div class="name clickable1" fxFlex="grow" fxLayoutAlign="start center"> <span
                                class="">{{ cat.name }}</span>

                            <ng-container *ngIf="!cat.done;else done1">
                                <span class="draft">DRAFT</span>
                            </ng-container>
                            <ng-template #done1>
                                <span class="check">
                                    <mat-icon>check</mat-icon>
                                </span>
                            </ng-template>
                        </div>

                        <button *ngIf="!panel11.expanded" mat-icon-button class="vr1  clickable-icon1"
                            (click)="$event.stopPropagation() || toCategorizedChecklist(cat)">
                            <mat-icon> check_circle_outline</mat-icon>
                        </button>
                        <button *ngIf="!panel11.expanded && ( auth.isPlanner  || 
                            (!customerEvent.hideVrMap[cat.id] && vendorResearchMapByCat[cat.id]?.length))"
                            class="vr1 clickable-icon1" mat-icon-button matTooltip="Vendor Research"
                            [routerLink]="['..','vendor-research',cat.id]">
                            <mat-icon>compare</mat-icon>
                        </button>
                        <flrgl-chat-link [dialog]="cat.dialog" fxFlexAlign="end">
                        </flrgl-chat-link>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>

                    <!-- <mat-divider></mat-divider> -->
                    <div class="p-b-10 cat-head" fxLayoutAlign="end center">

                        <ng-container *ngIf="customerEvent.getHighlightedChecklistItem(cat.id) as highlight">
                            <div class="checklist-highlight" fxLayoutAlign="end center" *ngIf="!highlight.done">
                                <mat-icon>gps_fixed</mat-icon>

                                <div *ngIf="highlight.type==='checklist';else paymentTitle" class="highlightedItem"
                                    fxLayoutAlign="end center">
                                    <span>{{highlight.name}}</span>
                                </div>
                                <ng-template #paymentTitle>
                                    <div class="highlightedItem" *ngIf="highlight.asPayment as hightlightPayment ">
                                        <span (click)="editMode=true">
                                            {{hightlightPayment.amount | currency:'EUR':'symbol'}} -
                                            {{hightlightPayment.paymentMethod=='cash'?'Cash':'Bank'}}
                                            Payment
                                        </span>
                                    </div>
                                </ng-template>
                            </div>
                        </ng-container>
                        <button mat-stroked-button (click)="$event.stopPropagation() || toCategorizedChecklist(cat)"
                            class="m-r-8 expanded-btn">
                            Checklist
                            <mat-icon> check_circle_outline</mat-icon>
                        </button>
                        <button mat-stroked-button [routerLink]="['..','vendor-research',cat.id]" class="expanded-btn"
                            *ngIf="auth.isPlanner ||
                         (!customerEvent.hideVrMap[cat.id]  && vendorResearchMapByCat[cat.id]?.length)">
                            Compare Vendors
                            <mat-icon>compare</mat-icon>
                        </button>
                    </div>
                    <div class="vendor-container mat-elevation-z3"
                        *ngFor="let vendorCat of customerEvent.getVendorCats(cat.id);trackBy:trackByIdFn">
                        <ng-container *ngIf="vendorCat.items.length">
                            <div class="title" fxLayout="row" fxLayoutAlign="start center">
                                <div class="name clickable1" fxFlex="grow" fxLayoutAlign="start center" (click)="openVendorShowcase(vendorCat.vendor)">
                                    <span class="underline1">
                                        <mat-icon *ngIf="vendorCat.hasVendor" class="m-r-5">store </mat-icon>
                                        {{vendorCat.getControlledName(customerEvent?.customerId,auth)}}
                                    </span>
                                </div>

                                <flrgl-chat-link *ngIf="vendorCat.hasVendor" [dialog]="vendorCat.dialog"
                                    fxFlexAlign="end">
                                </flrgl-chat-link>
                            </div>
                            <div class="items-container">
                                <div *ngFor="let item of vendorCat.items">
                                    <flrgl-overview-event-item [item]="item"></flrgl-overview-event-item>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>
</ng-container>