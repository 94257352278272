import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
  EntityDataService,
  EntityOp,
  EntityActionOptions,
} from '@ngrx/data';
import { Dictionary, Update } from '@ngrx/entity';
import {
  createSelector,
  MemoizedSelector,
  MemoizedSelectorWithProps,
  Store,
} from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  share,
  tap,
  first,
  mergeMap,
} from 'rxjs/operators';

import { EntityProjected } from './entity.model';
import { projectDictionary, projectList } from './entity.model.fn';
import { EntityData, EntityKeys } from './entity.model.data';
import {
  FirestoreEntityDataService,
  QueryFunction,
} from '../db/firestore-entity.data.service';
import { selectAppContext } from '@skylitup/base/util';

export interface EntityBaseSelectors<
  T extends EntityData,
  P extends EntityProjected<T>
  > {
  selectEntities: MemoizedSelector<any, P[]>;
  selectEntityMap: MemoizedSelector<any, Dictionary<P>>;
  selectEntityIdInContext: MemoizedSelector<any, string>;
  selectEntityInContext: MemoizedSelector<any, P>;
  selectEntityTuple: MemoizedSelector<any, [P[], Dictionary<P>]>;
}
export abstract class EntityBaseService<
  T extends EntityData,
  P extends EntityProjected<T>
  > extends EntityCollectionServiceBase<T> {
  public selectorsProjected: EntityBaseSelectors<T, P>;
  public entitiesProjected$: Observable<P[]>;
  public entityMapProjected$: Observable<Dictionary<P>>;
  public entityIdInContext$: Observable<string>;
  public entityInContext$: Observable<P>;
  // for internal use
  private DEBUG = true;
  protected _selectEntities: MemoizedSelector<any, P[]>;
  protected _selectEntityMap: MemoizedSelector<any, Dictionary<P>>;
  _selectPlainEntityTuple: MemoizedSelector<any, [P[], Dictionary<P>]>;

  constructor(
    private entityKeys: EntityKeys,
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private entityDataServices: EntityDataService
  ) {
    super(entityKeys.plural, serviceElementsFactory);
  }
  protected init() {
    this._selectEntities = createSelector(
      this.selectors.selectEntities,
      projectList<T, P>(this.newProjectedEntity)
    );
    this._selectEntityMap = createSelector(
      this._selectEntities,
      projectDictionary
    );
    this._selectPlainEntityTuple = createSelector(
      this._selectEntities,
      this._selectEntityMap,
      (a, b) => [a, b] as [P[], Dictionary<P>]
    );

    const selectEntityIdInContext = createSelector(
      selectAppContext,
      (context) => context[this.entityKeys.name]
    );

    this.selectorsProjected = {} as any;
    const selectEntityTuple = this.setupRxSelectors();

    const selectEntities = createSelector(
      selectEntityTuple,
      ([entities]): P[] => entities
    );
    const selectEntityMap = createSelector(
      selectEntityTuple,
      ([entities, entityMap]): Dictionary<P> => entityMap
    );
    const selectEntityInContext = createSelector(
      selectEntityIdInContext,
      selectEntityMap,
      (id, entityMap) => entityMap[id]
    );
    Object.assign(this.selectorsProjected, {
      selectEntities,
      selectEntityMap,
      selectEntityTuple,
      selectEntityIdInContext,
      selectEntityInContext,
    });

    //
    //
    this.entitiesProjected$ = this.store
      .select(selectEntities)
      .pipe(filter((o) => !!o));
    this.entityMapProjected$ = this.store
      .select(selectEntityMap)
      .pipe(filter((o) => !!o));
    this.entityIdInContext$ = this.store.select(selectEntityIdInContext);
    this.entityInContext$ = this.store.select(selectEntityInContext);
    // .pipe(filter(o => !!o));
    this.setupRx$();
  }

  protected abstract setupRxSelectors(): MemoizedSelector<
    any,
    [P[], Dictionary<P>]
  >;
  protected abstract setupRx$(): void;

  protected abstract newProjectedEntity(d: T);

  get entityDataService(): FirestoreEntityDataService<T> {
    return <any>this.entityDataServices.getService(this.entityKeys.plural);
  }

  getAll(path: string[] | EntityActionOptions): Observable<T[]> {
    return (<any>this.entityDataService).getAll(path).pipe(
      first(),
      tap((updates: T[]) =>
        this.createAndDispatch(EntityOp.QUERY_ALL_SUCCESS, updates)
      )
    );
  }

  syncAllRealtime$(path: string[]): Observable<boolean> {
    return this.entityDataService.getAllRealtime$(path).pipe(
      tap((updates: T[]) =>
        this.createAndDispatch(EntityOp.QUERY_ALL_SUCCESS, updates)
      ),
      // tap((updates: T[]) => console.log(updates)),
      map((_) => true),
      distinctUntilChanged(),
      share()
    );
  }

  syncDocRealtime$(path: string[], id: string): Observable<boolean> {
    if (this.DEBUG) {
      console.log(`[start-sync] @(${path}/${id})`);
    }
    return this.entityDataService.getDocRealtime$(path, id).pipe(
      tap((update: T) =>
        this.createAndDispatch(EntityOp.QUERY_BY_KEY_SUCCESS, update)
      ),
      tap((updates: T) => {
        if (this.DEBUG) {
          console.log(`[in-sync] @${path}/${id}`);
        }
      }),
      map((_) => true),
      distinctUntilChanged(),
      share()
    );
  }

  syncQueryRealtime$(
    path: string[],
    settings?: {
      queryFn?: QueryFunction | QueryFunction[];
      replaceState?: boolean;
    }
  ): Observable<boolean> {
    if (this.DEBUG) {
      console.log(`[start-sync] @(${path})`);
    }
    const entityOp = settings?.replaceState
      ? EntityOp.QUERY_LOAD_SUCCESS
      : EntityOp.QUERY_ALL_SUCCESS;

    let updates$: Observable<T[]>;
    if (!Array.isArray(settings?.queryFn)) {
      updates$ = this.entityDataService.buildRealtimeQuery$(
        path,
        <QueryFunction>settings?.queryFn
      );
    } else {
      updates$ = combineLatest(
        (<QueryFunction[]>settings.queryFn).map((q) =>
          this.entityDataService.buildRealtimeQuery$(path, q)
        )
      ).pipe(
        map((updatesCombined: T[][]) => {
          const index = {};
          const result: T[] = [];
          for (const updates of updatesCombined) {
            for (const update of updates) {
              if (!index[update.meta.id]) {
                index[update.meta.id] = true;
                result.push(update);
              }
            }
          }
          return result;
        })
      );
    }

    return updates$.pipe(
      tap((updates: T[]) => this.createAndDispatch(entityOp, updates)),
      tap((updates: T[]) => {
        if (this.DEBUG) {
          console.log('[in-sync] @' + path);
        }
      }),
      map((_) => true),
      distinctUntilChanged(),
      share()
    );
  }

  syncQueryRealtimeGroupCollection$(
    collection: string,
    settings?: {
      queryFn?: QueryFunction | QueryFunction[];
      replaceState?: boolean;
    }
  ): Observable<boolean> {
    if (this.DEBUG) {
      console.log(`[start-sync] @/**/(${collection})`);
    }
    const entityOp = settings?.replaceState
      ? EntityOp.QUERY_LOAD_SUCCESS
      : EntityOp.QUERY_ALL_SUCCESS;
    let updates$: Observable<T[]>;
    if (!Array.isArray(settings?.queryFn)) {
      updates$ = this.entityDataService.buildRealtimeCollectionGroupQuery$(
        collection,
        <QueryFunction>settings?.queryFn
      );
    } else {
      updates$ = combineLatest(
        (<QueryFunction[]>settings.queryFn).map((q) =>
          this.entityDataService.buildRealtimeCollectionGroupQuery$(
            collection,
            q
          )
        )
      ).pipe(
        map((updatesCombined: T[][]) => {
          const index = {};
          const result: T[] = [];
          for (const updates of updatesCombined) {
            for (const update of updates) {
              if (!index[update.meta.id]) {
                index[update.meta.id] = true;
                result.push(update);
              }
            }
          }
          return result;
        })
      );
    }
    return updates$.pipe(
      tap((updates: T[]) => this.createAndDispatch(entityOp, updates)),
      tap((updates: T[]) => {
        if (this.DEBUG) {
          console.log('[in-sync] @/**/' + collection);
        }
      }),
      map((_) => true),
      distinctUntilChanged(),
      share()
    );
  }

  updateBatch(updates: Partial<T>[]): Observable<boolean> {
    return this.entityDataService.updateBatch(updates).pipe(map((_) => true));
  }

  deleteEntity(entity: T) {
    return this.entityDataService.deleteEntity(entity).pipe(
      first(),
      mergeMap((_) => this.delete(entity.meta.id))
    );
  }

  // delete(
  //   entity: T,
  //   options?: EntityActionOptions
  // ): Observable<number | string> {
  //   throw Error('Use deleteEntity instead');
  // }
}
