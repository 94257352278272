<div class="gallery-wrapper">
    <ng-container *ngIf="eventItem.specMedia.length>0;else emptyGallery">
        <!-- TEMPORARILY UNTIL THE WE FIX NGX-GALLERY -->
        <div class="empty-gallery" *ngIf="!noEdit">
            <button mat-button (click)="editSelection.next()">
                <div>Specification Images <mat-icon class="m-l-10 edit1">edit</mat-icon>
                </div>
            </button>
        </div>
        <div fxLayout="row wrap" class="spec-images" fxLayoutAlign="center center" lightbox-group>
            <div *ngFor="let m of eventItem.specMedia;let i=index" [matBadge]="i+1" matBadgeColor="accent"
                matBadgeOverlap="true" matBadgePosition="below after" class="img-box">
                <img [src]="m.urlMedium" lightbox [fullImage]="{path:m.urlLarge}" #im (events)="lightboxEvents($event)">
            </div>
        </div>
        <!-- <ngx-gallery [images]="images" [options]="galleryOptions" class="ngx-gallery"></ngx-gallery> -->
    </ng-container>

    <ng-template #emptyGallery>
        <div class="empty-gallery" *ngIf="!noEdit">
            <button mat-button [disableRipple]="noEdit" (click)="editSelection.next()">
                <div>The Specification Gallery is Empty</div>
                <div>
                    <mat-icon>photo</mat-icon>
                </div>
            </button>
        </div>
    </ng-template>
</div>