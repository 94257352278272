import { UserCard } from '../user-card/user-card.model';
import { AuthState } from './state/auth.reducer';

export interface LoginResponse {
  displayName: string;
  uid: string;
  email: string;
  emailVerified: boolean;
  access: {
    owner: string[];
    member: string[];
    memberRestricted: string[];
    memberSales: string[];
    assignee: string[];
    customer: string[];
    readonly: string[];
    agency: string[];
  };
}

export class Auth {
  userCard: UserCard;
  constructor(public data: AuthState) { }
  get uid() {
    return this.data.uid;
  }
  get isLoggedIn(): boolean {
    return this.data.loggedIn;
  }
  get isLoggingOut(): boolean {
    return this.data.loggingOut;
  }
  get isCustomer(): boolean {
    return (
      this.data.impersonatingCustomer ||
      !!this.data?.access?.customer?.length ||
      !!this.data?.access?.readonly?.length
    );
  }

  isAssignedToCustomer(customerId) {
    return this.data.access?.assignee?.includes(customerId) || false;
  }

  isOwner(agencyId) {
    return this.data.access?.owner?.includes(agencyId) || false;
  }

  get isPlanner(): boolean {
    return (
      !this.data.impersonatingCustomer &&
      (!!this.data.access?.member?.length ||
        !!this.data.access?.memberRestricted?.length ||
        !!this.data.access?.memberSales?.length)
    ) || false;
  }
  get isFullMember(): boolean {
    return (
      !this.data.impersonatingCustomer && !!this.data.access?.member?.length
    ) || false;
  }
  get isRestrictedMember(): boolean {
    return (
      !this.data.impersonatingCustomer &&
      !!this.data.access?.memberRestricted?.length
    ) || false;
  }
  get isSalesMember(): boolean {
    return (
      !this.data.impersonatingCustomer &&
      !!this.data.access?.memberSales?.length
    ) || false;
  }

  get userId(): string {
    return this.data.uid;
  }
  get name() {
    return this.userCard?.name || this.data.displayName;
  }

  get email() {
    return this.data.email;
  }
  get isEmailVerified() {
    return this.data.emailVerified || false;;
  }
}
