import { Component, Inject } from '@angular/core';

import { VendorMapBaseComponent } from '@skylitup/flowergirl/features/vendor';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Vendor } from '@skylitup/flowergirl/core';

@Component({
  selector: 'flrgl-vendor-map',
  templateUrl: 'vendor-map.component.html',
  styleUrls: ['vendor-map.component.scss'],
})
export class VendorMapComponent extends VendorMapBaseComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public vendor: Vendor,
    private dialogRef: MatDialogRef<VendorMapComponent>
  ) {
    super();
  }
  close() {
    this.dialogRef.close();
  }
}
