import { EntityTuple } from '@skylitup/base/ngrx-data-fire';
import { VendorResearch } from './vendor-research.model';
import { Dictionary } from '@ngrx/entity';
import { Vendor } from '../vendor/vendor.model';
import { cloneEntityTuple } from '@skylitup/base/ngrx-data-fire';
import { CustomerEvent } from '../customer-event/customer-event.model';
import { Chat, Dialog, HasDialog } from '../chat/chat.model';

export const projectVendor = (
  t: EntityTuple<VendorResearch>,
  vendorMap: Dictionary<Vendor>
) => {
  const result = cloneEntityTuple(t);
  const [vv] = result;
  for (const v of vv) {
    v.vendor = vendorMap[v.data.vendorId];
  }
  return result;
};
export const projectVRToCustomerEvent = (
  t: EntityTuple<VendorResearch>,
  customerEventMap: Dictionary<CustomerEvent>
) => {
  const result = cloneEntityTuple(t);
  const [vv] = result;
  for (const v of vv) {
    v.customerEvent = customerEventMap[v.data.meta.parentId];
  }
  return result;
};

export const projectVendorResearchMapByEventVendor = (
  t: EntityTuple<VendorResearch>
) => {
  const result = {};
  const [vv] = t;
  for (const v of vv) {
    result[v.data.meta.parentId + v.data.vendorId] = v;
  }
  return result;
};

export const projectVendorResearchToChat = (
  t: EntityTuple<VendorResearch>,
  currentUserId: string,
  chats: Chat[]
) => {
  const result = cloneEntityTuple(t, vr => {
    vr.dialog = new Dialog(vr, 'vendorResearch',currentUserId);
  });
  const [vrs, vrMap] = result;
  for (const chat of chats) {
    const dialogObjs: HasDialog[] = [];
    const links = chat.data.links;

    if (links.vendorResearch) {
      for (const vrId of links.vendorResearch) {
        const vr = vrMap[vrId];
        if (vr) {
          dialogObjs.push(vr);
        }
      }
    }
    if (links.originType === 'vendorResearch') {
      const vr = vrMap[links.originId];
      if (vr) {
        dialogObjs.push(vr);
      }
    }
    for (const d of dialogObjs) {
      if (!chat.data?.readBy?.[currentUserId]) {
        d.dialog.unread.push(chat);
        d.dialog.hasUnread = true;
        d.dialog.hasUnreadDescendants = true;
      }
      d.dialog.history.push(chat);
      if (chat.recent) {
        d.dialog.recent.push(chat);
      }
      if (d.dialog.recent.length) {
        d.dialog.recentOldest = d.dialog.recent[d.dialog.recent.length - 1];
      }

      const lastChat = d.dialog.history[d.dialog.history.length - 1];
      d.dialog.lastContactDate = lastChat.date;
      d.dialog.lastContactDateString = lastChat.dateString;
      d.dialog.recentLatest = lastChat;
    }
  }

  return result;
};

export const projectVendorResearchMapByCat = (
  t: EntityTuple<VendorResearch>
): Dictionary<VendorResearch[]> => {
  const result = {};
  const [vrs] = t;
  for (const vr of vrs) {
    for (const catId of vr.data.catIds) {
      let vv = result[catId];
      if (!vv) {
        vv = [];
        result[catId] = vv;
      }
      vv.push(vr);
    }
  }
  return result;
};
