import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, ChangeDetectorRef } from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, share, takeUntil } from 'rxjs/operators';
import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChatLinkType,
  HasDialog,
  CustomerEvent,
  Auth,
  ContextService,
  EventItem,
  EventCategory,
  VendorCat,
  AppState,
  Category,
  createUiFocusChat, ChatTrackService, ChatTrack
} from '@skylitup/flowergirl/core';
import { ChatByCatBaseComponent } from '@skylitup/flowergirl/features/chat';

interface ChatFlatTreeNode {
  dialogId: string;
  type: ChatLinkType;
  expandable: boolean;
  level: number;
  name: string;
  hasDialog: boolean;
  hasUnread: boolean;
  dialogObj: HasDialog;
}
interface HasDialogNode {
  type: ChatLinkType;
  event: CustomerEvent;
  dialogObj: HasDialog;
  children: HasDialogNode[];
  parent: HasDialogNode;
}

@Component({
  selector: 'flrgl-chat-by-cat',
  templateUrl: 'chat-by-cat.component.html',
  styleUrls: ['chat-by-cat.component.scss', '../chat-container-common.scss'],
})
export class ChatByCatComponent extends ChatByCatBaseComponent {
  treeControl = new FlatTreeControl<ChatFlatTreeNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  dataSource: MatTreeFlatDataSource<HasDialogNode, ChatFlatTreeNode>;

  dialogObj: HasDialog = null;
  auth$: Observable<Auth>;
  customerEvent$: Observable<CustomerEvent>;
  nodeMap: Dictionary<HasDialogNode> = {};
  mobileQuery: MediaQueryList;
  mobileSideToggle = false;
  sideToggle = true;
  chatTrack$: Observable<ChatTrack>;

  toggle() {
    this.mobileSideToggle = !this.mobileSideToggle;
    this.sideToggle = !this.sideToggle;
  }

  constructor(
    private context: ContextService,
    private store: Store,
    media: MediaMatcher,
    chatTrackService: ChatTrackService,
    private cd: ChangeDetectorRef
  ) {
    super();
    this.mobileQuery = media.matchMedia('(max-width: 700px)');
    this.chatTrack$ = chatTrackService.chatTrackInContext$;
    // this.mobileQuery.addEventListener('change', () => {
    //   this.cd.markForCheck();
    // });

    this.auth$ = this.context.auth$;
    this.customerEvent$ = this.context.customerEvent$;

    const treeFlattener = new MatTreeFlattener<HasDialogNode, ChatFlatTreeNode>(
      (node: HasDialogNode, level: number) => {
        if (node.type === 'eventItem') {
          const item: EventItem = node.dialogObj as EventItem;
          return {
            dialogId: item.dialog.dialogId,
            type: node.type,
            expandable: false,
            level,
            name: item.name,
            hasDialog: !item.dialog.isEmpty,
            hasUnread: false,
            dialogObj: item,
          };
        } else if (node.type === 'general') {
          const ev: CustomerEvent = node.dialogObj as CustomerEvent;
          return {
            dialogId: ev.dialog.dialogId,
            type: node.type,
            expandable: false,
            level,
            name: 'General Chat',
            hasDialog: !ev.dialog.isEmpty,
            hasUnread: false,
            dialogObj: ev,
          };
        } else if (node.type === 'category') {
          const cat: EventCategory = node.dialogObj as EventCategory;
          return {
            dialogId: cat.dialog.dialogId,
            type: node.type,
            expandable:
              node.event.getVendorCats(cat.id).filter((v) => v.items.length > 0)
                .length > 0,
            level,
            name: cat.name,
            hasDialog: !cat.dialog.isEmpty,
            hasUnread: false,
            dialogObj: cat,
          };
        } else if (node.type === 'vendorCat') {
          const vendorCat: VendorCat = node.dialogObj as VendorCat;
          return {
            type: node.type,
            dialogId: vendorCat.dialog.dialogId,
            expandable: true,
            level,
            name: vendorCat.hasVendor ? vendorCat.vendor.name : '_',
            hasDialog: !vendorCat.dialog.isEmpty,
            hasUnread: false,
            dialogObj: vendorCat,
          };
        } else {
          return null;
        }
      },
      (f) => f.level,
      (f) => f.expandable,
      (node: HasDialogNode) => node.children
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      treeFlattener
    );
    combineLatest([
      this.context.customerEvent$.pipe(
        takeUntil(this.destroy$),
        filter((o) => !!o),
        map((event) => this.buildNestedTree(event)),
        share()
      ),
      this.store.select((s: AppState) => s.ui.focusDialog),
    ]).subscribe(([t, focusDialogId]) => {
      const [tree, nodeMap] = t;
      this.dataSource.data = tree;
      this.nodeMap = nodeMap;
      if (focusDialogId) {
        const currentNode = nodeMap[focusDialogId];
        if (currentNode) {
          this.dialogObj = currentNode.dialogObj;
        }
        this.expandBranch(focusDialogId);
      }
    });
  }
  expandBranch(dialogId: string) {
    let currentNode = this.nodeMap[dialogId];
    while (currentNode != null) {
      this.expandNode(currentNode.dialogObj.dialog.dialogId);
      currentNode = currentNode.parent;
    }
    this.cd.markForCheck();
  }
  expandNode(dialogId: string) {
    this.treeControl.expand(
      this.treeControl.dataNodes.find((n) => n?.dialogId === dialogId)
    );
    this.cd.markForCheck();
  }
  // trackByFn(i, o) {
  //   console.log(o.level + o.dialogId + i);
  //   return o.level + o.dialogId + i;
  // }
  buildNestedTree(
    event: CustomerEvent
  ): [HasDialogNode[], Dictionary<HasDialogNode>] {
    const r = [];
    const d = {};
    r.push(recursiveMakeNode(event, d));
    for (const cat of event.categories) {
      r.push(recursiveMakeNode(cat, d));
    }
    return [r, d];
    function recursiveMakeNode(
      dialogObj: HasDialog,
      mapDialogNode: Dictionary<HasDialogNode>
    ): HasDialogNode {
      const type = dialogObj.dialog.type;
      const result = { type, dialogObj, event, children: [], parent: null };
      mapDialogNode[dialogObj.dialog.dialogId] = result;
      if (type === 'eventItem') {
      } else if (type === 'general') {
        const ev = dialogObj as CustomerEvent;
      } else if (type === 'category') {
        const cat = dialogObj as Category;
        let noVendorCat = null;
        for (const vendorCat of event
          .getVendorCats(cat.id)
          .filter((v) => v.items.length > 0)) {
          if (vendorCat.hasVendor) {
            const child = recursiveMakeNode(vendorCat, mapDialogNode);
            child.parent = result;
            result.children.push(child);
          } else {
            noVendorCat = vendorCat;
          }
        }
        if (noVendorCat) {
          noVendorCat.items.forEach((item) => {
            const child = recursiveMakeNode(item, mapDialogNode);
            child.parent = result;
            result.children.push(child);
          });
        }
      } else if (type === 'vendorCat') {
        const vendorCat = dialogObj as VendorCat;
        for (const item of vendorCat.items) {
          const child = recursiveMakeNode(item, mapDialogNode);
          child.parent = result;
          result.children.push(child);
        }
      }
      return result;
    }
  }

  selectDialog(d: HasDialog) {
    this.dialogObj = d;
    this.store.dispatch(createUiFocusChat(d));
  }
  hasChild = (_: number, node: ChatFlatTreeNode) => node.expandable;
}
