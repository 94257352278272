import { Observable } from "rxjs";

interface UploadState {
  bytesTransfered: number;
  totalBytes: number;
  state: string;
}

export interface UploadTask {
  snapshotChanges$: Observable<UploadState>;
  percentageChanges$: Observable<number>;
  downloadURL$: Observable<string>;
}
export abstract class FirebaseStorageDataService {
  constructor() { }
  abstract put(data: File, m: { fileName: string; path: string[] }): UploadTask;
}
