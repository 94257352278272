import { Injectable } from '@angular/core';
import {
  EntityBaseSelectors,
  EntityBaseService
} from '@skylitup/base/ngrx-data-fire';
import {
  EntityCollectionServiceElementsFactory,
  EntityDataService
} from '@ngrx/data';
import { Agency } from '../agency/agency.model';
import { UserCard } from './user-card.model';
import { Dictionary } from '@ngrx/entity';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AGENCY_KEYS } from '../agency/agency.model.data';
import { UserCardData, USER_CARD_KEYS } from './user-card.model.data';

interface SelectorsProjected
  extends EntityBaseSelectors<UserCardData, UserCard> {
  selectUserCardByAuthUIDMap: MemoizedSelector<any, Dictionary<UserCard>>;
  selectUserCardByEmailMap: MemoizedSelector<any, Dictionary<UserCard>>;
  selectUsersByCustomerMap: MemoizedSelector<any, { [customerId: string]: UserCard[] }>;
}

@Injectable({ providedIn: 'root' })
export class UserCardService extends EntityBaseService<UserCardData, UserCard> {
  selectorsProjected: SelectorsProjected;
  usersByEmailMap$: Observable<Dictionary<UserCard>>;
  usersByAuthUidMap$: Observable<Dictionary<UserCard>>;
  usersByCustomerMap$: Observable<{ [customerId: string]: UserCard[] }>;
  constructor(
    entityServiceFactory: EntityCollectionServiceElementsFactory,
    entityDataServices: EntityDataService
  ) {
    super(USER_CARD_KEYS, entityServiceFactory, entityDataServices);
    this.init();
  }

  protected setupRxSelectors() {
    const selectUserCardByAuthUIDMap = createSelector(this._selectEntities, v =>
      v.reduce((r, c) => ((r[c.data.authUId] = c) && r) || r, {})
    );
    const selectUserCardByEmailMap = createSelector(this._selectEntities, v =>
      v.reduce(
        (r, c) => ((r[c.data?.email?.trim()?.toLowerCase()] = c) && r) || r,
        {}
      )
    );
    const selectUsersByCustomerMap = createSelector(this._selectEntities, uu => {
      const result: { [customerId: string]: UserCard[] } = {}
      for (const u of uu) {
        for (const cId of (u.customerScope || [])) {
          result[cId] = result[cId] || [];
          result[cId].push(u);
        }
      }
      return result;
    }
    );

    Object.assign(this.selectorsProjected, {
      selectUserCardByAuthUIDMap,
      selectUserCardByEmailMap,
      selectUsersByCustomerMap
    });
    return this._selectPlainEntityTuple;
  }

  protected setupRx$(): void {
    this.usersByEmailMap$ = this.store.select(this.selectorsProjected.selectUserCardByEmailMap);
    this.usersByAuthUidMap$ = this.store.select(this.selectorsProjected.selectUserCardByAuthUIDMap)
    this.usersByCustomerMap$ = this.store.select(this.selectorsProjected.selectUsersByCustomerMap)
  }

  public syncAgencyUserCards$(agency: string) {
    return this.syncQueryRealtime$(
      [AGENCY_KEYS.plural, agency, USER_CARD_KEYS.plural],
      { replaceState: true }
    );
  }
  public syncSingleUserCard$(agency: string, authUid: string) {
    return this.syncQueryRealtime$(
      [AGENCY_KEYS.plural, agency, USER_CARD_KEYS.plural],
      {
        queryFn: [
          q => q.where('authUId', '==', authUid),
        ],
        replaceState: false
      }
    );
  }

  public syncUserCardsForCustomer$(agency: string, customer: string) {
    return this.syncQueryRealtime$(
      [AGENCY_KEYS.plural, agency, USER_CARD_KEYS.plural],
      {
        queryFn: [
          q => q.where('agencyScope', '==', true),
          q => q.where('customerScope', 'array-contains', customer)
          // q => q.where(`access.${customer}`, '>', ''),
          // q => q.where('access', 'array-contains', customer)
        ],
        replaceState: true
      }
    );
  }


  protected newProjectedEntity(d: UserCardData) {
    const a = new UserCard(d);
    return a;
  }

  public create(agency: Agency, d: UserCardData) {
    const data: UserCardData = {
      meta: {
        collectionPath: [...agency._fullPath, USER_CARD_KEYS.plural],
        id: null
      },
      ...d
    };
    if (data.access) {
      data.customerScope = Object.keys(data.access);
    }
    return this.upsert(data);
  }
  public update(data: Partial<UserCardData>): Observable<UserCardData> {
    if (data.access) {
      data.customerScope = Object.keys(data.access);
    }

    return super.update(data);
  }
  public upsert(data: UserCardData): Observable<UserCardData> {
    if (data.access) {
      data.customerScope = Object.keys(data.access);
    }
    return super.upsert(data);
  }
}
