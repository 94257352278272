import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  EffectNotification,
  ofType,
} from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import {
  firestoreRealtime,
  firestoreRealtimeNoSyncing,
} from '@skylitup/base/ngrx-data-fire';
import { genericCatch$$ } from '@skylitup/base/util';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { NotificationService } from '../../util/services/notification.service';
import { AgencyService } from '../agency/agency.service';
import { Auth } from '../auth/auth.model';
import { AuthService } from '../auth/auth.service';
import { CustomerService } from './customer.service';

@Injectable()
export class CustomerEffects {
  agencyContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      switchMap((_) =>
        combineLatest([
          this.agencyService.entityIdInContext$,
          this.agencyService.agencies$,
          this.authService.auth$,
        ])
      ),
      map(([agencyIdInContext, agencies, auth]) => {
        let id: string = null;
        if (agencyIdInContext) {
          id = agencyIdInContext;
        } else if (agencies?.length === 1) {
          id = agencies[0].id;
        }
        return [id, auth] as [string, Auth];
      }),
      distinctUntilChanged(null, (a) => `${a[0]}-${a[1].userId}`),
      switchMap((a) =>
        of(a).pipe(
          filter(([agencyId, auth]) => !!auth.userId),
          switchMap(([agencyId, auth]) => {
            if (agencyId && auth.isLoggedIn && !auth.isLoggingOut) {
              if (auth.isPlanner) {
                if (auth.isFullMember) {
                  return this.customerService
                    .syncCustomersByAgency$(agencyId)
                    .pipe(
                      map((_) =>
                        firestoreRealtime(`agencies/${agencyId}/customers`)
                      )
                    );
                } else {
                  return this.customerService
                    .syncAssignedCustomersByAgency$(agencyId, auth.userId)
                    .pipe(
                      map((_) =>
                        firestoreRealtime(`agencies/${agencyId}/customers`)
                      )
                    );
                }
              } else {
                return this.customerService
                  .syncCustomer$(agencyId, auth.userId)
                  .pipe(
                    map((_) =>
                      firestoreRealtime(
                        `agencies/${agencyId}/customers | filter ${auth.userId}`
                      )
                    )
                  );
              }
            } else {
              return of(firestoreRealtimeNoSyncing('agencies/{id}/customers'));
            }
          }),
          genericCatch$$('CustomerEffects - agencyContext')
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private customerService: CustomerService,
    private agencyService: AgencyService,
    private authService: AuthService,
    private notifications: NotificationService
  ) {}
  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.notifications.trackCompletion(resolvedEffects$);
  }
}
