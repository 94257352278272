import { Component } from '@angular/core';
import { ChatBaseComponent } from '@skylitup/flowergirl/features/chat';
import { WindowService } from '@skylitup/base/util';
import { ActivatedRoute } from '@angular/router';
import { last } from 'rxjs/operators';

@Component({
  selector: 'flrgl-chat',
  templateUrl: 'chat.component.html',
  styleUrls: ['chat.component.scss'],
})
export class ChatComponent extends ChatBaseComponent {
  constructor(public windowService: WindowService, private activatedRoute: ActivatedRoute) {
    super();
  }
  get showTabs() {
    let lastChild = this.activatedRoute?.snapshot;
    let i = 0;
    while (lastChild?.children?.length && i < 10) {
      lastChild = lastChild?.children[0];
      i++;
    }
    if (lastChild?.url?.[0]?.path === 'media') {
      return false
    } else {
      return true;
    }
  }
}
