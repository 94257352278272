import { EntityData, EntityKeys } from '@skylitup/base/ngrx-data-fire';

export const AGENCY_KEYS: EntityKeys = {
  name: 'agency',
  plural: 'agencies'
};
export interface UserInfoAgencyData {
  username: string;
}
export interface AgencyData extends EntityData {
  members: string[];
  membersRestricted: string[];
  membersSales: string[];
  owners: string[];
  customers: string[];
  name: string;
  url: string;
  locations: LocData[];
  tags: { [cat: string]: TagData[] };
  timezone: string;
  usersInfo: { [authUid: string]: UserInfoAgencyData }
}

export interface TagData {
  id: string;
  cat: string;
  name: string;
}

export interface LocData extends EntityData {
  id: string;
  parent: string;
  name: string;
}
