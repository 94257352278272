import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';

import { VendorShowcaseBaseComponent } from '@skylitup/flowergirl/features/vendor';
import { first, mergeMap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FocusSortClick } from '../util/vendor-sort';
import {
  Vendor,
  Category,
  Loc,
  CustomerEvent,
  Auth,
  MediaService,
  VendorService,
  ContextService,
  UploadFilesRequest,
  showcaseVendorMedia,
  Media,
  doHeartBeat,
} from '@skylitup/flowergirl/core';
import { LightboxComponent } from '@skylitup/base/xplat/web/ui/external';
import { excerpt, linkifyString } from '@skylitup/base/util';

@Component({
  selector: 'flrgl-vendor-showcase',
  templateUrl: 'vendor-showcase.component.html',
  styleUrls: ['vendor-showcase.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorShowcaseComponent extends VendorShowcaseBaseComponent
  implements OnChanges {
  @Input()
  vendor: Vendor;
  @Input()
  withFilters = true;
  @Input()
  editable = true;
  @Input()
  socialNames = false;
  @Output()
  catclick = new EventEmitter<Category>();
  @Output()
  locationClick = new EventEmitter<Loc>();
  @Output()
  focusSortClick = new EventEmitter<FocusSortClick>();
  customerEvent$: Observable<CustomerEvent>;
  reorderImgs = false;
  isUploading = false;
  uploadError = null;
  showFullNotes = false;
  auth$: Observable<Auth>;
  excerptLength = 650;
  constructor(
    private mediaService: MediaService,
    private vendorService: VendorService,
    private cd: ChangeDetectorRef,
    private store: Store,
    private lightboxPopup: MatDialog,
    private context: ContextService
  ) {
    super();
    this.customerEvent$ = this.context.customerEvent$;
    this.auth$ = this.context.auth$;
  }
  attachFile($event) {
    this.isUploading = true;
    this.uploadError = null;
    const mediaUpload: UploadFilesRequest = {
      type: 'image',
      files: $event.target.files,
      agency: this.vendor._parentId,
      showcaseVendorId: this.vendor.id || null,
      customer: null,
      event: null,
      originCat: null,
      originVendor: null,
      originEventItem: null,
    };

    this.mediaService
      .upload(mediaUpload)
      .pipe(
        first(),
        mergeMap((mediaData) => {
          return this.vendorService.upsert({
            ...this.vendor.data,
            showcaseMedia: [
              ...(this.vendor.data.showcaseMedia || []),
              ...mediaData.map((m) => m.meta.id),
            ],
          });
        })
      )
      .subscribe({
        complete: () => {
          this.isUploading = false;
          this.cd.markForCheck();
        },
        error: (err: Error) => {
          this.uploadError = err.message;
          this.isUploading = false;
          this.cd.markForCheck();
        },
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    const changesVendor = changes['vendor'];
    const vC = changesVendor.currentValue as Vendor;
    const vP = changesVendor.previousValue as Vendor;
    if (vC) {
      this.store.dispatch(doHeartBeat());
    }
    // console.log('vc', vC);
    // this.cd.markForCheck();
    // console.log('ha', vC?.name || vC, vP?.name || vP);
    if (vC && (!vP || changesVendor.isFirstChange() || vC.id !== vP.id)) {
      this.store.dispatch(showcaseVendorMedia({ vendorId: vC.id }));
    }
  }
  trackByIdFn(i, o) {
    return o.id;
  }
  showLightbox(media: Media) {
    this.lightboxPopup.open(LightboxComponent, {
      data: media.urlLarge,
      disableClose: false,
      panelClass: 'p-0',
    });
  }

  get notes() {
    if (this.showFullNotes) {
      return linkifyString(this.vendor?.data.notes);
    } else {
      return excerpt(this.vendor?.data.notes, this.excerptLength);
    }
  }

  dropImage(event: CdkDragDrop<Media[]>, vendor: Vendor) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    this.vendorService
      .upsert({
        ...this.vendor.data,
        showcaseMedia: [...event.container.data.map((m) => m.id)],
      })
      .pipe(first())
      .subscribe();
  }
  deleteMedia(media) {
    this.mediaService
      .deleteEntity(media.data)
      .pipe(first())
      .subscribe((_) => { });
  }
  empty(notes: string) {
    return !notes || notes.trim().length === 0;
  }
}
