import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceElementsFactory,
  EntityDataService
} from '@ngrx/data';
import { createSelector } from '@ngrx/store';
import {
  EntityBaseSelectors,
  EntityBaseService
} from '@skylitup/base/ngrx-data-fire';
import { AGENCY_KEYS } from '../agency/agency.model.data';
import { CUSTOMER_EVENT_KEYS } from '../customer-event/customer-event.model.data';
import { CUSTOMER_KEYS } from '../customer/customer.model.data';
import { DocService } from '../upload/doc/doc.service';
import { MediaService } from '../upload/media/media.service';
import { Chat } from './chat.model';
import { ChatData, CHAT_KEYS } from './chat.model.data';
import { projectChatDocs, projectChatMedia } from './chat.model.fn';

interface SelectorsProjected extends EntityBaseSelectors<ChatData, Chat> { }

@Injectable({ providedIn: 'root' })
export class ChatService extends EntityBaseService<ChatData, Chat> {
  public selectorsProjected: SelectorsProjected;
  constructor(
    entityServiceFactory: EntityCollectionServiceElementsFactory,
    entityDataServices: EntityDataService,
    private mediaService: MediaService,
    private docService: DocService,
  ) {
    super(CHAT_KEYS, entityServiceFactory, entityDataServices);
    this.init();
  }
  setupRxSelectors() {
    const withMedia = createSelector(
      this._selectPlainEntityTuple,
      this.mediaService.selectorsProjected.selectEntityMap,
      projectChatMedia
    );
    const withDocs = createSelector(
      withMedia,
      this.docService.selectorsProjected.selectEntityMap,
      projectChatDocs
    );
    return withDocs;
  }

  setupRx$() { }

  protected newProjectedEntity(d: ChatData) {
    return new Chat(d);
  }

  syncChats$(agencyId: string, customerId: string, customerEventId: string) {
    return this.syncQueryRealtime$(
      [
        AGENCY_KEYS.plural,
        agencyId,
        CUSTOMER_KEYS.plural,
        customerId,
        CUSTOMER_EVENT_KEYS.plural,
        customerEventId,
        CHAT_KEYS.plural
      ],
      {
        queryFn: query =>
          query.where('recent', '==', true).orderBy('date', 'asc')
      }
    );
  }
}
