import { Dictionary } from '@ngrx/entity';
import { dateStringLong, EntityProjected, TIMEZONE_LONDON } from '@skylitup/base/ngrx-data-fire';
import { Agency, UserInfoAgency } from '../agency/agency.model';
import { CustomerEvent, EventInfoDescribed } from '../customer-event/customer-event.model';
import { CustomerData, HasTimezone, UserInfoCustomer } from './customer.model.data';

export class Customer extends EntityProjected<CustomerData> implements HasTimezone {
  eventMap: Dictionary<CustomerEvent> = {};
  get assignees(): UserInfoAgency[] {
    return (this.data.assignees || []).map(a => this?.agency?.usersInfo?.[a]).filter(o => !!o);
  }
  get customerUsers(): UserInfoCustomer[] {
    return (this.data.customers || []).map(a => this?.data?.usersInfo?.[a]).filter(o => !!o);
  }
  get userIds() {
    return [...(this.data.customers || []), ...this.data.readOnlyUsers || []]
  }
  agency: Agency;
  get timezone() {
    return this.data?.timezone || TIMEZONE_LONDON;
  }
  get isDemo() {
    return this.data.type === 'demo';
  }

  get usersInfo() {
    return this.data.usersInfo;
  }

  readonly eventsInfo: EventInfoDescribed[];
  get latestEventDate(): Date {
    return this.eventsInfo.length ? this.eventsInfo[this.eventsInfo.length - 1].latestDate : new Date();
  }
  get earliestEventDate(): Date {
    return this.eventsInfo.length ? this.eventsInfo[0].earliestDate : new Date();
  }

  static getEditUrl(customerData: CustomerData) {
    const r = [...customerData.meta.urlPath, customerData.meta.id];
    r.splice(2, 0, 'edit');
    return '/' + r.join('/');
  }

  get editUrl() {
    return Customer.getEditUrl(this.data);
  }
  get typeDescription() {
    switch (this.data.type) {
      case 'archive': return 'Archive'
      case 'client': return 'Client'
      case 'demo': return 'Demo'
      case 'lead': return 'Lead'
      case 'other': return 'Other'
      default: return 'ERROR';
    }
  }

  constructor(data: CustomerData) {
    super(data);
    this.eventsInfo = Object.keys(this.data.eventsInfo || [])
      .map(k => new EventInfoDescribed(this.data.eventsInfo[k], k, this))
    this.eventsInfo.sort(
      (d1, d2) => d1.earliestDate.getTime() - d2.earliestDate.getTime()
    );
  }

  dateString(date: Date) {
    return dateStringLong(date, this.data?.timezone);
  }

  clone(): Customer {
    return new Customer(this.data).copy(this);
  }
  copy(from: Customer): Customer {
    this.eventMap = from.eventMap;
    this.agency = from.agency;
    return this;
  }
}
