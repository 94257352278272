import {
  Component,
  EventEmitter,
  OnChanges,
  Output,
  SimpleChanges,
  Input
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap
} from 'rxjs/operators';
import { BudgetHeaderComponent } from '../budget-header/budget-header.component';

@Component({
  selector: 'flrgl-budget-header-expanded',
  templateUrl: 'budget-header-expanded.component.html',
  styleUrls: ['budget-header-expanded.component.scss']
})
export class BudgetHeaderExpandedComponent extends BudgetHeaderComponent
  implements OnChanges {
  goalControl = new FormControl();
  @Output()
  goalChange = new EventEmitter();
  @Input()
  extraCost;
  @Input()
  editable = false;
  
  constructor() {
    super();
    this.goalControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        debounceTime(1200)
      )
      .subscribe(v => this.goalChange.next(v));
  }

  get actualBudgetCalc() {
    if (this.extraCost === undefined) {
      return this.actualBudget;
    } else {
      return this.extraCost + this.actualBudget;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.goalControl.setValue(this.goalBudget, { emitEvent: false });
  }
}
