import {
  EntityTuple,
  EntityProjected
} from '@skylitup/base/ngrx-data-fire';
import { cloneEntityTuple } from '@skylitup/base/ngrx-data-fire';
import { UserCard } from '../user-card/user-card.model';
import { Dictionary } from '@ngrx/entity';
import { EventItem } from '../event-item/event-item.model';
import { CustomerEvent } from '../customer-event/customer-event.model';
import { Media } from '../upload/media/media.model';
import { Chat, HasDialog } from './chat.model';
import { VendorResearch } from '../vendor-research/vendor-research.model';
import { Customer } from '../customer/customer.model';
import { Doc } from '../upload/doc/doc.model';



export function projectChatMedia(
  t: EntityTuple<Chat>,
  mediaMap: Dictionary<Media>
): EntityTuple<Chat> {
  const result = cloneEntityTuple(t, c => (c.media = []));
  const [chats, chatsMap] = result;
  for (const c of chats) {
    if (c.isAttachment && c.data.attachment.mediaIds) {
      for (const mediaId of c.data.attachment.mediaIds) {
        const mediaObj = mediaMap[mediaId];
        if (mediaObj) {
          c.media.push(mediaObj);
        }
      }
    }
  }
  return result;
}

export function projectChatDocs(
  t: EntityTuple<Chat>,
  docMap: Dictionary<Doc>
): EntityTuple<Chat> {
  const result = cloneEntityTuple(t, c => (c.docs = []));
  const [chats] = result;
  for (const c of chats) {
    if (c.isAttachment && c.data.attachment.mediaIds) {
      for (const mediaId of c.data.attachment.mediaIds) {
        const docObj = docMap[mediaId];
        if (docObj) {
          c.docs.push(docObj);
        }
      }
    }
  }
  return result;
}

export function projectDialogObjectsByEvent(
  events: CustomerEvent[],
  vendorsResearch: VendorResearch[]

): Dictionary<EntityTuple<HasDialog>> {
  const result = {};
  const mapVendorResearchByEventId = {};
  for (const v of vendorsResearch) {

    if (v.event) {
      let vv = mapVendorResearchByEventId[v.event.id];
      if (!vv) {
        vv = [];
        mapVendorResearchByEventId[v.event.id] = vv;
      }
      vv.push(v);
    }
  }
  for (const e of events) {
    result[e.id] = projectAggregated(e, mapVendorResearchByEventId[e.id] || []);
  }
  return result;
}

export function projectAggregated(
  event: CustomerEvent,
  vendorsResearch: VendorResearch[]
): EntityTuple<HasDialog> {
  const list: HasDialog[] = [
    ...event.items.filter(e => !e?.dialog?.isEmpty),
    ...event.categories.filter(e => !e?.dialog?.isEmpty),
    ...event.allVendorCats.filter(e => !e?.dialog?.isEmpty),
    ...vendorsResearch.filter(e => !e?.dialog?.isEmpty)
  ];
  if (!event.dialog.isEmpty) {
    list.push(event);
  }
  list.sort(
    (a1, a2) =>
      -(
        a1.dialog.lastContactDate.getTime() -
        a2.dialog.lastContactDate.getTime()
      )
  );
  const map = list.reduce((r, l) => ((r[l?.dialog.dialogId] = l) && r) || r, {});
  return [list, map];
}
