import { Inject, Injectable } from '@angular/core';
import { EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { EntityBaseService } from '@skylitup/base/ngrx-data-fire';
import { ENV, Environment } from '@skylitup/base/util';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { CustomerEventService } from '../customer-event/customer-event.service';
import { AGENCY_KEYS, CUSTOMER_KEYS } from '../data-index';
import { ChatTrack } from './chat-track.model';
import { ChatTrackData, CHAT_TRACK_KEYS } from './chat-track.model.data';
import { projectChatTrackswithAuth } from './chat-track.model.fn';

@Injectable({ providedIn: 'root' })
export class ChatTrackService extends EntityBaseService<ChatTrackData, ChatTrack> {

    chatTrackInContext$: Observable<ChatTrack>;
    chatTrackByEventId$: Observable<Dictionary<ChatTrack>>;

    public get DEBUG_MODE() {
        return this._DEBUG_MODE && !this.env.production;
    };
    private _DEBUG_MODE = false;

    constructor(
        entityServiceFactory: EntityCollectionServiceElementsFactory,
        entityDataServices: EntityDataService,
        private authService: AuthService,
        private customerEventService: CustomerEventService,
        @Inject(ENV) private env: Environment) {
        super(CHAT_TRACK_KEYS, entityServiceFactory, entityDataServices);
        this.init();
    }
    setupRxSelectors() {
        const result = createSelector(
            this._selectPlainEntityTuple,
            this.authService.selectorsProjected.selectAuth,
            projectChatTrackswithAuth
        );
        return result;
    }
    setupRx$() {
        this.chatTrackInContext$ = combineLatest([this.customerEventService.entityIdInContext$, this.entitiesProjected$])
            .pipe(map(([eventId, chatTracks]) => chatTracks.find(c => c.id === eventId)));
        this.chatTrackByEventId$ = this.entitiesProjected$.pipe(map((cc: ChatTrack[]) => cc.reduce((r, c) => { r[c.id] = c; return r; }, {} as Dictionary<ChatTrack>)));
    }

    protected newProjectedEntity(d: ChatTrackData) {
        return new ChatTrack(d);
    }

    sync$(agencyId: string, customerId: string) {
        return this.syncQueryRealtime$(
            [
                AGENCY_KEYS.plural,
                agencyId,
                CUSTOMER_KEYS.plural,
                customerId,
                CHAT_TRACK_KEYS.plural,
            ],
        );
    }
    syncAllCustomers$(agencyId: string) {
        return this.syncQueryRealtimeGroupCollection$(CHAT_TRACK_KEYS.plural, {
            queryFn: (q) => q.where('agencyId', '==', agencyId)
        });
    }
}