import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';
import { ChatTrack } from '../chat-track/chat-track.model';
import { ChatTrackService } from '../chat-track/chat-track.service';
import { CustomerEvent } from '../customer-event/customer-event.model';
import { ChatData, ChatTrackData, CHAT_KEYS } from '../data-index';
import { DocService } from '../upload/doc/doc.service';
import { MediaService } from '../upload/media/media.service';
import { UploadFilesRequest } from '../upload/upload.model.data';
import { Chat, Dialog } from './chat.model';
import { ChatService } from './chat.service';

@Injectable({ providedIn: 'root' })
export class ChatEditService {

    constructor(
        private mediaService: MediaService,
        private docService: DocService,
        private chatService: ChatService,
        private chatTrackService: ChatTrackService
    ) { }

    createChat(
        customerEvent: CustomerEvent,
        d: ChatData,
        uploadFilesRequest: UploadFilesRequest,
    ): Observable<ChatData> {
        const data: ChatData = {
            meta: {
                collectionPath: [...customerEvent._fullPath, CHAT_KEYS.plural],
                id: null
            },
            ...d
        };
        return this.upsertChat(data, uploadFilesRequest);
    }

    markChatRead(unread: Chat[], currentUserId: string) {
        const updates = unread.map((chat) => {
            const readBy = { ...chat.data.readBy } || {};
            readBy[currentUserId] = true;
            return { meta: chat.data.meta, readBy };
        });
        return this.chatService.updateBatch(updates);
    }

    markChatTrackRead(dialog: Dialog, chatTrack: ChatTrack): Observable<ChatTrackData> {
        const dialogId = dialog.dialogId;
        const currentUserId = dialog.currentUserId;
        const customerType = dialog.customerType;
        const chatTrackData = { ...chatTrack.data };
        chatTrackData.readBy = { ...chatTrackData.readBy };
        const readBy = chatTrackData.readBy[dialogId] = { ...(chatTrackData.readBy[dialogId] || {}) };
        readBy[currentUserId] = (chatTrackData.totals[dialogId] || 0);
        chatTrackData.sumReadBy = this.sumReadBy(chatTrackData);

        const updateData = {
            meta: chatTrackData.meta,
            customerType,
            readBy: { [dialogId]: { [currentUserId]: readBy[currentUserId] } },
            sumReadBy: { [currentUserId]: (chatTrackData.sumReadBy[currentUserId] || 0) },
        } as ChatTrackData;

        if (chatTrackData.sumReadBy[currentUserId] === chatTrackData.sumTotals) {
            updateData.readAllBy = { [currentUserId]: new Date() };
        }
        return this.chatTrackService.update(updateData);
    }

    sumReadBy(tracker: ChatTrackData): { [uId: string]: number } {
        const result = {} as { [uId: string]: number };
        for (const c of Object.values(tracker?.readBy || {})) {
            for (const u of Object.keys(c)) {
                result[u] = (result[u] || 0) + c[u];
            }
        }
        return result;
    }

    sumTotals(tracker: ChatTrackData): number {
        let total = 0;
        if (tracker) {
            for (const c of Object.values(tracker?.totals || {})) {
                total += c;
            }
        }
        return total;
    }

    deleteChat(chat: Chat) {
        return this.chatService
            .deleteEntity(chat.data)
            .pipe(first());

    }

    updateChat(
        d: ChatData,
        uploadFilesRequest: UploadFilesRequest
    ): Observable<ChatData> {
        return this.upsertChat(d, uploadFilesRequest);
    }

    upsertChat(
        data: ChatData,
        uploadFilesRequest: UploadFilesRequest
    ): Observable<ChatData> {
        return this.chatService.upsert(data).pipe(
            first(),
            // tap()
            mergeMap(chatData => {
                let result$: Observable<ChatData>;
                if (uploadFilesRequest) {
                    uploadFilesRequest.chat = chatData.meta.id;
                    if (uploadFilesRequest.type === 'image') {
                        result$ = this.mediaService.upload(uploadFilesRequest).pipe(
                            mergeMap(mediaDatas => {
                                return this.chatService.upsert({
                                    ...chatData,
                                    attachment: {
                                        isAttachment: true,
                                        uploading: false,
                                        mediaIds: mediaDatas.map(m => m.meta.id),
                                        type: 'image'
                                    }
                                });
                            }),
                        );
                    } else {
                        result$ = this.docService.upload(uploadFilesRequest).pipe(
                            mergeMap(docDatas => {
                                return this.chatService.upsert({
                                    ...chatData,
                                    attachment: {
                                        isAttachment: true,
                                        uploading: false,
                                        mediaIds: docDatas.map(m => m.meta.id),
                                        type: 'doc'
                                    }
                                });
                            }),
                        );
                    }

                    result$ = result$.pipe(catchError((err: Error) => {
                        return this.chatService.upsert({
                            ...chatData,
                            attachment: {
                                isAttachment: true,
                                uploading: false,
                                mediaIds: [],
                                uploadError: err.message,
                                type: uploadFilesRequest.type
                            }
                        });
                    }))
                } else {
                    result$ = of(chatData);
                }
                return result$;
            })
        );
    }
}