<div mat-dialog-title>Notes</div>

<mat-dialog-content>
    <form [formGroup]="form">
        <div>
            <mat-form-field appearance="standard">
                <mat-label>Notes</mat-label>
                <textarea matInput formControlName="notes" required (keyup)="keyUp($event.keyCode)" rows='3'></textarea>
                <mat-error *ngIf="notes.invalid">{{getErrorMessage(notes,'Notes')}}</mat-error>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <!-- [disabled]="!notes.valid"  -->
    <button mat-button [mat-dialog-close]="notes.value || ''">Save</button>
    <button mat-button [mat-dialog-close]="null">Cancel</button>
</mat-dialog-actions>