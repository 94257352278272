import { Injectable } from '@angular/core';
import { EntityOp, ofEntityOp } from '@ngrx/data';
import {
    Actions,
    createEffect,

    EffectNotification, ofType
} from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import {
    firestoreRealtime,
    firestoreRealtimeNoSyncing
} from '@skylitup/base/ngrx-data-fire';
import { genericCatch$$ } from '@skylitup/base/util';
import { asyncScheduler, combineLatest, merge, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap, throttleTime } from 'rxjs/operators';
import { ContextService } from '../../util/services/context.service';
import { NotificationService } from '../../util/services/notification.service';
import { AGENCY_KEYS } from '../agency/agency.model.data';
import { AgencyService } from '../agency/agency.service';
import { AuthService } from '../auth/auth.service';
import { idTokenResult } from '../auth/state/auth.actions';
import { allUiActionCreators, uiTrigger1 } from '../ui/state/ui.actions';
import { USER_TRACK_KEYS } from './user-track-model.data';
import { doHeartBeat, syncUserTrack } from './user-track.actions';
import { UserTrackService } from './user-track.service';

@Injectable()
export class UserTrackEffects {

    syncUserTrackers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(syncUserTrack),
            distinctUntilChanged(null, (a) => a.sync),
            switchMap((action) =>
                this.agencyService.entityIdInContext$.pipe(
                    switchMap((agencyId) =>
                        action.sync && agencyId
                            ? this.userTrackService
                                .sync$(agencyId)
                                .pipe(
                                    map((_) => firestoreRealtime(`agencies/${agencyId}/users-track`))
                                )
                            : of(firestoreRealtimeNoSyncing(`agencies/${agencyId}/users-track`))
                    ),
                    genericCatch$$('UserTrack - sync')
                )
            )
        )
    );
    userActivity$ = this.actions$.pipe(
        switchMap(a => merge(
            of(a).pipe(ofType(routerNavigatedAction, ...allUiActionCreators, doHeartBeat, uiTrigger1)),
            of(a).pipe(
                ofEntityOp(EntityOp.SAVE_UPDATE_ONE, EntityOp.SAVE_ADD_ONE, EntityOp
                    .SAVE_UPSERT_ONE),
                filter(g => g?.payload?.entityName !== this.userTrackService.entityName)
            )
        )),
        map(_ => new Date().getTime()));



    HEARTBEAT_SEC = 120;
    heartBeat$ = createEffect(
        () =>
            this.userActivity$.pipe(
                throttleTime(this.HEARTBEAT_SEC * 1000, asyncScheduler, {
                    leading: true,
                    trailing: true
                }),
                throttleTime((this.HEARTBEAT_SEC - 1) * 1000, asyncScheduler, {
                    leading: true,
                    trailing: false
                }),
                // tap(_ => console.log('active')),
                switchMap(r1 => of(r1).pipe(
                    switchMap((__) =>
                        combineLatest([
                            this.agencyService.entityIdInContext$,
                            this.authService.auth$.pipe(filter(a => !!a.userCard?.username))
                        ]).pipe(
                            filter(([agencyId, auth]) => (!!agencyId && !!auth && !!auth.userId)),
                            switchMap(([agencyId, auth]) =>
                                this.userTrackService.upsert({
                                    meta: {
                                        collectionPath: [AGENCY_KEYS.plural, agencyId, USER_TRACK_KEYS.plural],
                                        id: auth.userId
                                    },
                                    name: auth?.userCard?.username || null,
                                    lastActive: new Date(),
                                    _del: null
                                }),
                            ),
                            tap(userTrack => this.userTrackService.printActivity(userTrack)),
                            // switchMap(___ => interval(1 * 1000).pipe(map(i => i + 1), startWith(0)).pipe(
                            //     tap(jj => console.log('tik', jj)),
                            // )),
                        )
                    ),
                    genericCatch$$('usercard-joined$')
                )),
            ),
        { dispatch: false }
    );
    userLoggedIn$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(idTokenResult),
                switchMap((idTokenResultAction) =>
                    of(idTokenResultAction).pipe(
                        // tap(gg => console.log('gg1', gg)),
                        debounceTime(this.HEARTBEAT_SEC * 1000),
                        // tap(gg => console.log('gg2', gg)),
                        switchMap((__) =>
                            combineLatest([
                                this.agencyService.entityIdInContext$,
                                // this.authService.auth$.pipe(tap(gg => console.log('ff3', gg)), filter(b => !!b.userCard?.username), tap(gg => console.log('ff6', gg)))
                            ]).pipe(
                                // tap(gg => console.log('gg3', gg)),
                                filter(([agencyId]) => !!agencyId && !!idTokenResultAction.payload.uid),
                                // tap(gg => console.log('gg4', gg)),
                                switchMap(([agencyId]) =>
                                    this.userTrackService.upsert({
                                        meta: {
                                            collectionPath: [AGENCY_KEYS.plural, agencyId, USER_TRACK_KEYS.plural],
                                            id: idTokenResultAction.payload.uid,
                                        },
                                        // name: auth?.userCard?.username || null,
                                        lastActive: new Date(),
                                        lastLogin: new Date(),
                                        _del: null
                                    })
                                ),
                                tap(userTrack => this.userTrackService.printLogin(userTrack))
                            )
                        ),
                        genericCatch$$('usercard-joined$')
                    )
                )
            ),
        { dispatch: false }
    );



    constructor(
        private actions$: Actions,
        private userTrackService: UserTrackService,
        private agencyService: AgencyService,
        private authService: AuthService,
        private notifications: NotificationService,
    ) {
        // this.userActivity$ = 
    }
    ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
        return this.notifications.trackCompletion(resolvedEffects$);
    }
}
