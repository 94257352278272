import { Params, RouterStateSnapshot } from "@angular/router";
import { RouterStateSerializer } from "@ngrx/router-store";

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  paramsMapWithoutScope: Params;
  edit: boolean;
  segmentPaths: string[]
}
export interface FlrglRouterState {
  state: RouterStateUrl;
}

export class CustomRouterSerializer
  implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {

    let route = routerState.root;
    let segmentPaths = [];
    let paramsMapWithoutScope = {};
    let edit = false;
    while (route.firstChild) {
      route = route.firstChild;
      paramsMapWithoutScope = { ...paramsMapWithoutScope, ...route.params };
      if (route.url && route.url.find((s) => s.path === "edit")) {
        edit = true;
      }
      if (route.url && route.url.length) {
        segmentPaths = route.url.map(u => u.path);
      }
    }
    const {
      url,
      root: { queryParams },
    } = routerState;
    const { params } = route;
    // const g = rout
    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    const result = { url, params, queryParams, paramsMapWithoutScope, edit, segmentPaths };
    return result;
  }
}
