<div class='cat-header1' fxLayout="row"  fxLayout.lt-sm="column" fxLayoutAlign="end">
    <div fxFlex="grow" fxLayoutAlign="start center">
        <ng-content></ng-content>
    </div>
    <div fxLayout="column" fxLayoutAlign="start end" fxFlex="none" fxFlexOrder.lt-sm="3" class="payments-container1">
        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" class="total " [ngClass]="{'pending':digits2(paymentsPaid - actualBudgetCalc)!==0, 
                'paid':digits2(paymentsPaid - actualBudgetCalc)===0}">
                <mat-label> Pending <span *ngIf="name">({{name}})</span></mat-label>
                <input matInput class="right1"
                    [ngModel]="actualBudgetCalc - paymentsPaid  | currency:'EUR':'symbol'" readonly>
            </mat-form-field>
            <mat-form-field appearance="outline" class="total paid">
                <mat-label> Paid <span *ngIf="name">({{name}})</span></mat-label>
                <input matInput class="right1" [ngModel]="paymentsPaid | currency:'EUR':'symbol'" readonly>
            </mat-form-field>
        </div>
        <ng-container *auth1="['planner']">
            <mat-hint align="end" *ngIf="digits2(paymentsTotal - actualBudgetCalc)!==0" fxFlex='none'>
                Payments! {{paymentsTotal | currency:'EUR':'symbol':'1.0-0'}}
                ( <span class="goal-below" *ngIf="paymentsTotal > actualBudgetCalc">
                    € {{digits2(paymentsTotal - actualBudgetCalc)}} more than the Budget</span>
                <span class="goal-below" *ngIf="actualBudgetCalc > paymentsTotal">
                    € {{digits2(actualBudgetCalc - paymentsTotal)}} less than the Budget</span> )
            </mat-hint>
        </ng-container>
    </div>
    <div fxFlex.gt-xs="1 1 20%"></div>
    <div fxLayout="column" fxLayoutAlign="start end" class="budget-container1" fxFlexOrder.lt-sm="2">
        <div fxLayout="row" fxLayoutGap="10px">
            <ng-container *ngIf="goalBudget!==undefined;else noGoal">
                <mat-form-field [appearance]="editable?'fill':'outline'" class="goal">
                    <mat-label> Goal <span *ngIf="name">({{name}})</span></mat-label>
                    <input matInput type="number" #input1 class="right1" [formControl]="goalControl"
                        [readonly]="!editable" (focus)="editable && onFocus(input1)">
                    <span matPrefix>€&nbsp;</span>
                </mat-form-field>
            </ng-container>
            <ng-template #noGoal>
                <div class="goal">
                </div>
            </ng-template>
            <mat-form-field appearance="outline" class="total" [ngClass]="getCatActualColor()">
                <mat-label> Budget <span *ngIf="name;else noname6">({{name}})</span>
                    <ng-template #noname6>(unassigned)</ng-template>
                </mat-label>
                <input matInput class="right1" [ngModel]="actualBudgetCalc | currency:'EUR':'symbol'" readonly>
            </mat-form-field>
        </div>


        <ng-container *ngIf="goalBudget!==undefined;else noGoal5">
            <mat-hint *ngIf="goalBudget > actualBudgetCalc" align="end" [ngClass]="getCatActualColor()">
                € {{digits2(goalBudget - actualBudgetCalc)}} less than
                the Goal </mat-hint>
            <mat-hint *ngIf="goalBudget < actualBudgetCalc" align="end" [ngClass]="getCatActualColor()">
                € {{digits2( actualBudgetCalc - goalBudget) }}
                more than the Goal
            </mat-hint>
        </ng-container>
        <ng-template #noGoal5>
            <mat-hint *ngIf="extraCost" align="end">
                € {{digits2( actualBudget) }} + extra
            </mat-hint>
        </ng-template>
    </div>
</div>