import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigateComponent } from './components/navigate/navigate.component';
import {
  LoginGuard,
  SingleAgencyGuard,
  AGENCY_KEYS,
  AgencyGuard,
  CUSTOMER_KEYS,
  CUSTOMER_EVENT_KEYS,
  EVENT_ITEM_KEYS,
  CustomerGuard,
  LoggedInRedirectGuard,
} from '@skylitup/flowergirl/core';
import { AgencyListComponent } from './components/agency-list/agency-list.component';
import { AgencyComponent } from './components/agency/agency.component';
import { CustomerComponent } from './components/agency/customer/customer.component';
import { CustomerEventComponent } from './components/agency/customer/customer-event/customer-event.component';
import { CustomerEventListComponent } from './components/agency/customer/customer-event-list/customer-event-list.component';
import { CustomerListComponent } from './components/agency/customer-list/customer-list.component';
import { LoginComponent } from './components/login/login.component';
import { VerifyEmailComponent } from './components/login/verify-email/verify-email.component';
import { MediaComponent } from '@skylitup/flowergirl/xplat/web/features/media';
import {
  ChatByCatComponent,
  ChatRecentComponent,
  ChatComponent,
} from '@skylitup/flowergirl/xplat/web/features/chat';
import {
  CompareVendorsComponent,
  ComparePricesComponent,
  VendorResearchComponent,
} from '@skylitup/flowergirl/xplat/web/features/vendor';
import {
  EventItemDetailsComponent,
  OverviewComponent,
} from '@skylitup/flowergirl/xplat/web/features/overview';
import {
  BudgetComponent,
  PaymentsComponent,
} from '@skylitup/flowergirl/xplat/web/features/budget';
import { ChecklistAgencyComponent, ChecklistComponent } from '@skylitup/flowergirl/xplat/web/features/checklist';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/agencies' },
  {
    path: '',
    component: NavigateComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: 'agencies',
        component: AgencyListComponent,
        canActivate: [SingleAgencyGuard],
        children: [],
      },
      {
        path: 'agency/:' + AGENCY_KEYS.name,
        component: AgencyComponent,
        children: [
          {
            path: 'edit',
            canActivate: [AgencyGuard],
            loadChildren: () =>
              import('@skylitup/flowergirl/xplat/web/features/edit').then(
                (m) => m.FlowergirlXplatWebFeaturesEditModule
              ),
          },
          {
            path: 'big-plan',
            canActivate: [AgencyGuard],
            component: ChecklistAgencyComponent,
          },
          {
            path: 'customer/:' + CUSTOMER_KEYS.name,
            component: CustomerComponent,
            children: [
              {
                path: 'event/:' + CUSTOMER_EVENT_KEYS.name,
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: 'overview',
                  },
                  {
                    path: 'event-item/:' + EVENT_ITEM_KEYS.name,
                    pathMatch: 'full',
                    redirectTo: 'overview/details/:' + EVENT_ITEM_KEYS.name,
                  },
                  {
                    path: '',
                    component: CustomerEventComponent,
                    children: [
                      {
                        path: '',
                        component: VendorResearchComponent,
                        children: [
                          {
                            path: 'vendor-research/:researchCatId',
                            children: [
                              {
                                path: '',
                                pathMatch: 'full',
                                component: CompareVendorsComponent,
                              },
                              {
                                path: 'media',
                                component: MediaComponent,
                              },
                            ],
                          },
                          {
                            path: 'compare-prices/:researchCatId',
                            component: ComparePricesComponent,
                          },
                        ],
                      },
                      {
                        path: 'overview',
                        children: [
                          {
                            path: 'details/:' + EVENT_ITEM_KEYS.name,
                            children: [
                              {
                                path: '',
                                component: EventItemDetailsComponent,
                                pathMatch: 'full',
                              },
                              {
                                path: 'media',
                                component: MediaComponent,
                              },
                            ],
                          },
                          {
                            path: '',
                            component: OverviewComponent,
                            pathMatch: 'full',
                          },
                        ],
                      },
                      {
                        path: 'budget',
                        children: [
                          {
                            path: '',
                            component: BudgetComponent,
                            pathMatch: 'full',
                          },
                          {
                            path: 'payments',
                            component: PaymentsComponent,
                          },
                        ],
                      },
                      {
                        path: 'plan',
                        component: ChecklistComponent,
                      },
                      {
                        path: 'chat',
                        component: ChatComponent,
                        children: [
                          {
                            path: '',
                            pathMatch: 'full',
                            redirectTo: 'categorized',
                          },
                          {
                            path: 'recent',
                            children: [
                              {
                                path: '',
                                component: ChatRecentComponent,
                                pathMatch: 'full',
                              },
                              {
                                path: 'media',
                                component: MediaComponent,
                              },
                            ],
                          },
                          {
                            path: 'categorized',
                            children: [
                              {
                                path: '',
                                component: ChatByCatComponent,
                                pathMatch: 'full',
                              },
                              {
                                path: 'media',
                                component: MediaComponent,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'media',
                        component: MediaComponent,
                        data: { rootMedia: true }

                      }
                    ],
                  },
                ],
              },
              {
                path: '',
                component: CustomerEventListComponent,
                children: [],
                pathMatch: 'full',
              },
            ],
          },
          {
            path: '',
            component: CustomerListComponent,
            children: [],
            canActivate: [CustomerGuard],
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoggedInRedirectGuard],
  },
  { path: 'verify-email', component: VerifyEmailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FlowergirlFeaturesNavigateModuleRoutingModule { }
