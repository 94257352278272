import {
  ChangeDetectionStrategy, Component,
  Input
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ChatTrack, ChatTrackService, ContextService,
  createUiFocusChat, Dialog
} from '@skylitup/flowergirl/core';
import { ChatLinkBaseComponent } from '@skylitup/flowergirl/ui';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'flrgl-chat-link',
  templateUrl: 'chat-link.component.html',
  styleUrls: ['chat-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatLinkComponent extends ChatLinkBaseComponent {
  @Input()
  dialog: Dialog;
  chatTrack$: Observable<ChatTrack>;
  constructor(
    private store: Store,
    private router: Router,
    private context: ContextService,
    private chatTrackService: ChatTrackService
  ) {
    super();
    this.chatTrack$ = this.chatTrackService.chatTrackInContext$;

  }
  navigateToChat() {
    this.store.dispatch(createUiFocusChat(this.dialog.dialogObj));
    this.context.contextPath$
      .pipe(first())
      .subscribe((path) => this.router.navigate([...path, 'chat']));
  }
}
