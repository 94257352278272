import {
  EntityData,
  EntityKeys,
} from '@skylitup/base/ngrx-data-fire';
import { CandiDate } from '../customer-event/customer-event.model.data';
import { UserAccessCustomer } from '../user-card/user-card.model.data';

export interface UserInfoCustomer extends UserAccessCustomer {
  username: string;
  agencyScope: boolean;
}
export const CUSTOMER_KEYS: EntityKeys = {
  name: 'customer',
  plural: 'customers',
};
export interface CustomerData extends EntityData {
  name: string;
  agencyName: string;
  type: CustomerDataType;
  customers: string[];
  assignees: string[];
  readOnlyUsers: string[];
  eventsInfo: { [eventId: string]: EventInfo },
  usersInfo: { [authUid: string]: UserInfoCustomer },
  timezone: string;
}
export type CustomerDataType = 'client' | 'other' | 'demo' | 'archive' | 'lead';

export interface EventInfo {
  name: string;
  candiDates: CandiDate[];
  isDateBooked: boolean;
  bookedCandiDateId?: string | null;
}

export interface HasTimezone {
  timezone: string;
}