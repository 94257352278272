import { Dictionary } from '@ngrx/entity';
import { EntityProjected, TIMEZONE_LONDON } from '@skylitup/base/ngrx-data-fire';
import { Customer } from '../customer/customer.model';
import { AgencyData, UserInfoAgencyData } from './agency.model.data';
import { LocStore } from './location.model';

export class Agency extends EntityProjected<AgencyData> {
  members: UserInfoAgency[] = [];
  memberMap: Dictionary<UserInfoAgency> = {};
  membersExludeOwners: UserInfoAgency[] = [];

  owners: UserInfoAgency[] = [];
  readonly usersInfo: { [authUid: string]: UserInfoAgency };
  // readonly usersInfoByEmail: { [email: string]: UserInfoAgency };
  constructor(data: AgencyData) {
    super(data);
    this.usersInfo = {}
    for (const k of Object.keys(this.data.usersInfo)) {
      this.usersInfo[k] = new UserInfoAgency(this.data.usersInfo[k], k);
    }
    // this.usersInfoByEmail = {};
    // for (const u of Object.values(this.usersInfo)) {
    //   this.usersInfoByEmail[u.email] = u;
    // }
    this.owners = (this.data.owners || []).filter(o => !!this.usersInfo[o]).map(k => this.usersInfo[k]);
    this.memberMap = {};
    for (const k of (this.data.members || [])) {
      if (!!this.usersInfo[k]) {
        this.memberMap[k] = this.usersInfo[k];
      }
    }
    this.members = Object.values(this.memberMap);
    this.membersExludeOwners = this.members.filter(m => !this.owners.map(o => o.uid).includes(m.uid))
  }
  get timezone() {
    return this.data.timezone || TIMEZONE_LONDON;
  }
  get allTeamAuthUIds() {
    return [...(this.data.members || []), ...(this.data.membersRestricted || []), ...(this.data.membersSales || [])]
  }
  customerMap: Dictionary<Customer> = {};
  locStore: LocStore = { locations: [], locationMap: {}, locationTree: [] };
  clone(): Agency {
    return new Agency(this.data).copy(this);
  }
  copy(from: Agency): Agency {
    this.customerMap = from.customerMap;
    this.locStore = { ...from.locStore };

    this.owners = from.owners;
    return this;
  }

}
export class UserInfoAgency {
  get username() {
    return this.data.username;
  }
  get name() {
    return this.data.username;
  }
  // get email() {
  //   return this.data.email;
  // }
  get authUid() {
    return this.uid;
  }

  constructor(public data: UserInfoAgencyData, public uid: string) {
  }
}