<div class="month-outer">
    <div class="month mat-elevation-z2">
        <div class="cal-title" fxLayoutAlign="center center">
            {{month.title}}
        </div>
    </div>
    <div fxLayout="row" class="weekdays-normal">
        <div *ngFor="let day of weekDays" class="day" fxFlex="grow" fxLayoutAlign="center start">
            <span>{{day}}</span>
        </div>
    </div>
    <div fxLayout="row" class="weekdays-mobile">
        <div *ngFor="let day of _weekDays" class="day" fxFlex="grow" fxLayoutAlign="center start">
            <span>{{day}}</span>
        </div>
    </div>

    <div *ngFor="let week of month.weeks" fxLayout="row">
        <ng-container *ngFor="let day of week.days">
            <div *ngIf="!day.empty;else emptyDay"
                [ngClass]="{active:day.active,event:day.isEventDate,today:day.isToday}" class="day" fxFlex="grow"
                (cdkDropListEntered)="enterDay(day)" (cdkDropListExited)="exitDay(day)"
                (cdkDropListDropped)="selectedDate(day,$event)" cdkDropList fxLayoutAlign="start center"
                fxLayout="column">
                <div>
                    {{day.number}}
                </div>
                <div *ngIf="printEvents">
                    <div *ngFor="let e of day.momentEvents" class="event mat-elevation-z3" matRipple
                        (click)="clicked.next(e)">
                        <div [matBadge]="eventUnread(e.eventId)" matBadgePosition="above after" matBadgeSize='small'
                            matBadgeOverlap='true' [matBadgeHidden]="!eventUnread(e.eventId)">
                            {{e.name}}
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #emptyDay>
                <div class="day" fxFlex="grow">
                </div>
            </ng-template>
        </ng-container>

    </div>
</div>