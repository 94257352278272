import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent, WindowService } from '@skylitup/base/util';
import {
  AppState,
  Category,
  CustomerService,
  SystemService,
  THEME_NAME,
} from '@skylitup/flowergirl/core';
import { Observable } from 'rxjs';
import { filter, pairwise, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'flrgl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends BaseComponent {
  title = 'web-flowergirl';
  cats$: Observable<Category[]>;
  innerHeight: number;
  constructor(
    private systemService: SystemService,
    customerService: CustomerService,
    store: Store<AppState>,
    private windowService: WindowService,
  ) {
    super();
    const defaultTheme: THEME_NAME = 't-indigo';
    store
      .select((state: AppState) => state.ui.theme)
      .pipe(
        takeUntil(this.destroy$),
        filter((o) => !!o),
        startWith(defaultTheme),
        pairwise()
      )
      .subscribe(([previousTheme, theme]) => {
        // console.log('theme dude', theme);
        const bodyClassList = document.getElementsByTagName('body')[0]
          .classList;
        bodyClassList.remove(previousTheme);
        bodyClassList.add(theme);
      });
    this.innerHeight = this.windowService.window.innerHeight;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerHeight > this.innerHeight) {
      this.windowService.innerHeightIncreased$.next(true);
      const bodyClassList = document.getElementsByTagName('body')[0].classList;
      bodyClassList.add('innerHeightIncreased');
    } else {
      this.windowService.innerHeightIncreased$.next(false);
      const bodyClassList = document.getElementsByTagName('body')[0].classList;
      bodyClassList.remove('innerHeightIncreased');
    }
    this.innerHeight = event.target.innerHeight;
  }
}
