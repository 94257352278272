import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  DateAdapter,
  MatRippleModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
// ?import { } from '@angular/material/datepicker/datepicker-module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { CrystalLightboxModule } from '@skylitup/base/xplat/web/ui/external';
import { GoogleMapsModule } from '@angular/google-maps';

// libs
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { ToastrModule } from 'ngx-toastr';

import { DomSanitizer, BrowserModule } from '@angular/platform-browser';
import { UI_SVG_ICONS } from './svg-icons';
import { HeaderComponent } from './components/header/header.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { GoBackComponent } from './components/go-back/go-back.component';
import { ChatLinkComponent } from './components/chat-link/chat-link.component';
import { LightboxComponent } from './components/lightbox/lightbox.component';
import { PdfboxComponent } from './components/pdfbox/pdfbox.component';
import { BaseXplatWebUiMaterialModule } from '@skylitup/base/xplat/web/ui/material';
import { ThemeSelectorComponent } from './components/theme-selector/theme-selector.component';
import { RatingComponent } from './components/rating/rating.component';
import { TimeTravelComponent } from './components/time-travel/time-travel.component';
import { FlowergirlUiModule, AuthDirective } from '@skylitup/flowergirl/ui';
import { BudgetHeaderExpandedComponent } from './components/budget/budget-header-expanded/budget-header-expanded.component';
import { BudgetHeaderComponent } from './components/budget/budget-header/budget-header.component';
import { CommissionItemComponent } from './components/budget/commission-item/commission-item.component';
import { CommissionItemCalculationComponent } from './components/budget/commission-item-calculation/commission-item-calculation.component';
import { CommissionPaymentsReceivedComponent } from './components/budget/commission-payments-received/commission-payments-received.component';

import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
// import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';


const UI_COMPONENTS = [
  HeaderComponent,
  BreadcrumbComponent,
  GoBackComponent,
  ChatLinkComponent,
  LightboxComponent,
  PdfboxComponent,
  ThemeSelectorComponent,
  BudgetHeaderExpandedComponent,
  BudgetHeaderComponent,
  CommissionItemComponent,
  CommissionItemCalculationComponent,
  CommissionPaymentsReceivedComponent,
  RatingComponent,
  TimeTravelComponent,
];

const UI_DIRECTIVES = [AuthDirective];
const MODULES = [
  CommonModule,
  FormsModule,
  RouterModule,
  ReactiveFormsModule,
  BaseXplatWebUiMaterialModule,
  FlowergirlUiModule,
  FlexLayoutModule,
  NgxGalleryModule,
  CrystalLightboxModule,
  GoogleMapsModule,
  // TableVirtualScrollModule
];

const MAT_MODULES = [
  MatDialogModule,
  MatIconModule,
  MatListModule,
  MatExpansionModule,
  MatSelectModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatAutocompleteModule,
  MatTreeModule,
  MatChipsModule,
];

const everything = [
  A11yModule,
  ClipboardModule,
  CdkStepperModule,
  CdkTableModule,
  CdkTreeModule,
  DragDropModule,
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatStepperModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  PortalModule,
  ScrollingModule,
  ExperimentalScrollingModule
];
export const MY_DATE_FORMATS = {
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    ...MODULES,
    ...everything,
    ToastrModule.forRoot({
      autoDismiss: false,
      preventDuplicates: true,
      countDuplicates: true,
      positionClass: 'toast-bottom-right',
      disableTimeOut: true,
      tapToDismiss: true,
    }),
  ],
  declarations: [...UI_COMPONENTS, ...UI_DIRECTIVES],
  exports: [...MODULES, ...everything, ...UI_COMPONENTS, ...UI_DIRECTIVES],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_DATE_FORMATS,
    },
  ],
})
export class FlowergirlXplatWebUiModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    for (const icon of UI_SVG_ICONS) {
      iconRegistry.addSvgIcon(
        icon,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon}.svg`)
      );
    }
  }
}
