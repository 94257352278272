import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { CommissionItemCalculationComponent } from '../commission-item-calculation/commission-item-calculation.component';
import { CommissionPaymentsReceivedComponent } from '../commission-payments-received/commission-payments-received.component';
import { CommissionData } from '@skylitup/flowergirl/core';
import { CommissionItemBaseComponent } from '@skylitup/flowergirl/ui';


@Component({
  selector: 'flrgl-commission-item',
  templateUrl: 'commission-item.component.html',
  styleUrls: ['commission-item.component.scss'],
})
export class CommissionItemComponent extends CommissionItemBaseComponent
  implements OnInit {
  constructor(public dialog: MatDialog) {
    super();
  }
  @Input()
  commissionRate: number;
  @Input()
  commissionAmount: number;
  @Input()
  commissionPaid: number;
  @Input()
  debug = false;
  @Input()
  commissionData: CommissionData;
  @Output()
  calcChange = new EventEmitter();
  @Output()
  paymentsReceivedChange = new EventEmitter();

  @Input()
  editable = true;

  editCalculation() {
    this.dialog
      .open(CommissionItemCalculationComponent, {
        data: this.commissionData,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((d) => {
        if (d) {
          this.calcChange.next(d);
        }
      });
  }

  editPaymentsReceived() {
    this.dialog
      .open(CommissionPaymentsReceivedComponent, {
        data: this.commissionData.paymentsReceived,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((d) => {
        if (d !== null && d !== undefined) {
          this.paymentsReceivedChange.next(+d);
        }
      });
  }

  ngOnInit(): void {
    if (this.debug) {
      this.editCalculation();
    }
  }
}
