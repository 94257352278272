import {
  EntityData,
  EntityKeys,
  Timestamp,
} from '@skylitup/base/ngrx-data-fire';
import { Dictionary } from '@ngrx/entity';
import { CustomerDataType, EventInfo } from '../customer/customer.model.data';

export const CUSTOMER_EVENT_KEYS: EntityKeys = {
  name: 'event',
  plural: 'events',
};
export interface CustomerEventData
  extends EntityData, EventInfo,
  CustomerEventIndexData,
  CustomerEventCardData,
  CustomerEventMoney,
  VendorResearchEventDefData {
  customerType: CustomerDataType;
  agencyId: string;
  timezone: string;
  notes?: string;
  eventType: 'wed_religious' | 'wed_civil' | 'wed_symbolic' | 'business' | 'baptism' | 'other';
  ceremonyType: 'orthodox' | 'catholic' | 'coptic' | 'jewish' | 'hindu';
}

export const EVENT_TYPE_OPTIONS = {
  'wed_religious': "Religious Wedding",
  'wed_civil': "Civil Wedding",
  'wed_symbolic': "Symbolic Wedding",
  'business': "Business Event",
  'baptism': "Baptism",
  'other': "Other"
}

export const CEREMONY_TYPE_OPTIONS = {
  "orthodox": "Orthodox"
  , "catholic": "Catholic"
  , "coptic": "Coptic"
  , "jewish": "Jewish"
  , "hindu": "Hindu"
  , 'other': "Other"
}

export interface CommissionData {
  type: 'amount' | 'rate';
  amount: number;
  rate: number;
  paymentsReceived: number;
}
export interface CustomerEventMoney {
  catGoalBudgets: {
    [catId: string]: number;
  };
  commissions?: {
    [vendorId: string]: CommissionData;
  };
}

export interface CustomerEventCardData {
  date: Timestamp | Date;
  adultGuests: number;
  childrenGuests: number;
}
export interface CustomerEventIndexData {
  cats: string[];
  vendors: {
    [catId: string]: string[];
  };
  checklistNext: {
    [catId: string]: string;
  };
}


export interface VendorResearchEventDefData {
  // (hide the button from the overview?)
  hideVrMap: Dictionary<boolean>;
  research: { [catId: string]: VendorResearchCatDefData };
}
export interface VendorResearchCatDefData {
  versions: VendorResearchItemDefData[];
  priceItems: VendorResearchItemDefData[];
  useDates: boolean;
}
export interface VendorResearchItemDefData {
  id: string;
  name: string;
  // versionDescription: string;
}
export interface CandiDate {
  date: Date | Timestamp;
  dateFrom?: Date | Timestamp;
  id: string;
}

export interface CloneData {
  cats: string[],
  dataTypes: CloneDataType[],
  fromCustomerEventId: string,
  fromCustomerId: string,
  agencyId: string,
  toCustomerEventId: string
  toCustomerId: string
  candiDatesMap: { [currentCandiDateId: string]: string },
  replaceMatches: boolean
}
export type CloneDataType = 'vendorResearch' | 'eventItemsAndVendors' | 'specs' | 'checklist' | 'payments' | 'budget' | 'chats';
export const CLONE_CANDIDATE_NO_MAP = "xx-no-map-xx";