<mat-tab-group animationDuration="0ms" (selectedTabChange)="tabChanged($event)" #tabGroup1
    [selectedIndex]="checklistTabIndex$ | async">
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="m-r-15">event_note</mat-icon>
            <span class="text1">
                <ng-container *ngIf="paymentsOnly;else checklist5">Payments Timeline</ng-container>
                <ng-template #checklist5>Timeline</ng-template>
            </span>
        </ng-template>
        <ng-template matTabContent>
            <flrgl-checklist-timeline [paymentsOnly]="paymentsOnly"></flrgl-checklist-timeline>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="m-r-15">account_tree</mat-icon>
            <span class="text1">
                <ng-container *ngIf="paymentsOnly;else checklist5">Categorized Payments</ng-container>
                <ng-template #checklist5>Categorized</ng-template>
            </span>
        </ng-template>
        <ng-template matTabContent>
            <flrgl-checklist-by-cat [paymentsOnly]="paymentsOnly"></flrgl-checklist-by-cat>
        </ng-template>
    </mat-tab>
</mat-tab-group>