import { Dictionary } from '@ngrx/entity';
import { cloneEntityTuple, EntityTuple } from '@skylitup/base/ngrx-data-fire';
import { Agency } from '../agency/agency.model';
import { Chat, Dialog, HasDialog } from '../chat/chat.model';
import { ChecklistVendorCat } from '../checklist-item/checklist-cat.model';
import {
  ChecklistItem,
  compareChecklistItems,
} from '../checklist-item/checklist-item.model';
import { ChecklistMonth } from '../checklist-item/checklist-month.model';
import { Payment } from '../checklist-item/payment.model';
import { Customer } from '../customer/customer.model';
import { EventItem } from '../event-item/event-item.model';
import { Category } from '../system/system.model';
import { Vendor } from '../vendor/vendor.model';
import { CustomerEvent } from './customer-event.model';
import { EventCategory, VendorCat } from './vendor-cat.model';

export const projectCustomerEventsWithAgency = (
  t: EntityTuple<CustomerEvent>,
  agencyMap: Dictionary<Agency>
) => {
  const result = cloneEntityTuple(t);
  const [customerEvents] = result;
  for (const customerEvent of customerEvents) {
    customerEvent.agency = agencyMap[customerEvent.agencyId];
  }
  return result;
};

export const projectCustomerEventToCustomer = (
  t: EntityTuple<CustomerEvent>,
  customerMap: Dictionary<Customer>
) => {
  const result = cloneEntityTuple(t);
  const [customerEvents] = result;
  for (const customerEvent of customerEvents) {
    customerEvent.customer = customerMap[customerEvent.customerId];
  }
  return result;
};

export const projectCustomerEventsCategories = (
  t: EntityTuple<CustomerEvent>,
  categoryMap: Dictionary<Category>
): EntityTuple<CustomerEvent> => {
  const result = cloneEntityTuple<CustomerEvent>(t, (event) => {
    event.categories = [];
    event.categoryMap = {};
    event.totalGoalBudget = 0;
  });
  const [events] = result;
  for (const event of events) {
    if (event.data.cats) {
      for (const id of event.data.cats) {
        const cat = categoryMap[id];
        if (cat) {
          const eventCat = new EventCategory(cat.data, event);
          event.categories.push(eventCat);
          event.categoryMap[eventCat.id] = eventCat;
          event.totalGoalBudget += event.getCatGoalBudget(cat.id);
        }
      }
    }
  }
  return result;
};

export const projectVendorCats = (
  t: EntityTuple<CustomerEvent>,
  vendorMap: Dictionary<Vendor>
): EntityTuple<CustomerEvent> => {
  const result = cloneEntityTuple(t, (event) => {
    event.clearVendorCats();
  });
  const [events, eventMap] = result;
  for (const event of events) {
    if (event.data.cats && event.data.vendors) {
      for (const catId of event.data.cats) {
        const cat = event.categoryMap[catId];
        const vendorIds = event.data.vendors[catId] || [];

        for (const vendorId of vendorIds) {
          const vendor = vendorMap[vendorId];
          if (cat) {
            let vendorCat = event.getVendorCat(cat.id, vendor?.id);
            if (!vendorCat) {
              vendorCat = new VendorCat(cat, vendor, event);
              event.pushVendorCat(vendorCat);
            }
          }
        }
      }
    }
  }
  return result;
};

export const projectEventToItems = (
  t: EntityTuple<CustomerEvent>,
  eventItems: EventItem[]
): EntityTuple<CustomerEvent> => {
  const result = cloneEntityTuple(t, (event) => {
    event.clearItems();
    event.clearBudgets();
    event.markVendorCatsDone();
  });
  const [events, eventMap] = result;
  for (const item of eventItems) {
    const eventId = item.data.meta.parentId;
    const event = eventMap[eventId];
    if (event) {
      const vendorCat = event.getVendorCat(item.catId, item.vendorId);
      if (vendorCat) {
        item.event = event;
        item.vendorCat = vendorCat;
        vendorCat.items.push(item);
        vendorCat.money.budget += item.budget;
        event.itemMap[item.id] = item;
        event.items.push(item);
        if (!item.done) {
          if (event.categoryMap[vendorCat.id]) {
            event.categoryMap[vendorCat.id].done = false;
          }
        }
      }
    }
  }

  for (const event of events) {
    for (const v of event.allVendorCats) {
      v.items.sort((i1, i2) => i1.data.sortIndex - i2.data.sortIndex);
      event.setCatBudget(v.id, event.getCatBudget(v.id) + v.money.budget);
      if (v.vendorId) {
        event.vendorBudgetMap[v.vendorId] =
          (event.vendorBudgetMap[v.vendorId] || 0) + v.money.budget;
      }
      event.totalActualBudget += v.money.budget;
    }
  }

  return result;
};

export const projectEventoToChecklist = (
  t: EntityTuple<CustomerEvent>,
  cc: ChecklistItem[],
  vendorMap: Dictionary<Vendor>
) => {
  const result = cloneEntityTuple(t, (event) => {
    event.checklistMonths = [];
    event.checklistMonthMap = {};
    event.checklistCatMap = {};
    event.checklistCats = [];
    event.checklistItems = [];
    event.highlightedChecklistItem = null;
    event.catPaidMap = {};
    event.catPaymentPendingMap = {};
    event.catExtraCostMap = {};
    event.totalPaid = 0;
    event.totalPaymentPending = 0;
    event.allVendorCats.forEach((v) => {
      v.money.paid = 0;
      v.money.pendingPayment = 0;
      v.money.extraCost = 0;
      v.payments = [];
    });
    event.vendorPaidMap = {};
  });

  const [events, eventMap] = result;

  let checklistItems = [...cc];
  for (const ev of events) {
    checklistItems = checklistItems.concat(ev.items as any);
  }
  for (const item of checklistItems) {
    // console.log('hehe',item.data.meta.urlPath);
    const event = eventMap[item.eventId];
    item.event = event;

    if (event) {
      event.checklistItems.push(item);
      if (event.agency && item.assigneeId) {
        item.assignee = event.agency.memberMap[item.assigneeId];
      }
      const cat = event.categoryMap[item.catId];
      if (cat) {
        let checklistCat = event.checklistCatMap[item.catId];
        if (!checklistCat) {
          checklistCat = new ChecklistVendorCat(cat, event);
          event.checklistCatMap[checklistCat.id] = checklistCat;
          event.checklistCats.push(checklistCat);
        }
        checklistCat.items.push(item);
        item.checklistCat = checklistCat;
        if (event.data?.checklistNext?.[item.catId] === item.id) {
          checklistCat.highlightedChecklistItem = item;
        }
        if (item.isPayment) {
          const payment = item as Payment;
          const vendor = vendorMap[item.vendorId];
          payment.vendor = vendor || null;
          const vendorCat = event.getVendorCat(cat.id, vendor?.id);
          if (vendorCat) {
            vendorCat.payments.push(payment);
          }
          if (item.done) {
            event.setCatPaid(cat.id, event.getCatPaid(cat.id) + payment.amount);
            if (vendorCat) {
              vendorCat.money.paid += payment.amount;
              if (payment.includesCommission && vendor) {
                event.vendorPaidMap[vendor.id] =
                  (event.vendorPaidMap[vendor.id] || 0) +
                  payment.commissionAmount;
                // console.log('v', vendor.id, event.vendorPaidMap[vendor.id]);
              }
            }
            event.totalPaid += payment.amount;
          } else {
            event.setPaymentPending(
              cat.id,
              event.getCatPaymentPending(cat.id) + payment.amount
            );
            if (vendorCat) {
              vendorCat.money.pendingPayment += payment.amount;
            }
            event.totalPaymentPending += payment.amount;
          }
          if (!payment.isCash) {
            const extra =
              payment.amount -
              payment.amount * (1 / (1 + payment.extraRate / 100));
            event.setCatExtraCost(
              cat.id,
              event.getCatExtraCost(cat.id) + extra
            );
            event.totalExtra += extra;
            if (vendorCat) {
              vendorCat.money.extraCost += extra;
            }
          }
        }
      }

      ChecklistMonth.updateMonth(
        item,
        event.checklistMonthMap,
        event.checklistMonths
      );
    }
  }

  for (const event of events) {
    for (const checklist of event.checklistCats) {
      checklist.items.sort(compareChecklistItems);
      checklist.payments = <any>checklist.items.filter((i) => i.isPayment);
    }
    event.checklistMonths.sort((a, b) => a.idNum - b.idNum);
    for (const checklist of event.checklistMonths) {
      checklist.items.sort(compareChecklistItems);
    }
  }
  return result;
};

export function projectEventDialogs(
  t: EntityTuple<CustomerEvent>,
  currentUserId: string,
  chats: Chat[]
): EntityTuple<CustomerEvent> {
  const result = cloneEntityTuple(t, (event) => {
    event.dialog = new Dialog(event, 'general', currentUserId);
    event.categories.forEach(
      (cat) => (cat.dialog = new Dialog(cat, 'category', currentUserId))
    );
    event.allVendorCats.forEach((vendorCat) => {
      vendorCat.dialog = new Dialog(vendorCat, 'vendorCat', currentUserId);
    });
    event.items.forEach(
      (item) => (item.dialog = new Dialog(item, 'eventItem', currentUserId))
    );
  });
  const [events, eventMap] = result;
  // let dddcount = 0;
  for (const chat of chats) {
    // if (chat.data?.readBy?.['Lrj9mP4PzSUMsiAxiiPlbknnHQ53']) {
    //   dddcount++;
    //    console.log('@@@ ' + dddcount);
    // }else{
    //   console.log(chat.data);
    // }
    // Lrj9mP4PzSUMsiAxiiPlbknnHQ53
    const eventId = chat.data.meta.parentId;
    const event = eventMap[eventId];
    if (event) {
      chat.customerEvent = event;
      const dialogObjs: HasDialog[] = [];
      const links = chat.data.links;
      if (links.cats) {
        for (const catLink of links.cats) {
          const cat = event.categoryMap[catLink];
          if (cat) {
            dialogObjs.push(cat);
          }
        }
      }
      if (links.eventItems) {
        for (const eventItemLink of links.eventItems) {
          const eventItem = event.itemMap[eventItemLink];
          if (eventItem) {
            dialogObjs.push(eventItem);
          }
        }
      }
      if (links.vendorCats) {
        for (const vendorLink of links.vendorCats) {
          const vendorCat = event.getVendorCat(
            vendorLink.cat,
            vendorLink.vendor
          );
          if (vendorCat) {
            dialogObjs.push(vendorCat);
          }
        }
      }
      if (event.id === links.event) {
        dialogObjs.push(event);
      }
      if (links.originType === 'category') {
        const cat = event.categoryMap[links.originId];
        if (cat) {
          dialogObjs.push(cat);
        }
      } else if (links.originType === 'eventItem') {
        const eventItem = event.itemMap[links.originId];
        if (eventItem) {
          dialogObjs.push(eventItem);
        }
      } else if (links.originType === 'vendorCat') {
        const vendorCat = event.getVendorCat(
          links.originId,
          links.originCompositeVendorId
        );
        if (vendorCat) {
          dialogObjs.push(vendorCat);
        }
      } else if (links.originType === 'general') {
        if (event.id === links.originId) {
          dialogObjs.push(event);
        }
      }
      for (const d of dialogObjs) {
        if (!chat.data?.readBy?.[currentUserId]) {
          d.dialog.unread.push(chat);
          d.dialog.hasUnread = true;
          event.dialog.hasUnreadDescendants = true;
          if (d.dialog.type === 'eventItem') {
            const d1 = d as EventItem;
            const v1 = d1.vendorCat;
            v1.dialog.hasUnreadDescendants = true;
            if (event.categoryMap[v1.id]?.dialog) {
              event.categoryMap[v1.id].dialog.hasUnreadDescendants = true;
            }
          } else if (d.dialog.type === 'vendorCat') {
            const d1 = d as VendorCat;
            if (event.categoryMap[d1.id]?.dialog) {
              event.categoryMap[d1.id].dialog.hasUnreadDescendants = true;
            }
          }
        }
        d.dialog.history.push(chat);
        if (chat.recent) {
          d.dialog.recent.push(chat);
        }
        if (d.dialog.recent.length) {
          d.dialog.recentOldest = d.dialog.recent[d.dialog.recent.length - 1];
        }

        const lastChat = d.dialog.history[d.dialog.history.length - 1];
        d.dialog.lastContactDate = lastChat.date;
        d.dialog.lastContactDateString = lastChat.dateString;
        d.dialog.recentLatest = lastChat;
      }
    }
  }

  return result;
}
