import {
  EntityData,
  entityDate,
  EntityProjected,
  Timestamp,
} from '@skylitup/base/ngrx-data-fire';
import * as moment from 'moment';
import { UserInfoAgency } from '../agency/agency.model';
import { UserInfoAgencyData } from '../agency/agency.model.data';
import { CustomerEvent } from '../customer-event/customer-event.model';
import { EventItem } from '../event-item/event-item.model';
import { UserCard } from '../user-card/user-card.model';
import { ChecklistVendorCat } from './checklist-cat.model';
import {
  ChecklistItemData,
  ChecklistItemType,
} from './checklist-item.model.data';
import { ChecklistMonth } from './checklist-month.model';
import { Payment } from './payment.model';

export interface ChecklistItem {
  id: string;
  isPayment: boolean;
  flagged: boolean;
  details: string;
  done: boolean;
  internal: boolean;
  highlight: boolean;
  type: ChecklistItemType;
  eventId: string;
  asPayment: Payment;
  asEventItem: EventItem;
  checklistCat: ChecklistVendorCat;
  checklistMonth: ChecklistMonth;
  event: CustomerEvent;
  assignee: UserInfoAgency;
  dueDate: Date;
  doneDate: Date;
  month: number;
  year: number;
  dueDateString: string;
  doneDateString: string;
  overdue: boolean;
  monthId: string;
  assigneeId: string;
  catId: string;
  vendorId: string;
  name: string;
  urlPath: string[];
  meta: EntityData['meta'];

  clone(): EntityProjected<any>;
  copy(from: EntityProjected<any>): EntityProjected<any>;
}
export const compareChecklistItems = (c1, c2) => {
  const d1 = (c1.done ? c1.doneDate : c1.dueDate) || new Date();
  const d2 = (c2.done ? c2.doneDate : c2.dueDate) || new Date();
  let result = d1.getTime() - d2.getTime();
  if (result === 0) {
    result = c1.name.localeCompare(c2.name);
  }
  return result;
};
export class ChecklistItemImp extends EntityProjected<ChecklistItemData>
  implements ChecklistItem {
  get isPayment(): boolean {
    return this.data.type === 'payment';
  }

  get flagged(): boolean {
    return this.data.flagged;
  }
  get details() {
    return this.data.details;
  }
  get done() {
    return this.data.done;
  }
  get assigneeId() {
    return this.data.assigneeId;
  }
  get internal() {
    return this.data.internal;
  }
  get highlight(): boolean {
    return this.checklistCat?.highlightedChecklistItem === this;
  }
  get type(): ChecklistItemType {
    return this.data.type || 'checklist';
  }
  get eventId() {
    return this.data.meta.parentId;
  }
  get asPayment(): Payment {
    return (<any>this) as Payment;
  }
  get asEventItem(): EventItem {
    return (<any>this) as EventItem;
  }
  get vendorId() {
    return this.data.vendorId;
  }
  get catId() {
    return this.data.catId;
  }
  get urlPath(): string[] {
    return this.data?.meta?.urlPath;
  }
  get meta(): EntityData['meta'] {
    return this.data?.meta;
  }

  checklistCat: ChecklistVendorCat;
  checklistMonth: ChecklistMonth;
  event: CustomerEvent;
  assignee: UserInfoAgency;
  readonly dueDate: Date;
  readonly doneDate: Date;
  readonly month: number;
  readonly year: number;
  readonly dueDateString: string;
  readonly doneDateString: string;
  readonly overdue: boolean;
  readonly monthId: string;
  constructor(data: ChecklistItemData) {
    super(data);
    this.dueDate = (<any>this.data?.dueDate)?.toDate
      ? (<Timestamp>this.data?.dueDate)?.toDate()
      : (this.data?.dueDate as Date);
    this.doneDate = entityDate(this.data?.doneDate);
    const m = moment(this.done ? this.doneDate : this.dueDate);
    this.year = m.year();
    this.month = m.month();
    if (moment().isAfter(m)) {
      this.overdue = true;
    }
    this.dueDateString = moment(this.dueDate).format('LL');
    this.doneDateString = moment(this.doneDate).format('LL');
    this.monthId = '' + 12 * this.year + this.month;
  }
  clone(): ChecklistItemImp {
    return new ChecklistItemImp(this.data).copy(this);
  }
  copy(from: ChecklistItemImp): ChecklistItemImp {
    this.checklistCat = from.checklistCat;
    this.checklistMonth = from.checklistMonth;
    return this;
  }

  // =================================================
}
