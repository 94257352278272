import { FormControl } from '@angular/forms';
import linkifyStr from "linkifyjs/string";

export function getErrorMessageHandler(control: FormControl, name: string) {
  let result = '';
  if (control.invalid) {
    for (const error in control.errors) {
      if (error === 'required') {
        result += name + ' is required';
      } else if (error === 'email') {
        result += 'invalid email';
      } else if (error === 'pattern') {
        result += 'invalid ' + name;
      } else {
        // console.log(error);
        // console.log(control.errors[error]);
      }
    }
  }
  return result;
}

export function excerpt(text: string, excerptLength: number) {
  let result = '';
  if (text && text.length > excerptLength) {
    result = text.substring(0, excerptLength) + ' ...';
  } else {
    result = text;
  }
  return linkifyStr(result);
}

export function linkifyString(text: string): string {
  return linkifyStr(text);
}