import { Component, Input, EventEmitter, Output } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { EventItemSpecImgBaseComponent } from '@skylitup/flowergirl/features/overview';
import { Media } from '@skylitup/flowergirl/core';
import { LightboxComponent } from '@skylitup/base/xplat/web/ui/external';
import { Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'flrgl-event-item-spec-img',
  templateUrl: 'event-item-spec-img.component.html',
  styleUrls: ['event-item-spec-img.component.scss'],
})
export class EventItemSpecImgComponent extends EventItemSpecImgBaseComponent {
  @Input()
  eventItem;
  @Output()
  editSelection = new EventEmitter();
  @Input()
  noEdit = false;
  @Output()
  lightboxStarted = new EventEmitter();
  lightboxStarted$ = new Subject();
  @Output()
  lightboxEnded = new EventEmitter();
  lightboxEnded$ = new Subject();
  constructor(private dialog: MatDialog) {
    super();
    this.lightboxStarted$.pipe(throttleTime(1000), takeUntil(this.destroy$)).subscribe(_ => this.lightboxStarted.next('on'))
    this.lightboxEnded$.pipe(throttleTime(1000), takeUntil(this.destroy$)).subscribe(_ => this.lightboxEnded.next('off'))
  }

  showLightbox(media: Media) {
    this.dialog.open(LightboxComponent, {
      data: media.urlLarge,
      disableClose: false,
      panelClass: 'p-0',
    });
  }
  lightboxEvents(e) {
    if (e?.type === "show-state:initial") {
      this.lightboxStarted$.next('on');
    } else if (e?.type === "closing-state:animation-end") {
      this.lightboxEnded$.next('off');
    }
  }
  debug1(e) {
    console.log('eee', e);
  }
}
