<div fxLayout="column" class="container" *ngIf="vendor$ | async as vendor">
    <mat-dialog-content fxFlex="grow">
        <flrgl-vendor-showcase [withFilters]="false" [editable]="false" [vendor]="vendor" [socialNames]="true">
        </flrgl-vendor-showcase>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button mat-dialog-close>
            <mat-icon>close</mat-icon> Close
        </button>
    </mat-dialog-actions>
</div>