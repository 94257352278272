import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Dictionary } from '@ngrx/entity';
import { createSelector, Store } from '@ngrx/store';
import { EntityProjected } from '@skylitup/base/ngrx-data-fire';
import { getErrorMessageHandler, WindowService } from '@skylitup/base/util';
import { EntityAutocomplete } from '@skylitup/base/xplat/web/ui/material';
import {
  AppState,
  Category,
  ContextService,
  Doc,
  DocService,
  DOC_TYPE_OPTIONS,
  EventCategory,
  EventItem,
  EventItemService,
  Media,
  MediaLibraryService,
  MediaService,
  SystemService,
  uiFocusChat,
  uiMediaTabChanged,
  Upload,
  UploadFilters,
  Vendor,
  VendorService
} from '@skylitup/flowergirl/core';
import { MediaBaseComponent } from '@skylitup/flowergirl/features/media';
import { LightboxComponent, PdfboxComponent } from '@skylitup/flowergirl/xplat/web/ui';

import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, startWith, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'flrgl-media',
  templateUrl: 'media.component.html',
  styleUrls: ['media.component.scss'],
})
export class MediaComponent extends MediaBaseComponent implements OnInit {
  media$: Observable<Upload[]>;
  originMedia$: Observable<Upload[]>;
  showcaseMedia$: Observable<Upload[]>;
  imageResultsNotEmpty$: Observable<boolean>

  docs$: Observable<Upload[]>;
  originDocs$: Observable<Upload[]>;
  allDocs$: Observable<Upload[]>;

  filtersFormGroupMedia: FormGroup;
  catsAutocompleteMedia: EntityAutocomplete<EventCategory>;
  vendorsAutocompleteMedia: EntityAutocomplete<Vendor>;
  itemsAutocompleteMedia: EntityAutocomplete<EventItem>;

  filtersFormGroupDocs: FormGroup;
  catsAutocompleteDocs: EntityAutocomplete<EventCategory>;
  vendorsAutocompleteDocs: EntityAutocomplete<Vendor>;
  itemsAutocompleteDocs: EntityAutocomplete<EventItem>;
  docType = new FormControl(null);
  // docsDatasource: MatTableDataSource<Upload>

  @Input()
  selectMode = false;
  @Input()
  selectedMedia: Media[] = [];
  @Output()
  selectionChange = new EventEmitter();
  vendorMap$: Observable<Dictionary<Vendor>>;
  catMap$: Observable<Dictionary<Category>>;
  eventItemMap$: Observable<Dictionary<EventItem>>;
  mediaTabIndex$: Observable<number>;
  isRootMedia$: Observable<boolean>;
  docTypeKeys = Object.entries(DOC_TYPE_OPTIONS);

  get catsControlMedia() {
    return this.filtersFormGroupMedia.get('cat') as FormControl
  }
  get vendorControlMedia() {
    return this.filtersFormGroupMedia.get('vendor') as FormControl;
  }
  get itemControlMedia() {
    return this.filtersFormGroupMedia.get('item') as FormControl
  }


  get catsControlDocs() {
    return this.filtersFormGroupDocs.get('cat') as FormControl
  }
  get vendorControlDocs() {
    return this.filtersFormGroupDocs.get('vendor') as FormControl;
  }
  get itemControlDocs() {
    return this.filtersFormGroupDocs.get('item') as FormControl
  }

  selectedMediaIndexMedia: Dictionary<Media> = {};
  filterRippleOnMedia = true;
  filterRippleOnDocs = true;

  docsTableColumns = ['icon', 'type', 'name', 'cat', 'vendor', 'item', 'fileName', 'actions'];

  constructor(
    private mediaService: MediaService,
    private lightboxPopup: MatDialog,
    private context: ContextService,
    private mediaLibraryService: MediaLibraryService,
    private docService: DocService,
    fb: FormBuilder,
    private store: Store,
    private eventItemService: EventItemService,
    private systemService: SystemService,
    private vendorService: VendorService,
    private activatedRoute: ActivatedRoute,
    private windowService: WindowService,
    private matDialog: MatDialog
  ) {
    super();
    this.isRootMedia$ = this.activatedRoute.data.pipe(map(d => !!d.rootMedia));
    this.eventItemMap$ = this.eventItemService.entityMapProjected$;
    this.catMap$ = this.systemService.categoryMap$;
    this.vendorMap$ = this.vendorService.entityMapProjected$;

    this.filtersFormGroupMedia = fb.group({ cat: [''], vendor: [''], item: [''] });
    this.filtersFormGroupDocs = fb.group({ cat: [''], vendor: [''], item: [''] });

    let aa = this.setupFilters(this.filtersFormGroupMedia, this.mediaLibraryService.currentEventMediaFilters$, 'filterRippleOnMedia');
    this.catsAutocompleteMedia = aa.cats;
    this.vendorsAutocompleteMedia = aa.vendors;
    this.itemsAutocompleteMedia = aa.items;
    aa = this.setupFilters(this.filtersFormGroupDocs, this.mediaLibraryService.currentEventDocFilters$, 'filterRippleOnDocs');
    this.catsAutocompleteDocs = aa.cats;
    this.vendorsAutocompleteDocs = aa.vendors;
    this.itemsAutocompleteDocs = aa.items;

    this.media$ = combineLatest([
      this.mediaService.entitiesProjected$,
      this.mediaLibraryService.mediaIndex$,
      this.catsAutocompleteMedia.optionSelected$,
      this.vendorsAutocompleteMedia.optionSelected$,
      this.itemsAutocompleteMedia.optionSelected$,
    ]).pipe(
      map(([all, mediaIndex, cat, vendor, item]) => {
        if (mediaIndex) {
          if (item) {
            return mediaIndex.eventItemMap[item.id];
          } else if (vendor) {
            return mediaIndex.vendorMap[vendor.id];
          } else if (cat) {
            return mediaIndex.catMap[cat.id];
          }
        }
        return all;
      })
    );

    this.originMedia$ = combineLatest([
      this.mediaService.entitiesProjected$,
      this.mediaLibraryService.mediaIndex$,
      this.catsAutocompleteMedia.optionSelected$,
      this.vendorsAutocompleteMedia.optionSelected$,
      this.itemsAutocompleteMedia.optionSelected$,
    ]).pipe(
      map(([all, mediaIndex, cat, vendor, item]) => {
        if (mediaIndex) {
          if (item) {
            return mediaIndex.originEventItemMap[item.id];
          } else if (vendor) {
            return mediaIndex.originVendorMap[vendor.id];
          } else if (cat) {
            return mediaIndex.originCatMap[cat.id];
          }
        }
        return [];
      })
    );
    this.showcaseMedia$ = combineLatest([
      this.mediaLibraryService.mediaIndex$,
      this.vendorsAutocompleteMedia.optionSelected$,
    ]).pipe(
      map(([mediaIndex, vendor]) => {
        if (mediaIndex) {
          if (vendor) {
            return mediaIndex.showcaseVendorMap[vendor.id];
          }
        }
        return [];
      })
    );
    this.imageResultsNotEmpty$ = combineLatest([this.media$, this.originMedia$, this.showcaseMedia$]).pipe(map(([m1, m2, m3]) => !!m1?.length || !!m2?.length || !!m3?.length))
    //=======
    this.docs$ = combineLatest([
      this.docService.entitiesProjected$,
      this.mediaLibraryService.docIndex$,
      this.catsAutocompleteDocs.optionSelected$,
      this.vendorsAutocompleteDocs.optionSelected$,
      this.itemsAutocompleteDocs.optionSelected$,
    ]).pipe(
      map(([all, docsIndex, cat, vendor, item]) => {
        if (docsIndex) {
          if (item) {
            return docsIndex.eventItemMap[item.id];
          } else if (vendor) {
            return docsIndex.vendorMap[vendor.id];
          } else if (cat) {
            return docsIndex.catMap[cat.id];
          }
        }
        return all;
      })
    );
    this.originDocs$ = combineLatest([
      this.docService.entitiesProjected$,
      this.mediaLibraryService.docIndex$,
      this.catsAutocompleteDocs.optionSelected$,
      this.vendorsAutocompleteDocs.optionSelected$,
      this.itemsAutocompleteDocs.optionSelected$,
    ]).pipe(
      map(([all, docsIndex, cat, vendor, item]) => {
        if (docsIndex) {
          if (item) {
            return docsIndex.originEventItemMap[item.id];
          } else if (vendor) {
            return docsIndex.originVendorMap[vendor.id];
          } else if (cat) {
            return docsIndex.originCatMap[cat.id];
          }
        }
        return [];
      })
    );
    this.allDocs$ = combineLatest([this.docs$, this.originDocs$, this.docType.valueChanges.pipe(startWith(null as string))])
      .pipe(map(
        ([docs, originDocs, docType]) => [...(docs || []), ...(originDocs || [])]?.filter((d: Doc) => docType === null || d.data.type === docType),
        map((docs: Upload[]) => docs.sort((d1, d2) => d1?.data?.name?.localeCompare(d2?.data?.name)))
      ));
    // this.allDocs$.pipe(takeUntil(this.destroy$)).subscribe(docs => this.docsDatasource = new MatTableDataSource(docs))
  }

  setupFilters(formGroup: FormGroup, filters$: Observable<UploadFilters>, filterRippleFlagName: string): {
    cats: EntityAutocomplete<EventCategory>,
    vendors: EntityAutocomplete<Vendor>, items: EntityAutocomplete<EventItem>
  } {

    const cats = new EntityAutocomplete<EventCategory>({
      label: 'Category',
      options$: filters$.pipe(
        map((o) => o.cats)
      ),
      formControl: formGroup.get('cat') as FormControl,
    });

    const vendors = new EntityAutocomplete<Vendor>({
      label: 'Vendor',
      options$: filters$.pipe(
        map((o) => o.vendors)
      ),
      formControl: formGroup.get('vendor') as FormControl,
      // optionDisplayFn: vc => `${vc?.name} (${vc?.data?.name})`
    });

    const items = new EntityAutocomplete<EventItem>({
      label: 'Event Item',
      options$: filters$.pipe(
        map((o) => o.eventItems)
      ),
      formControl: formGroup.get('item') as FormControl,
      optionDisplayFn: (item) =>
        `${item?.name} (${item?.vendorCat?.data?.name})`,
    });

    cats.optionSelected$
      .pipe(
        takeUntil(this.destroy$)
        // distinctUntilChanged(null, a => a?.id)
      )
      .subscribe(() => {
        if (this[filterRippleFlagName]) {
          this[filterRippleFlagName] = false;
          formGroup.patchValue({ vendor: null, item: null });
          this[filterRippleFlagName] = true;
        }
      });
    vendors.optionSelected$
      .pipe(
        takeUntil(this.destroy$)
        // distinctUntilChanged(null, a => a?.id)
      )
      .subscribe((v: Vendor) => {
        if (v && this[filterRippleFlagName]) {
          this[filterRippleFlagName] = false;
          formGroup.patchValue(
            // { cat: v?.event?.categoryMap?.[v.id] },
            { cat: null },
            { emitEvent: true }
          );
          items?.formControl.setValue(null);
          this[filterRippleFlagName] = true;
        }
      });

    items.optionSelected$
      .pipe(
        takeUntil(this.destroy$)
        // distinctUntilChanged(null, a => a?.id)
      )
      .subscribe((item: EventItem) => {
        if (item && this[filterRippleFlagName]) {
          this[filterRippleFlagName] = false;

          formGroup.patchValue(
            {
              cat: item?.event?.categoryMap?.[item?.vendorCat.id],
              vendor: item?.vendorCat,
            },
            { emitEvent: true }
          );
          this[filterRippleFlagName] = true;
        }
      });
    return { items, vendors, cats };
  }

  clearItemMedia() {
    this.itemsAutocompleteMedia?.formControl.setValue(null);
  }

  clearVendorAndItemMedia() {
    this.filtersFormGroupMedia.patchValue({ vendor: null, item: null });
  }
  clearItemDocs() {
    this.itemsAutocompleteDocs?.formControl.setValue(null);
  }

  clearVendorAndItemDocs() {
    this.filtersFormGroupDocs.patchValue({ vendor: null, item: null });
  }

  mediaIsSelected(media: Media) {
    return !!this.selectedMediaIndexMedia[media.id];
  }

  mediaSelectionChanged(checked: boolean, media: Media) {
    if (checked) {
      this.selectedMedia.push(media);
      this.selectedMediaIndexMedia[media.id] = media;
    } else {
      delete this.selectedMediaIndexMedia[media.id];
      const i = this.selectedMedia.findIndex((m) => media.id === m.id);
      if (i !== -1) {
        this.selectedMedia.splice(i, 1);
      }
    }
    this.selectionChange.next(this.selectedMedia);
  }

  ngOnInit(): void {
    this.selectedMediaIndexMedia = this.selectedMedia.reduce(
      (r, o) => ((r[o.id] = o) && r) || r,
      {}
    );
    combineLatest([
      this.store.select((s: AppState) => s.ui),
      this.context.customerEvent$.pipe(filter((o) => !!o)),
      this.isRootMedia$,
      this.vendorService.entityMap$
    ])
      .pipe(
        filter(([uiState, event, isRootMedia]) => !isRootMedia),
        map(([uiState, event, isRootMedia, vendorMap]) => {
          let vendor = null;
          console.log('99', uiState);

          if (uiState.focusCat && uiState.focusVendor) {
            vendor = event.getVendorCat(uiState.focusCat, uiState.focusVendor);
          } else if (uiState.focusVendor) {
            console.log('0')
            vendor = vendorMap[uiState.focusVendor];
          } else {
            vendor = null;
          }
          return [
            event.categoryMap[uiState.focusCat],
            vendor,
            event.itemMap[uiState.focusItem],
          ]
        }),
        tap(i => console.log('iii', i)),
        map(([cat, vendor, item]) => ({ cat, vendor, item })),
        takeUntil(this.destroy$)
      )
      .subscribe((v) => {
        this.filtersFormGroupMedia.patchValue(v, { emitEvent: true });
        this.filtersFormGroupDocs.patchValue(v, { emitEvent: true });
      });

    this.mediaTabIndex$ = this.store.select(
      createSelector(
        (s: AppState) => s.ui,
        (s) => s.mediaTabIndex
      )
    );
  }

  clearCatFilterMedia(autoCatTrigger?: MatAutocompleteTrigger) {
    this.filtersFormGroupMedia.patchValue({ cat: null, vendor: null, item: null });
    if (autoCatTrigger) {
      setTimeout(() => autoCatTrigger.openPanel(), 0);
    }
  }
  tabChanged($event: MatTabChangeEvent) {
    this.store.dispatch(uiMediaTabChanged({ index: $event.index }));
  }
  reorderSelection(event: CdkDragDrop<any>) {
    moveItemInArray(
      this.selectedMedia,
      event.previousIndex,
      event.currentIndex
    );
  }

  deleteMedia(media) {
    this.mediaService
      .deleteEntity(media.data)
      .pipe(first())
      .subscribe((_) => { });
  }
  showLightbox(media: Media) {
    this.lightboxPopup.open(LightboxComponent, {
      data: media.urlLarge,
      disableClose: false,
      panelClass: 'p-0',
    });
  }
  trackByIdFn(i, o) {
    return o.id;
  }
  private _filter(
    options: EntityProjected<any>[],
    name: string
  ): EntityProjected<any>[] {
    const filterValue = name.toLowerCase();
    return options.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }
  docRowClicked(doc: Doc, tr) {
    if (doc.renderType === 'pdf') {
      this.matDialog.open(PdfboxComponent, { data: doc.fileUrl, height: '80vh', width: '80vw' });
    } else if (doc.renderType === 'image') {
      const hiddenImg = document.getElementById('hidden-img-' + doc.id) as HTMLElement;
      hiddenImg?.click();
    } else {
      this.windowService.window.open(doc.fileUrl, '_blank');
    }
    // console.error('haaaa', r)
  }

  hasFilterMedia() {
    return this.catsControlMedia.value || this.vendorControlMedia.value || this.itemControlMedia.value;
  }
  hasFilterDocs() {
    return this.catsControlDocs.value || this.vendorControlDocs.value || this.itemControlDocs.value || this.docType.value;
  }

  clearAllFiltersMedia() {
    this.catsControlMedia.setValue(null);
  }
  clearAllFiltersDocs() {
    this.docType.setValue(null);
    this.catsControlDocs.setValue(null);
  }
  asDoc(d): Doc {
    return d as Doc;
  }
  getDocCatName(catMap: Dictionary<Category>, doc: Doc): string {
    const catId = doc.data?.links?.originCat || doc?.data?.links?.cats?.[0];
    return catMap[catId]?.name;
  }
  getDocVendorName(vendorMap: Dictionary<Vendor>, doc: Doc): string {
    const vendorId = doc.data?.links?.originVendor || doc?.data?.links?.vendors?.[0];
    return vendorMap[vendorId]?.name;
  }
  getDocItemName(itemMap: Dictionary<EventItem>, doc: Doc): string {
    const itemId = doc.data?.links?.originItem || doc?.data?.links?.eventItems?.[0];
    return itemMap[itemId]?.name;
  }

  editDocMeta(doc: Doc) {
    this.matDialog.open(EditDocMetaPopupComponent, { data: { doc } }).afterClosed().subscribe(u => this.docService.update(u))
  }
  deleteDoc(doc: Doc) {
    if (confirm(`you are about to delete document ${doc.name} (${doc.fileName})! Are you sure?`)) {
      this.docService.deleteEntity(doc.data);
    }
  }
}

@Component({
  templateUrl: 'edit-doc-meta-popup.component.html',
  styles: [''],
})
export class EditDocMetaPopupComponent {
  getErrorMessage = getErrorMessageHandler;
  doc: Doc;
  form = new FormGroup({
    name: new FormControl('', Validators.required),
    type: new FormControl(null, Validators.required),
  });
  docTypeKeys = Object.entries(DOC_TYPE_OPTIONS);

  get name() {
    return this.form.get('name');
  }
  get type() {
    return this.form.get('type');
  }
  constructor(
    private dialogRef: MatDialogRef<EditDocMetaPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data: { doc: Doc }) {
    const { doc } = data;
    this.doc = doc;
    this.name.setValue(doc.data.name);
    this.type.setValue(doc.data.type);
  }
  keyUp(code: number) {
    if (code === 13) {
      this.dialogRef.close(this.result());
    }
  }

  result() {
    return {
      meta: this.doc.data.meta,
      name: this.name.value,
      type: this.type.value
    }
  }
}