import { Dictionary } from '@ngrx/entity';
import {
  EntityProjected,
  EntityTuple,
  EntityProjectedBase
} from './entity.model';
import { EntityDataModule } from '@ngrx/data';
import { EntityData } from './entity.model.data';

export function projectDictionary<T extends EntityProjectedBase<any>>(
  v: T[]
): Dictionary<T> {
  return v
    ? v.reduce((r, c) => {
        r[c.id] = c;
        return r;
      }, {})
    : {};
}
export function projectList<
  T extends EntityData,
  P extends EntityProjectedBase<T>
>(newEntityProjectedFn) {
  return (aa: T[]): P[] => (aa ? aa.map(a => newEntityProjectedFn(a)) : []);
}

export function projectOneToMany<
  Y extends EntityProjected<any>,
  T extends EntityProjected<any>
>(resetLinkFn: (x: Y) => void, linkFn: (x: T, d: Dictionary<Y>) => void) {
  return (ones: Y[], manys: T[]): EntityTuple<Y> => {
    const rMap = {};
    const rs = [];
    for (const a of ones) {
      try {
        const r = a.clone() as Y;
        resetLinkFn(r);
        rs.push(r);
        rMap[r.id] = r;
      } catch (e) {
        console.log(e);
        throw e;
      }
    }
    for (const m of manys) {
      linkFn(m, rMap);
    }

    return [rs, rMap];
  };
}

interface J extends EntityProjected<any> {}

export function cloneEntityTuple<T extends EntityProjectedBase<any>>(
  a: EntityTuple<T>,
  resetLinkFn?: (t: T) => void
): EntityTuple<T> {
  const [l1, m1] = a;
  const l2 = [];
  const m2 = {};
  l1.forEach(d => {
    const r = d.clone() as T;
    if (resetLinkFn) {
      resetLinkFn(r);
    }
    l2.push(r);
    m2[r.id] = r;
  });
  return [l2, m2];
}

export function projectOneToManyDefault<
  Y extends EntityProjected<any>,
  T extends EntityProjected<any>
>(
  listProperty: string,
  mapProperty: string,
  oneProperty: string
): (ones: Y[], manys: T[]) => EntityTuple<Y> {
  return projectOneToMany(
    one => {
      one[listProperty] = [];
      one[mapProperty] = {};
    },
    (aMany, oneMap) => {
      const oneId = aMany.data.meta.parentId;
      const one = oneMap[oneId];
      if (one) {
        one[listProperty].push(aMany);
        one[mapProperty][aMany.id] = aMany;
        aMany[oneProperty] = one;
      }
    }
  );
}
