import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  OnDestroy,
  OnInit,
  Input,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@skylitup/flowergirl/core';
import { ENV, Environment } from '@skylitup/base/util';

type AuthType =
  | 'dev'
  | 'planner'
  | 'customer'
  | 'fullMember'
  | 'restrictedMember'
  | 'salesMember'
  | 'impersonating';
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[auth1]',
})
export class AuthDirective implements OnInit, OnDestroy {
  private _destroy$: Subject<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private vcr: ViewContainerRef,
    private authService: AuthService,
    @Inject(ENV) private env: Environment,
    private cd: ChangeDetectorRef
  ) {
    this._destroy$ = new Subject();
  }

  @Input('auth1')
  types: AuthType[] = [];

  ngOnInit() {
    this.authService.auth$.pipe(takeUntil(this._destroy$)).subscribe((auth) => {
      this.vcr.clear();
      let show = false;
      if (this.types.includes('dev') && !this.env.production) {
        show = true;
      }
      if (this.types.includes('planner') && auth.isPlanner) {
        show = true;
      }
      if (this.types.includes('customer') && auth.isCustomer) {
        show = true;
      }
      if (this.types.includes('fullMember') && auth.isFullMember) {
        show = true;
      }
      if (this.types.includes('restrictedMember') && auth.isRestrictedMember) {
        show = true;
      }
      if (this.types.includes('salesMember') && auth.isSalesMember) {
        show = true;
      }
      if (
        this.types.includes('impersonating') &&
        auth.data.impersonatingCustomer
      ) {
        show = true;
      }

      // add condition ownerUiMode from authReducer, for dev only
      if (show) {
        this.vcr.createEmbeddedView(this.templateRef);
      }
      this.cd.markForCheck();
    });
  }
  ngOnDestroy() {
    if (this._destroy$) {
      this._destroy$.next(true);
      this._destroy$.complete();
    }
  }
}
