<ng-container *ngIf="customerEvent$ |async as customerEvent">
    <ng-container *ngIf="researchCategory$ |async as researchCategory">
        <div fxLayoutAlign="start center" fxLayout="row" class="title1" (click)="trigger1()">
            <div class="nav-button btn1" fxLayout="row">
                <!-- <mat-button-toggle-group fxFlex="none">
                    <mat-button-toggle [checked]="!isComparePrices"
                        [routerLink]="[customerEvent.url,'vendor-research',researchCategory.id]">
                        Attributes <mat-icon>compare</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle [checked]="isComparePrices"
                        [routerLink]="[customerEvent.url,'compare-prices',researchCategory.id]">
                        Prices <mat-icon>euro_symbol</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group> -->
                <!-- *ngIf="isComparePrices; else compareVendors1" -->
               
                <a mat-flat-button [ngClass]="{active:!isComparePrices}" [routerLink]="[customerEvent.url,'vendor-research',researchCategory.id]">
                    Attributes <mat-icon>compare</mat-icon>
                </a>
                <a mat-flat-button [ngClass]="{active:isComparePrices}" [routerLink]="[customerEvent.url,'compare-prices',researchCategory.id]">
                    Prices <mat-icon>euro_symbol</mat-icon>
                </a>
                <ng-template #compareVendors1>
                </ng-template>
            </div>
            <mat-checkbox *auth1="['planner']" class="m-l-5" [formControl]="showToCustomerControl"> <span
                    class="show-text">show to
                    customer</span>
            </mat-checkbox>

            <div fxFlex="grow" fxFlexAlign="center center" class="center1"> Compare Vendors for
                {{researchCategory.name}}
                <button mat-stroked-button class="m-l-6 edit-button" *auth1="['planner']" [routerLink]="isComparePrices? [customerEvent.editUrl,'compare-prices',researchCategory.id]:
                [customerEvent.editUrl,'vendor-research',researchCategory.id]">Edit<mat-icon class="m-l-2">
                        edit</mat-icon> </button>
            </div>

            <div class="nav-button right1 up1">
                <a mat-icon-button [routerLink]="customerEvent.url">
                    <mat-icon>arrow_upward</mat-icon>
                </a>
            </div>
        </div>
        <router-outlet></router-outlet>
    </ng-container>
</ng-container>