<div class="p-x-2" [ngClass]="{'editMode':editMode,'editable':editable,'na':isNA()}"
    (click)="this.clickedSomewhereInside() && edit(true)">
    <div class="price-item-label">
        <button *ngIf="editable && !isNA() && (!isLinked() || isLinkedSource())" mat-icon-button class="func m-r-5"
            matTooltip="Budget Calcualtion" (click)="editCalculation() || $event.stopPropagation()">
            <mat-icon>
                functions
            </mat-icon>
        </button>
        <button mat-icon-button class="func m-r-5" matTooltip="copy to all cells in this row"
            *ngIf="editable && vr.getCalculation(candiDate.id,version.id,priceItem.id) "
            (click)="toggleLink()  || $event.stopPropagation()">
            <mat-icon fontSet="fas" fontIcon="fa-unlink" *ngIf="!vr.linkedItemMap[priceItem.id];else linked1">
            </mat-icon>
            <ng-template #linked1>
                <mat-icon class="color-primary" fontSet="fas" fontIcon="fa-link">
                </mat-icon>
            </ng-template>
        </button>
        <button *ngIf="editable && (!isLinked() || isLinkedSource())" mat-icon-button class="func m-r-5"
            matTooltip="Add/Edit Note" (click)="showEditNotes()  || $event.stopPropagation()">
            <mat-icon>description</mat-icon>
        </button>
        <button *ngIf="editable && (!isLinked() || isLinkedSource())" mat-icon-button class="func m-r-5 na-btn"
            matTooltip="Not Applicable" (click)="toggleNA()  || $event.stopPropagation()">
            <mat-icon [ngClass]="{'color-primary':isNA()}">clear</mat-icon>
        </button>
        {{priceItem.name}} <mat-icon class="edit-icon" *ngIf="editable && (!isLinked() || isLinkedSource())">edit
        </mat-icon>
    </div>
    <div *ngIf="!isNA();else naT">
        <ng-container *ngIf="calcItem && calcItem.data.calcType !=='none';else noCalculationTmpl">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div>
                    <div class="cLabel">{{calcItem.calculationLabel}}
                    </div>
                    <div class="cNum right1">
                        {{calcItem.calculationNumber}}</div>
                </div>
                <mat-icon class="multiply" fxFlexAlign="center">clear
                </mat-icon>
                <div>
                    <div class="cLabel">Item Price</div>
                    <div class="cNum">
                        €{{calcItem.calculationItemPrice}}
                    </div>
                </div>
                <span fxFlexAlign="center" class="equals">=</span>
                <div>
                    <div class="cLabel">Cost</div>
                    <div class="cNum">
                        €{{calcItem.calcBudget}}
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noCalculationTmpl>
            <div *ngIf="(vr.isLinked(priceItem.id) && !budgetControl.value ) ||  !editMode;else editformField1">
                <ng-container *ngIf="vr.isPriceSet(candiDate.id, version.id, priceItem.id)">
                    <div class="cLabel">Cost</div>
                    <div class="cNum">
                        €{{calcItem?.calcBudget}}
                    </div>
                </ng-container>
            </div>
            <ng-template #editformField1>
                <mat-form-field appearance="fill" fxFlexLayout="auto end" class="no-calc">
                    <mat-label>Budget</mat-label>
                    <input matInput type="number" class="right1" #input1 [formControl]="budgetControl" min="0"
                        (keyup)="keyUp1($event)" (focus)="editMode && onFocus(input1)">
                    <span matPrefix>€&nbsp;</span>
                </mat-form-field>
            </ng-template>
        </ng-template>
    </div>
    <ng-template #naT>
        <div class="cLabel">
        </div>
        <div class="cNum">
            N/A
        </div>
    </ng-template>
    <div *ngIf="calcItem?.notes?.length" class="notes" (click)="showEditNotes()  || $event.stopPropagation()">
        {{calcItem.notes}}
    </div>
</div>