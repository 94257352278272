<div class="container">
    <ng-container *ngFor="let contact of vendor.data.contacts">
        <div fxLayout='column' class='contact'>
            <div class='m-y-6'>
                <span fxFlex="grow" class="name">
                    <mat-icon>person</mat-icon> {{contact.name}} {{contact.surname}}
                </span>
                <a href="mailto:{{contact?.email}}" *ngIf="contact.email" fxFlex="none" class="clickable1 underline1">
                    <mat-icon>email</mat-icon> {{contact.email}}
                </a>
            </div>
            <div>
                <a href="tel:{{contact?.phone1}}" *ngIf="contact.phone1" class="clickable1 underline1">
                    <mat-icon>phone</mat-icon> {{contact.phone1}}
                </a>
                <a href="tel:{{contact?.phone2}}" *ngIf="contact.phone2" class="clickable1 underline1">
                    <mat-icon>smartphone</mat-icon> {{contact.phone2}}
                </a>
                <a href="tel:{{contact?.phone3}}" *ngIf="contact.phone3" class="clickable1 underline1">
                    <mat-icon>headset</mat-icon> {{contact.phone3}}
                </a>
            </div>
            <mat-divider class="m-y-10"></mat-divider>
        </div>
    </ng-container>
</div>