<div class="m-l-30 focused-sort" fxFlex="none" *ngIf="vendorSort.focusedSort$ | async as focusedSort">
    <ng-container *ngIf="focusedSort.paramType ==='select';then selectSort;else simpleSort">
    </ng-container>

    <ng-template #simpleSort>
        <button mat-button class="sort" (click)="toggleOrderFocusSort(focusedSort)">
            <mat-icon *ngIf="focusedSort.direction==='desc'" class="color-accent" fontSet="fas" fontIcon="fa-sort-amount-down">
            </mat-icon>
            <mat-icon *ngIf="focusedSort.direction==='asc'" class="color-accent" fontSet="fas" fontIcon="fa-sort-amount-down-alt">
            </mat-icon>
            Sort by: {{focusedSort.label}}
            <!-- {{focusedSort.type}} -->
        </button>
        <button mat-icon-button class="close" (click)="removeFocusSort()">
            <mat-icon>close</mat-icon>
        </button>
    </ng-template>
    <ng-template #selectSort>

        <mat-form-field *ngIf="asVendorSelectParam(focusedSort.config) as config" class="sort">
            <mat-icon matPrefix fontSet="fas" fontIcon="fa-sort-amount-down" class="m-r-10">
            </mat-icon>
            <mat-label>Sort by {{focusedSort.label}}</mat-label>
            <mat-select [value]="focusedSort.value.code"
                (selectionChange)="focusSortSelectChanged(focusedSort,$event.value)">
                <mat-option *ngFor="let option of config.options" [value]="option.code">
                    {{option.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-icon-button class="close" (click)="removeFocusSort()">
            <mat-icon>close</mat-icon>
        </button>
    </ng-template>
</div>