<ng-container *ngIf="dialogs$ | async as dialogs">
    <ng-container *ngIf="auth$ |async as auth">
        <ng-container *ngIf="customerEvent$ |async as customerEvent">

            <div class="outer" fxLayout="row"
                [ngClass]="{'mobile-mode':this.mobileQuery.matches,'side-open':drawer1.opened}">
                <mat-drawer-container class="example-container">
                    <mat-drawer mode="side" [opened]="this.mobileQuery.matches?mobileSideToggle:sideToggle" #drawer1>
                        <div class="left">
                            <div *ngFor="let d of dialogs" (click)="selectDialog(d)">
                                <flrgl-chat-thumb [dialog]="d.dialog"
                                    [active]="dialogObj?.dialog?.dialogId==d.dialog.dialogId">
                                </flrgl-chat-thumb>
                            </div>
                        </div>
                    </mat-drawer>
                    <mat-drawer-content>
                        <button mat-button (click)="toggle()" class="drawer-button mat-elevation-z3">
                            <mat-icon *ngIf="drawer1.opened;else rightb">chevron_left</mat-icon>
                            <ng-template #rightb>
                                <mat-icon>chevron_right</mat-icon>
                            </ng-template>
                        </button>
                        <mat-divider vertical="true" fxFlex="none" class='m-l-3' *ngIf="!drawer1.opened"></mat-divider>
                        <div class="right" fxFlex="4 1 100%" fxLayout="column">
                            <ng-container *ngIf="dialogObj!=null">
                                <flrgl-chat-header [dialogObj]="dialogObj"></flrgl-chat-header>
                                <mat-divider></mat-divider>
                                <ng-container *ngIf="chatTrack$ | async as chatTrack">
                                    <flrgl-chat-portal [dialog]="dialogObj.dialog" [auth]="auth" [event]="customerEvent"
                                        [chatTrack]="chatTrack" fxFlex="grow">
                                    </flrgl-chat-portal>
                                </ng-container>
                            </ng-container>
                        </div>
                    </mat-drawer-content>
                </mat-drawer-container>
            </div>
        </ng-container>
    </ng-container>
</ng-container>