import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import {
  Auth,
  ContextService,
  doHeartBeat,
  EventCategory,
  uiChecklistTabChanged, Vendor, VendorResearch,
  VendorResearchService
} from '@skylitup/flowergirl/core';
import { OverviewBaseComponent } from '@skylitup/flowergirl/features/overview';
import { VendorShowcasePopupComponent } from '@skylitup/flowergirl/xplat/web/features/vendor';
import { Observable } from 'rxjs';

@Component({
  selector: 'flrgl-overview',
  templateUrl: 'overview.component.html',
  styleUrls: ['overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewComponent extends OverviewBaseComponent {
  vendorResearchMapByCat$: Observable<Dictionary<VendorResearch[]>>;
  auth$: Observable<Auth>;
  constructor(
    context: ContextService,
    store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private vendorResearchService: VendorResearchService, private dialog: MatDialog,
  ) {
    super(context, store);
    this.vendorResearchMapByCat$ = this.vendorResearchService.vendorResearchMapByCat$;
    this.auth$ = context.auth$;
  }
  toCategorizedChecklist(cat: EventCategory) {
    this.focusedOnCat(cat);
    this.store.dispatch(uiChecklistTabChanged({ index: 1 }));
    this.router.navigate(['..', 'plan'], { relativeTo: this.route.parent });
  }
  openVendorShowcase(vendor: Vendor) {
    this.dialog
      .open(VendorShowcasePopupComponent, {
        data: { vendorId: vendor.id },
        disableClose: false,
        height: "90vh",
        width: "90vw",
        maxWidth: "900px",
        autoFocus: false
      }).afterClosed()
      .subscribe((d) => {
        this.store.dispatch(doHeartBeat());
      });
  }
}
