import { NgModule } from '@angular/core';
import { FlowergirlFeaturesVendorModule } from '@skylitup/flowergirl/features/vendor';
import { FlowergirlXplatWebUiModule } from '@skylitup/flowergirl/xplat/web/ui';
import { FlowergirlXplatWebFeaturesChatModule } from "@skylitup/flowergirl/xplat/web/features/chat"

import { VendorSortComponent } from './components/util/vendor-sort.component';
import { VendorShowcasePopupComponent } from './components/vendor-showcase-popup/vendor-showcase-popup.component';
import { VendorCardComponent, VendorContactsComponent } from './components/vendor-card';
import { VendorShowcaseComponent } from './components/vendor-showcase/vendor-showcase.component';
import { VendorMapComponent } from './components/vendor-map/vendor-map.component';
import { VendorResearchComponent } from './components/vendor-research/vendor-research.component';
import { VendorResearchItemComponent } from './components/vendor-research-item/vendor-research-item.component';
import { ComparePricesComponent } from './components/compare-prices/compare-prices.component';
import { ComparePricesItemComponent } from './components/compare-prices-item/compare-prices-item.component';
import { CompareVendorsComponent } from './components/compare-vendors/compare-vendors.component';
import { VendorResearchFeedbackComponent } from './components/vendor-research-feedback/vendor-research-feedback.component';
import { BudgetResearchItemNotesEdit } from './components/budget-research-item-notes-edit/budget-research-item-notes-edit.component';
import { BaseUtilModule } from '@skylitup/base/util';

@NgModule({
  imports: [
    FlowergirlFeaturesVendorModule,
    FlowergirlXplatWebUiModule,
    FlowergirlXplatWebFeaturesChatModule,
    BaseUtilModule
  ],
  declarations: [
    VendorCardComponent,
    VendorContactsComponent,
    VendorShowcaseComponent,
    VendorMapComponent,
    VendorResearchComponent,
    VendorResearchItemComponent,
    ComparePricesComponent,
    ComparePricesItemComponent,
    BudgetResearchItemNotesEdit,
    VendorSortComponent,
    CompareVendorsComponent,
    VendorResearchFeedbackComponent,
    VendorShowcasePopupComponent,
  ],
  exports: [
    VendorCardComponent,
    VendorShowcaseComponent,
    VendorShowcasePopupComponent,
    ComparePricesComponent,
    VendorSortComponent,
  ],
})
export class FlowergirlXplatWebFeaturesVendorModule { }
