import { Component, Input, EventEmitter, OnInit, AfterViewInit, HostBinding, HostListener, ViewChild, ViewChildren, ElementRef, ChangeDetectorRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { PinchZoomComponent } from './pinch-zoom.component';
import { LightboxData } from './interfaces';
import { ShowState, ClosingState, AnimationMode } from './types';
import { EventService } from './event.service';
import { LightboxCommonComponent } from './lightbox-common.component';

@Component({
    selector: 'cui-lightbox-touchscreen',
    templateUrl: './lightbox-touchscreen.component.html',
    styleUrls: ['./style/lightbox-touchscreen.component.sass']
})

export class LightboxTouchscreenComponent extends LightboxCommonComponent {
    pinchZoom: PinchZoomComponent;
    preloaderTimeout: number = 100;
    moveX: number;
    moveY: number;
    imageIndex: number;
    slideDirection: 'left' | 'right';
    verticalSwipeDirection: 'top' | 'bottom';
    landscapeMode: boolean;
    minTimeout = 30;
    _firstSlide: any;
    _secondSlide: any;
    _lastSlide: any;

    firstSlidePositions = ['curr-image', 'prev-image', 'next-image'];
    secondSlidePositions = ['next-image', 'curr-image', 'prev-image'];
    lastSlidePositions = ['prev-image', 'next-image', 'curr-image'];

    events = new EventEmitter();

    @ViewChild('wrapper', {static: true}) wrapperElement: ElementRef;
    @ViewChildren(PinchZoomComponent) pinchZoomComponent;

    get currentImage() {
        const imagePositions = ['_imageFirst', '_imageSecond', '_imageLast'];
        return this[imagePositions[this.indexCurrentSlide]];
    }

    get currentImageElement() {
        if (this.currentImage) {
            return this.currentImage.nativeElement;
        }
    }
    /*
        get currentImageNaturalHeight(){
            if (this.currentImageElement){
                return this.currentImageElement.naturalHeight;
            }
        }

        get currentImageNaturalWidth(){
            if (this.currentImageElement){
                return this.currentImageElement.naturalWidth;
            }
        }
    */
    get isLandscape() {
        return window.innerWidth > window.innerHeight;
    }

    get firstSlidePosition() {
        return this.firstSlidePositions[this.indexCurrentSlide];
    }

    get secondSlidePosition() {
        return this.secondSlidePositions[this.indexCurrentSlide];
    }

    get lastSlidePosition() {
        return this.lastSlidePositions[this.indexCurrentSlide];
    }

    slidePath(_position: 'first' | 'second' | 'last') {
        const position = '_' + _position + 'Slide';
        if (this[position]) {
            let path;
            let image = this[position];

            if (image.fullImage && image.fullImage.path) {
                path = image.fullImage.path;
            } else if (image.thumbnailImage && image.thumbnailImage.path) {
                path = image.thumbnailImage.path;
            } else if (image.path) {
                path = image.path;
            }

            return path;
        }
    }

    @HostBinding('class.lightbox-animation') hostAnimation: boolean;
    @HostBinding('class.lightbox-shown') hostShown: boolean = false;
    @HostBinding('class.lightbox-light') get hostLightTheme() {
        return this.properties.backgroundColor === 'white';
    }

    @HostBinding('style.top') hostTop: string;
    @HostBinding('style.left') hostLeft: string;
    @HostBinding('style.backgroundColor') hostStyleBackgroundColor: string;

    @HostListener('window:resize', ['$event'])
    onWindowResize(event: any) {
        this.landscapeMode = this.isLandscape;
        this.ref.detectChanges();
    }

    @HostListener('transitionend', ['$event'])
    transitionEnd(event) {
        if (event.propertyName === "transform" && this.hostAnimation) {
            this.hostAnimation = false;
        }
    }

    @HostListener('window:scroll') scrolling() {
        if (this.showState === 'initial-thumbnail-image' ||
            this.showState === 'initial-virtual-image' ||
            this.closingState === 'animation') {
            this.updateThumbnailPosition();
        }
    }

    constructor(
        private elementRef: ElementRef,
        private ref: ChangeDetectorRef,
        public eventService: EventService) {
        super(eventService);
    }

    ngOnInit() {
        this.currentImageIndex = this.properties.index;
        this.initialization();
        this.setInitialSlidePositions();
    }

    ngAfterViewInit() {
        this.pinchZoom = this.pinchZoomComponent.find(elem => elem.id === "pinch-zoom");

        setTimeout(() => {
            if (this.currImageLoadingState === 'not-loaded') {
                this.currImageLoadingState = 'loading';
            }
        }, this.preloaderTimeout);

        // Mode: default
        if (this.animationMode === 'default') {
            setTimeout(() => {
                this.startOpeningAnimation();
            }, this.minTimeout);
        }

    }

    initialization() {
        this.setInitialContainerStyles(this.animationMode);
    }

    setInitialContainerStyles(mode: AnimationMode) {
        switch (mode) {
            case 'default':
                this.setShowState('initial-default');
                this.containerStyles = {
                    transform: 'translate3d(0, 0, 0)',
                    height: '100%'
                }

                // next step: AfterViewInit -> startOpeningAnimation()
                break;
            case 'zoom-preloader':
                this.setShowState('initial-thumbnail-image');
                this.containerStyles = {
                    transform: this.containerInitialPosition,
                    height: this.thumbnailImagePosition.height + 'px',
                    width: this.thumbnailImagePosition.width + 'px'
                }

                // The image is hidden when onImageLoaded() is called
                // next step: AfterViewInit -> startOpeningAnimation()
                break;
        }
    }

    setInitialSlidePositions() {
        this._firstSlide = this.images[this.currentImageIndex - 1];
        this._secondSlide = this.images[this.currentImageIndex];
        this._lastSlide = this.images[this.currentImageIndex + 1];
    }

    /*
        initialLightboxThumbnailImage(){
            this.setShowState('initial-thumbnail-image');
            this.containerStyles = {
                transform: this.containerInitialPosition,
                height: this.thumbnailImagePosition.height + 'px',
                width: this.thumbnailImagePosition.width + 'px'
            }

            // The image is hidden when onImageLoaded() is called
            // next step: AfterViewInit -> startOpeningAnimation()
        }

        initialLightboxDefault(){
            this.setShowState('initial-default');
            this.containerStyles = {
                transform: 'translate3d(0, 0, 0)',
                height: '100%'
            }
            // next step: AfterViewInit -> startOpeningAnimation()
        }
    */

    setVirtualImageStyles() {
        this.setShowState('initial-virtual-image');
        this.containerStyles = {
            transform: this.containerInitialPosition,
            height: this.virtualImageDimension.height + 'px',
            width: this.virtualImageDimension.width + 'px'
        }

        // next step: handleImageLoad() -> startOpeningAnimation()
    }


    startOpeningAnimation() {
        this.hostAnimation = true;
        this.setShowState('animation');
        this.hostShown = true;
        this.setBackgroundColor();
        this.setAnimationDuration();

        // Transforming to full size
        if (this.animationMode === 'zoom-preloader' &&
            this.currImageLoadingState !== 'error') {
            this.containerStyles.transform = this.containerstandalonePosition;
        }

        // next step: transitionEnd
    }

    finishOpeningAnimation() {
        this.setShowState('animation-end');
        this.containerStyles = {
            transform: 'translate3d(0, 0, 0)',
            height: '100%',
            width: '100%',
        }
    }

    close() {
        // In the future, transfer the transformation inside pinch-zoom

        this.setClosingState('initial');

        if (this.pinchZoom.scale > 1) {
            this.pinchZoom.resetScale();
            this.setClosingState('reset-scale');
        }

        if (this.closingState === 'initial') {
            this.startClosingAnimation();
        }
    }

    startClosingAnimation() {
        this.setClosingState('initial-styles');
        this.hostShown = false;

        if (this.animationMode === 'zoom-preloader') {
            this.containerStyles = {
                transform: this.containerstandalonePosition,
                height: this.virtualImageDimension.height + 'px',
                width: this.virtualImageDimension.width + 'px',
            }

            this.hostStyleBackgroundColor = '';
        }

        setTimeout(() => {
            this.setClosingState('animation');
            this.hostAnimation = true;

            switch (this.animationMode) {
                case 'zoom-preloader':
                    this.containerStyles.transform = this.containerInitialPosition;
                    this.setAnimationDuration();
                    break;
                case 'default':
                    this.containerStyles.opacity = '0';
                    break;
            }
        }, this.minTimeout);

        // next step: handleLightboxTransitionEnd

        if (this.animationDuration === 0) {
            this.finishClosingAnimation();
        }
    }

    finishClosingAnimation() {
        this.setClosingState('animation-end');
        this.events.emit({
            type: 'close'
        });
        if (this.animationMode === 'zoom-preloader') {
            this.showThumbnailImage();
        }
    }

    onImageLoaded(elem: any) {
        if (elem = "curr") {
            /*
            if (this.animationMode === 'zoom-preloader'){
                this.hideThumbnailImage();
            }
            */
        }

        if (this.animationMode === 'zoom-preloader' &&
            this.showState === 'initial-thumbnail-image') {
            this.setVirtualImageStyles();
            setTimeout(() => {
                this.startOpeningAnimation();
                this.currImageLoadingState = 'uploaded';

                if (this.properties.hideThumbnail) {
                    this.hideThumbnailImage();
                }
            }, this.minTimeout);
        }

        /*
        if (this.showState === 'animation-end'){
            this.currImageLoadingState = 'uploaded';
            // Выполнять только при анимации открытия 
            if (this.properties.hideThumbnail){
                this.hideThumbnailImage();
            }
        }
        */

        //this.currImageLoadingState = 'uploaded';
    }

    onImageError(event) {
        this.currImageLoadingState = 'error';
        this.setInitialContainerStyles('default');

        setTimeout(() => {
            this.startOpeningAnimation();
        }, this.minTimeout);
    }

    handlePinchEvents(event) {
        if (event.type === 'horizontal-swipe') {
            this.moveX = event.moveX;
        }

        if (event.type === 'vertical-swipe') {
            this.moveY = event.moveY;
        }

        if (event.type === 'swipe') {

        }

        if (event.type === 'touchend') {
            this.handlePinchTouchendEvent();
        }

        if (event.type === 'transitionend') {
            this.handlePinchTransitionEnd();
        }
    }

    handlePinchTouchendEvent() {
        this.slideDirection = this.detectSlide(this.moveX);
        this.verticalSwipeDirection = this.detectVerticalSwipe(this.moveY);

        this.handleSlide(this.slideDirection);
        this.handleVerticalSwipe(this.verticalSwipeDirection);
    }

    // Determine the direction of the slide from the moveX value obtained with 'horizontal-swipe'
    detectSlide(moveX: number): 'left' | 'right' {
        if (moveX > 50) {
            return "right"
        } else if (moveX < -50) {
            return "left"
        } else {
            return undefined;
        }
    }

    detectVerticalSwipe(moveY: number): 'top' | 'bottom' {
        if (moveY > 100) {
            return "top"
        } else if (moveY < -100) {
            return "bottom"
        } else {
            return undefined;
        }
    }

    handleSlide(direction: 'left' | 'right'): void {
        let moveX = window.innerWidth;

        if (direction) {
            this.moveX = 0;

            if (direction === 'left') {
                if (this.isLastImage) {
                    this.pinchZoom.alignImage();
                } else {
                    this.pinchZoom.setMoveX(-moveX);
                    this.showThumbnailImage();
                }
            }
            if (direction === 'right') {
                if (this.isFirstImage) {
                    this.pinchZoom.alignImage();
                } else {
                    this.pinchZoom.setMoveX(moveX);
                    this.showThumbnailImage();
                }
            }
        } else {
            this.pinchZoom.alignImage();
        }
    }

    handleVerticalSwipe(direction: 'top' | 'bottom'): void {
        if (direction) {
            this.moveY = 0;

            if (direction === 'top' || direction === 'bottom') {
                this.close();
            }
        } else {
            //this.pinchZoom.alignImage();
        }
    }


    /*
     * Transition End
     */

    handleLightboxTransitionEnd(event) {
        if (this.showState === 'animation') {
            this.finishOpeningAnimation();
        }

        // Last close step
        if (this.closingState === 'animation') {
            this.finishClosingAnimation();
        }
    }

    handlePinchTransitionEnd() {
        if (this.slideDirection) {
            this.handleSlideTransitionEnd();
        }

        if (this.closingState === 'reset-scale') {
            this.startClosingAnimation();
        }
    }

    handleSlideTransitionEnd() {
        // Last slide
        if (this.slideDirection === 'left' && this.isLastImage) {
            return;
        }
        // First slide
        if (this.slideDirection === 'right' && this.isFirstImage) {
            return;
        }

        this.pinchZoom.setMoveX(0, 0);
        this.setSlidePosition(this.slideDirection);
        this.replaceImages(this.slideDirection);
        this.slideDirection = undefined;

        if (this.properties.hideThumbnail) {
            this.hideThumbnailImage();
        }

        this.ref.detectChanges();
    }

    /*
     * Image substitution, used after slide animation.
     * Need to call upon transitionEnd.
     */
    replaceImages(direction: 'left' | 'right'): void {
        if (direction === 'left' && !this.isLastImage) {
            this.currentImageIndex++;
        }
        if (direction === 'right' && !this.isFirstImage) {
            this.currentImageIndex--;
        }

        if (this.indexCurrentSlide === 0) {
            this._secondSlide = this.images[this.currentImageIndex + 1];
            this._lastSlide = this.images[this.currentImageIndex - 1];
        }

        if (this.indexCurrentSlide === 1) {
            this._firstSlide = this.images[this.currentImageIndex - 1];
            this._lastSlide = this.images[this.currentImageIndex + 1];
        }

        if (this.indexCurrentSlide === 2) {
            this._firstSlide = this.images[this.currentImageIndex + 1];
            this._secondSlide = this.images[this.currentImageIndex - 1];
        }
    }

    setSlidePosition(direction: 'left' | 'right') {
        if (direction === 'left') {
            this.indexCurrentSlide++;
            if (this.indexCurrentSlide > 2) {
                this.indexCurrentSlide = 0;
            }
        }
        if (direction === 'right') {
            this.indexCurrentSlide--;
            if (this.indexCurrentSlide < 0) {
                this.indexCurrentSlide = 2;
            }
        }
    }
}