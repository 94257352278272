<div [ngClass]="{innerHeightIncreased:windowService.innerHeightIncreased$|async}">
    <nav *ngIf="showTabs" mat-tab-nav-bar animationDuration="0ms">
        <a mat-tab-link label="Recent" [routerLink]="['recent']" routerLinkActive #rlaOverview="routerLinkActive"
            [active]="rlaOverview.isActive" fxLayoutAlign="center center">
            <mat-icon class="m-r-15">event_note
            </mat-icon>
            <span class="text1">
                Recent
            </span>
        </a>
        <a mat-tab-link label="Categorized" [routerLink]="['categorized']" routerLinkActive
            #rlaBudget="routerLinkActive" [active]="rlaBudget.isActive" fxLayoutAlign="center center">
            <mat-icon class="m-r-15">account_tree</mat-icon>
            <span class="text1">
                Categorized
            </span>
        </a>
    </nav>
    <router-outlet></router-outlet>
</div>