<ng-container *ngIf="context.auth$ |async as auth">
    <div class="breadcrumb" fxLayout="row" fxLayoutAlign="start center">
        <a mat-button *ngIf="context.agency$ | async as agency" [routerLink]="agency.url">
            <mat-icon class="home-icon">home</mat-icon> <span class="text1 agency"
                *ngIf="!auth.data.timeTravelling">{{agency.name}}</span>
        </a>
        <mat-icon class="chevron" *ngIf="context.customer$ | async">chevron_right</mat-icon>
        <a mat-button *ngIf="context.customer$ | async as customer" [routerLink]="customer.url">
            <mat-icon class="customer-icon material-icons-outlined">account_box</mat-icon>
            <span class="text1 customer" *ngIf="!auth.data.timeTravelling"> {{customer.name}} </span>
        </a>
        <mat-icon class="chevron" *ngIf="context.customerEvent$ | async"> chevron_right</mat-icon>
        <a mat-button *ngIf="context.customerEvent$ | async as customerEvent" [routerLink]="customerEvent.url">
            <!-- <mat-icon class="material-icons-outlined details-icon">details</mat-icon> -->
            <span class="text1 event">
                {{customerEvent.name}}
                <ng-container *ngIf="customerEvent.isDateBooked">
                    <span *ngIf="customerEvent.info.bookedCandiDate as date1"><span class="date1">( {{date1.description}} )</span></span>
                </ng-container>
            </span>
        </a>
        <mat-icon *ngIf="context.edit$ | async" class="settings1 chevron"> chevron_right</mat-icon>
        <a mat-icon-button *ngIf="context.edit$ | async as edit" class="settings1">
            <mat-icon class="settings">settings</mat-icon>
        </a>
    </div>
</ng-container>