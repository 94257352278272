import { EntityTuple } from '@skylitup/base/ngrx-data-fire';
import { EventItem } from './event-item.model';
import { cloneEntityTuple } from '@skylitup/base/ngrx-data-fire';
import { Dictionary } from '@ngrx/entity';
import { Media } from '../upload/media/media.model';

export const projectSpecMedia = (
  t: EntityTuple<EventItem>,
  mediaMap: Dictionary<Media>
): EntityTuple<EventItem> => {
  const result = cloneEntityTuple(t, e => (e.specMedia = []));
  const [events] = result;
  for (const e of events) {
    const mediaIds = e.data?.specMediaIds || [];
    for (const mId of mediaIds) {
      const m = mediaMap[mId];
      if (m) {
        e.specMedia.push(m);
      }
    }
  }
  return result;
};
