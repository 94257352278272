<div fxLayout="row wrap" fxLayoutGap="20px" (keyup)="keyUp($event)">
    <div fxLayout="row wrap" fxLayoutGap="20px" [formGroup]="calcForm">

        <mat-form-field class="type">
            <mat-label>Type</mat-label>
            <mat-select formControlName="calcType">
                <mat-option *ngFor="let type of types" [value]="type.key">
                    {{type.text}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="typeControl.value=='custom'" class="customNumber">
            <mat-label>Number of Items</mat-label>
            <input #itemPrice type="number" min="0" matInput [formControl]="calcCustomNumberControl" required
                class="right1" (focus)="onFocus(itemPrice)">
            <mat-error *ngIf="calcCustomNumberControl.invalid">
                {{getErrorMessage(calcCustomNumberControl,'Number of Items')}}</mat-error>
        </mat-form-field>

        <mat-icon fxFlexAlign="center" *ngIf="typeControl.value!='none'">clear</mat-icon>
        <mat-form-field *ngIf="typeControl.value!='none'" appearance="fill" class="itemPrice">
            <mat-label>Item Price</mat-label>
            <input #itemPrice type="number" min="0" matInput [formControl]="itemPriceControl" required
                class="right1" (focus)="onFocus(itemPrice)">
            <mat-error *ngIf="itemPriceControl.invalid">{{getErrorMessage(itemPriceControl,'Item Price')}}</mat-error>
            <span matPrefix>€&nbsp;</span>
            <!-- <span matSuffix>.00</span> -->
        </mat-form-field>
        <span fxFlexAlign="center" *ngIf="typeControl.value!='none'" class="equals">=</span>
    </div>
    <mat-form-field appearance="outline" *ngIf="typeControl.value!='none'" class="result">
        <mat-label>Result</mat-label>
        <input matInput class="right1" [ngModel]="calcTotal() | currency:'EUR':'symbol'" readonly>
    </mat-form-field>
</div>
<mat-dialog-actions fxLayoutAlign="center">
    <button mat-button [mat-dialog-close]="saveCalculation()" type="submit">
        <!-- [disabled]=" typeControl.value!='none' &&  !calcForm.valid " -->
        <mat-icon>save</mat-icon> Save
    </button>
    <button mat-button [mat-dialog-close] type="submit">
        <!-- [disabled]=" typeControl.value!='none' &&  !calcForm.valid " -->
        <mat-icon>close</mat-icon> Cancel
    </button>
</mat-dialog-actions>