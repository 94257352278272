import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';

import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommissionData } from '@skylitup/flowergirl/core';
import { CommissionItemCalculationBaseComponent } from '@skylitup/flowergirl/ui';
import { getErrorMessageHandler } from '@skylitup/base/util';

@Component({
  selector: 'flrgl-commission-item-calculation',
  templateUrl: 'commission-item-calculation.component.html',
  styleUrls: ['commission-item-calculation.component.scss'],
})
export class CommissionItemCalculationComponent
  extends CommissionItemCalculationBaseComponent
  implements OnInit {
  types: { code: CommissionData['type']; name: string }[] = [
    { code: 'rate', name: 'Rate' },
    { code: 'amount', name: 'Amount' },
  ];
  get rate() {
    return this.form.get('rate');
  }
  get amount() {
    return this.form.get('amount');
  }
  get type() {
    return this.form.get('type');
  }
  form: FormGroup;
  getErrorMessage = getErrorMessageHandler;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CommissionData,
    public dialogRef: MatDialogRef<CommissionItemCalculationComponent>,
    private cd: ChangeDetectorRef
  ) {
    super();
  }
  onFocus(input: any) {
    input.select();
  }
  saveCalculation() {
    return { ...this.data, ...this.form.value };
  }
  ngOnInit(): void {
    this.form = new FormGroup({
      type: new FormControl(this.data.type),
      amount: new FormControl(this.data.amount, [Validators.required]),
      rate: new FormControl(this.data.rate, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
    });
    // setTimeout(() => this.cd.markForCheck(), 0);
  }
}
