import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
  AfterViewInit,
  ApplicationRef,
} from '@angular/core';

import { Observable } from 'rxjs';
import { map, first, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import {
  CustomerEvent,
  Customer,
  Agency,
  Category,
  Auth,
  impersonateCustomer,
  timeTravel,
  logout,
  ContextService,
  ChatTrackService,
  ChatTrack,
} from '@skylitup/flowergirl/core';
import { ThemeSelectorComponent } from '@skylitup/flowergirl/xplat/web/ui';
import { NavigateBaseComponent } from '@skylitup/flowergirl/features/navigate';
import { ENV, Environment } from '@skylitup/base/util';
import { EventSummaryComponent } from '../agency/customer/customer-event/event-summary/event-summary.component';
const THEME_DEV_MODE = false;
@Component({
  selector: 'flrgl-navigate',
  templateUrl: 'navigate.component.html',
  styleUrls: ['navigate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigateComponent extends NavigateBaseComponent
  implements AfterViewInit {
  customerEventEditPath$: Observable<string>;
  customerEvent$: Observable<CustomerEvent>;
  customer$: Observable<Customer>;
  agency$: Observable<Agency>;
  researchCategory$: Observable<Category>;
  overviewPath$: Observable<string>;

  edit$: Observable<boolean>;
  themeDevMode = false;
  auth$: Observable<Auth>;
  chatTrackInContext$: Observable<ChatTrack>;
  constructor(
    private context: ContextService,
    private dialog: MatDialog,
    private router: Router,
    private store: Store,
    @Inject(ENV) env: Environment,
    private cd: ChangeDetectorRef,
    private appRef: ApplicationRef,
    private chatTrackService: ChatTrackService,

  ) {
    super();
    this.themeDevMode = THEME_DEV_MODE && !env.production;
    this.customerEventEditPath$ = context.contextEditPath$.pipe(
      map((e) => e?.join('/'))
    );
    this.overviewPath$ = context.contextPath$.pipe(map((e) => e?.join('/')));
    this.customerEvent$ = context.customerEvent$;
    this.customer$ = context.customer$;
    this.agency$ = context.agency$;
    this.researchCategory$ = context.researchCategory$;
    this.edit$ = context.edit$;
    this.chatTrackInContext$ = this.chatTrackService.chatTrackInContext$;
    if (this.themeDevMode) {
      this.showThemeSelector();
    }
    this.auth$ = this.context.auth$;

    // this.store
    //   .select((s: AppState) => s.ui.theme)
    //   .subscribe(() => this.showThemeSelector());
  }
  navigateToChatRecents() {
    this.context.contextPath$
      .pipe(first())
      .subscribe((path) => this.router.navigate([...path, 'chat', 'recent']));
  }
  showThemeSelector() {
    this.dialog.open(ThemeSelectorComponent, {
      data: THEME_DEV_MODE,
      position: { top: '10px', right: '30px' },
      backdropClass: 'theme-backdrop',
      disableClose: this.themeDevMode,
      hasBackdrop: !this.themeDevMode,
    });
  }
  get isInVendorResearch() {
    return (
      this.router.url.includes('vendor-research') ||
      this.router.url.includes('compare-prices')
    );
  }
  toggleNotifications(chatTrack: ChatTrack) {

    this.chatTrackService.update({
      meta: chatTrack.data.meta,
      pauseNotifications: !chatTrack.isPaused,
      pauseNotificationsUntil: moment().add(4, 'h').toDate()
    });
  }
  extendPauseNotifications(chatTrack: ChatTrack) {
    this.chatTrackService.update({
      meta: chatTrack.data.meta,
      pauseNotificationsUntil: moment().add(4, 'h').toDate()
    });
  }
  impersonateCustomer(impersonatingCustomer: boolean) {
    this.store.dispatch(impersonateCustomer({ impersonatingCustomer }));
  }
  timeTravel(timeTravelling: boolean) {
    this.store.dispatch(timeTravel({ timeTravelling }));
  }
  logout() {
    this.store.dispatch(logout());
  }
  ngAfterViewInit(): void {
    // this.auth$
    //   .pipe(
    //     takeUntil(this.destroy$),
    //     distinctUntilChanged(null, a => a.data.impersonatingCustomer),
    //     tapDebug(a => ['yo'])
    //   )
    //   .subscribe(() => this.appRef.tick());
  }
  showSummary(customerEvent: CustomerEvent) {
    this.dialog.open(EventSummaryComponent, {
      data: customerEvent,
      width: "80vw",
      height: "80vh",
      maxWidth: "1000px"
    });
  }

}