import { Injectable } from "@angular/core";
import { LocalStorageService } from "../../local-storage.service";

@Injectable({ providedIn: "root" })
export class WebLocalStorageService extends LocalStorageService {
  setItem(key: string, val: string): void {
    window.localStorage.setItem(key, val);
  }
  getItem(key: string): string {
    return window.localStorage.getItem(key);
  }
  removeItem(key: string): void {
    window.localStorage.removeItem(key);
  }
  clear(): void {
    window.localStorage.clear();
  }
}
