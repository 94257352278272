<ng-container *ngIf="customerEvent$ | async as customerEvent">
    <mat-sidenav-container
        [ngClass]="{'expanded':fullCardOpen , innerHeightIncreased:windowService.innerHeightIncreased$|async}">
        <mat-sidenav-content>
            <div fxFlex="grow" fxLayout="column">
                <div>
                    <div class="top" fxLayout="row" fxLayoutAlign="start center">
                        <flrgl-vendor-sort [vendorSort]="vendorSort"></flrgl-vendor-sort>
                    </div>
                </div>
                <div class="container" fxFlex="grow" *ngIf="vendorSort.highlightKey$ |async as highlightKey">
                    <div class="vendors" fxLayout="row wrap" fxLayoutGap="10px">
                        <div *ngFor="let vr of vendorResearchInContext$ | async ;trackBy:trackByIdFn"
                            class="vendor-card m-b-6" fxLayout="column">
                            <ng-container *ngIf="vr.vendor as v">
                                <div class="mat-elevation-z2" fxLayout="column">
                                    <flrgl-vendor-card [withFilters]="false" (nameclick)="showcase(true,false,vr)"
                                        [vendor]="v" (focusSortClick)="focusSort($event)" [highlightKey]="highlightKey"
                                        [editable]="false" [customerEvent]="customerEvent">
                                    </flrgl-vendor-card>
                                    <div (click)="showcase(true,true,vr)|| (rippler.launch())"
                                        class="p-5 pointer1 feedback1 hover1" mat-ripple #rippler="matRipple"
                                        [matRippleDisabled]="true" [matRippleCentered]="true">
                                        <div fxLayout="row" fxLayoutAlign="start center">
                                            <flrgl-rating size="small" fxFlex="none" readOnly="true"
                                                [rating]="vr.rating">
                                            </flrgl-rating>
                                            <!-- class="clickable1 underline1" -->
                                            <button mat-icon-button class="m-l-5 clickable-icon1"
                                                matTooltip="Sort by Rating"
                                                (click)="$event.stopPropagation() || sortByHearts()">
                                                <mat-icon [ngClass]="{'color-accent':highlightKey==='rating'}"
                                                    fontSet="fas" fontIcon="fa-sort-amount-down">
                                                </mat-icon>
                                            </button>
                                            <!--  -->
                                            <div fxFlex="grow" class="right1">
                                                <mat-icon class="chat-icon">forum</mat-icon>
                                                <mat-icon class="chat-icon">favorite</mat-icon>
                                            </div>
                                        </div>
                                        <div>
                                            <flrgl-chat-thumb [dialog]="vr?.dialog" [hideName]="true"
                                                *ngIf="!vr?.dialog.isEmpty">
                                            </flrgl-chat-thumb>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </mat-sidenav-content>
        <mat-sidenav #sidenav mode="side" [(opened)]="fullCardOpen" position="end" mode="side">
            <div class="topx mat-elevation-z2">
                <button mat-stroked-button *ngIf="showFeedback;else showFeedbackBtn" (click)="showcase(true,false)"
                    matTooltip="showcase" fxFlex="none">
                    <mat-icon class="chat-icon m-r-5">photo_library</mat-icon> Showcase
                </button>
                <ng-template #showFeedbackBtn>
                    <button mat-stroked-button (click)="showcase(true,true)" matTooltip="show Feedback" fxFlex="none">
                        <mat-icon class="chat-icon m-r-5">forum </mat-icon>
                        <mat-icon class="chat-icon">favorite</mat-icon>Feedback
                    </button>
                </ng-template>
                <button fxFlex="grow" mat-stroked-button (click)="showcase(false,false)">
                    <mat-icon>close</mat-icon>Close
                </button>
            </div>
            <ng-container *ngIf="selectedVendorResearch">
                <div fxLayout="row" class="container6"
                    [ngClass]="{innerHeightIncreased:windowService.innerHeightIncreased$|async}">
                    <button mat-button *ngIf="fullCardOpen" class="drawer-button mat-elevation-z3"
                        (click)="showcase(false,false)">
                        <mat-icon>chevron_right</mat-icon>
                    </button>

                    <mat-divider vertical="true" fxFlex="none" class='m-x-2'></mat-divider>
                    <flrgl-vendor-showcase *ngIf="!showFeedback;else feedbackTmpl" [withFilters]="false"  [editable]="false" 
                        [vendor]="selectedVendorResearch.vendor" (focusSortClick)="focusSort($event)">
                    </flrgl-vendor-showcase>
                    <ng-template #feedbackTmpl>
                        <flrgl-vendor-research-feedback (ratingSortClick)="sortByHearts()"
                            [vendorResearch]="selectedVendorResearch">
                        </flrgl-vendor-research-feedback>
                    </ng-template>
                </div>
            </ng-container>
        </mat-sidenav>
    </mat-sidenav-container>
</ng-container>