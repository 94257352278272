<div class="main-container" #chatHistoryEl>
    <div fxLayout="column" class="inner-container" fxLayout="column" fxLayoutAlign="end center">
        <div *ngFor="let chat of dialog.history;index as i;trackBy:trackByIdFn" class="msg"
            [ngClass]="{'mine':isMySide(chat,auth),'cont':isNextSameSide(i)}">

            <button mat-icon-button class="chat-more" [matMenuTriggerFor]="chatMenu" *auth1="['planner']"
                [matTooltip]="fullDate(chat)">
                <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #chatMenu="matMenu">
                <a mat-menu-item (click)="msgDeleted.next(chat)">
                    <mat-icon>delete</mat-icon> Delete
                </a>
                <a mat-menu-item (click)="msgEditStart.next(chat)">
                    <mat-icon>edit</mat-icon> Edit
                </a>
                <!-- (click)="deleteMedia(media)"  -->
            </mat-menu>


            <div class="header">
                <span class="name">{{chat.name}}</span>
                <span class="date">{{chat.dateString}} </span>
            </div>
            <div class="text" *ngIf="!chat.isAttachment;else attachment" [innerHTML]="chat.msg">
            </div>
            <!-- <div>
               chatAgency {{chat.senderIsAgency}} - isplanner {{auth.isPlanner}} - isCustomer {{auth.isCustomer}} 
            </div> -->
            <ng-template #attachment>
                <div *ngIf="chat.data?.attachment.uploading;else notUploadingT">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <ng-template #notUploadingT>
                    <div *ngIf="!chat.attachmentType || chat.attachmentType==='image';else docsT">
                        <div class="img-container" lightbox-group>
                            <!-- {{debug1(chat)}} -->
                            <img *ngFor="let m of chat.media;trackBy:trackByIdFn" [src]="m.urlMedium" lightbox
                                [fullImage]="{path:m.urlLarge}" (load)="scrollToBottom()">
                        </div>
                    </div>
                    <ng-template #docsT>
                        <ng-container *ngIf="chat?.docs[0] as doc1">
                            <div class="doc-container">
                                <div class="title">

                                    <ng-container *ngIf="chat?.docs?.length==1;else titleDocs" target="_blank">

                                        <ng-container [ngSwitch]="doc1.renderType">
                                            <a *ngSwitchCase="'image'" (click)="img6.click()" class="clickable1">
                                                <img [src]="doc1.fileUrl" lightbox #img6 hidden>
                                                <ng-container *ngTemplateOutlet="docLineTitle"></ng-container>
                                            </a>
                                            <a *ngSwitchCase="'pdf'" (click)="showPdf(doc1)" class="clickable1">
                                                <ng-container *ngTemplateOutlet="docLineTitle"></ng-container>
                                            </a>
                                            <a *ngSwitchDefault [href]="doc1.fileUrl" target="_blank"
                                                class="clickable1">
                                                <ng-container *ngTemplateOutlet="docLineTitle"></ng-container>
                                            </a>
                                        </ng-container>


                                        <ng-template #docLineTitle>
                                            <mat-icon class='doc-icon' fontSet="far" [fontIcon]="doc1.iconCode">
                                            </mat-icon>
                                            <span class="doc-type"> {{doc1?.data.type}} : </span>
                                            <span class="doc-name">{{doc1.name}}</span>
                                            <mat-divider></mat-divider>
                                        </ng-template>


                                    </ng-container>
                                    <ng-template #titleDocs>
                                        <span class="doc-type"> {{doc1?.data.type}} : </span>
                                        <span class="doc-name">{{doc1.name}}</span>
                                        <mat-divider></mat-divider>
                                    </ng-template>
                                </div>
                                <ng-container *ngIf="chat?.docs?.length>1">
                                    <div *ngFor="let d of chat?.docs">
                                        <ng-container [ngSwitch]="d.renderType">
                                            <a *ngSwitchCase="'image'" (click)="img6.click()" class="clickable1">
                                                <img [src]="d.fileUrl" lightbox #img6 hidden>
                                                <ng-container *ngTemplateOutlet="docLine"></ng-container>
                                            </a>
                                            <a *ngSwitchCase="'pdf'" (click)="showPdf(d)" class="clickable1">
                                                <ng-container *ngTemplateOutlet="docLine"></ng-container>
                                            </a>
                                            <a *ngSwitchDefault [href]="d.fileUrl" target="_blank" class="clickable1">
                                                <ng-container *ngTemplateOutlet="docLine"></ng-container>
                                            </a>
                                        </ng-container>
                                        <ng-template #docLine>
                                            <mat-icon class='doc-icon' fontSet="far" [fontIcon]="d.iconCode"></mat-icon>
                                            <span class="doc-name">{{d.fileName}}</span>
                                        </ng-template>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                        <!-- <ngx-gallery [options]="chatImageOptions" [images]="chatImageUrls(chat)" #galleryPreview>
                        </ngx-gallery> -->
                    </ng-template>
                </ng-template>
                <mat-expansion-panel *ngIf="chat.data?.attachment.uploadError">
                    <mat-expansion-panel-header>
                        <mat-panel-description fxLayoutAlign="end center">
                            <mat-icon class='m-r-10 error'>error</mat-icon> There's been an error during uploading
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    {{chat.data?.attachment.uploadError}}
                </mat-expansion-panel>

            </ng-template>
        </div>
    </div>
</div>