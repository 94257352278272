import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Moment } from 'moment';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { ChecklistItemUpsertBehavior } from '../checklist-item-upsert-behavior';
import { ChecklistItemEditBaseComponent } from '@skylitup/flowergirl/features/checklist';
import {
  Agency,
  ChecklistItem,
  ChecklistItemData,
  ChecklistItemImp,
  Vendor,
} from '@skylitup/flowergirl/core';
import { getErrorMessageHandler } from '@skylitup/base/util';
@Component({
  selector: 'flrgl-checklist-item-edit',
  templateUrl: 'checklist-item-edit.component.html',
  styleUrls: ['checklist-item-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistItemEditComponent extends ChecklistItemEditBaseComponent
  implements OnInit {
  @Input()
  upsert: ChecklistItemUpsertBehavior;
  @Input()
  agency: Agency;

  item: ChecklistItem;
  @Output()
  itemChange: EventEmitter<{
    data: ChecklistItemData;
    highlight: boolean;
    done: () => void;
  }> = new EventEmitter();
  @Output()
  cancel: EventEmitter<void> = new EventEmitter();
  @Output()
  deleted: EventEmitter<void> = new EventEmitter();

  @Input()
  vendors: Vendor[] = [];
  //
  form: FormGroup;
  getErrorMessage(control: FormControl, name) {
    if (control?.errors?.['bigger']) {
      return 'Part Amount should be less than the Payment Amount';
    } else {
      return getErrorMessageHandler(control, name);
    }
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.form = this.upsert.form;
    this.item = this.upsert.item;
    this.form.valueChanges
      .pipe(
        filter(() => this.form.valid),
        // tap((o) => console.log(o)),
        takeUntil(this.destroy$),
        debounceTime(1200)
      )
      .subscribe((value) => {
        const dueDate = (<Moment>value.dueDate).toDate();
        // const val = { ...this.form.value };
        // val.vendorId = val.vendor?.id || null;
        // delete val.vendor;
        this.itemChange.next({
          data: { ...this.form.value, dueDate },
          highlight: this.upsert.highlight,
          done: () => {},
        });
      });
  }
  onFocus(input: any) {
    input.select();
  }
  keyUp(e) {
    if (e.keyCode === 13) {
      this.close();
    }
  }
  close() {
    this.cancel.next();
  }
  deletedItem() {
    if (confirm(`Sure you want to delete this checklist-item?`)) {
      this.deleted.next();
    }
  }
}
