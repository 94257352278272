<mat-form-field (click)="inputMat.focus()" *ngIf="control.optionsSelected$ |async as optionsSelected">

    <mat-label>{{control.label}}</mat-label>

    <mat-chip-list #chipList>
        <mat-chip *ngFor=" let selection of optionsSelected" selectable="false" removable="true"
            (removed)="control.removeSingle(selection)">
            {{control.displayFn(selection)}}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input type="text" matInput [formControl]="control.formControl" [matAutocomplete]="auto1"
            [matChipInputFor]="chipList" (matChipInputTokenEnd)="clearInputText($event)"
            #auto1Trigger="matAutocompleteTrigger" (focus)="control.clearFilter()" #input1 #inputMat='matInput'>
        <mat-error *ngIf="control.formControl.touched && control.formControl.invalid">
            {{control.label}} is required
        </mat-error>
    </mat-chip-list>
    <mat-autocomplete #auto1="matAutocomplete">
        <ng-container *ngIf="!control.hasGroup;else group1">
            <mat-option *ngFor="let option of control.filteredOptions$ | async" [value]="option">
                {{option.name}}
            </mat-option>
        </ng-container>
        <ng-template #group1>
            <mat-optgroup *ngFor="let group of control.filteredOptions$ | async" [label]="control.displayFn(group)">
                <ng-container *ngFor="let child of control.groupFn(group)">
                    <mat-option *ngIf="!child.isRoot" [value]="child">
                        {{control.displayFn(child)}}
                    </mat-option>
                    <mat-option *ngIf="control.groupOptionIsRootFn(child)" [value]="child">
                        {{control.groupOptionDisplayFn(group)}}
                    </mat-option>
                </ng-container>
            </mat-optgroup>
        </ng-template>
    </mat-autocomplete>
    <button mat-icon-button matSuffix *ngIf="!!optionsSelected.length" (click)="removeAll(auto1Trigger)">
        <mat-icon>close</mat-icon>
    </button>


</mat-form-field>