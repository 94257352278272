import {
  EntityData,
  entityDate,
  EntityProjected,
  Timestamp,
} from '@skylitup/base/ngrx-data-fire';
import { linkifyString } from '@skylitup/base/util';
import * as moment from 'moment';
import { UserInfoAgency } from '../agency/agency.model';
import { UserInfoAgencyData } from '../agency/agency.model.data';
import { Dialog, HasDialog } from '../chat/chat.model';
import { ChecklistVendorCat } from '../checklist-item/checklist-cat.model';
import { ChecklistItem } from '../checklist-item/checklist-item.model';
import { ChecklistItemType } from '../checklist-item/checklist-item.model.data';
import { ChecklistMonth } from '../checklist-item/checklist-month.model';
import { Payment } from '../checklist-item/payment.model';
import { CustomerEvent } from '../customer-event/customer-event.model';
import { VendorCat } from '../customer-event/vendor-cat.model';
import { Media } from '../upload/media/media.model';
import { UserCard } from '../user-card/user-card.model';
import {
  calcBudget,
  CalculationType,
  getCalcNumber,
} from './budget-item.model.data';
import { EventItemData } from './event-item.model.data';

export class EventItem extends EntityProjected<EventItemData>
  implements HasDialog, ChecklistItem {
  get isPayment(): boolean {
    return false;
  }
  get flagged(): boolean {
    return this.data.flagged;
  }
  get details(): string {
    return undefined;
  }
  get done(): boolean {
    return this.data.done;
  }
  get internal(): boolean {
    return false;
  }
  get highlight(): boolean {
    return this.checklistCat?.highlightedChecklistItem === this;
  }
  get type(): ChecklistItemType {
    return 'eventItem';
  }
  get eventId() {
    return this.data.meta.parentId;
  }
  get asPayment(): Payment {
    return (<any>this) as Payment;
  }
  get asEventItem(): EventItem {
    return (<any>this) as EventItem;
  }
  get assigneeId() {
    return this.data.assigneeId;
  }
  get urlPath(): string[] {
    return this.data?.meta?.urlPath;
  }
  checklistCat: ChecklistVendorCat;
  checklistMonth: ChecklistMonth;
  assignee: UserInfoAgency;
  readonly _dueDate: Date;
  readonly doneDate: Date;
  get month(): number {
    return moment(this.done ? this.doneDate : this.dueDate).month();
  }
  get year(): number {
    return moment(this.done ? this.doneDate : this.dueDate).year();
  }
  get dueDateString(): string {
    return moment(this.dueDate).format('LL');
  }
  readonly doneDateString: string;
  get overdue(): boolean {
    return moment().isAfter(this.dueDate);
  }
  get monthId(): string {
    return '' + 12 * this.year + this.month;
  }

  event: CustomerEvent;
  vendorCat: VendorCat;
  dialog: Dialog = new Dialog(this, 'eventItem');
  specMedia: Media[] = [];

  get vendorId() {
    return this.data.vendorId || 'void';
  }
  get catId() {
    return this.data.catId;
  }
  get budget() {
    return calcBudget(this.event.data, this.data);
  }
  get calcType(): CalculationType {
    return this.data.calcType || 'none';
  }
  get calcItemPrice() {
    return this.data.calcItemPrice || 0;
  }
  get calcNumber(): number {
    return getCalcNumber(this.event.data, this.data);
  }
  get dueDate(): Date {
    return this._dueDate || this.event?.info.earliestDate;
  }
  clone(): EventItem {
    return new EventItem(this.data).copy(this);
  }
  copy(from: EventItem): EventItem {
    this.vendorCat = from.vendorCat;
    this.event = from.event;
    this.dialog = from.dialog;
    return this;
  }
  get spec() {
    let s;
    return (() => {
      if (s) { return s; }
      s = this.data.spec || null;
      if (s) {
        s = linkifyString(s);
      }
      return s;
    })();
    // return s
  }

  get nextItem(): EventItem {
    const result = null;
    if (this.event && this.vendorCat) {
      const vendorCatIndex = this.event.allVendorCats.findIndex(
        (v) =>
          v.id === this.vendorCat.id && v.vendorId === this.vendorCat.vendorId
      );
      const itemIndex = this.vendorCat.items.findIndex(
        (i) => i.id === this.data.meta.id
      );
      if (itemIndex < this.vendorCat.items.length - 1) {
        return this.vendorCat.items[itemIndex + 1];
      } else {
        for (
          let nextVendorCatIndex = vendorCatIndex + 1;
          nextVendorCatIndex < this.event.allVendorCats.length;
          nextVendorCatIndex++
        ) {
          for (
            let nextItemIndex = 0;
            nextItemIndex <
            this.event.allVendorCats[nextVendorCatIndex].items.length;
            nextItemIndex++
          ) {
            const nextItem = this.event.allVendorCats[nextVendorCatIndex].items[
              nextItemIndex
            ];
            if (nextItem) {
              return nextItem;
            }
          }
        }
      }
    }

    return result;
  }
  get previousItem() {
    const result = null;
    if (this.event && this.vendorCat) {
      const vendorCatIndex = this.event.allVendorCats.findIndex(
        (v) =>
          v.id === this.vendorCat.id && v.vendorId === this.vendorCat.vendorId
      );
      const itemIndex = this.vendorCat.items.findIndex(
        (i) => i.id === this.data.meta.id
      );
      if (itemIndex > 0) {
        return this.vendorCat.items[itemIndex - 1];
      } else {
        for (
          let prevVendorCatIndex = vendorCatIndex - 1;
          prevVendorCatIndex >= 0;
          prevVendorCatIndex--
        ) {
          for (
            let prevItemIndex =
              this.event.allVendorCats[prevVendorCatIndex].items.length - 1;
            prevItemIndex >= 0;
            prevItemIndex--
          ) {
            const prevItem = this.event.allVendorCats[prevVendorCatIndex].items[
              prevItemIndex
            ];
            if (prevItem) {
              return prevItem;
            }
          }
        }
      }
    }
    return result;
  }
  get meta(): EntityData['meta'] {
    return this.data?.meta;
  }
  constructor(data: EventItemData) {
    super(data);
    this._dueDate = this.data.dueDate ? entityDate(this.data.dueDate) : null;
    this.doneDate = entityDate(this.data?.doneDate);


    this.doneDateString = moment(this.doneDate).format('LL');
  }
}
