import { DATE_STRING_LONG_FORMAT, entityDate, EntityProjected, EntityProjectedBase } from '@skylitup/base/ngrx-data-fire';
import { excerpt, linkifyString } from '@skylitup/base/util';
import * as moment from 'moment';
import { UserInfoAgency } from '../agency/agency.model';
import { CustomerEvent } from '../customer-event/customer-event.model';
import { VendorCat } from '../customer-event/vendor-cat.model';
import { UserInfoCustomer } from '../customer/customer.model.data';
import { Doc } from '../upload/doc/doc.model';
import { Media } from '../upload/media/media.model';
import { ChatData, ChatLinkType, getCatDialogId, getCustomerEventItemId, getGeneralDialogId, getVendorCatDialogId, getVendorResearchDialogId } from './chat.model.data';
export class Chat extends EntityProjected<ChatData> {
  readonly date: Date;
  readonly dateString: string;
  readonly dateStringFull: string;
  readonly msg: string;
  readonly msgRaw: string;
  public customerEvent: CustomerEvent;

  media: Media[] = [];
  docs: Doc[] = [];

  public get customerEventId() {
    return this.data.meta.parentId;
  }

  // user: UserInfoCustomer;
  get user(): UserInfoAgency {
    return this.customerEvent?.agency?.usersInfo?.[this.data._uid] || null;
  }

  constructor(data: ChatData) {
    super(data);
    this.date = entityDate(this.data?.date);
    this.dateString = moment(this.date).fromNow();
    this.dateStringFull = moment(this.date).format(DATE_STRING_LONG_FORMAT);
    this.msgRaw = this._msgRaw;
    this.msg = linkifyString(this._msgRaw);
  }

  get name() {
    return this.user ? this.user.username : '';
  }
  get recent() {
    return this.data.recent || false;
  }

  get _msgRaw() {
    let m: string;
    if (this.data.msg) {
      m = this.data.msg;
    } else if (this.data.attachment?.isAttachment) {
      if (this.data.attachment?.mediaIds?.length > 1) {
        m = `Attached ${this.data.attachment?.mediaIds?.length} ${this.attachmentType === 'doc' ? "documents" : "images"}`;
      } else {
        m = `Attached ${this.attachmentType === 'doc' ? "a document" : "an image"}`;
      }
    } else {
      m = '';
    }
    return m;
  }

  get senderId(): string {
    return this.data._uid;
  }
  get senderIsAgency(): boolean {
    return this.data.senderIsAgency === undefined
      ? true
      : this.data.senderIsAgency;
  }
  clone(): Chat {
    return new Chat(this.data).copy(this);
  }
  copy(from: Chat): Chat {
    this.media = [...from.media];
    this.docs = [...from.docs];
    this.customerEvent = from.customerEvent;
    return this;
  }
  get isAttachment() {
    return this.data?.attachment?.isAttachment;
  }
  get attachmentType() {
    return this.data?.attachment?.type;
  }
}
export class Dialog {
  history: Chat[] = [];
  recent: Chat[] = [];
  lastContactDate: Date;
  lastContactDateString: string;
  recentOldest: Chat = null;
  recentLatest: Chat = null;
  hasUnread = false;
  hasUnreadDescendants = false;
  unread: Chat[] = [];
  constructor(
    public dialogObj: HasDialog,
    public type: ChatLinkType,
    public currentUserId?: string
  ) { }

  get customerType() {
    return this.dialogObj?.event?.data.customerType;
  }

  get dialogId(): string {
    if (this.type === 'category') {
      return getCatDialogId(this.dialogObj.id);
    } else if (this.type === 'vendorCat') {
      return getVendorCatDialogId(
        this.dialogObj.id,
        (<VendorCat>this.dialogObj).vendorId
      );
    } else if (this.type === 'eventItem') {
      return getCustomerEventItemId(this.dialogObj.id);
    } else if (this.type === 'general') {
      return getGeneralDialogId();
    } else if (this.type === 'vendorResearch') {
      return getVendorResearchDialogId(this.dialogObj.id);
    } else {
      return null
    }
  }

  get isEmpty(): boolean {
    return this.history.length === 0;
  }

  get recentLatestExcerpt() {
    const text = this.recentLatest?.msgRaw;
    return excerpt(text, 55);
  }
  get recentLatestExcerptLonger() {
    const text = this.recentLatest?.msgRaw;
    return excerpt(text, 90);
  }


}
export interface HasDialog extends EntityProjectedBase<any> {
  id: string;
  dialog: Dialog;
  name: string;
  event: CustomerEvent;
}
