<ng-container *ngIf="auth$ | async as auth">
    <ng-container *ngIf="customerEvent$|async as customerEvent">
        <div class="totals mat-elevation-z1">
            <flrgl-budget-header [withLabels]="true" [goalBudget]="customerEvent.totalGoalBudget"
                [actualBudget]="customerEvent.totalActualBudgetIncludingExtra" [paymentsPaid]="customerEvent.totalPaid"
                [paymentsPending]="customerEvent.totalPaymentPending">
                <button class="payments underline1 clickable1" mat-raised-button mat-elevation-z3
                    [routerLink]="['payments']">
                    <mat-icon>euro_symbol</mat-icon> Payments
                </button>
            </flrgl-budget-header>
            <mat-divider></mat-divider>
        </div>
        <div class="spacer"></div>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let cat of customerEvent.categories;trackBy:trackByIdFn;let ic=index"
                (opened)="focusedOnCat(cat)" [expanded]="(focusCat$ |async)==cat.id" #panel1>
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                        <flrgl-budget-header [name]="cat.name" [showNumbers]="!panel1.expanded"
                            [goalBudget]="customerEvent.getCatGoalBudget(cat.id)"
                            [actualBudget]="customerEvent.getCatBudget(cat.id)"
                            [paymentsPaid]="customerEvent.getCatPaid(cat.id)"
                            [paymentsPending]="customerEvent.getCatPaymentPending(cat.id)">
                        </flrgl-budget-header>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                    <flrgl-budget-header-expanded [name]='cat.name'
                        [goalBudget]="customerEvent.getCatGoalBudget(cat.id)"
                        [actualBudget]="customerEvent.getCatBudget(cat.id)"
                        [paymentsPaid]="customerEvent.getCatPaid(cat.id)"
                        [paymentsPending]="customerEvent.getCatPaymentPending(cat.id)"
                        (goalChange)="goalChanged($event,customerEvent,cat)" [editable]="auth.isPlanner">
                    </flrgl-budget-header-expanded>

                    <ng-container
                        *ngFor="let vendorCat of customerEvent.getVendorCats(cat.id);trackBy:trackByIdFn;let i=index">
                        <div class="vendor-container mat-elevation-z2" *ngIf="vendorCat.items.length">
                            <div class="title">
                                <mat-icon *ngIf="vendorCat.hasVendor" class="m-r-5">store</mat-icon>
                                {{vendorCat.getControlledName(customerEvent?.customerId,auth)||'(Unassigned)'}}
                            </div>

                            <flrgl-budget-header-expanded
                                [name]='vendorCat.getControlledName(customerEvent?.customerId,auth)'
                                [actualBudget]="vendorCat.money.budget" [extraCost]="vendorCat.money.extraCost"
                                [paymentsPaid]="vendorCat.money.paid" [paymentsPending]="vendorCat.money.pendingPayment"
                                (goalChange)="goalChanged($event,customerEvent,cat)" [editable]="auth.isPlanner">
                            </flrgl-budget-header-expanded>

                            <mat-divider></mat-divider>

                            <div class="items-container">
                                <div *ngFor="let item of vendorCat.items;trackBy:trackByIdFn">
                                    <flrgl-budget-item [editable]="auth.isPlanner" [item]="item"
                                        (budgetChange)="budgetChange($event,item)">
                                    </flrgl-budget-item>
                                </div>
                            </div>
                            <mat-card class="budget extra">
                                <div fxLayout="row" fxLayoutAlign="end center">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Other Costs </mat-label>
                                        <input matInput class="right1"
                                            [ngModel]="vendorCat.money.extraCost | currency:'EUR':'symbol'" readonly>
                                    </mat-form-field>
                                </div>
                            </mat-card>
                            <!-- [debug]="(i==1 && ic==0 )" -->
                            <ng-container *auth1="['planner']">
                                <flrgl-commission-item *ngIf="vendorCat.hasVendor"
                                    [commissionRate]="customerEvent.getCommissionRate(vendorCat.vendorId)"
                                    [commissionAmount]="customerEvent.getCommissionAmount(vendorCat.vendorId)"
                                    [commissionPaid]="customerEvent.getCommissionPaid(vendorCat.vendorId)"
                                    [commissionData]="customerEvent.getCommissionData(vendorCat.vendorId)"
                                    (calcChange)="commissionChange(customerEvent,vendorCat.vendorId,$event)"
                                    (paymentsReceivedChange)="paymentsReceivedChange(customerEvent,vendorCat.vendorId,$event)">
                                </flrgl-commission-item>
                            </ng-container>
                        </div>

                    </ng-container>
                </ng-template>


            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>
</ng-container>