import { ChecklistItem, ChecklistItemImp } from './checklist-item.model';
import { ChecklistItemData } from './checklist-item.model.data';
import { Vendor } from '../vendor/vendor.model';

export interface PaymentData extends ChecklistItemData {
  amount: number;
  paymentMethod: 'cash' | 'bank';
  extraRate: number;
}

export class Payment extends ChecklistItemImp {
  vendor: Vendor;
  constructor(data: ChecklistItemData) {
    super(data);
  }

  get paymentMethod() {
    return this.data.paymentMethod || 'cash';
  }
  get isCash(): boolean {
    return this.paymentMethod === 'cash';
  }
  clone(): Payment {
    return new Payment(this.data).copy(this);
  }
  copy(from: Payment): Payment {
    super.copy(from);
    return this;
  }
  get extraRate() {
    return this.data.extraRate || 0;
  }
  get vendorId(): string {
    return this.data.vendorId;
  }

  get amount(): number {
    return this.data.amount || 0;
  }
  get amountString(): string {
    return '' + this.amount;
  }
  get commissionAmount(): number {
    return this.data.commissionAmount || 0;
  }
  get commissionAmountString(): string {
    return '' + this.data.commissionAmount;
  }
  get includesCommission() {
    return !!this.data.includesCommission;
  }
}
