import { Component } from '@angular/core';
import { PaymentsBaseComponent } from '@skylitup/flowergirl/features/budget';

@Component({
  selector: 'flrgl-payments',
  templateUrl: 'payments.component.html',
  styleUrls: ['payments.component.scss'],
})
export class PaymentsComponent extends PaymentsBaseComponent {
  constructor() {
    super();
  }
}
