import {
  EntityData,
  EntityKeys,
  Timestamp,
} from '@skylitup/base/ngrx-data-fire';
import { CustomerDataType } from '../customer/customer.model.data';
import { BudgetItemData } from './budget-item.model.data';

export const EVENT_ITEM_KEYS: EntityKeys = {
  name: 'event-item',
  plural: 'event-items',
};

export interface EventItemData extends EntityData, BudgetItemData {
  name: string;
  catId: string;
  vendorId?: string;
  spec: string;
  specMediaIds: string[];
  agencyId: string;
  //
  sortIndex: number;
  customerType: CustomerDataType;
  //
  // ChecklistItemTodo data
  done: boolean;
  dueDate: Timestamp | Date;
  doneDate: Timestamp | Date;
  assigneeId?: string;
  flagged: boolean;
}
