import { Inject, Injectable } from '@angular/core';
import { EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { Dictionary } from '@ngrx/entity';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { EntityBaseSelectors, EntityBaseService, entityDate } from '@skylitup/base/ngrx-data-fire';
import { ENV, Environment } from '@skylitup/base/util';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AGENCY_KEYS } from '../data-index';
import { UserTrack } from './user-track-model';
import { UserTrackData, USER_TRACK_KEYS } from './user-track-model.data';

interface SelectorsProjected
    extends EntityBaseSelectors<UserTrackData, UserTrack> {
    selectUserTrackByUidMap: MemoizedSelector<any, Dictionary<UserTrack>>;
}

@Injectable({ providedIn: 'root' })
export class UserTrackService extends EntityBaseService<UserTrackData, UserTrack> {

    // userTrackByUidMap$: Observable<Dictionary<UserTrack>>;

    public selectorsProjected: SelectorsProjected;
    constructor(
        entityServiceFactory: EntityCollectionServiceElementsFactory,
        entityDataServices: EntityDataService,
        @Inject(ENV) private env: Environment) {
        super(USER_TRACK_KEYS, entityServiceFactory, entityDataServices);
        this.init();
    }
    setupRxSelectors() {
        // this.selectorsProjected.selectUserTrackByUidMap = createSelector(
        //     this._selectPlainEntityTuple,
        //     (t) => {
        //         const [uu] = t;
        //         const r = {} as Dictionary<UserTrack>
        //         for (const u of uu) {
        //             r[u.data.meta.id] = u;
        //         }
        //         return r;
        //     }
        // );
        return this._selectPlainEntityTuple;
    }
    setupRx$() {
        // this.userTrackByUidMap$ = this.store.select(this.selectorsProjected.selectUserTrackByUidMap);
    }

    protected newProjectedEntity(d: UserTrackData) {
        return new UserTrack(d);
    }
    printActivity(userTrack: UserTrackData): void {
        console.log(userTrack.name, 'Active at: ', moment(entityDate(userTrack.lastActive)).format('LTS'))
    }
    printLogin(userTrack: UserTrackData): void {
        console.log('Logged in: ', moment(entityDate(userTrack.lastLogin)).format('LLLL'))
    }

    sync$(agencyId: string) {
        return this.syncQueryRealtime$(
            [
                AGENCY_KEYS.plural,
                agencyId,
                USER_TRACK_KEYS.plural,
            ],
        );
    }
}
