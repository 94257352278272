import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Moment } from 'moment';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ChecklistItemUpsertBehavior } from '../checklist-item-upsert-behavior';
import { ChecklistItemCreateBaseComponent } from '@skylitup/flowergirl/features/checklist';
import {
  CustomerEvent,
  Vendor,
  ChecklistItemData,
  AppState, Agency
} from '@skylitup/flowergirl/core';
import { getErrorMessageHandler } from '@skylitup/base/util';

@Component({
  selector: 'flrgl-checklist-item-create',
  templateUrl: 'checklist-item-create.component.html',
  styleUrls: ['checklist-item-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistItemCreateComponent
  extends ChecklistItemCreateBaseComponent
  implements OnInit {
  @Input()
  customerEvent: CustomerEvent;
  @Input()
  forcePayment = false;
  @Input()
  agency:Agency;
  @Input()
  vendors: Vendor[] = [];
  @Output()
  itemSubmitted: EventEmitter<{
    data: ChecklistItemData;
    highlight: boolean;
    clear: () => void;
  }> = new EventEmitter();
  upsert: ChecklistItemUpsertBehavior;
  form: FormGroup;
  expanded = false;

  getErrorMessage(control: FormControl, name) {
    if (control?.errors?.['bigger']) {
      return 'Part Amount should be less than the Payment Amount';
    } else {
      return getErrorMessageHandler(control, name);
    }
  }

  @HostListener('click')
  clickInside() {
    this.upsert.clickInside();
  }
  @HostListener('document:click', ['$event.target'])
  clickDoc = () => {
    if (this.expanded) {
      if (this.upsert.form.valid) {
        this.upsert.clickDoc(() => {
          this.expanded = false;
        });
      } else {
        this.upsert.form.markAllAsTouched();
      }
    }
  };

  constructor(private fb: FormBuilder, store: Store<AppState>) {
    super();
    store
      .select((s) => s.ui.focusCat)
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((_) => {
        this.expanded = false;
        this.upsert?.clear();
      });
  }

  keyUp(e) {
    if (e.keyCode === 13) {
      this.create();
    }
  }
  onFocus(input: any) {
    input.select();
  }

  create = () => {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const dueDate = (<Moment>this.form.value.dueDate).toDate();
      // const val = { ...this.form.value };
      // val.vendorId = val.vendor?.id || null;
      // delete val.vendor;
      this.itemSubmitted.next({
        data: { ...this.form.value, dueDate },
        highlight: this.upsert.highlight,
        clear: this.upsert.clear,
      });
    }
  };
  cancel() {
    this.form.markAsUntouched();
    // this.upsert.clickDoc(() => {
    this.expanded = false;
    // });
  }

  ngOnInit(): void {
    this.upsert = new ChecklistItemUpsertBehavior(this.fb, this.customerEvent);
    if (this.forcePayment) {
      this.upsert.typeChanged(true);
    }
    this.form = this.upsert.form;
  }
}
