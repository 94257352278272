import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  EffectNotification,
  ofType
} from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import {
  firestoreRealtime,
  firestoreRealtimeNoSyncing
} from '@skylitup/base/ngrx-data-fire';
import { genericCatch$$ } from '@skylitup/base/util';
import { combineLatest, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  map,

  switchMap
} from 'rxjs/operators';
import { NotificationService } from '../../../util/services/notification.service';
import { AGENCY_KEYS } from '../../agency/agency.model.data';
import { AgencyService } from '../../agency/agency.service';
import { CustomerEventService } from '../../customer-event/customer-event.service';
import { CUSTOMER_KEYS } from '../../customer/customer.model.data';
import { CustomerService } from '../../customer/customer.service';
import { DOC_KEYS } from './doc.model.data';
import { DocService } from './doc.service';

@Injectable({ providedIn: 'root' })
export class DocEffects {
  docs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      switchMap((_) =>
        combineLatest([
          this.agencyService.entityIdInContext$,
          this.customerService.entityIdInContext$,
          this.customerEventService.entityIdInContext$,
        ])
      ),
      distinctUntilChanged(null, (a) => a.join('')),
      switchMap((a) =>
        of(a).pipe(
          switchMap(([agencyId, customerId, customerEventId]) =>
            agencyId && customerId && customerEventId
              ? this.docService
                .syncEventDocs$(agencyId, customerId, customerEventId)
                .pipe(
                  map((_) =>
                    firestoreRealtime(
                      `${AGENCY_KEYS.plural}/${agencyId}/${CUSTOMER_KEYS.plural}/${customerId}/${DOC_KEYS.plural}`
                    )
                  )
                )
              : of(firestoreRealtimeNoSyncing('doc'))
          ),
          genericCatch$$('DocEffects - customerContext')
        )
      )
    )
  );


  constructor(
    private actions$: Actions,
    private agencyService: AgencyService,
    private customerService: CustomerService,
    private customerEventService: CustomerEventService,
    private docService: DocService,
    private notifications: NotificationService
  ) { }

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.notifications.trackCompletion(resolvedEffects$);
  }
}
