import { BaseComponent } from '@skylitup/base/util';

import { Observable } from 'rxjs';

import { Store, createSelector } from '@ngrx/store';

import { AfterViewInit, Directive } from '@angular/core';
import {
  CustomerEvent,
  ContextService,
  AppState,
  Category,
  uiFocusCat,
} from '@skylitup/flowergirl/core';
@Directive()
export abstract class OverviewBaseComponent extends BaseComponent
  implements AfterViewInit {
  customerEvent$: Observable<CustomerEvent>;
  focusCat$: Observable<string>;
  viewInitialized = false;
  constructor(context: ContextService, protected store: Store) {
    super();
    this.customerEvent$ = context.customerEvent$;
    this.focusCat$ = this.store.select(
      createSelector(
        (s: AppState) => s.ui,
        (s) => s.focusCat
      )
    );
  }
  ngAfterViewInit(): void {
    this.viewInitialized = true;
  }

  focusedOnCat(cat: Category) {
    if (this.viewInitialized) {
      this.store.dispatch(uiFocusCat({ cat: cat.id }));
    }
  }
  trackByIdFn(i, o) {
    return o.id;
  }
}
