import { EntityProjectedBase } from '@skylitup/base/ngrx-data-fire';
import { Dictionary } from '@ngrx/entity';
import { LocData } from './agency.model.data';



export interface LocStore {
  locations: Loc[]; // ALL THE LOCATIONS FLAT
  locationMap: Dictionary<Loc>; // ALL THE LOCATIONS DICTIONARY
  locationTree: Loc[]; // THE ROOT LOCATIONS only (the descendants are connected tree-fashion)
}
export class Loc extends EntityProjectedBase<LocData> {
  parent: Loc = null;
  children: Loc[] = [];
  isRoot = false;
  root: Loc = null;
  descendants: Loc[] = [];
  ancestors: Loc[] = [];
  get depth(): number {
    let r = 0;
    let p = this.parent;
    while (p) {
      r++;
      p = p.parent;
      if (r === 20) {
        throw Error('more than 20 depth not suppoted');
      }
    }
    return r;
  }
  toString() {
    return this.name;
  }
  hasChildren() {
    return !!this.children.length;
  }
  clone(): Loc {
    return new Loc(this.data).copy(this);
  }
  copy(from: Loc): Loc {
    this.parent = from.parent;
    this.children = [...from.children];
    this.descendants = [...from.descendants];
    this.root = from.root;
    return this;
  }
  get url(): string {
    return '#';
  }
}
