import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgxGalleryAnimation,
  NgxGalleryImage,
  NgxGalleryOptions,
} from '@kolkov/ngx-gallery';
import { Store } from '@ngrx/store';
import { excerpt, WindowService } from '@skylitup/base/util';
import {
  Auth,
  ChatService,
  ChatTrack,
  ChatTrackService,
  ContextService,
  createUiFocusChat,
  doHeartBeat,
  EventItem,
  EventItemService,
  Media,
  MediaLibraryService,
  uiFocusCat,
  uiFocusChat,
  uiMediaTabChanged,
} from '@skylitup/flowergirl/core';
import { EventItemDetailsBaseComponent } from '@skylitup/flowergirl/features/overview';
import { MediaPopupContainerComponent } from '@skylitup/flowergirl/xplat/web/features/media';
import { Observable } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { EventItemSpecComponent } from './event-item-spec/event-item-spec.component';
export enum KEY_CODE {
  RIGHT_ARROW = 'ArrowRight',
  LEFT_ARROW = 'ArrowLeft'
}
@Component({
  selector: 'flrgl-event-item-details',
  templateUrl: 'event-item-details.component.html',
  styleUrls: ['event-item-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventItemDetailsComponent extends EventItemDetailsBaseComponent
  implements OnInit {
  auth$: Observable<Auth>;
  eventItem$: Observable<EventItem>;
  chatTrack$: Observable<ChatTrack>;
  docsNum$: Observable<number>;
  noNav: boolean;

  galleryOptions: NgxGalleryOptions[] = [
    {
      image: false,
      thumbnailsColumns: 5,
      arrowPrevIcon: 'fa fa-chevron-left',
      arrowNextIcon: 'fa fa-chevron-right',
      imageAnimation: NgxGalleryAnimation.Slide,
      width: '800px',
      height: '100px',
      preview: true,
      previewCloseOnClick: true,
      imagePercent: 40,
      thumbnailsMargin: 0,
      thumbnailMargin: 4,
    },
  ];

  constructor(
    private context: ContextService,
    private chatService: ChatService,
    private dialog: MatDialog,
    private eventItemService: EventItemService,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    public windowService: WindowService,
    chatTrackService: ChatTrackService,
    private mediaLibraryService: MediaLibraryService

  ) {
    super();
    this.auth$ = context.auth$;
    this.eventItem$ = context.eventItem$;
    this.eventItem$
      .pipe(
        filter(
          (o) =>
            !!o &&
            !!o.vendorCat &&
            (!!o.vendorCat.vendor || o.vendorCat.vendorId === 'void')
        ),
        first()
      )
      .subscribe((eventItem) =>
        this.store.dispatch(createUiFocusChat(eventItem))
      );
    this.chatTrack$ = chatTrackService.chatTrackInContext$;
    this.docsNum$ = this.mediaLibraryService.docsInItemScope$.pipe(map(l => l?.length));

  }
  navigateToMedia(docs: boolean) {
    this.store.dispatch(uiMediaTabChanged({ index: docs ? 1 : 0 }));
    this.router.navigate(['media'], {
      relativeTo: this.route,
    });
  }
  openMediaSelect() {
    this.noNav = true;
    this.context.eventItem$
      .pipe(
        filter((o) => !!o),
        first()
      )
      .subscribe((eventItem) =>
        this.dialog
          .open(MediaPopupContainerComponent, {
            data: eventItem.specMedia,
            height: '80vh',
            width: '80vw',
            disableClose: false,
            autoFocus: false,
          })
          .afterClosed()
          .subscribe((selectedMedia: Media[]) => {
            this.noNav = false;

            if (selectedMedia) {
              const data = eventItem.data;

              const specMediaIds = selectedMedia.map((m) => m.id);
              this.eventItemService
                .update({ ...data, specMediaIds })
                .subscribe();
            }
          })
      );
  }
  toggleDecided(decided: boolean, item: EventItem) {
    this.eventItemService
      .update({ ...item.data, done: decided, doneDate: new Date() })
      .pipe(first())
      .subscribe();
  }
  excerpt(text: string) {
    return excerpt(text, 800);
  }
  specHasMore(eventItem: EventItem) {
    return eventItem?.spec?.length >= 800;
  }
  showSpec(item: EventItem, auth: Auth) {
    this.noNav = true;

    this.store.dispatch(doHeartBeat());
    this.dialog
      .open(EventItemSpecComponent, {
        data: { item, auth },
        disableClose: false,
      })
      .afterClosed()
      .subscribe((d) => {
        this.store.dispatch(doHeartBeat());
        if (d) {
          this.eventItemService.update(d);
        }
        this.noNav = false;
      });
  }

  ngOnInit(): void {
    this.context.eventItem$.pipe(
      takeUntil(this.destroy$),
      filter(a => !!a))
      .subscribe(focusItem =>
        this.store.dispatch(createUiFocusChat(focusItem))
      )
  }

  getCalculationNumber(item: EventItem) {
    return item?.calcNumber || 0;
  }
  getCalculationItemPrice(item: EventItem) {
    return item?.calcItemPrice || 0;
  }
  getCalculationLabel(item: EventItem) {
    if (item?.calcType === 'adults') {
      return 'Adults';
    } else if (item?.calcType === 'children') {
      return 'Children';
    } else if (item?.calcType === 'guests') {
      return 'Guests';
    } else {
      return '';
    }
  }
  toOverview() {
    this.context.contextPath$.pipe(first()).subscribe((path) => {
      this.router.navigate(path);
    });
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (!this.noNav) {
      if (event.key === KEY_CODE.RIGHT_ARROW) {
        this.toNextItem();
      }

      if (event.key === KEY_CODE.LEFT_ARROW) {
        this.toPreviousItem();
      }
    }
  }

  debug1(e) {
    console.log('eee', e);
  }

  toNextItem() {
    this.context.eventItem$.pipe(first()).subscribe((item) => {
      const nextItem = item.nextItem;
      if (nextItem) {
        this.router.navigateByUrl(nextItem.url);
      }
    });
  }

  toPreviousItem() {
    this.context.eventItem$.pipe(first()).subscribe((item) => {
      const previousItem = item.previousItem;
      if (previousItem) {
        this.router.navigateByUrl(previousItem.url);
      }
    });
  }


  doHeartBeat() {
    this.store.dispatch(doHeartBeat());
  }

  ____to_revisit____getGalleryImages(mmm: Media[]): NgxGalleryImage[] {
    const r = [
      ...mmm.map(
        (m) =>
          new NgxGalleryImage({
            small: m.data.urlFull,
            medium: m.data.urlFull,
            big: m.data.urlFull,
            type: 'image',
          })
      ),
      ...[
        {
          small: 'https://preview.ibb.co/jrsA6R/img12.jpg',
          medium: 'https://preview.ibb.co/jrsA6R/img12.jpg',
          big: 'https://preview.ibb.co/jrsA6R/img12.jpg',
        },
        {
          small: 'https://preview.ibb.co/kPE1D6/clouds.jpg',
          medium: 'https://preview.ibb.co/kPE1D6/clouds.jpg',
          big: 'https://preview.ibb.co/kPE1D6/clouds.jpg',
        },
      ],
    ];
    return r;
  }
}
