import { Dictionary } from '@ngrx/entity';
import { dateString, dateStringLong, entityDate, EntityProjected, TIMEZONE_LONDON } from '@skylitup/base/ngrx-data-fire';
import { Agency } from '../agency/agency.model';
import { Dialog, HasDialog } from '../chat/chat.model';
import { ChecklistVendorCat } from '../checklist-item/checklist-cat.model';
import { ChecklistItem } from '../checklist-item/checklist-item.model';
import { ChecklistMonth } from '../checklist-item/checklist-month.model';
import { Customer } from '../customer/customer.model';
import { EventInfo, HasTimezone } from '../customer/customer.model.data';
import { EventItem } from '../event-item/event-item.model';
import { Category } from '../system/system.model';
import { Vendor } from '../vendor/vendor.model';
import {
  CandiDate, CEREMONY_TYPE_OPTIONS, CommissionData, CustomerEventData,

  EVENT_TYPE_OPTIONS,

  VendorResearchItemDefData
} from './customer-event.model.data';
import { EventCategory, VendorCat } from './vendor-cat.model';

export class CustomerEvent extends EntityProjected<CustomerEventData>
  implements HasDialog, HasTimezone {
  agency!: Agency;
  customer!: Customer;
  get timezone() {
    return this.data?.timezone || TIMEZONE_LONDON;
  }
  get agencyId(): string {
    return this.data.agencyId || this.agency?.id;
  }
  get customerId(): string {
    return this.data?.meta?.collectionPath?.[3] || '';
  }

  get editUrl() {
    return CustomerEvent.getEditUrl(this.data);
  }
  get isDateBooked() {
    return (
      this.data.isDateBooked === undefined ||
      this.data.isDateBooked === null ||
      this.data.isDateBooked
    );
  }

  get adultGuests() {
    return this.data?.adultGuests || 0;
  }
  get childrenGuests() {
    return this.data?.childrenGuests || 0;
  }
  get guests() {
    return this.adultGuests + this.childrenGuests;
  }

  get totalActualBudgetIncludingExtra() {
    return this.totalActualBudget + this.totalExtra;
  }
  get event() {
    return this;
  }

  get hideVrMap() {
    return this.data.hideVrMap || {};
  }

  _vendorCatsbyCat: Dictionary<VendorCat[]> = {};
  _vendorCatMapbyCatByVendor: Dictionary<Dictionary<VendorCat>> = {};
  _allVendorCats: Dictionary<VendorCat> = {};
  // _allVendorCatsWithVendor: Dictionary<VendorCat> = {};
  categories: EventCategory[] = [];
  categoryMap: Dictionary<EventCategory> = {};
  checklistCats: ChecklistVendorCat[] = [];
  checklistCatMap: Dictionary<ChecklistVendorCat> = {};
  checklistMonths: ChecklistMonth[] = [];
  checklistMonthMap: Dictionary<ChecklistMonth> = {};
  checklistItems: ChecklistItem[] = [];
  itemMap: Dictionary<EventItem> = {};
  items: EventItem[] = [];
  totalActualBudget = 0;
  totalGoalBudget = 0;
  totalPaymentPending = 0;
  totalPaid = 0;
  totalExtra = 0;

  highlightedChecklistItem!: ChecklistItem;
  catBudgetMap: Dictionary<number> = {};
  catPaidMap: Dictionary<number> = {};
  catPaymentPendingMap: Dictionary<number> = {};
  catExtraCostMap: Dictionary<number> = {};

  vendorBudgetMap: Dictionary<number> = {};
  vendorPaidMap: Dictionary<number> = {};

  dialog: Dialog;

  info: EventInfoDescribed = new EventInfoDescribed(this.data, this.id, this);

  constructor(public data: CustomerEventData) {
    super(data);
    // this.info = ;
    this.dialog = new Dialog(this, 'general');
  }
  static getEditUrl(customerEventData: CustomerEventData) {
    const r = [...customerEventData.meta.urlPath, customerEventData.meta.id];
    r.splice(2, 0, 'edit');
    return '/' + r.join('/');
  }
  static getEditEventIdUrl(customerEventData: CustomerEventData) {
    const r = [...customerEventData.meta.urlPath, customerEventData.meta.id];
    r.splice(2, 0, 'edit');
    r.push('event-id');
    return '/' + r.join('/');
  }
  getCandiDate(id: string) {
    return this.info.getCandiDate(id);
  }
  clone(): CustomerEvent {
    return new CustomerEvent(this.data).copy(this);
  }
  copy(from: CustomerEvent): CustomerEvent {
    this._vendorCatsbyCat = { ...from._vendorCatsbyCat };
    this._vendorCatMapbyCatByVendor = { ...from._vendorCatMapbyCatByVendor };
    this.categories = [...from.categories];
    this.categoryMap = { ...from.categoryMap };
    this.customer = from.customer;
    this.checklistItems = from.checklistItems;
    this.checklistCatMap = { ...from.checklistCatMap };
    this.checklistCats = [...from.checklistCats];
    this.checklistMonths = [...from.checklistMonths];
    this.checklistMonthMap = { ...from.checklistMonthMap };
    this.highlightedChecklistItem = from.highlightedChecklistItem;
    this.totalActualBudget = from.totalActualBudget;
    this.totalGoalBudget = from.totalGoalBudget;
    this.catBudgetMap = { ...from.catBudgetMap };
    this.vendorBudgetMap = { ...from.vendorBudgetMap };
    this.vendorPaidMap = { ...from.vendorPaidMap };
    this.catPaidMap = { ...from.catPaidMap };
    this.catPaymentPendingMap = { ...from.catPaymentPendingMap };
    this.catExtraCostMap = { ...from.catExtraCostMap };
    this.totalPaymentPending = from.totalPaymentPending;
    this.totalPaid = from.totalPaid;
    this.totalExtra = from.totalExtra;
    this.dialog = from.dialog;
    this.itemMap = { ...from.itemMap };
    this.items = [...from.items];
    this._allVendorCats = { ...from._allVendorCats };
    this.agency = from.agency;
    return this;
  }
  get allVendorCats() {
    return Object.values(this._allVendorCats);
  }
  get allVendors() {
    return this.allVendorCats.filter((v) => v.hasVendor).map((v) => v.vendor);
  }
  isUseDatesInResearch(researchCat: Category): boolean {
    const result = this.data?.research?.[researchCat.id]?.useDates;
    return result === undefined ? true : result;
  }
  clearBudgets() {
    this.catBudgetMap = {};
    this.vendorBudgetMap = {};
    this.totalActualBudget = 0;
    this.allVendorCats.forEach((v) => {
      v.money.budget = 0;
    });
  }
  markVendorCatsDone() {
    for (const v of this.categories) {
      v.done = true;
    }
  }
  getCatGoalBudget(catId: string) {
    return Math.round((this.data?.catGoalBudgets?.[catId] || 0) * 100) / 100;
  }
  getCatBudget(catId: string) {
    const c =
      Math.round(
        ((this.catBudgetMap[catId] || 0) + this.getCatExtraCost(catId)) * 100
      ) / 100;
    return c;
  }

  setCatBudget(catId: string, budget: number) {
    this.catBudgetMap[catId] = budget;
  }

  getCommissionCalcType(vendorId: string): CommissionData['type'] {
    return this.data.commissions?.[vendorId]?.type || 'rate';
  }

  getCommissionRate(vendorId: string): number {
    return this.data.commissions?.[vendorId]?.rate ?? 0;
  }

  getCommissionAmount(vendorId: string): number {
    return this.getCommissionCalcType(vendorId) === 'amount'
      ? Math.round((this.data.commissions?.[vendorId]?.amount || 0) * 100) / 100
      : Math.round(
        this.getCommissionRate(vendorId) *
        (this.vendorBudgetMap[vendorId] || 0)
      ) / 100;
  }

  getCommissionPaid(vendorId: string): number {
    return (
      Math.round(
        ((this.vendorPaidMap[vendorId] || 0) +
          (this.data.commissions?.[vendorId]?.paymentsReceived || 0)) *
        100
      ) / 100
    );
  }
  getCommissionData(vendorId: string): CommissionData {
    return (
      this.data.commissions?.[vendorId] || {
        type: 'rate',
        rate: 0,
        amount: 0,
        paymentsReceived: 0,
      }
    );
  }

  getCatPaid(catId: string) {
    return this.catPaidMap[catId] || 0;
  }
  setCatPaid(catId: string, paid: number) {
    this.catPaidMap[catId] = paid;
  }
  getCatPaymentPending(catId: string) {
    return this.catPaymentPendingMap[catId] || 0;
  }

  getCatPaymentsTotal(catId: string) {
    return this.getCatPaymentPending(catId) + this.getCatPaid(catId);
  }

  setPaymentPending(catId: string, paid: number) {
    this.catPaymentPendingMap[catId] = paid;
  }
  getCatExtraCost(catId: string) {
    return Math.round((this.catExtraCostMap[catId] || 0) * 100) / 100;
  }
  setCatExtraCost(catId: string, paid: number) {
    this.catExtraCostMap[catId] = paid;
  }
  pushVendorCat(c: VendorCat) {
    if (!this._vendorCatsbyCat[c.id]) {
      this._vendorCatsbyCat[c.id] = [];
    }

    if (!this._vendorCatMapbyCatByVendor[c.id]) {
      this._vendorCatMapbyCatByVendor[c.id] = {};
    }
    this._vendorCatsbyCat[c.id].push(c);
    this._vendorCatMapbyCatByVendor[c.id][c.vendorId] = c;
    this._allVendorCats[c.vendorId + c.id] = c;
  }

  public getVendorCats(catId: string): VendorCat[] {
    return this._vendorCatsbyCat[catId || 'void'] || [];
  }

  public getVendors(catId: string): Vendor[] {
    return this.getVendorCats(catId)
      .filter((vc) => !!vc.vendor)
      .map((vc) => vc.vendor);
  }
  public getVendorCatMap(catId: string): Dictionary<VendorCat> {
    return this._vendorCatMapbyCatByVendor[catId || 'void'] || {};
  }
  getVendorCatsByVendor(vendorId: string) {
    throw Error('not implemented yet');
  }
  getVendorCat(catId: string, vendorId: string): VendorCat {
    if (!catId) {
      throw Error('catId passed was empty');
    }
    const vId = vendorId || 'void';
    return this._vendorCatMapbyCatByVendor[catId]
      ? this._vendorCatMapbyCatByVendor[catId][vId]
      : null;
  }
  clearVendorCats() {
    this._vendorCatsbyCat = {};
    this._vendorCatMapbyCatByVendor = {};
    this._allVendorCats = {};
    // this._allVendorCatsWithVendor = {};
    this.catBudgetMap = {};
  }
  clearItems(): void {
    this.items = [];
    this.itemMap = {};
    for (const v of this.allVendorCats) {
      v.items = [];
    }
  }

  getHighlightedChecklistItem(catId: string) {
    const result = this.checklistCatMap?.[catId]?.highlightedChecklistItem;
    if (!result?.internal) {
      return result;
    }
    return null;
  }

  getResearchVersions(catId): VendorResearchItemDefData[] {
    return this.data.research?.[catId]?.versions || [];
  }

  getResearchPriceItems(catId): VendorResearchItemDefData[] {
    return this.data.research?.[catId]?.priceItems || [];
  }

  get summaryText() {

    const eventName = `${this.customer?.name} ${this.name}`;
    const datesLine = this.isDateBooked ? `Date: ${this.info.bookedCandiDate.description}` : `Possible Dates: ${this.info.candiDates.map(c => c.description).join(' | ')}`
    const typeLine = `${EVENT_TYPE_OPTIONS[this.data.eventType] || ""}`;
    const guestsLine = this.guests ? `Guests ${this.guests}` : ''
    const ceremonySnippet = (!!this.data.ceremonyType && this.data.eventType === 'wed_religious') ? `(${CEREMONY_TYPE_OPTIONS[this.data.ceremonyType] || ""})` : "";
    let vendorLines = '';
    for (const cat of this.categories) {
      const vcMap = this.getVendorCatMap(cat.id);
      const vendorNames = Object.values(vcMap).filter(vc => !!vc.vendor).map(vc => `${vc.name} ${vc.vendor.instagramOrWebText}`).join(", ");
      if (vendorNames) {
        vendorLines += `- ${cat.name}: ${vendorNames} \r\n`;
      }
    }
    const notesSection = this.data.notes ? `----- Notes -----\r\n${this.data.notes}` : '';

    return `----- Info -----
${eventName}
${typeLine} ${ceremonySnippet}
${datesLine}
${guestsLine}

----- Vendors -----
${vendorLines}
${notesSection}
`;


  }


}

export class CandidateDescribed {

  get id(): string {
    return this.data.id;
  }
  get dateFrom(): Date {
    return this.data.dateFrom ? entityDate(this.data?.dateFrom) : null;
  }
  get date(): Date {
    return this.data.date ? entityDate(this.data?.date) : null;
  }

  get earliestDate(): Date {
    return this.dateFrom || this.date;
  }

  get description(): string {
    if (this.date && this.dateFrom && this.hasTimezone?.timezone) {
      return `${dateString(this.dateFrom, this.hasTimezone?.timezone)} - ${dateString(this.date, this.hasTimezone?.timezone)}`
    } else if (this.date && this.hasTimezone?.timezone) {
      return dateStringLong(this.date, this.hasTimezone?.timezone);
    } else {
      console.log('xxxxx', this.date, this.dateFrom, this.hasTimezone?.timezone);
      return "";
    }
  }

  constructor(public data: CandiDate, private hasTimezone: HasTimezone) {

  }
}

export class EventInfoDescribed {
  get name(): string {
    return this.data.name;
  }
  get isDateBooked(): boolean {
    return this.data.isDateBooked;
  }
  get bookedCandiDateId(): string | null {
    return this.data.bookedCandiDateId;
  }

  readonly candiDates: CandidateDescribed[];
  readonly earliestCandiDate: CandidateDescribed;
  readonly latestCandiDate: CandidateDescribed;
  readonly bookedCandiDate: CandidateDescribed;
  readonly bookedDate: Date;

  get earliestDate(): Date {
    return (this.isDateBooked ? this.bookedCandiDate?.date : (this.earliestCandiDate?.dateFrom || this.earliestCandiDate?.date)) || new Date();
  }
  get latestDate(): Date {
    return (this.isDateBooked ? this.bookedCandiDate?.date : this.latestCandiDate?.date) || new Date();
  }
  get earliestDateDisplay(): string {
    // console.log('907070', this.isDateBooked, this.bookedCandiDate, this.earliestCandiDate);
    return this.isDateBooked ? this.bookedCandiDate?.description : this.earliestCandiDate?.description;
  }

  constructor(public data: EventInfo, public id: string, hasTimezone: HasTimezone) {
    this.candiDates = (this.data.candiDates || []).map(c => new CandidateDescribed(c, hasTimezone));
    this.candiDates.sort(
      (d1, d2) => entityDate(d1.dateFrom || d1.date).getTime() - entityDate(d2.dateFrom || d2.date).getTime()
    );
    this.earliestCandiDate = this.candiDates.length ? this.candiDates[0] : null;
    this.latestCandiDate = this.candiDates.length ? this.candiDates[this.candiDates.length - 1] : null;
    this.bookedCandiDate = this.candiDates.find(c => c.id === this.bookedCandiDateId) || null;
    this.bookedDate = this.bookedCandiDate?.date || null;
  }

  getCandiDate(id: string) {
    return this.candiDates.find(c => c.id === id);
  }


}