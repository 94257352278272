import { Dictionary } from '@ngrx/entity';
import { EntityKeys } from '@skylitup/base/ngrx-data-fire';
export const MAIL_KEYS: EntityKeys = {
  name: 'mail',
  plural: 'mails'
};

export interface Mail {
  to?: string | string[];
  toUids?: string | string[];
  cc?: string | string[];
  ccUids?: string | string[];
  bcc?: string | string[];
  bccUids?: string | string[];
  headers?: Dictionary<string>;
  from?: string;
  replyTo?: string;

  message: {
    subject: string;
    text?: string;
    html?: string;
    messageId?: string;
    amp?: any;
    attachments?: any;
  };
  template?: any; // may set it up in the future to use handlebars templates
  delivery?: {
    // updated automatically by the cloud function
    state: 'PENDING' | 'PROCESSING' | 'SUCCESS' | 'ERROR';
    startTime: Date;
    endTime: Date;
    error: string;
    attempts: number;
    info: any;
    leaseExpireTime: any;
  };
}
