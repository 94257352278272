<nav mat-tab-nav-bar animationDuration="0ms" (selectedTabChange)="tabChanged($event)">
    <a mat-tab-link label="Overview" [routerLink]="['overview']" routerLinkActive #rlaOverview="routerLinkActive"
        class="overview1" [active]="rlaOverview.isActive">
        <mat-icon svgIcon="magic-book1"></mat-icon> <span class="text1">Overview </span>
    </a>
    <a mat-tab-link label="Budget" [routerLink]="['budget']" routerLinkActive #rlaBudget="routerLinkActive"
        class="budget1" [active]="rlaBudget.isActive">
        <mat-icon svgIcon="piggy-bank2"></mat-icon><span class="text1">Budget</span>
    </a>
    <a mat-tab-link label="Plan" [routerLink]="['plan']" routerLinkActive #rlaPlan="routerLinkActive"
        [active]="rlaPlan.isActive">
        <mat-icon svgIcon="plan1"></mat-icon><span class="text1">Plan</span>
    </a>
    <a mat-tab-link label="Media" [routerLink]="['media']" routerLinkActive #rlaMedia="routerLinkActive"
        [active]="rlaMedia.isActive">
        <mat-icon class="media">perm_media</mat-icon><span class="text1">Media</span>
    </a>
    <ng-container *ngIf="customerEvent$ | async as customerEvent">
        <a mat-tab-link label="Chat" [routerLink]="['chat']" routerLinkActive #rlaChat="routerLinkActive"
            [ngClass]="{hasUnread: customerEvent.dialog.hasUnreadDescendants}" [active]="rlaChat.isActive">
            <!-- <mat-icon class="material-icons-outlined">forum</mat-icon>Chat -->
            <mat-icon svgIcon="chat1"></mat-icon><span class="text1" matBadgeSize='small' matBadgePosition="after"
                matBadge="!" matBadgeColor='warn' matBadgeOverlap='false'
                [matBadgeHidden]="!customerEvent.dialog.hasUnreadDescendants">Chat</span>
        </a>
    </ng-container>
</nav>
<div class="spacer"></div>
<router-outlet></router-outlet>

<!-- 
<a mat-tab-link *ngFor="let link of navLinks" [routerLink]="link.path" routerLinkActive #rla="routerLinkActive"
[active]="rla.isActive">
{{link.label}}
</a>
 -->

<!-- <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabChanged($event)" [selectedIndex]="mainTabIndex$ | async">
    <mat-tab label="Overview">
        <flrgl-overview></flrgl-overview>
    </mat-tab>
    <mat-tab label="Budget">
        <flrgl-budget></flrgl-budget>
    </mat-tab>
    <mat-tab label="Plan">
        <flrgl-checklist></flrgl-checklist>
    </mat-tab>
    <mat-tab label="Chat" disabled>Content 3</mat-tab>
</mat-tab-group> -->