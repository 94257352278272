import { Component, Input } from '@angular/core';
import { BudgetHeaderBaseComponent } from '@skylitup/flowergirl/ui';

@Component({
  selector: 'flrgl-budget-header',
  templateUrl: 'budget-header.component.html',
  styleUrls: ['budget-header.component.scss'],
})
export class BudgetHeaderComponent extends BudgetHeaderBaseComponent {
  constructor() {
    super();
  }
  @Input()
  name: string;
  @Input()
  showNumbers = true;
  @Input()
  goalBudget: number;
  @Input()
  actualBudget: number;
  @Input()
  paymentsPaid: number;
  @Input()
  paymentsPending: number;
  @Input()
  withLabels = false;

  get paymentsTotal() {
    return this.paymentsPaid + this.paymentsPending;
  }
  static getCatActualColor(goalBudget, actualBudget) {
    if (goalBudget > actualBudget) {
      return 'goal-above';
    }
    if (goalBudget === actualBudget) {
      return 'goal-equal';
    }

    if (goalBudget < actualBudget) {
      return 'goal-below';
    }
  }

  getCatActualColor() {
    return BudgetHeaderComponent.getCatActualColor(
      this.goalBudget,
      this.actualBudget
    );
  }

  onFocus(input: any) {
    input.select();
  }
  digits2(a: number) {
    return Math.round(a * 100) / 100;
  }
}
