import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
 import { Store } from '@ngrx/store';
import { AgencyBaseComponent } from '@skylitup/flowergirl/features/navigate';
import { AgencyService } from '@skylitup/flowergirl/core';
 
@Component({
  selector: 'flrgl-agency',
  templateUrl: 'agency.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgencyComponent extends AgencyBaseComponent {
  constructor(agencyService: AgencyService, private store: Store) {
    super(agencyService);
    // this.store.select(selectAppContext).subscribe(o => console.log(o));
  }
}
