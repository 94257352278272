import { ofType } from "@ngrx/effects";
import {
  RouterNavigatedAction,
  routerNavigatedAction,
} from "@ngrx/router-store";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { distinctUntilChanged, map, mergeMap, switchMap } from "rxjs/operators";
import { RouterStateUrl } from "./router-serializer";

// deprecated:
// alternative approach could be to use mergeMap.takeOne of the entityInContext$
// where all this is alredy taken careof

const entityInContextDeprecated$ = (entityName: string) => (
  o: Observable<RouterNavigatedAction>
): Observable<string> => {
  return o.pipe(
    map((a) => (<any>a.payload.routerState) as RouterStateUrl),
    map(({ paramsMapWithoutScope: { [entityName]: id } }) => id),
    distinctUntilChanged()
  );
};
