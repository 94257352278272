import { Component } from '@angular/core';
import { ChatMessageBaseComponent } from '@skylitup/flowergirl/features/chat';

@Component({
  selector: 'flrgl-chat-message',
  templateUrl: 'chat-message.component.html',
})
export class ChatMessageComponent extends ChatMessageBaseComponent {
  constructor() {
    super();
  }
}
