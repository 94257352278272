import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceElementsFactory,
  EntityDataService
} from '@ngrx/data';
import { Dictionary } from '@ngrx/entity';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Observable } from 'rxjs';
import { System, SystemData, SYSTEM_KEYS, Category } from './system.model';
import { filter } from 'rxjs/operators';
import {
  EntityBaseSelectors,
  EntityBaseService
} from '@skylitup/base/ngrx-data-fire';
import {
  projectList,
  projectDictionary
} from '@skylitup/base/ngrx-data-fire';
import { selectAppContext } from '@skylitup/base/util';
 
interface SelectorsProjected extends EntityBaseSelectors<SystemData, System> {
  selectCategories: MemoizedSelector<any, Category[]>;
  selectCategoryMap: MemoizedSelector<any, Dictionary<Category>>;
  selectCategoryIdInContext: MemoizedSelector<any, string>;
  selectCategoryInContext: MemoizedSelector<any, Category>;
}

@Injectable({ providedIn: 'root' })
export class SystemService extends EntityBaseService<SystemData, System> {
  selectorsProjected: SelectorsProjected;
  categoryMap$: Observable<Dictionary<Category>>;
  categories$: Observable<Category[]>;
  categoryInContext$: Observable<Category>;
  categoryIdInContext$: Observable<string>;

  constructor(
    entityServiceFactory: EntityCollectionServiceElementsFactory,
    entityDataServices: EntityDataService
  ) {
    super(SYSTEM_KEYS, entityServiceFactory, entityDataServices);
    this.init();
  }

  setupRxSelectors() {
    const projectCatsData = createSelector(this._selectEntityMap, dd =>
      !!dd && !!dd.data && !!dd.data.data.cats ? dd.data.data.cats : []
    );

    const selectCategories = createSelector(
      projectCatsData,
      projectList(c => new Category(c))
    );
    const selectCategoryMap = createSelector(
      selectCategories,
      projectDictionary
    );
    const selectCategoryIdInContext = createSelector(
      selectAppContext,
      context => context['catId']
    );
    const selectCategoryInContext = createSelector(
      selectCategoryIdInContext,
      selectCategoryMap,
      (id, categoryMap) => categoryMap[id]
    );

    Object.assign(this.selectorsProjected, {
      selectCategories,
      selectCategoryMap,
      selectCategoryIdInContext,
      selectCategoryInContext
    });
    return this._selectPlainEntityTuple;
  }
  protected setupRx$(): void {
    //
    this.categories$ = this.store
      .select(this.selectorsProjected.selectCategories)
      .pipe(filter(o => !!o));
    this.categoryMap$ = this.store
      .select(this.selectorsProjected.selectCategoryMap)
      .pipe(filter(o => !!o));
    this.categoryIdInContext$ = this.store.select(
      this.selectorsProjected.selectCategoryIdInContext
    );
    this.categoryInContext$ = this.store.select(
      this.selectorsProjected.selectCategoryInContext
    );
  }

  protected newProjectedEntity(d: SystemData) {
    return new System(d);
  }
}
