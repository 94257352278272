import { BaseComponent } from '@skylitup/base/util';

 
export abstract class ChecklistItemBaseComponent extends BaseComponent {
  public text = 'ChecklistItem';

  constructor() {
    super();
  }
}
