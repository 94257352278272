import { createAction, props } from '@ngrx/store';
import { UIState } from './ui.reducer';
import { HasDialog } from '../../chat/chat.model';
import { EventItem } from '../../event-item/event-item.model';
import { VendorCat } from '../../customer-event/vendor-cat.model';
import { VendorResearch } from '../../vendor-research/vendor-research.model';

export type THEME_NAME = 't-indigo' | 't-indigo-dark';
export const THEME_NAMES = [
  { code: 't-indigo', name: 'Indigo' },
  { code: 't-indigo-dark', name: 'Indigo Dark' },
];

export const uiFocusCat = createAction(
  '[UI - Focus Cat]',
  props<{ cat: string }>()
);
export const uiFocusVendor = createAction(
  '[UI - Focus Vendor]',
  props<{ vendor: string }>()
);
export const uiMainTabChanged = createAction(
  '[UI - Main Tab]',
  props<{ index: number }>()
);
export const uiChecklistTabChanged = createAction(
  '[UI - Checklist Tab]',
  props<{ index: number }>()
);
export const uiMediaTabChanged = createAction(
  '[UI - Media Tab]',
  props<{ index: number }>()
);
export const uiInit = createAction(
  '[UI - Init]',
  props<{ initState: UIState }>()
);

export const uiHideCompleted = createAction(
  '[UI - Hide Completed]',
  props<{ hide: boolean }>()
);
export const uiShowDemo = createAction(
  '[UI - Show Demo]',
  props<{ show: boolean }>()
);
export const uiCustomerListStyle = createAction(
  '[UI - Customer List Style]',
  props<{ style: UIState['uiCustomerListStyle'] }>()
);
export const uiSortUsersBy = createAction(
  '[UI - Manage Users Sort By]',
  props<{ sortType: UIState['uiSortUsersBy'] }>()
);
export const uiCustomerListType = createAction('[UI - Customer List Type]',
  props<{ listType: UIState['uiCustomerListType'] }>()
);
export const uiSetTheme = createAction(
  '[UI - THEME]',
  props<{ theme: THEME_NAME }>()
);
export const uiFocusChat = createAction(
  '[UI - Focus Chat]',
  props<{
    dialogId: string;
    focusCat?: string;
    focusVendor?: string;
    focusItem?: string;
  }>()
);
export const uiTrigger1 = createAction(
  '[UI - Trigger1]',
  props<{ trigger: string }>()
);
export const uiBigPlanAssignee = createAction(
  '[UI - BigPlan Assignee]',
  props<{ bigPlanAssignee: string }>()
);
export const uiBigPlanCustomer = createAction(
  '[UI - BigPlan Customer]',
  props<{ bigPlanCustomer: string }>()
);
export const uiBigPlanHideCompleted = createAction(
  '[UI - BigPlan HideCompleted]',
  props<{ bigPlanHideCompleted: boolean }>()
);
export const uiBigPlanExpandAllMonths = createAction(
  '[UI - BigPlan ExpandAllMonths]',
  props<{ bigPlanExpandAllMonths: boolean }>()
);
export const uiManageVendorsFilters = createAction(
  '[UI - Manage Vendors Filter]',
  props<{ vendors: { name: string, locations: string[], coverage: string[], cat: string } }>()
);

export const createUiFocusChat = (d: HasDialog) => {
  // console.log('bbbbbb', d);
  let focusCat = null;
  let focusVendor = null;
  let focusItem = null;
  if (d.dialog.type === 'category') {
    focusCat = d.id;
  } else if (d.dialog.type === 'vendorCat') {
    const vendorCat = d as VendorCat;
    focusCat = vendorCat.id;
    focusVendor = vendorCat.vendorId;
  } else if (d.dialog.type === 'eventItem') {
    const eventItem = d as EventItem;
    focusCat = eventItem.vendorCat.id;
    focusVendor = eventItem.vendorCat.vendorId;
    focusItem = eventItem.id;
  } else if (d.dialog.type === 'vendorResearch') {
    const vr = d as VendorResearch;
    focusCat = null;
    focusVendor = vr.data.vendorId;
    focusItem = null;
  }
  return uiFocusChat({
    dialogId: d.dialog.dialogId,
    focusCat,
    focusVendor,
    focusItem,
  });
};
export const allUiActionCreators = [
  uiFocusCat,
  uiMainTabChanged,
  uiChecklistTabChanged,
  uiHideCompleted,
  uiSetTheme,
  uiFocusChat,
  uiShowDemo,
  uiCustomerListStyle,
  uiCustomerListType,
  uiBigPlanAssignee,
  uiBigPlanCustomer,
  uiBigPlanHideCompleted,
  uiSortUsersBy,
  uiManageVendorsFilters
];
