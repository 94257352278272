import {
  EntityData,
  EntityKeys,
  Timestamp,
} from '@skylitup/base/ngrx-data-fire';
import { CustomerDataType } from '../customer/customer.model.data';

export const CHECKLIST_ITEM_KEYS: EntityKeys = {
  name: 'checklist-item',
  plural: 'checklist-items',
};
export type ChecklistItemType = 'checklist' | 'payment' |'eventItem';

export interface ChecklistItemData extends EntityData {
  readonly type: ChecklistItemType;
  name: string;
  agencyId: string;
  details?: string;
  catId: string;
  dueDate: Timestamp | Date;
  flagged: boolean;
  internal: boolean;
  done: boolean;
  doneDate: Timestamp | Date;
  assigneeId?: string;
  //

  amount: number;
  paymentMethod: 'cash' | 'bank';
  extraRate: number;
  vendorId: string;
  includesCommission: boolean;
  commissionAmount: number;
  customerType: CustomerDataType;
}
