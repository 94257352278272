<ng-container *ngIf="auth$ |async as auth">
    <ng-container *ngIf="eventItem$ | async as eventItem">
        <mat-accordion displayMode="flat">
            <mat-expansion-panel #specPanel [expanded]="true" (afterCollapse)="chatPanel.open();noNav=true;"
                (afterExpand)='doHeartBeat()'>
                <mat-expansion-panel-header collapsedHeight="70px" expandedHeight="20px" class="spec-header1"
                    *ngIf="!specPanel.expanded">
                    <mat-panel-title>
                        <ng-container *ngIf="!specPanel.expanded"> {{eventItem.vendorCat?.catName}}
                            <mat-icon> chevron_right </mat-icon>
                            {{eventItem.name}}</ng-container> specification
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div fxFlex="0 0 auto" class="outer"
                    [ngClass]="{'innerHeightIncreased':(windowService.innerHeightIncreased$|async)}" fxLayout="column">

                    <div class="header1" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
                        <div fxFlex="grow" class="title" fxLayoutAlign="start center" fxLayout="row">
                            <div fxFlex="grow">
                                {{eventItem.vendorCat?.catName}}
                                <mat-icon> chevron_right </mat-icon>
                                {{eventItem.name}}
                            </div>
                        </div>
                        <div class="budget m-x-10" *ngIf="eventItem.budget">
                            <ng-container>
                                <ng-container
                                    *ngIf="eventItem.calcType=='none';then noCalculationTmpl;else calculationTmpl">
                                </ng-container>
                            </ng-container>
                            <ng-template #noCalculationTmpl>
                                <mat-form-field appearance="outline" class="budget">
                                    <mat-label>Budget</mat-label>
                                    <input matInput class="right1"
                                        [ngModel]="eventItem.budget | currency:'EUR':'symbol'" readonly>
                                </mat-form-field>
                            </ng-template>
                            <ng-template #calculationTmpl>
                                <div fxLayout="row" fxLayoutGap="10px" class="calculation"
                                    fxLayoutAlign="center center">
                                    <mat-form-field appearance="outline" class="number1">
                                        <mat-label>{{getCalculationLabel(eventItem)}}</mat-label>
                                        <input matInput class="right1" [ngModel]="getCalculationNumber(eventItem)"
                                            readonly>
                                    </mat-form-field>
                                    <mat-icon class="times1" fxFlexAlign="center">clear</mat-icon>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Item Price</mat-label>
                                        <input matInput class="right1"
                                            [ngModel]="getCalculationItemPrice(eventItem) | currency:'EUR':'symbol'"
                                            readonly>
                                    </mat-form-field>
                                    <span fxFlexAlign="center" class="equals">=</span>
                                    <mat-form-field appearance="outline" class="budget budget1">
                                        <mat-label>Budget</mat-label>
                                        <input matInput class="right1"
                                            [ngModel]="eventItem.budget | currency:'EUR':'symbol'" readonly>
                                    </mat-form-field>
                                </div>
                            </ng-template>
                        </div>
                        <a class="nav-l up m-r-10" mat-icon-button (click)="toOverview()">
                            <mat-icon>arrow_upward</mat-icon>
                        </a>
                    </div>
                    <div class="spec-toggle" fxLayoutAlign="row">
                        <a class="nav-l m-l-4" mat-icon-button (click)="toPreviousItem()">
                            <mat-icon>arrow_back</mat-icon>
                        </a>

                        <div fxFlex="grow">
                            <div class="center1">
                                Specification
                                <ng-container *ngIf="!eventItem.done;else done1">
                                    <span class="draft">DRAFT</span>
                                </ng-container>
                                <ng-template #done1>
                                    <span class="check">
                                        <mat-icon>check</mat-icon>
                                    </span>
                                </ng-template>
                                <ng-container *ngIf="auth.isPlanner;else custDecided">
                                    <mat-slide-toggle (toggleChange)="toggleDecided(!eventItem.done,eventItem)"
                                        [checked]="eventItem.done">Decided
                                    </mat-slide-toggle>
                                </ng-container>
                                <ng-template #custDecided>
                                    <span *ngIf="eventItem.done">Decided</span>
                                </ng-template>
                            </div>
                        </div>

                        <a class="nav-l m-r-4" mat-icon-button (click)="toNextItem()">
                            <mat-icon>arrow_forward</mat-icon>
                        </a>
                    </div>


                    <div class="spec-content">
                        <div class="spec" [ngClass]="{'has-more':specHasMore(eventItem)}"
                            (click)="(auth.isPlanner || eventItem.spec) && showSpec(eventItem,auth)">
                            <ng-container *ngIf="eventItem.spec;else specMissing">
                                <ng-container *ngIf="eventItem.specMedia.length>0;else emptyGal1">
                                    <div [innerHTML]="eventItem.spec">
                                    </div>
                                    <button mat-stroked-button *ngIf="specHasMore(eventItem)">more</button>
                                    <div class="gradient1" *ngIf="specHasMore(eventItem)"></div>
                                </ng-container>
                                <ng-template #emptyGal1>
                                    <div [innerHTML]="eventItem.spec"></div>
                                </ng-template>
                            </ng-container>
                            <ng-template #specMissing>
                                <div fxLayout="column" fxLayoutAlign="start center" class="spec-missing">
                                    <button mat-button *auth1="['planner']">click to add description</button>
                                </div>
                            </ng-template>
                        </div>

                        <mat-divider class="m-y-2"></mat-divider>

                        <ng-container *ngIf="docsNum$ | async as docsNum">
                            <div class="docs-row m-t-4" fxLayout="row">

                                <!-- <div fxFlex="grow"></div> -->
                                <mat-divider [vertical]="true"></mat-divider>
                                <button mat-button class="media clickable1 underline1" (click)="navigateToMedia(true)"
                                    fxLayoutAlign="center center" fxFlex="none">
                                    <mat-icon>description</mat-icon>
                                    <span class="text1">Docs
                                        <span class="docs-number"> ({{docsNum}})</span>
                                    </span>
                                </button>
                                <mat-divider [vertical]="true"></mat-divider>

                            </div>
                            <mat-divider class="m-y-4"></mat-divider>
                        </ng-container>


                        <flrgl-event-item-spec-img [noEdit]="!auth.isPlanner" [eventItem]="eventItem"  
                            (lightboxStarted)="noNav=true;" (lightboxEnded)="noNav=false;"
                            (editSelection)="auth.isPlanner && openMediaSelect()">
                        </flrgl-event-item-spec-img>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel #chatPanel (afterCollapse)="specPanel.open();noNav=false;"
                (afterExpand)='doHeartBeat()'>
                <!-- <mat-divider fxFlex="none"></mat-divider> -->

                <mat-expansion-panel-header collapsedHeight="70px" expandedHeight="20px" *ngIf="!chatPanel.expanded">

                    <flrgl-chat-thumb [dialog]="eventItem.dialog" [inOverview]="true"
                        *ngIf="!chatPanel.expanded ;else title1"></flrgl-chat-thumb>
                    <ng-template #title1>
                        <!-- <mat-panel-title> Chat </mat-panel-title> -->
                    </ng-template>
                </mat-expansion-panel-header>
                <div class="outer" [ngClass]="{'innerHeightIncreased':(windowService.innerHeightIncreased$|async)}"
                    fxLayout="column">
                    <!-- <div fxFlex="1 1 100%" style="background-color: green;"> -->
                    <flrgl-chat-header [dialogObj]="eventItem" inItemDetails="true"></flrgl-chat-header>
                    <mat-divider></mat-divider>
                    <ng-container *ngIf="chatTrack$ | async as chatTrack">
                        <flrgl-chat-portal [dialog]="eventItem.dialog" [event]="eventItem.event" [auth]="auth"
                            [chatTrack]="chatTrack" [forceRead]="chatPanel.expanded" [canBeReadBy]="chatPanel.expanded"
                            fxFlex="grow">
                        </flrgl-chat-portal>
                    </ng-container>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>
</ng-container>