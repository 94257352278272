import { Observable } from 'rxjs';
import {
  Action,
  Store,
  createSelector,
  createFeatureSelector,
  select,
  MemoizedSelector,
} from '@ngrx/store';
import { Auth } from './auth.model';
import { AuthState } from './state/auth.reducer';
import { filter } from 'rxjs/operators';
import { UserCard } from '../user-card/user-card.model';
import { UserCardService } from '../user-card/user-card.service';

interface SelectorsProjected {
  selectAuth: MemoizedSelector<any, Auth>;
}

export abstract class AuthService {
  public auth$: Observable<Auth>;

  public isLoggedIn$: Observable<boolean>;
  public isCustomer$: Observable<boolean>;
  public isPlanner$: Observable<boolean>;
  public userId$: Observable<string>;
  public authResultEvents$: Observable<Action>;
  public displayName$: Observable<string>;
  public userCard$: Observable<UserCard>;

  public redirectUrl = null; // used for after login
  public selectUserId: MemoizedSelector<any, string>;
  public selectUserCard: MemoizedSelector<any, UserCard>;
  selectorsProjected: SelectorsProjected;

  //

  abstract login(email: string, password: string): Observable<string>;
  abstract logout(): Observable<boolean>;
  abstract sendSignInLinkToEmail(email: string): Observable<boolean>;
  abstract sendResetPasswordEmail(email: string): Observable<boolean>;

  abstract isSignInWithEmailLink(url: string): Observable<boolean>;
  abstract signInWithEmailLink(email: string, url: string): Observable<boolean>;
  abstract refreshToken(): void;
  abstract confirmPasswordReset(
    code: string,
    password: string
  ): Observable<string>;

  constructor(public store: Store, public userCardService: UserCardService) {
    const selectAuthState = createFeatureSelector<AuthState>('auth');
    const selectAuth = createSelector(selectAuthState, (s) => new Auth(s));
    this.selectorsProjected = { selectAuth };
    //=======
    this.selectUserId = createSelector(selectAuth, (a) => a.userId);
    this.selectUserCard = createSelector(
      this.selectUserId,
      this.userCardService.selectorsProjected.selectUserCardByAuthUIDMap,
      (uId, map1) => map1[uId]
    );
    const authWithUserCard = createSelector(selectAuth,this.selectUserCard,(auth,userCard)=>{
      if(auth && userCard){
        auth.userCard = userCard;
      }
      return auth;
    })


    this.auth$ = this.store.select(authWithUserCard);
    this.isLoggedIn$ = this.store
      .select(createSelector(selectAuth, (a) => a.isLoggedIn))
      .pipe(filter((o) => o != null));
    this.isCustomer$ = this.store.select(
      createSelector(selectAuth, (a) => a.isCustomer)
    );
    this.isPlanner$ = this.store.select(
      createSelector(selectAuth, (a) => a.isPlanner)
    );

    this.userId$ = this.store.select(this.selectUserId);
    this.displayName$ = this.store.select(
      createSelector(selectAuth, (a) => a.name)
    );
    this.userCard$ = this.store.select(this.selectUserCard);

    // this.currentUserCard$ = this.userCardService.
    // this.isLoggedIn$ = this.store.select(
    //   createSelector(
    //     selectAuth,
    //     s => s.loggedIn
    //   )
    // );
    // // this.isCustomer$ = this.store.select(
    //   createSelector(
    //     selectAuth,
    //     s => !!(s?.access?.customer?.length)
    //   )
    // ).pipe(distinctUntilChanged());
    // this.isPlanner$ = this.store.select(
    //   createSelector(
    //     selectAuth,
    //     s => !!(s?.access?.member?.length)
    //   )
    // ).pipe(distinctUntilChanged());
    // this.userId$ = this.store.select(
    //   createSelector(
    //     selectAuth,
    //     s => s.uid
    //   )
    // ).pipe(distinctUntilChanged());
  }
}
