import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CHAT_TRACK_KEYS } from '../chat-track/chat-track.model.data';
import { ChatTrackService } from '../chat-track/chat-track.service';
import { Customer } from '../customer/customer.model';
import {
    CUSTOMER_EVENT_KEYS
} from './customer-event.model.data';
import { CustomerEventService } from './customer-event.service';

@Injectable({ providedIn: 'root' })
export class CustomerEventEditService {
    constructor(
        private customerEventService: CustomerEventService,
        private chatTrackService: ChatTrackService
    ) { }

    createCustomerEvent(customer: Customer, name: string) {
        return this.customerEventService.upsert({
            meta: {
                collectionPath: [...customer._fullPath, CUSTOMER_EVENT_KEYS.plural],
            },
            name,
            eventType: null,
            ceremonyType: null,
            adultGuests: 0,
            childrenGuests: 0,
            vendors: { venue: ['void'] },
            catGoalBudgets: {},
            checklistNext: {},
            cats: ['venue'],
            _del: null,
            date: null,
            candiDates: [],
            isDateBooked: false,
            research: {},
            hideVrMap: {},
            customerType: customer.data.type,
            agencyId: customer.agency.id,
            timezone: customer.timezone,
        }).pipe(
            switchMap(ev => of(ev).pipe(
                switchMap(_ => this.chatTrackService.upsert({
                    meta: {
                        id: ev.meta.id,
                        collectionPath: [...customer._fullPath, CHAT_TRACK_KEYS.plural],
                    },
                    readAllBy: {}, customerType: customer.data.type, readBy: {}, totals: {}, sumTotals: 0, sumReadBy: {}, agencyId: ev.agencyId, _del: null, snapshotEmails: {}, pauseNotifications: false
                })),
                map(_ => ev))
            )
        );
    }

}
