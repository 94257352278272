import {
  CustomerEventData,
  CustomerEventIndexData
} from './customer-event.model.data';

function initCatsIndex(
  customerEvent: CustomerEventData,
  cats: string[]
): CustomerEventIndexData {
  const vendors = customerEvent.vendors ? { ...customerEvent.vendors } : {};

  const checklistNext = customerEvent.checklistNext
    ? { ...customerEvent.checklistNext }
    : {};
  for (const cat of cats) {
    vendors[cat] = vendors[cat] ? [...vendors[cat]] : ['void'];
  }
  return { cats, vendors, checklistNext };
}

export const indexChangeEventCategories = (
  customerEvent: CustomerEventData,
  catIds: string[]
) => {
  checkForDuplicates(catIds);
  const u: Partial<CustomerEventData> = {
    ...customerEvent,
    ...initCatsIndex(customerEvent, catIds)
  };
  return u;
};

export function indexChangeEventVendors(
  customerEvent: CustomerEventData,
  cat: string,
  vendorIds: string[]
) {
  checkForDuplicates(vendorIds);
  const { vendors } = initCatsIndex(customerEvent, [cat]);
  vendors[cat] = vendorIds;

  const u: Partial<CustomerEventData> = {
    ...customerEvent,
    vendors
  };

  return u;
}

export function indexChangeChecklistNextItem(
  customerEvent: CustomerEventData,
  cat: string,
  itemId: string
): Partial<CustomerEventData> {
  const { checklistNext } = initCatsIndex(customerEvent, [cat]);
  checklistNext[cat] = itemId;
  const u: Partial<CustomerEventData> = {
    ...customerEvent,
    checklistNext
  };
  return u;
}

function checkForDuplicates(a: string[]) {
  const d = {};
  a.forEach(i => {
    if (!d[i]) {
      d[i] = true;
    } else {
      throw Error(i + ' is duplicate');
    }
  });
}
