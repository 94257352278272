import { Action, createReducer, on } from '@ngrx/store';
import {
  uiFocusCat,
  uiMainTabChanged,
  uiInit,
  uiChecklistTabChanged,
  uiHideCompleted,
  uiSetTheme,
  THEME_NAME,
  uiFocusChat,
  uiFocusVendor,
  uiShowDemo,
  uiCustomerListStyle,
  uiBigPlanAssignee,
  uiBigPlanCustomer,
  uiBigPlanHideCompleted,
  uiBigPlanExpandAllMonths, uiCustomerListType, uiSortUsersBy, uiMediaTabChanged, uiManageVendorsFilters
} from './ui.actions';
import { Dialog } from '../../chat/chat.model';
import { CustomerDataType } from '../../customer/customer.model.data';
import { getCatDialogId } from '../../chat/chat.model.data';

export interface UIState {
  focusCat: string;
  focusVendor: string;
  focusItem: string;
  focusDialog: string;
  mainTabIndex: number;
  checklistTabIndex: number;
  mediaTabIndex: number;
  hideCompleted: boolean;
  showDemo: boolean | null;
  uiCustomerListStyle: 'cards' | 'cal' | 'cal-compact' | null;
  uiCustomerListType: CustomerDataType | null;
  theme: THEME_NAME;
  bigPlanAssignee: string;
  bigPlanCustomer: string;
  bigPlanHideCompleted: boolean;
  bigPlanExpandAllMonths: boolean;
  uiSortUsersBy: 'lastSeen' | 'email' | 'name';
  vendors: { name: string, locations: string[], coverage: string[], cat: string }
}

const initialState: UIState = {
  focusCat: null,
  focusVendor: null,
  focusItem: null,
  focusDialog: null,
  mainTabIndex: 0,
  checklistTabIndex: 0,
  mediaTabIndex: 0,
  hideCompleted: false,
  showDemo: null,
  uiCustomerListStyle: 'cal-compact',
  uiCustomerListType: 'client',
  theme: 't-indigo',
  bigPlanAssignee: null,
  bigPlanCustomer: null,
  bigPlanHideCompleted: true,
  bigPlanExpandAllMonths: false,
  uiSortUsersBy: 'name',
  vendors: { name: null, locations: [], coverage: [], cat: null }
};

const reducer = createReducer(
  initialState,
  on(uiFocusCat, (state, { cat }) => ({
    ...state,
    ...{ focusCat: cat, focusDialog: getCatDialogId(cat) },
  })),
  on(uiFocusVendor, (state, { vendor }) => ({
    ...state,
    ...{ focusVendor: vendor },
  })),
  on(uiFocusChat, (state, { dialogId, focusCat, focusVendor, focusItem }) => {
    if (focusCat === undefined) {
      focusCat = state.focusCat;
    }
    if (focusVendor === 'void') {
      focusVendor = null;
    }
    return {
      ...state,
      ...{ focusDialog: dialogId, focusCat, focusVendor, focusItem },
    };
  }),
  on(uiManageVendorsFilters, (state, { vendors }) => {
    return { ...state, vendors }
  }),
  on(uiHideCompleted, (state, { hide }) => ({
    ...state,
    ...{ hideCompleted: hide },
  })),
  on(uiBigPlanAssignee, (state, { bigPlanAssignee }) => ({
    ...state,
    ...{ bigPlanAssignee },
  })),
  on(uiBigPlanCustomer, (state, { bigPlanCustomer }) => ({
    ...state,
    ...{ bigPlanCustomer },
  })),
  on(uiBigPlanHideCompleted, (state, { bigPlanHideCompleted }) => ({
    ...state,
    ...{ bigPlanHideCompleted },
  })),
  on(uiBigPlanExpandAllMonths, (state, { bigPlanExpandAllMonths }) => ({
    ...state,
    ...{ bigPlanExpandAllMonths },
  })),
  on(uiShowDemo, (state, { show }) => ({
    ...state,
    ...{ showDemo: show },
  })),
  on(uiCustomerListStyle, (state, { style }) => ({
    ...state,
    ...{ uiCustomerListStyle: style },
  })),
  on(uiSortUsersBy, (state, { sortType }) => ({
    ...state,
    ...{ uiSortUsersBy: sortType },
  })),
  on(uiCustomerListType, (state, { listType }) => ({
    ...state,
    ...{ uiCustomerListType: listType },
  })),
  on(uiMainTabChanged, (state, { index }) => ({
    ...state,
    ...{ mainTabIndex: index },
  })),
  on(uiChecklistTabChanged, (state, { index }) => ({
    ...state,
    ...{ checklistTabIndex: index },
  })),
  on(uiMediaTabChanged, (state, { index }) => ({
    ...state,
    ...{ mediaTabIndex: index },
  })),
  on(uiSetTheme, (state, { theme }) => ({
    ...state,
    theme,
  })),
  on(uiInit, (state, { initState }) => ({
    ...state,
    ...initState,
  }))
);

export function uiReducer(state: UIState | undefined, action: Action) {
  return reducer(state, action);
}
