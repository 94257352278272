import {
  ChangeDetectorRef, Component,
  EventEmitter,
  Input,


  OnChanges, OnInit,
  Output,

  SimpleChanges
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { WindowService } from '@skylitup/base/util';
import {
  Auth, Category,
  ContextService, CustomerEvent,
  Loc, Vendor, VendorParam,
  VendorParamNumber,
  VendorParamSelect,
  VENDOR_DYNAMIC_DOMAIN,
  VENDOR_DYNAMIC_DOMAIN_DEF
} from '@skylitup/flowergirl/core';
import { VendorCardBaseComponent } from '@skylitup/flowergirl/features/vendor';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FocusSortClick } from '../util/vendor-sort';
import { VendorMapComponent } from '../vendor-map/vendor-map.component';
import { VendorContactsComponent } from './vendor-contacts/vendor-contacts.component';

interface DynamicFieldValue {
  key: string;
  type: VendorParam['type'];
  value: any;
  cat: string;
  config: VendorParam;
}
interface BoolFieldValue extends DynamicFieldValue {
  value: boolean;
  config: VendorParam;
}
interface NumberFieldValue extends DynamicFieldValue {
  value: number;
  config: VendorParamNumber;
}
interface SelectFieldValue extends DynamicFieldValue {
  value: { code: string; name: string };
  config: VendorParamSelect;
}

@Component({
  selector: 'flrgl-vendor-card',
  templateUrl: 'vendor-card.component.html',
  styleUrls: ['vendor-card.component.scss'],
})
export class VendorCardComponent extends VendorCardBaseComponent
  implements OnInit, OnChanges {
  @Input()
  vendor: Vendor;
  @Input()
  highlightKey: string;
  @Input()
  inShowCase = false;
  @Input()
  withFilters = true;
  @Input()
  socialNames = false;
  @Input()
  customerEvent: CustomerEvent;
  @Output()
  nameclick = new EventEmitter<void>();
  @Output()
  catclick = new EventEmitter<Category>();
  @Output()
  locationClick = new EventEmitter<Loc>();
  @Output()
  coverageClick = new EventEmitter<Loc>();
  @Input()
  editable = true;
  customerId: string;
  auth$: Observable<Auth>;

  get cats() {
    if (this.vendor?.cats?.length) {
      return this.vendor?.cats;
    } else {
      return [];
    }
  }
  dynamicFieldValues: DynamicFieldValue[] = [];
  @Output()
  focusSortClick = new EventEmitter<FocusSortClick>();
  @Output()
  mapClick = new EventEmitter<void>();

  get priceLevel() {
    if (this.vendor.data.priceLevel) {
      let r = '';
      for (let i = 0; i < this.vendor.data.priceLevel; i++) {
        r += '€';
      }
      return r;
    } else {
      return null;
    }
  }
  constructor(
    private _bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private context: ContextService,
    private windowService: WindowService
  ) {
    super();
    this.auth$ = this.context.auth$;
    this.context.customerId$
      .pipe(takeUntil(this.destroy$))
      .subscribe((customerId) => (this.customerId = customerId));
  }
  ngOnInit(): void {
    // this.buildDynamicFields();
  }

  clickExternal(link) {
    this.windowService.window.open(link);
    // setTimeout(() => this.windowService.window.open(link), 0);
    // _blank
  }

  private buildDynamicFields() {
    const vendor = this.vendor;
    this.dynamicFieldValues = [];
    for (const type1 in VENDOR_DYNAMIC_DOMAIN) {
      if (vendor.cats.map((c) => c.id).includes(type1)) {
        const fieldIds = VENDOR_DYNAMIC_DOMAIN[type1];
        this.dynamicFieldValues.push(
          ...this._buildDynamicFields(type1, fieldIds)
        );
      }
    }
  }
  private _buildDynamicFields(cat, l: string[]): DynamicFieldValue[] {
    const r: DynamicFieldValue[] = [];
    for (const k of l) {
      const type: VendorParam['type'] = VENDOR_DYNAMIC_DOMAIN_DEF[k]['type'];
      const config = VENDOR_DYNAMIC_DOMAIN_DEF[k];
      const rowVal = this.vendor.data[cat]?.[k];
      const value =
        type === 'select'
          ? (<VendorParamSelect>config).options.find((o) => o.code === rowVal)
          : rowVal;
      const v = {
        key: k,
        config,
        type,
        value,
        cat,
      };
      r.push(v);
    }
    return r;
  }

  asNumberField(fieldVal: DynamicFieldValue): NumberFieldValue {
    return fieldVal as NumberFieldValue;
  }
  numValueUnits(fieldVal: NumberFieldValue): string {
    const u = fieldVal.config.units;
    if (u === 'na') {
      return '';
    } else {
      return u;
    }
  }
  // selectValueName(fieldVal: SelectFieldValue): string {
  //   return fieldVal.config.options.find(o => o.code === fieldVal.value);
  // }
  asBoolField(fieldVal: DynamicFieldValue): BoolFieldValue {
    return fieldVal as BoolFieldValue;
  }
  asSelectField(fieldVal: DynamicFieldValue): SelectFieldValue {
    return fieldVal as SelectFieldValue;
  }

  sort(fieldVal: DynamicFieldValue, fieldPath: string[]) {
    this.focusSortClick.next({
      label: fieldVal.config.label,
      key: fieldVal.key,
      paramType: fieldVal.config.type,
      value: fieldVal.value,
      config: fieldVal.config,
      fieldPath,
    });
  }

  showContactsInfo() {
    this._bottomSheet.open(VendorContactsComponent, {
      data: this.vendor,
    });
  }
  showFullCard() {
    this.nameclick.next();
  }

  showMap() {
    this.dialog.open(VendorMapComponent, {
      data: this.vendor,
      disableClose: false,
      height: '80vh',
      width: '80vw',
      autoFocus: false,
    });
    this.mapClick.next();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['vendor']) {
      this.buildDynamicFields();
      this.cd.markForCheck();
    }
  }

  // editUrl() {
  //   if (this.customerEvent) {
  //     const r = [
  //       ...this.customerEvent.editUrl,
  //       'vendor',
  //       this.vendor.data.meta.id
  //     ];
  //     return '/' + r.join('/');
  //   } else {
  //     const r = [...this.vendor.data.meta.urlPath, this.vendor.data.meta.id];
  //     r.splice(2, 0, 'edit');
  //     return '/' + r.join('/');
  //   }
  // }
}
