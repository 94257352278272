<form [formGroup]="form">
    <mat-form-field>
        <mat-label>Type</mat-label>
        <mat-select #type1 formControlName="type">
            <mat-option *ngFor="let type of types" [value]="type.code">
                {{type.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <!-- {{type.value}} {{type.value=='rate'}} -->
    <mat-form-field *ngIf="type.value=='rate'" class="rate m-l-10">
        <mat-label>Rate</mat-label>
        <input #rate1 type="number" min="0" max="100" matInput formControlName="rate" required class="right1"
            (focus)="onFocus(rate1)">
        <mat-error *ngIf="rate.invalid">
            {{getErrorMessage(rate,'Rate')}}</mat-error>
        <span matSuffix>%</span>
    </mat-form-field>

    <mat-form-field *ngIf="type.value=='amount'" class="rate m-l-10">
        <mat-label>Amount</mat-label>
        <input #amount1 type="number" min="0" matInput formControlName="amount" required class="right1"
            (focus)="onFocus(amount1)">
        <mat-error *ngIf="amount.invalid">
            {{getErrorMessage(amount,'Amount')}}</mat-error>
        <span matPrefix>€</span>
    </mat-form-field>

    <mat-dialog-actions fxLayoutAlign="center">
        <button mat-button [mat-dialog-close]="saveCalculation()" type="submit">
            <mat-icon>save</mat-icon> Save
        </button>
        <button mat-button [mat-dialog-close] type="submit">
            <mat-icon>close</mat-icon> Cancel
        </button>

    </mat-dialog-actions>
</form>