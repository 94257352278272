<header class="flex">
    <h2>
        <span *ngIf="resettingPassword;else welcome1">Please enter your new password</span>
        <ng-template #welcome1>
            <span>Welcome to Flowergirl!</span>
        </ng-template>
    </h2>
    <div *ngIf="signingIn">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        <div>Logging In ... please wait</div>
    </div>
</header>
<!-- <div>
    <span *ngIf="auth$ | async as auth">
        <pre>{{json(auth.data)}}</pre></span>
</div> -->
<mat-tab-group [selectedIndex]='tabIndex()'>
    <!-- headerPosition="below" -->
    <mat-tab label="Email Link Method">
        <div class="passwordless-tab">
            <mat-form-field class="email1">
                <mat-label>Your E-mail</mat-label>
                <input matInput [formControl]="email" required email>
                <mat-error *ngIf=" email.invalid">{{getErrorMessage( email,'Your E-mail')}}
                </mat-error>
            </mat-form-field>
            <form>
                <button mat-raised-button  type="submit" [disabled]="!email.valid" (click)="sendSignInLinkToEmail()">
                    <span *ngIf="!emailSent;else resend"> Send me the login link </span>
                    <ng-template #resend>Resend login-link</ng-template>
                </button>
            </form>
            <!-- *ngIf="emailSent" -->
            <div class="feedback m-t-10" *ngIf="emailSent">
                <p class="text1">
                    The Login link has been emailed. Please check your inbox and follow the link to log-in!.
                </p>
                <p class="text2">If you can't find it, please double-check the email address you entered and then see
                    your
                    junk/spam
                    folder.</p>
            </div>
            <div *ngIf="emailSentError" class=" color-warn">
                <p class="text1">
                    The Email could not be sent, there was error!
                </p>
            </div>
            <div *ngIf="loginError" class=" color-warn">
                <p class="text1">
                    Login Failed: {{loginError}}
                </p>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Password Method">
        <div class="login-tab">
            <ng-container *ngIf="!resettingPassword;else resettingPwdTpl">
                <div fxLayout="row wrap" fxLayoutGap="15px" class="p-x-5">
                    <mat-form-field class="email1">
                        <mat-label>Your E-mail</mat-label>
                        <input matInput [formControl]="email" required email
                            (keyup.enter)="pwdLoginMode.value=='login'?signInWithEmailPassword():sendResetPasswordLink()">
                        <mat-error *ngIf=" email.invalid">{{getErrorMessage( email,'Your E-mail')}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="pwd1" *ngIf="pwdLoginMode.value=='login'">
                        <mat-label>Your Password</mat-label>
                        <input matInput required password [formControl]="password" type="password"
                            (keyup.enter)="signInWithEmailPassword()">
                    </mat-form-field>
                </div>
                <div fxLayout="row wrap" fxLayoutGap="15px" class="p-x-5 pwd-login-buttons"
                    fxLayoutAlign="start center">
                    <button *ngIf="pwdLoginMode.value=='login';else resetBtn" mat-raised-button type="submit"
                        (click)="signInWithEmailPassword()" class="sign-in-btn" [disabled]="!email.valid || !password.value">
                        Sign-in
                    </button>
                    <ng-template #resetBtn>
                        <button mat-raised-button type="submit" class="reset-btn" [disabled]="!email.valid"
                            (click)="sendResetPasswordLink()">
                            Send Reset Password email
                        </button>
                    </ng-template>
                    <mat-radio-group [formControl]="pwdLoginMode">
                        <mat-radio-button value="login">Login</mat-radio-button>
                        <mat-radio-button value="reset">Setup/Reset Password</mat-radio-button>
                    </mat-radio-group>
                </div>
            </ng-container>
            <ng-template #resettingPwdTpl>
                <div fxLayout="row wrap" fxLayoutGap="15px" class="p-x-5">
                    <mat-form-field class="pwd1">
                        <mat-label>Choose Password</mat-label>
                        <input matInput required password [formControl]="password" type="password" minlength="8"
                            (keyup.enter)="saveNewPassword()">
                        <mat-error *ngIf=" password.invalid"> Minimum 8 chars </mat-error>
                    </mat-form-field>
                    <mat-form-field class="pwd1">
                        <mat-label>Confirm Password</mat-label>
                        <input matInput required password [formControl]="passwordConfirm" minlength="8" type="password"
                            (keyup.enter)="saveNewPassword()">
                        <mat-error *ngIf="passwordConfirm.invalid">The Passwords don't match
                        </mat-error>
                    </mat-form-field>

                </div>
                <div fxLayout="row wrap" fxLayoutGap="15px" class="p-x-5 pwd-login-buttons"
                    fxLayoutAlign="start center">
                    <button [disabled]="!(password.valid && passwordConfirm.valid)" mat-raised-button type="submit"
                        class="sign-in-btn" (click)="saveNewPassword()">
                        Save the New Password
                    </button>
                </div>
            </ng-template>
        </div>
        <div class="feedback" *ngIf="resetPasswordEmailSent">
            <p class="text1">
                A Reset-Password Email has been sent. Please check your inbox and follow the link to change your
                password!.
            </p>
            <p class="text2">If you can't find it, please double-check the email address you entered and then see your
                junk/spam
                folder.</p>
        </div>
        <div *ngIf="emailSentError" class="color-warn">
            <p class="text1">
                The Email could not be sent, there was error!
            </p>
        </div>
        <div *ngIf="loginError" class=" color-warn">
            <p class="text1">
                Login Failed: {{loginError}}
            </p>
        </div>
    </mat-tab>
</mat-tab-group>


<div class="img1"  [routerLink]="['/']">
    <img src="/assets/flowergirl-img1.jpg">
</div>
<div> <button mat-raised-button class="m-y-10" routerLink="/dev" *auth1="['dev']"> TO DEV </button> </div>