<mat-card fxLayout="row" fxLayoutAlign="start center">
    <div class="title" fxFlex="grow" [routerLink]="[ 'details',item.id]">
        <div class="name clickable1"><span><span class="underline1">{{item.name}}</span>
                <ng-container *ngIf="!item.done;else done1"> <span class="draft">DRAFT</span> </ng-container>
                <ng-template #done1> <span class="check">
                        <mat-icon>check</mat-icon>
                    </span></ng-template>
            </span></div>
    </div>
    <flrgl-chat-link [dialog]="item.dialog" class='m-r-15'></flrgl-chat-link>
</mat-card>