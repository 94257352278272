import { Component } from '@angular/core';

import { TimeTravelService } from './time-travel.service';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { TimeTravelBaseComponent } from '@skylitup/flowergirl/ui';

@Component({
  selector: 'flrgl-time-travel',
  templateUrl: 'time-travel.component.html',
  styleUrls: ['time-travel.component.scss'],
})
export class TimeTravelComponent extends TimeTravelBaseComponent {
  date1 = new FormControl();
  time1 = new FormControl();

  constructor(public timeTravelService: TimeTravelService) {
    super();
    this.date1.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((a) => {
      const currentMoment = this.timeTravelService.fakeTimeMoment;
      const mins = currentMoment.minutes();
      const hours = currentMoment.hours();
      const newMoment = moment(a).set('hour', hours).set('minute', mins);
      this.timeTravelService.fakeTime = newMoment.toDate();
    });
    this.time1.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((a) => {
      const currentMoment = this.timeTravelService.fakeTimeMoment;
      // console.log(a);
      const time1 = moment(a, 'hh:mm');
      const mins = time1.minutes();
      const hours = time1.hours();
      // console.log('&&', hours, mins);
      const newMoment = currentMoment.set('hour', hours).set('minute', mins);
      this.timeTravelService.fakeTime = newMoment.toDate();
      // console.log('+++', this.timeTravelService.fakeTimeMoment);
    });
  }
}
