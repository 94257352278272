import {
  Component,
  Inject,
  OnInit,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { BudgetItemCalculationBaseComponent } from '@skylitup/flowergirl/features/budget';
import {
  CalculationType,
  BudgetItemData,
  CustomerEvent,
  EventItemService,
  calcBudget,
} from '@skylitup/flowergirl/core';
import { getErrorMessageHandler } from '@skylitup/base/util';

@Component({
  selector: 'flrgl-budget-item-calculation',
  templateUrl: 'budget-item-calculation.component.html',
  styleUrls: ['budget-item-calculation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetItemCalculationComponent
  extends BudgetItemCalculationBaseComponent
  implements OnInit, OnChanges {
  calcForm: FormGroup;
  types: { key: CalculationType; text: string }[] = [
    { key: 'custom', text: 'Custom' },
    { key: 'guests', text: 'Total Guests' },
    { key: 'adults', text: 'Adults' },
    { key: 'children', text: 'Children' },
    { key: 'none', text: 'No Calculation' },
  ];
  formIsValid = true;
  item: BudgetItemData;
  customerEvent: CustomerEvent;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: { item: BudgetItemData; customerEvent: CustomerEvent },
    fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private eventItemService: EventItemService,
    public dialogRef: MatDialogRef<BudgetItemCalculationComponent>
  ) {
    super();
    this.item = data.item;
    this.customerEvent = data.customerEvent;
    this.calcForm = fb.group({
      calcType: [''],
      calcCustomNumber: [''],
      calcItemPrice: [''],
    });
  }
  getErrorMessage = getErrorMessageHandler;
  keyUp($e) {
    if ($e.keyCode === 13) {
      this.dialogRef.close(this.saveCalculation());
    }
  }
  onFocus(input: any) {
    input.select();
  }
  ngOnInit(): void {
    this.types = [
      { key: 'custom', text: `Custom` },
      { key: 'guests', text: `Total Guests (${this.customerEvent.guests})` },
      { key: 'adults', text: `Adults (${this.customerEvent.adultGuests})` },
      {
        key: 'children',
        text: `Children (${this.customerEvent.childrenGuests})`,
      },
      { key: 'none', text: 'No Calculation' },
    ];
    const calcType = this.item.calcType || 'none';
    this.calcForm.patchValue({ ...this.item, calcType }, { emitEvent: false });
    // setTimeout(() => this.cd.detectChanges(), 0);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.formIsValid = this.typeControl.value === 'none' || this.calcForm.valid;
    setTimeout(() => this.cd.detectChanges(), 0);
  }
  get itemPriceControl() {
    return this.calcForm.get('calcItemPrice');
  }
  get calcCustomNumberControl() {
    return this.calcForm.get('calcCustomNumber');
  }
  get typeControl() {
    return this.calcForm.get('calcType');
  }
  calcTotal() {
    return calcBudget(this.customerEvent.data, this.calcForm.value);
  }
  saveCalculation() {
    const calcType = this.calcForm.value.calcType || 'none';
    const calcItemPrice = this.calcForm.value.calcItemPrice || 0;
    const calcCustomNumber = this.calcForm.value.calcCustomNumber || 0;
    const d = {
      calcType,
      calcItemPrice,
      calcCustomNumber,
    };

    return d;
  }
}
