import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { mergeMap, first, filter, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerEventListBaseComponent } from '@skylitup/flowergirl/features/navigate';
import {
  CustomerService,
  CustomerEventService,
  Customer,
  CustomerEvent, CustomerEventEditService, ChatTrack, ChatTrackService, ContextService, Auth
} from '@skylitup/flowergirl/core';
import { getErrorMessageHandler } from '@skylitup/base/util';
import { Observable } from 'rxjs';
import { Dictionary } from '@ngrx/entity';
import { MatRipple } from '@angular/material/core/ripple';

@Component({
  selector: 'flrgl-customer-event-list',
  templateUrl: 'customer-event-list.component.html',
  styleUrls: ['customer-event-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerEventListComponent extends CustomerEventListBaseComponent {
  events$: Observable<CustomerEvent[]>;
  chatTrackMap$: Observable<Dictionary<ChatTrack>>;
  customer$: Observable<Customer>;
  auth$: Observable<Auth>
  constructor(
    private customerService: CustomerService,
    private matDialog: MatDialog,
    private customerEventService: CustomerEventService,
    private router: Router,
    private customerEventEditService: CustomerEventEditService,
    public chatTrackService: ChatTrackService,
    private contextService: ContextService, private activatedRoute: ActivatedRoute,

  ) {
    super();
    this.events$ = this.customerEventService.customerEventsInContext$;
    this.customer$ = this.customerService.customerInContext$;
    this.chatTrackMap$ = this.chatTrackService.chatTrackByEventId$;
    this.auth$ = this.contextService.auth$;
  }
  addCustomerEvent(customer: Customer) {
    this.matDialog
      .open(CreateCustomerEventPopupComponent)
      .afterClosed()
      .pipe(
        first(),
        filter((n) => !!n),
        mergeMap((name) =>
          this.customerEventEditService.createCustomerEvent(customer, name)
        )
      )
      .subscribe((c) =>
        this.router.navigateByUrl(CustomerEvent.getEditEventIdUrl(c))
      );
  }
  getChatTrack(chatTrackMap: Dictionary<ChatTrack>, customerEvent: CustomerEvent): ChatTrack {
    return chatTrackMap?.[customerEvent?.id];
  }

  getEventsUpcoming(events: CustomerEvent[]) {
    return events.filter((e) => e.info.earliestDate.getTime() >= Date.now()).reverse();
  }

  getEventsPast(events: CustomerEvent[]) {
    return events.filter((e) => e.info.earliestDate.getTime() < Date.now());
  }

  chatRecentLink(event: CustomerEvent) {
    return ['/', ...event.data.meta.urlPath, event.id, 'chat', 'recent'];
  }
  navigateToEvent(customerEvent: CustomerEvent, rippler: MatRipple) {
    rippler.launch(null);
    setTimeout(() => {
      this.router.navigate(['event', customerEvent.id], { relativeTo: this.activatedRoute })
    }, 300)

  }
  navigateToEditEvent(customerEvent: CustomerEvent) {
    this.router.navigateByUrl(customerEvent.editUrl + '/event-id');
  }
}

@Component({
  templateUrl: 'create-customer-event-popup.component.html',
  styles: [''],
})
export class CreateCustomerEventPopupComponent {
  form = new FormGroup({ name: new FormControl('', Validators.required) });
  get name() {
    return this.form.get('name');
  }

  getErrorMessage = getErrorMessageHandler;
  constructor(
    private dialogRef: MatDialogRef<CreateCustomerEventPopupComponent>
  ) { }
  keyUp(code: number) {
    if (code === 13) {
      this.dialogRef.close(this.name.value);
    }
  }
}
