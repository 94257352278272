import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export const idGen = (): string => {
  const CHARS =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  let autoId = "";

  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
  }
  return autoId;
};

export function tapDebug(
  fn1: (args: any) => any[]
): (o: Observable<any>) => Observable<any> {
  return (o: Observable<any>) => o.pipe(tap((a) => console.log(...fn1(a))));
}
