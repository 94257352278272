import { BaseComponent } from '@skylitup/base/util';
import { Directive } from '@angular/core';

@Directive()
export abstract class ChatHeaderBaseComponent extends BaseComponent {
  public text = 'ChatHeader';

  constructor() {
    super();
  }
}
