import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ContextService } from '@skylitup/flowergirl/core';
import { BreadcrumbBaseComponent } from '@skylitup/flowergirl/ui';

@Component({
  selector: 'flrgl-breadcrumb',
  templateUrl: 'breadcrumb.component.html',
  styleUrls: ['breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent extends BreadcrumbBaseComponent {
  constructor(contextService: ContextService) {
    super(contextService);
  }
}
