import {
  Component,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';

import { Observable, combineLatest } from 'rxjs';
import { filter, first, takeUntil, map, tap } from 'rxjs/operators';
import { Dictionary } from '@ngrx/entity';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChatRecentBaseComponent } from '@skylitup/flowergirl/features/chat';
import {
  HasDialog,
  Auth,
  CustomerEvent,
  ChatAggregatorService,
  ContextService, ChatTrackService, ChatTrack, createUiFocusChat, AppState
} from '@skylitup/flowergirl/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'flrgl-chat-recent',
  templateUrl: 'chat-recent.component.html',
  styleUrls: ['chat-recent.component.scss', '../chat-container-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatRecentComponent extends ChatRecentBaseComponent {
  dialogs$: Observable<HasDialog[]>;
  dialogObjectMap$: Observable<Dictionary<HasDialog>>;
  auth$: Observable<Auth>;
  dialogObj: HasDialog = null;
  customerEvent$: Observable<CustomerEvent>;
  mobileQuery: MediaQueryList;
  mobileSideToggle = false;
  sideToggle = true;
  chatTrack$: Observable<ChatTrack>;

  constructor(
    private chatAggregatorService: ChatAggregatorService,
    private context: ContextService,
    media: MediaMatcher,
    chatTrackService: ChatTrackService,
    private store: Store,
    private cd: ChangeDetectorRef
  ) {
    super();
    this.mobileQuery = media.matchMedia('(max-width: 700px)');

    this.customerEvent$ = this.context.customerEvent$;
    const c$ = combineLatest([
      this.customerEvent$.pipe(filter((o) => !!o)),
      this.chatAggregatorService.allDialogObjectsByEvent$,
    ]).pipe(
      map(([event, t]) => t[event.id]),
      filter((o) => !!o)
    );
    this.chatTrack$ = chatTrackService.chatTrackInContext$;

    this.dialogs$ = c$.pipe(map((a) => a[0]));
    this.dialogObjectMap$ = c$.pipe(map((a) => a[1]));
    this.auth$ = this.context.auth$;

    combineLatest([
      this.dialogObjectMap$.pipe(filter((o) => !!o)),
      this.store.select((s: AppState) => s.ui.focusDialog),
      this.dialogs$.pipe(
        filter((o) => !!o && o.length > 0),
        map((dd) => dd[0]),
        filter((d) => !!d?.dialog?.dialogObj), first())
    ])
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(([m, u, d]) => {
        if (this.dialogObj) {
          this.dialogObj = m[this.dialogObj?.dialog?.dialogId];
        } else if (u && m[u]) {
          this.dialogObj = m[u];
        } else {
          this.dialogObj = d;
        }
        // tap(a => console.log('~~~~~~~', a))
      })
  }

  selectDialog(d: HasDialog) {
    this.dialogObj = d;
    this.store.dispatch(createUiFocusChat(d));
    this.cd.markForCheck();

    if (this.mobileQuery.matches) {
      this.mobileSideToggle = false;
    }
  }
  toggle() {
    this.mobileSideToggle = !this.mobileSideToggle;
    this.sideToggle = !this.sideToggle;
  }
}
