import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChatMessageCreateBaseComponent } from '@skylitup/flowergirl/features/chat';
import {
  HasDialog,
  UploadFilesRequest,
  ChatData,
  Chat,
  EventCategory,
  EventItem,
  VendorCat,
  VendorResearch, DocData, DOC_TYPE_OPTIONS, Doc
} from '@skylitup/flowergirl/core';
import { TimeTravelService } from '@skylitup/flowergirl/xplat/web/ui';
import { getErrorMessageHandler } from '@skylitup/base/util';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'flrgl-chat-message-create',
  templateUrl: 'chat-message-create.component.html',
  styleUrls: ['chat-message-create.component.scss'],
})
export class ChatMessageCreateComponent extends ChatMessageCreateBaseComponent {
  msg = new FormControl();

  @Input()
  origin: HasDialog;
  @Input()
  userId: string;
  @Input()
  userIsAgency: boolean;
  @Output()
  newSent = new EventEmitter<{
    uploadFilesRequest: UploadFilesRequest;
    data: ChatData;
  }>();
  @Output()
  editSent = new EventEmitter<{
    uploadFilesRequest: UploadFilesRequest;
    data: ChatData;
  }>();

  chatToEdit: Chat;
  uploadingType: UploadFilesRequest['type'];
  uploadingDocName: string;
  uploadingDocType: DocData['type'];
  docAcceptTypes: 'image/*'
  // @ViewChild('fileUpload')
  // fileUpload: ElementRef;
  constructor(public timeTravelService: TimeTravelService,
    private dialog: MatDialog
  ) {
    super();
  }
  keyup1($event) {
    if ($event.keyCode === 13) {
      this.upsert();
    }
  }
  uploadDoc(fileUpload) {
    this.uploadingType = 'doc';
    this.dialog
      .open(UploadDocPopupComponent, {
        data: { fileUploadEl: fileUpload },
        disableClose: true,
        // height: '80vh',
        // width: '60vw',
        // minWidth: '375px',
        // maxWidth: '800px'
      }).afterClosed().subscribe(docMeta => {
        const { fileUploadEl, name, type } = docMeta;
        this.uploadingDocName = name;
        this.uploadingDocType = type;
        fileUploadEl.click();
      });
  }

  uploadImg(fileUpload) {
    this.uploadingType = 'image';
    fileUpload.click()
  }

  attachFile($event) {
    if (!this.uploadingType) throw Error('uploading type not set');
    const customerEvent = this.origin.event;
    const uploadFilesRequest: UploadFilesRequest = {
      type: this.uploadingType,
      docName: this.uploadingDocName,
      docType: this.uploadingDocType,
      files: $event.target.files,
      agency: customerEvent.agencyId,
      customer: customerEvent.customerId,
      event: customerEvent.id,
      originCat: null,
      originVendor: null,
      originEventItem: null,
    };
    if (this.origin.dialog.type === 'category') {
      const cat = this.origin as EventCategory;
      uploadFilesRequest.originCat = cat.id;
    } else if (this.origin.dialog.type === 'eventItem') {
      const eventItem = this.origin as EventItem;
      uploadFilesRequest.cat = eventItem.vendorCat.id;
      uploadFilesRequest.vendor = eventItem.vendorCat.hasVendor
        ? eventItem.vendorCat.vendorId
        : null;
      uploadFilesRequest.originEventItem = eventItem.id;
    } else if (this.origin.dialog.type === 'vendorCat') {
      const vendorCat = this.origin as VendorCat;
      uploadFilesRequest.cat = vendorCat.hasVendor ? vendorCat.id : null;
      uploadFilesRequest.originVendor = vendorCat.vendorId;
    } else if (this.origin.dialog.type === 'vendorResearch') {
      const vendorResearch = this.origin as VendorResearch;
      // mediaUpload.cat = vendorResearch.data. ? vendorCat.id : null;
      uploadFilesRequest.originVendor = vendorResearch.data.vendorId;
    }
    this.upsert(uploadFilesRequest);
    // this.mediaService.upload(mediaUploadMeta, files);
    // for (let index = 0; index < files.length; index++) {
    //   const file = files[index];

    //   console.log(file);
    // }
    // const filePath = 'name-your-file-path-here';
    // const task = this.storage.upload(filePath, file);
  }
  msgPlaceHolder() {
    if (!this.chatToEdit) {
      return 'Type a new message';
    } else {
      return `Chat sent by ${this.chatToEdit.name}, ${this.chatToEdit.dateStringFull} `;
    }
  }

  upsert(uploadFilesRequest?: UploadFilesRequest) {
    const originType = this.origin.dialog.type;
    const originId = this.origin.id;
    let originCompositeVendorId = null;
    if (originType === 'vendorCat') {
      const v = this.origin as VendorCat;
      originCompositeVendorId = v.vendorId;
    }
    let isAttachment = false;
    let uploading = false;
    if (uploadFilesRequest) {
      isAttachment = true;
      uploading = true;
    }
    if (isAttachment || (this.msg.value && this.msg.value?.trim() !== '')) {
      const date =
        this.timeTravelService.active && this.timeTravelService.fakeTime
          ? this.timeTravelService.fakeTime
          : this.chatToEdit
            ? this.chatToEdit.date
            : new Date();

      const data = {
        msg: this.msg.value,
        date,
        _uid: this.userId,
        links: {
          originId,
          originCompositeVendorId,
          originType,
          vendorCats: [],
          eventItems: [],
          cats: [],
          vendorResearch: [],
          event: null,
        },
        senderIsAgency: this.userIsAgency,
        recent: true,
        readBy: { [this.userId]: true },
        attachment: {
          isAttachment,
          uploading,
          mediaIds: [],
          type: uploadFilesRequest?.type || null
        },
        _del: null,
      };
      if (!this.chatToEdit) {
        this.newSent.next({ uploadFilesRequest: uploadFilesRequest, data });
      } else {
        this.editSent.next({
          uploadFilesRequest: uploadFilesRequest,
          data: { ...this.chatToEdit.data, ...data },
        });
      }

      this.msg.setValue('');
    }
  }
  editChat(chat: Chat) {
    this.chatToEdit = chat;
    this.msg.setValue(chat.data.msg.trim());
  }
}



@Component({
  templateUrl: 'upload-doc-popup.component.html',
  styles: [''],
})
export class UploadDocPopupComponent {
  getErrorMessage = getErrorMessageHandler;
  form = new FormGroup({
    name: new FormControl('', Validators.required),
    type: new FormControl(null, Validators.required),
  });
  docTypeKeys = Object.entries(DOC_TYPE_OPTIONS);

  get name() {
    return this.form.get('name');
  }
  get type() {
    return this.form.get('type');
  }
  fileUploadEl: any;
  constructor(
    private dialogRef: MatDialogRef<UploadDocPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data: { fileUploadEl: any }) {
    this.fileUploadEl = data.fileUploadEl;
  }
  keyUp(code: number) {
    if (code === 13) {
      this.dialogRef.close(this.result());
    }
  }

  result() {
    return { fileUploadEl: this.fileUploadEl, name: this.name.value, type: this.type.value }
  }
}