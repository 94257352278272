import { props, createAction } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";

export const genericError = createAction(
  "[error - generic]",
  props<{ msg: string; hint: string }>()
);

/*
For this to work, it needs to be piped to the flattener.
otherwise, the exception stops it all and it unsubscribes.
In other words, it needs to be outside the flattener, next to it
*/
export const genericCatch$$ = (hint: string) => (
  o: Observable<any>
): Observable<any> => {
  return o.pipe(
    catchError((err) => {
      let msg = "";
      if (typeof err === "object") {
        msg = err.toString();
      }
      console.error("XXXXXXXXXXX ERROR XXXXXXXXXXX");
      console.error(`XXXXXXXXXXX ${hint} XXXXXXXXXX`);
      console.error(err);
      console.error("XXXXXXXXXXX XXXXX XXXXXXXXXXX");
      return of(genericError({ msg, hint }));
    })
  );
};
