import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { WindowService } from '@skylitup/base/util';
import {
  Auth,
  CandidateDescribed,
  Category,
  ContextService,
  CustomerEvent,
  doHeartBeat,
  VendorResearch,
  VendorResearchData,
  VendorResearchItemDefData,
  VendorResearchService
} from '@skylitup/flowergirl/core';
import { ComparePricesBaseComponent } from '@skylitup/flowergirl/features/vendor';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, switchMap, takeLast, takeUntil } from 'rxjs/operators';
import { BudgetResearchItemNotesEdit } from '../budget-research-item-notes-edit/budget-research-item-notes-edit.component';
import { ComparePricesItemComponent } from '../compare-prices-item/compare-prices-item.component';

interface IColumn {
  id: string;
  version: VendorResearchItemDefData;
  candiDate: CandidateDescribed;
  versionIndex: number;
}
@Component({
  selector: 'flrgl-compare-prices',
  templateUrl: 'compare-prices.component.html',
  styleUrls: ['compare-prices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComparePricesComponent extends ComparePricesBaseComponent {
  @Input()
  editMode = false;

  unfold$ = new BehaviorSubject(1);
  researchCategory$: Observable<Category>;
  customerEvent$: Observable<CustomerEvent>;
  vendorResearchInContext$: Observable<VendorResearch[]>;
  priceItemsInContext$: Observable<{ vr: VendorResearch; priceItem: VendorResearchItemDefData; i: number }[]>;
  // dataSource: MatTableDataSource<{ vr: VendorResearch; priceItem: VendorResearchItemDefData; i: number }>;
  auth$: Observable<Auth>;

  constructor(
    private context: ContextService,
    private vendorResearchService: VendorResearchService,
    public windowService: WindowService,
    public dialog: MatDialog,
    private store: Store
  ) {
    super();
    this.auth$ = this.context.auth$;

    this.researchCategory$ = this.context.researchCategory$.pipe(filter(o => !!o));
    this.customerEvent$ = this.context.customerEvent$.pipe(filter(o => !!o));
    this.vendorResearchInContext$ = this.vendorResearchService.vendorResearchInContext$.pipe(
      map((vv) => vv.filter((v) => !!v.vendor))
    );
    this.priceItemsInContext$ = combineLatest([
      this.vendorResearchInContext$,
      this.customerEvent$.pipe(filter((o) => !!o)),
      this.researchCategory$.pipe(filter((o) => !!o)),
      this.unfold$,
    ]).pipe(
      map(([vrs, event, cat, unfold]) => {
        const isUseDates = event.isUseDatesInResearch(cat);
        const result = [];
        for (const vr of vrs) {
          const ps = event.getResearchPriceItems(cat.id);
          let offset = 0;

          if (unfold === 1) {
            if (isUseDates) {
              result.push({ vr, priceItem: null, i: offset++ });// Calendar 
            }
            result.push({ vr, priceItem: null, i: offset++ }); // Version
          }
          result.push({ vr, priceItem: null, i: offset++ }); // Totals
          if (unfold === 1) {
            for (let i = 0; i < ps.length; i++) {
              result.push({ vr, priceItem: ps[i], i: i + offset });
            }
          }
        }
        return result;
      })
    );
    this.unfold$.pipe(takeUntil(this.destroy$)).subscribe(_ => this.store.dispatch(doHeartBeat()))
  }
  trackById(a) {
    return a;
  }
  asVendorResearch(vr): VendorResearch {
    return vr as VendorResearch;
  }
  asIColumn(col): IColumn {
    return col as IColumn;
  }
  asPriceItem(p): VendorResearchItemDefData {
    return p as VendorResearchItemDefData;
  }
  getVendorNameRowSpan(customerEvent: CustomerEvent, researchCategory: Category, unfold: boolean, isUseDates: boolean) {
    const offset = isUseDates ? 3 : 2;
    return unfold ? customerEvent.getResearchPriceItems(researchCategory.id).length + offset : 1
  }
  getCandiDateColumns(customerEvent: CustomerEvent, researchCat: Category) {
    if (customerEvent.isUseDatesInResearch(researchCat)) {
      const result = [
        { id: '_name', v: null },
        ...customerEvent.info.candiDates.map((v) => ({
          id: v.id,
          v,
        })),
      ];
      return result;
    } else {
      const result = [
        { id: '_name', v: null },
        {
          id: 'voiDate',
          v: { id: 'voiDate', description: '' },
        },
      ];
      return result;
    }
  }

  showEditNotesVersion(vr: VendorResearch, versionId: string) {
    if (this.editMode) {
      const naObj = this.getVersionNA(vr, versionId);
      this.dialog
        .open(BudgetResearchItemNotesEdit, {
          data: { notes: naObj?.notes },
          disableClose: true,
        })
        .afterClosed().pipe(
          switchMap(notes => {
            const naObjx = this.getVersionNA(vr, versionId);
            if (notes !== undefined && notes !== null) {
              return this._upsertNA(vr, 'naVersions', versionId, naObjx?.na || false, notes);
            } else {
              return of(null);
            }
          }))
        .subscribe();
    }
  }
  showEditNotesCandiDate(vr: VendorResearch, candidateId: string) {
    if (this.editMode) {
      const naObj = this.getCandiDateNA(vr, candidateId);
      this.dialog
        .open(BudgetResearchItemNotesEdit, {
          data: { notes: naObj?.notes },
          disableClose: true,
        })
        .afterClosed().pipe(
          switchMap(notes => {
            const naObjx = this.getCandiDateNA(vr, candidateId);
            if (notes !== undefined && notes !== null) {
              return this._upsertNA(vr, 'naCandiDates', candidateId, naObjx?.na || false, notes);
            } else {
              return of(null);
            }
          }))
        .subscribe();
    }
  }

  getVersionNA(vr: VendorResearch, versionId: string) {
    return vr?.getVersionNA(versionId);
  }
  getCandiDateNA(vr: VendorResearch, candiDateId: string) {
    return vr?.getCandiDateNA(candiDateId);
  }

  isVersionNA(vr: VendorResearch, versionId: string) {
    return vr?.isVersionNA(versionId);
  }
  isCandiDateNA(vr: VendorResearch, candiDateId: string) {
    return vr?.isCandiDateNA(candiDateId);
  }


  _upsertNA(vr: VendorResearch, type: 'naCandiDates' | 'naVersions', id: string, na: boolean, notes?: string): Observable<boolean> {
    const data = { ...vr.data };
    let naMap = data[type];
    if (!naMap) {
      naMap = {};
    } else {
      naMap = { ...data[type] };
    }
    data[type] = naMap;
    let naObj = data[type]?.[id];
    if (!naObj) {
      naObj = { na: false, notes: null };
    } else {
      naObj = { ...naObj };
    }
    data[type][id] = naObj;

    naObj.na = na;
    if (notes !== undefined) {
      naObj.notes = notes;
    }
    return this.vendorResearchService.update(data).pipe(first(), map(_ => true))
  }


  toggleVersionNA(vr: VendorResearch, versionId: string) {
    const n = this.isVersionNA(vr, versionId);
    return this._upsertNA(vr, 'naVersions', versionId, !n).subscribe(() => {
      // if (!n) {
      //   this.showEditNotesVersion(vr, versionId);
      // }
    });
  }

  toggleCandiDateNA(vr: VendorResearch, candiDateId: string) {
    const n = this.isCandiDateNA(vr, candiDateId);
    return this._upsertNA(vr, 'naCandiDates', candiDateId, !n).subscribe(() => {
      // if (!n) {
      //   this.showEditNotesCandiDate(vr, candiDateId);
      // }
    });
  }


  getCandiDateColumnIds(customerEvent: CustomerEvent, cat: Category) {
    const result = this.getCandiDateColumns(customerEvent, cat).map((a) => a.id);
    return result;
  }

  getColumns(customerEvent: CustomerEvent, researchCat: Category): IColumn[] {
    const result = <any>[{ id: 'name' }];
    if (customerEvent.isUseDatesInResearch(researchCat)) {
      for (const d of customerEvent.info.candiDates) {
        result.push(
          ...customerEvent
            .getResearchVersions(researchCat.id)
            .map((v, i) => ({ id: v.id + d.id, version: v, versionIndex: i, candiDate: d }))
        );
      }
    } else {
      result.push(
        ...customerEvent
          .getResearchVersions(researchCat.id)
          .map((v, i) => ({
            id: v.id + 'voiDate', version: v, versionIndex: i, candiDate: { id: 'voiDate', description: '' },
          })));
    }
    return result;
  }
  getColumnIds(
    customerEvent: CustomerEvent,
    researchCat: Category,
    versionSuffix: boolean
  ) {
    const result = [
      ...this.getColumns(customerEvent, researchCat).map((a) => a.id),
    ];
    if (versionSuffix) {
      result.splice(0, 1);
      return result.map((s) => s + '_th');
    }
    return result;
  }
  isWarn(vr: VendorResearch, candiDateId: string, versionId: string, priceItemId: string, it: ComparePricesItemComponent) {
    return (!vr.isPriceSet(candiDateId, versionId, priceItemId)) && (!this.editMode || !it.editMode)
      && !it.isNA() && !vr.getNotes(candiDateId, versionId, priceItemId);
  }
}
