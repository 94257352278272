<div fxLayout="row wrap" fxLayoutAlign="center center" [fxLayoutGap]="expanded?'20px':'0px'" [formGroup]="form"
    class="p-x-10">
    <div *ngIf="expanded" class="buttons" fxFlex="0 0 50px" fxLayout="column" fxLayoutAlign="auto center">

        <mat-button-toggle  #internal [checked]="form?.get('internal').value" 
            *ngIf="!upsert.isPayment"
            (change)="upsert.internalChanged(internal.checked)">
            <mat-icon class="material-icons-outlined" *ngIf="!internal.checked">spa</mat-icon>
            <mat-icon *ngIf="internal.checked">spa</mat-icon>
        </mat-button-toggle>

        <mat-button-toggle *ngIf="!forcePayment && vendors?.length && !upsert?.isInternal"         
            [checked]="upsert.isPayment" (change)="upsert.typeChanged(payment.checked)" #payment>
            <mat-icon>euro</mat-icon>
        </mat-button-toggle>

        <mat-button-toggle value="bold" #flag [checked]="form?.get('flagged').value"
            (change)="upsert.flagChanged(flag.checked)" 
            *ngIf="!upsert?.isInternal">
            <mat-icon *ngIf="!flag.checked">outlined_flag</mat-icon>
            <mat-icon *ngIf="flag.checked">flag</mat-icon>
        </mat-button-toggle>

        <mat-button-toggle #highlight [checked]="upsert.highlight"
            (change)="upsert.highlightChanged(highlight.checked)"
             *ngIf="!upsert?.isInternal">
            <mat-icon *ngIf="!highlight.checked">gps_not_fixed</mat-icon>
            <mat-icon *ngIf="highlight.checked">gps_fixed</mat-icon>
        </mat-button-toggle>
    </div>
    <div class="text" fxLayout="column" fxFlex="1 1 0px" fxLayoutGap="5px">
        <!-- fxFlex="grow"  -->
        <mat-form-field fxFlex="grow" *ngIf="!upsert.isPayment || !expanded;else paymentMode" class="todo1">
            <mat-label *ngIf="!forcePayment;else addPaymentLabel">
                + To Do
            </mat-label>
            <ng-template #addPaymentLabel>
                <mat-label>+ Payment</mat-label>
            </ng-template>
            <input matInput formControlName='name' #nameEl (keyup)="keyUp($event)" maxlength="50"
                (focus)="expanded=true">
            <mat-error *ngIf="form.get('name').invalid">{{getErrorMessage( form.get('name'),'Contact Name')}}
            </mat-error>
        </mat-form-field>
        <ng-template #paymentMode>
            <div *ngIf="expanded" fxLayout="row wrap" class="payment" fxLayoutAlign="auto center">
                <mat-form-field appearance="fill" fxFlexLayout="auto end" class="payment-field">
                    <mat-label>Payment Amount</mat-label>
                    <input matInput type="number" class="right1" #amountEl min="0" (focus)="onFocus(amountEl)"
                        formControlName="amount" (keyup.enter)="create()">
                    <span matPrefix>€&nbsp;</span>
                    <!-- <span matSuffix>.00</span> -->
                    <mat-error *ngIf="form.get('amount').invalid">{{getErrorMessage( form.get('amount'),'Amount')}}
                    </mat-error>
                </mat-form-field>
                <mat-radio-group aria-label="Payment Method" color="primary" formControlName="paymentMethod">
                    <mat-radio-button checked="true" value="cash">Cash</mat-radio-button>
                    <mat-radio-button value="bank">Bank</mat-radio-button>
                </mat-radio-group>
                <mat-form-field *ngIf="upsert.paymentMethod==='bank'" appearance="fill" class="extra">
                    <mat-label>Extra Rate</mat-label>
                    <input matInput type="number" class="right1" #input2 min="0" max="100" formControlName="extraRate"
                        (focus)="onFocus(input2)">
                    <span matSuffix>%</span>
                </mat-form-field>
                <mat-checkbox class="has-a-part" formControlName="includesCommission">Has A Part</mat-checkbox>
            </div>
            <div fxLayout="row wrap" fxLayoutGap="20px">
                <mat-form-field appearance="fill" fxFlexLayout="auto end"
                    *ngIf="form?.get('includesCommission')?.value">
                    <mat-label>A Part Amount</mat-label>
                    <input matInput type="number" class="right1" #amountEl2 min="0" (focus)="onFocus(amountEl2)"
                        formControlName="commissionAmount" (keyup)="keyUp($event)">
                    <span matPrefix>€&nbsp;</span>
                    <mat-error *ngIf="form.get('commissionAmount').invalid">
                        {{getErrorMessage( form.get('commissionAmount'),'Amount')}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="vendors.length">
                    <mat-label>Vendor</mat-label>
                    <mat-select (openedChange)="upsert.datePickerOpen=$event" formControlName="vendorId" #vendor1
                        required (keyup.enter)="create()">
                        <mat-option *ngFor="let vendor of vendors" [value]="vendor.id">
                            {{vendor.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('vendorId').invalid">{{getErrorMessage( form.get('vendorId'),'Vendor')}}
                    </mat-error>
                </mat-form-field>
            </div>

        </ng-template>

        <mat-form-field *ngIf="expanded" class="details" fxFlex="grow">
            <mat-label>Details (optional)</mat-label>
            <textarea matInput placeholder="" #todoTextArea=matInput rows=2 formControlName="details"></textarea>
        </mat-form-field>
    </div>
    <div fxFlex="none" *ngIf="expanded">
        <div class="dueDate">
            <mat-form-field>
                <mat-label>Due date</mat-label>
                <input matInput [matDatepicker]="picker" (click)="picker.open()" formControlName="dueDate"
                    (keyup)="keyUp($event)" readonly required>
                <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle> -->
                <mat-datepicker startView="year" [startAt]="upsert.startAt()" #picker
                    (opened)="upsert.datePickerOpen=true" (closed)="upsert.datePickerOpen=false"></mat-datepicker>
                <button mat-icon-button matSuffix (click)="upsert.setToEventDate(picker)">
                    <mat-icon>event_available</mat-icon>
                </button>
                <mat-error *ngIf="form.get('dueDate').invalid">
                    {{getErrorMessage( form.get('dueDate'),'Due-Date')}}
                </mat-error>
            </mat-form-field>

        </div>
        <div class="assignee">
            <mat-form-field *ngIf="agency">
                <mat-label>Assignee</mat-label>
                <mat-select #assignee1 (openedChange)="upsert.datePickerOpen=$event" formControlName="assigneeId"
                    (keyup.enter)="cancel()">
                    <mat-option *ngFor="let member of agency.members" [value]="member.id">
                        {{member.name}}
                    </mat-option>
                </mat-select>
                <button mat-icon-button matSuffix *ngIf="assignee1.value"
                    (click)="assignee1.value=null;upsert.form.patchValue({assigneeId:null}) ;$event.stopPropagation(); ">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
    <div fxLayout="column">
        <button mat-icon-button *ngIf="!upsert.name?.empty && expanded" (click)="create()">
            <mat-icon>done</mat-icon>
        </button>
        <button mat-icon-button *ngIf="expanded" (click)="cancel()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>