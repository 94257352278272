import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MediaPopupContainerBaseComponent } from '@skylitup/flowergirl/features/media';
import { Media } from '@skylitup/flowergirl/core';

@Component({
  selector: 'flrgl-media-popup-container',
  templateUrl: 'media-popup-container.component.html',
  styles: [
    '.container {height:calc(80vh - 110px)}',
    'flrgl-media{height:100%}',
  ],
})
export class MediaPopupContainerComponent extends MediaPopupContainerBaseComponent {
  selectedMedia: Media[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) selectedMedia: Media[],
    private dialogRef: MatDialogRef<MediaPopupContainerComponent>
  ) {
    super();
    if (selectedMedia) {
      this.selectedMedia = selectedMedia;
    }
  }
}
