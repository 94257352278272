import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerEvent } from '@skylitup/flowergirl/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'flrgl-event-summary',
  templateUrl: './event-summary.component.html',
  styleUrls: ['./event-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventSummaryComponent implements OnInit {

  constructor(
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public customerEvent: CustomerEvent,
  ) { }

  ngOnInit(): void {
  }
  copySummaryToClipboard(summaryText: any) {
    summaryText.select();
    summaryText.setSelectionRange(0, 9999999); /*For mobile devices*/
    /* Copy the text inside the text field */
    document.execCommand("copy");
    this.toastrService.info(
      'Event-Summary copied to clipboard',
      null,
      {
        positionClass: 'toast-top-right',
        disableTimeOut: false,
        timeOut: 3000,
      }
    );    /* Alert the copied text */
  }
}
