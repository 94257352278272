import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BaseComponent } from '@skylitup/base/util';
import { takeUntil } from 'rxjs/operators';
import { EntityMultiAutocomplete } from './entity-multi-autocomplete';


@Component({
  selector: 'flrgl-entity-multi-autocomplete',
  templateUrl: 'entity-multi-autocomplete.component.html',
  styleUrls: ['entity-multi-autocomplete.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityMultiAutocompleteComponent extends BaseComponent
  implements AfterViewInit {
  @Input()
  control: EntityMultiAutocomplete<any>;
  @Output()
  cleared = new EventEmitter<void>();
  @Input()
  openOnClear = true;
  @ViewChild('input1')
  input1: ElementRef;
  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  clearInputText() {
    this.control.formControl.setValue(null);
    this.input1.nativeElement.value = '';
    // setTimeout(_ => {
    //   // this.control.removeAll();
    // }, 0);
  }
  removeAll(autoTrigger?: MatAutocompleteTrigger) {
    this.control.removeAll();
    this.cleared.next();
    if (autoTrigger && this.openOnClear) {
      setTimeout(() => autoTrigger.openPanel(), 0);
    }
  }
  ngAfterViewInit(): void {
    // this.control.cd = this.cd;
    this.control.optionsSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe(optionsSelected => {
        setTimeout(() => {
          this.input1.nativeElement.blur();
          this.clearInputText();
          this.cd.markForCheck();
          if (this.control.required) {
            if (!optionsSelected.length) {
              this.control.formControl.setErrors({ required: true });
            } else {
              this.control.formControl.setErrors(null);
            }
          }
        }, 0);
      });
  }
}
