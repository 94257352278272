<div class="lightbox-back" (click)="close()"></div>
<div class="lightbox-counter" *ngIf="properties.counter && images.length > 1">{{counter}}</div>

<div class="lightbox-container"
	[ngStyle]="containerStyles"
	(transitionend)="handleLightboxTransitionEnd($event)">

	<div class="lightbox-preloader" 
		*ngIf="animationMode === 'zoom-preloader' && currImageLoadingState === 'loading'">
		<div class="lightbox-spinner"></div>
	</div>

	<pinchzoom 
		id="pinch-zoom"
		class="lightbox-wrapper" 
		[class.lightbox-landscape]="landscapeMode"
		(events)="handlePinchEvents($event)"
		[linear-vertical-swipe]="true"
		[linear-horizontal-swipe]="true"
		[image-index]="0"
		[transition-duration]="300"
		#wrapper>

		<div class="lightbox-image-wrap" [ngClass]="'lightbox-'+firstSlidePosition"> 
			<img #imageFirst
				[src]="slidePath('first')" 
				*ngIf="slidePath('first')" />
		</div>

		<div class="lightbox-image-wrap" [ngClass]="'lightbox-'+secondSlidePosition">
			<div class="lightbox-spinner" 
				*ngIf="currImageLoadingState === 'loading' && showState === 'animation-end'"></div>

			<div class="lightbox-error" *ngIf="currImageLoadingState === 'error' && !closingState">Failed to load image</div>
				
			<img #imageSecond
				[src]="slidePath('second')"
				*ngIf="slidePath('second')"
				[class.lightbox-hide]="currImageLoadingState !== 'uploaded'" 
				(load)="onImageLoaded('curr')"
				(error)="onImageError($event)" />
		</div>
		
		<div class="lightbox-image-wrap" [ngClass]="'lightbox-'+lastSlidePosition">
			<img #imageLast
				[src]="slidePath('last')"
				*ngIf="slidePath('last')" />
		</div>
	</pinchzoom>
</div>