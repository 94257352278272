<ng-container *ngIf="auth$|async as auth">
    <ng-container *ngIf="chatTrackMap$ | async as chatTrackMap">
        <ng-container *ngIf="agency$|async as agency">
            <ng-container *ngIf="auth.isPlanner;else customerTopT">
                <div class='m-t-5' fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutAlign="start center">
                    <button class="hover1" mat-icon-button matTooltip="Add Vendor" [routerLink]="['vendor']"
                        *auth1="['planner']" (click)="addCustomer(agency,auth)">
                        <mat-icon>add</mat-icon>
                    </button>
                    <mat-form-field class="m-l-20">
                        <mat-label>
                            <mat-icon class="m-r-5">filter_list</mat-icon>Customer Account Name
                        </mat-label>
                        <input matInput [formControl]="customerNameFilter">
                        <button mat-icon-button matSuffix *ngIf="customerNameFilter.value"
                            (click)="customerNameFilter.setValue(null)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-button-toggle-group fxFlex="none" class="m-l-10 type-filter" [formControl]="type">
                        <mat-button-toggle value="client" matTooltip="Customer Accounts">
                            Clients
                        </mat-button-toggle>
                        <mat-button-toggle value="demo" matTooltip="Demo Accounts">
                            Demo
                        </mat-button-toggle>
                        <mat-button-toggle value="lead" matTooltip="Leads">
                            Leads
                        </mat-button-toggle>
                        <mat-button-toggle value="other" matTooltip="Special Purpose Accounts">
                            Other
                        </mat-button-toggle>
                        <mat-button-toggle value="archive" matTooltip="Archive">
                            <mat-icon fontSet="fas" fontIcon="fa-archive"></mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                    <mat-button-toggle-group fxFlex="none" class="m-l-12" [formControl]="listStyle">
                        <mat-button-toggle value="cards" matTooltip="Cards list">
                            <mat-icon>recent_actors</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="cal" matTooltip="Group by month">
                            <mat-icon>date_range</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="cal-compact" matTooltip="Group by month-compact">
                            <mat-icon>calendar_view_day</mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                    <div fxFlex="grow"></div>
                    <a class="m-l-5" mat-stroked-button [routerLink]="['edit','manage-users']" fxFlex="none"
                        matTooltip="Manage Users" *auth1="['planner']">
                        <mat-icon>people</mat-icon>
                    </a>
                    <a class="m-l-5" mat-stroked-button [routerLink]="['edit','vendors']" fxFlex="none"
                        matTooltip="Manage Vendors" *auth1="['planner']">
                        <mat-icon>store</mat-icon>
                    </a>
                    <a class="m-l-5" mat-stroked-button [routerLink]="['big-plan']" fxFlex="none" matTooltip="Big Plan"
                        *auth1="['planner']">
                        <mat-icon>event_note</mat-icon>
                    </a>
                </div>
            </ng-container>
            <ng-template #customerTopT>
                <div class='m-t-5' fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutAlign="start center">
                    <mat-button-toggle-group fxFlex="none" class="m-l-12" [formControl]="listStyle">
                        <mat-button-toggle value="cal" matTooltip="Group by month">
                            <mat-icon>date_range</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="cal-compact" matTooltip="Group by month-compact">
                            <mat-icon>calendar_view_day</mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </ng-template>
            <div *ngIf="creatingAccountFlag"> Creating Customer Account... <mat-progress-bar mode="indeterminate">
                </mat-progress-bar>
            </div>
            <mat-divider class="m-y-5"></mat-divider>
            <div *ngIf="listStyle.value as style">
                <!-- <div *ngFor=" let "></div> -->
                <ng-container *ngIf="style=='cards'">
                    <ng-container *ngTemplateOutlet="upcomingEventsStatsT"></ng-container>
                    <div class="container" fxLayout="row wrap" fxLayoutGap="10px">
                        <ng-container *ngFor="let customer of customersUpcoming$ | async">
                            <ng-container
                                *ngTemplateOutlet="customer1;context:{customer:customer,compact:!auth.isPlanner}">
                            </ng-container>
                        </ng-container>
                    </div>
                    <mat-divider class="m-y-5"></mat-divider>
                    <ng-container *ngTemplateOutlet="pastEventsStatsT"></ng-container>
                    <div class="container" fxLayout="row wrap" fxLayoutGap="10px">
                        <ng-container *ngFor="let customer of customersPast$ | async">
                            <ng-container
                                *ngTemplateOutlet="customer1;context:{customer:customer,compact:auth.isCustomer}">
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="style=='cal'">
                    <ng-container *ngTemplateOutlet="upcomingEventsStatsT"></ng-container>
                    <div>
                        <ng-container *ngFor="let c of customersUpcomingGroupedByMonth$ | async">
                            <flrgl-calendar-month [start]="c.date" [longWeekdays]="true" [events]="c.events"
                                [chatTrackMap]="chatTrackMap" [printEvents]="true" (clicked)="clickedEvent($event)">
                            </flrgl-calendar-month>
                        </ng-container>
                    </div>
                    <mat-divider class="m-y-5"></mat-divider>
                    <ng-container *ngTemplateOutlet="pastEventsStatsT"></ng-container>
                    <div>
                        <ng-container *ngFor="let c of customersPastGroupedByMonth$ | async">
                            <flrgl-calendar-month [start]="c.date" [longWeekdays]="true" [events]="c.events"
                                [printEvents]="true" (clicked)="clickedEvent($event)">
                            </flrgl-calendar-month>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="style=='cal-compact'">
                    <ng-container *ngTemplateOutlet="upcomingEventsStatsT"></ng-container>
                    <div>
                        <ng-container *ngFor="let c of customersUpcomingGroupedByMonth$ | async">
                            <mat-divider></mat-divider>
                            <div>
                                {{c.month}} {{c.year}}
                            </div>
                            <div class="container" fxLayout="row wrap" fxLayoutGap="10px">
                                <ng-container *ngFor="let customer of c.customers">
                                    <ng-container
                                        *ngTemplateOutlet="customer1;context:{customer:customer,compact:true}">
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <mat-divider class="m-y-5"></mat-divider>
                    <ng-container *ngTemplateOutlet="pastEventsStatsT"></ng-container>
                    <div>
                        <ng-container *ngFor="let c of customersPastGroupedByMonth$ | async">
                            <mat-divider></mat-divider>
                            <div>
                                {{c.month}} {{c.year}}
                            </div>
                            <div class="container" fxLayout="row wrap" fxLayoutGap="10px">
                                <ng-container *ngFor="let customer of c.customers">
                                    <ng-container
                                        *ngTemplateOutlet="customer1;context:{customer:customer,compact:true}">
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-template let-customer="customer" let-compact="compact" #customer1>
                    <div *ngIf="asCustomer(customer) as customer" (click)="navigateToCustomer(customer,rippler)"
                        class='customer-container mat-elevation-z2  p-5 m-b-5 pointer1'
                        [ngClass]="{demo:customer.isDemo}">
                        <div fxLayout="row" class="top" (click)="navigateToCustomer(customer,rippler)">
                            <div>
                                <a mat-icon-button matTooltip="Edit Customer Account"
                                    (click)=" $event.stopPropagation() || navigateToEditCustomer(customer)"
                                    *ngIf="auth.isPlanner;else editNoPlannerT">
                                    <mat-icon class="material-icons-outlined">edit_outline</mat-icon>
                                </a>
                            </div>
                            <ng-template #editNoPlannerT>
                                <button mat-icon-button disabled *ngIf="getSumUnreadForCustomer(customer,chatTrackMap)">
                                </button>
                            </ng-template>
                            <div class="center1 customer-name p-l-5" fxFlex="grow">
                                <!-- <mat-icon *ngIf="customer.isDemo" fontSet="fas" fontIcon="fa-lock"></mat-icon> -->
                                {{customer.data.name}}
                            </div>
                            <button mat-icon-button disabled color="primary"
                                *ngIf="getSumUnreadForCustomer(customer,chatTrackMap) as unread1;else noChatPlannerT"
                                [matBadge]="unread1" matBadgePosition="above after" [matBadgeHidden]="!unread1"
                                matBadgeSize='small' matBadgeOverlap='true'>
                                <mat-icon class="material-icons chat1">forum</mat-icon>
                            </button>
                            <ng-template #noChatPlannerT>
                                <button mat-icon-button disabled *ngIf="auth.isPlanner">
                                </button>
                            </ng-template>
                        </div>
                        <div class="datesx">
                            <div class="center1 pointer1 date" *ngFor="let eventInfo of customer.eventsInfo">
                                {{eventInfo.name}} <span *ngIf="!eventInfo.isDateBooked;else dSymbol">?</span>
                                <ng-template #dSymbol> / </ng-template>
                                {{eventInfo.earliestDateDisplay}}<span *ngIf="!eventInfo.isDateBooked">?</span>
                                <!-- <span> {{getEventDate(customer)}}</span> -->
                            </div>
                        </div>
                        <mat-divider *ngIf="!compact"></mat-divider>
                        <div class="assignees" *ngIf="!compact">
                            <div class="title">Assignees</div>
                            <div class="p-x-5" fxLayout="row wrap">
                                <span *ngFor="let assignee of customer.assignees;let i=index">
                                    <span class="name planner">{{assignee?.username}}</span> <span
                                        *ngIf="i<(customer.assignees.length-1)"> ,
                                    </span>
                                </span>
                            </div>
                        </div>
                        <mat-divider class="m-y-3" *ngIf="!compact"></mat-divider>
                        <div class="customers" *ngIf="!compact">
                            <div class="title">Customer Users</div>
                            <div class="p-x-5" fxLayout="row wrap">
                                <span *ngFor="let user of customer.customerUsers;let i=index" class="m-b-2">
                                    <ng-container *ngIf="user.active">
                                        <span class="name" [ngClass]="{'planner':user.agencyScope}">
                                            <ng-container [ngSwitch]="user.type">
                                                <span *ngSwitchCase="'poc'" matTooltip="Names Hidden / Can Edit">
                                                    <mat-icon fontSet="fas" fontIcon="fa-unlock"></mat-icon>
                                                    {{user.username}}
                                                </span>
                                                <span *ngSwitchCase="'readonly'" matTooltip="Read Only">
                                                    <mat-icon fontSet="fas" fontIcon="fa-lock"></mat-icon>
                                                    {{user.username}}
                                                </span>
                                                <span *ngSwitchDefault matTooltip="Normal Access">
                                                    <mat-icon fontSet="fas" fontIcon="fa-lock-open"></mat-icon>
                                                    {{user.username}}
                                                </span>
                                            </ng-container>
                                        </span> <span *ngIf="i<(customer.customerUsers.length-1)"> ,
                                        </span>
                                    </ng-container>

                                </span>
                            </div>
                        </div>
                        <div class="bg" mat-ripple #rippler="matRipple" [matRippleDisabled]="true"
                            [matRippleCentered]="true"></div>
                    </div>

                </ng-template>
            </div>
            <ng-template #upcomingEventsStatsT>
                <div class=" p-10" *ngIf="auth.isPlanner">
                    Customers with Upcoming Events
                    <ng-container *ngTemplateOutlet="stats;context:{yearStats:statsUpcoming$|async}">
                    </ng-container>
                </div>
            </ng-template>
            <ng-template #pastEventsStatsT>
                <div class="p-10" *ngIf="auth.isPlanner">
                    Customers with Past Events <ng-container
                        *ngTemplateOutlet="stats;context:{yearStats:statsPast$|async}">
                    </ng-container>
                </div>
            </ng-template>
            <ng-template #stats let-yearStats="yearStats">
                <ng-container *ngIf="asYearStats(yearStats) as yearStats">
                    ( {{yearStats.total}} in Total<ng-container *ngIf="yearStats.total!=0">: </ng-container> <span
                        *ngFor="let y of yearStats.years;let i=index">{{y.n}} in {{y.y}}<span
                            *ngIf="i<(yearStats.years.length-1)">,
                        </span>
                    </span> )
                </ng-container>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-container>
<router-outlet></router-outlet>