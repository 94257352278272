import { Injectable } from '@angular/core';

import { Observable, combineLatest } from 'rxjs';
import { Customer } from '../../domain-model/customer/customer.model';
import { CustomerEvent } from '../../domain-model/customer-event/customer-event.model';
import { CustomerService } from '../../domain-model/customer/customer.service';
import { CustomerEventService } from '../../domain-model/customer-event/customer-event.service';

import { Store, createSelector } from '@ngrx/store';

import { map, debounce, debounceTime, startWith } from 'rxjs/operators';
import { Dictionary } from '@ngrx/entity';

import * as moment from 'moment';
import { Agency } from '../../domain-model/agency/agency.model';
import { Category } from '../../domain-model/system/system.model';
import { Vendor } from '../../domain-model/vendor/vendor.model';
import { UIState } from '../../domain-model/ui/state/ui.reducer';
import { EventItem } from '../../domain-model/event-item/event-item.model';
import { Loc } from '../../domain-model/agency/location.model';
import {
  AGENCY_KEYS,
  CUSTOMER_KEYS,
  CUSTOMER_EVENT_KEYS,
} from '../../domain-model/data-index';
import { Auth } from '../../domain-model/auth/auth.model';
import { AgencyService } from '../../domain-model/agency/agency.service';
import { SystemService } from '../../domain-model/system/system.service';
import { VendorService } from '../../domain-model/vendor/vendor.service';
import { AuthService } from '../../domain-model/auth/auth.service';
import { EventItemService } from '../../domain-model/event-item/event-item.service';
import { VendorResearchService } from '../../domain-model/vendor-research/vendor-research.service';
import { AppState } from '@skylitup/flowergirl/core';
import { selectRoutePathSegments } from '@skylitup/base/util';

export interface AllContext {
  agency: Agency;
  customer: Customer;
  customerEvent: CustomerEvent;
  category: Category;
  vendor: Vendor;
  ui: UIState;
  eventItem: EventItem;
}
@Injectable({ providedIn: 'root' })
export class ContextService {
  agency$: Observable<Agency>;
  customer$: Observable<Customer>;
  customerEvent$: Observable<CustomerEvent>;
  category$: Observable<Category>;
  agencyId$: Observable<string>;
  customerId$: Observable<string>;
  customerEventId$: Observable<string>;
  categoryId$: Observable<string>;
  researchCatId$: Observable<string>;
  researchCategory$: Observable<Category>;
  all$: Observable<AllContext>;
  contextPath$: Observable<string[]>;
  contextEditPath$: Observable<string[]>;
  vendor$: Observable<Vendor>;
  loc: {
    locationTree$: Observable<Loc[]>;
    locations$: Observable<Loc[]>;
    locationMap$: Observable<Dictionary<Loc>>;
  } = {} as any;
  edit$: Observable<boolean>;
  userDisplayName$: Observable<string>;
  userId$: Observable<string>;
  auth$: Observable<Auth>;
  eventItem$: Observable<EventItem>;
  constructor(
    public agencyService: AgencyService,
    public customerService: CustomerService,
    public customerEventService: CustomerEventService,
    public systemService: SystemService,
    public vendorService: VendorService,
    public authService: AuthService,
    public eventItemService: EventItemService,
    public vendorResearchService: VendorResearchService,
    public store: Store
  ) {
    this.agency$ = this.agencyService.agencyInContext$;
    this.agencyId$ = this.agencyService.entityIdInContext$;
    this.customer$ = this.customerService.customerInContext$;
    this.customerId$ = this.customerService.entityIdInContext$;
    this.customerEvent$ = this.customerEventService.customerEventInContext$;
    this.researchCatId$ = this.vendorResearchService.categoryIdInContext$;
    this.researchCategory$ = combineLatest([
      this.systemService.categoryMap$,
      this.researchCatId$,
    ]).pipe(map(([categoryMap, researchCatId]) => categoryMap[researchCatId]));
    // .pipe(
    //   debounceTime(500)
    // );
    this.customerEventId$ = this.customerEventService.entityIdInContext$;
    this.category$ = this.systemService.categoryInContext$;
    this.categoryId$ = this.systemService.categoryIdInContext$;
    this.vendor$ = this.vendorService.entityInContext$;
    this.eventItem$ = this.customerEventService.eventItemInContext$;
    this.loc.locationTree$ = this.agencyService.locationTreeInContext$;
    this.loc.locations$ = this.agencyService.locationsInContext$;
    this.loc.locationMap$ = this.agencyService.locationMap$;
    this.userId$ = this.authService.userId$;
    this.userDisplayName$ = this.authService.displayName$;
    this.auth$ = this.authService.auth$;

    const a$ = combineLatest([
      this.agency$,
      this.customer$.pipe(startWith(null as Customer)),
      this.vendor$,
      this.customerEvent$.pipe(startWith(null as CustomerEvent)),
      this.category$,
      this.store.select((s: AppState) => s.ui),
    ]).pipe(
      map(([agency, customer, vendor, customerEvent, category, ui]) => ({
        agency,
        customer,
        vendor,
        customerEvent,
        category,
        ui,
      }))
    );
    this.all$ = combineLatest([
      this.eventItem$.pipe(startWith(null as EventItem)),
      a$,
    ]).pipe(map(([eventItem, a]) => ({ ...a, eventItem })));

    this.edit$ = this.store.select((s: AppState) => s?.router?.state?.edit);
    const routerPathSegments$ = this.store.select(selectRoutePathSegments);
    // this.store.select((s: AppState) => s).subscribe(o => console.log(o));
    this.contextPath$ = combineLatest([
      this.agencyId$,
      this.customerId$,
      this.customerEventId$,
      this.researchCatId$.pipe(startWith(null as string)),
      routerPathSegments$
    ]).pipe(
      map(([agencyId, customerId, customerEventId, researchCatId, pathSegments]) => {
        const result = ['/'];
        if (agencyId) {
          result.push(AGENCY_KEYS.name, agencyId);
          if (pathSegments.includes('vendor')) {
            result.push('edit');
          }
          if (customerId) {
            result.push(CUSTOMER_KEYS.name, customerId);
            if (customerEventId) {
              result.push(CUSTOMER_EVENT_KEYS.name, customerEventId);
              if (researchCatId) {
                if (pathSegments.includes('vendor-research')) {
                  result.push('vendor-research', researchCatId);
                } else if (pathSegments.includes('compare-prices')) {
                  result.push('compare-prices', researchCatId);
                }
              }
            }
          }
        }
        if (pathSegments.includes('vendor')) {
          result.push('vendors');
        }
        // console.log('nnnn', pathSegments);
        // console.log('xxx', result);
        return result;
      })
    );
    this.contextEditPath$ = combineLatest([
      this.agencyId$,
      this.customerId$,
      this.customerEventId$,
      // this.store.select((s: AppState) => s.ui),
      routerPathSegments$
    ]).pipe(
      map(([agencyId, customerId, customerEventId, pathSegments]) => {
        const result = ['/'];
        if (agencyId) {
          result.push(AGENCY_KEYS.name, agencyId, 'edit');
          if (customerId) {
            result.push(CUSTOMER_KEYS.name, customerId);
            if (customerEventId) {
              result.push(CUSTOMER_EVENT_KEYS.name, customerEventId);
            }
          }
          // if (ui && ui.focusCat) {
          //   result.push('event-cat-vendors', ui.focusCat);
          // }
          if (pathSegments.includes('vendor')) {
            result.push('vendors');
          }
        }

        return result;
      })
    );

  }
}
