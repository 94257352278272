import { Observable, of, from, interval, combineLatest } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  map,
  tap,
  share,
  distinctUntilChanged,
  mergeMap,
  first,
  filter,
  catchError,
  startWith,
  mapTo,
  take,
} from 'rxjs/operators';
import { Store, createSelector, createFeatureSelector } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { AuthService } from '../../domain-model/auth/auth.service';
import { UserCardService } from '../../domain-model/user-card/user-card.service';
import { ENV, Environment } from '@skylitup/base/util';
import {
  refreshIdTokenResult,
  idTokenResult,
  idTokenResultGuest,
} from '../../domain-model/auth/state/auth.actions';

@Injectable({ providedIn: 'root' })
export class WebAuthService extends AuthService {
  constructor(
    private angularFireAuth: AngularFireAuth,
    store: Store,
    private updates$: Actions,
    userCardService: UserCardService,
    @Inject(ENV) private env: Environment
  ) {
    super(store, userCardService);

    super.authResultEvents$ = combineLatest([
      this.angularFireAuth.authState.pipe(
        distinctUntilChanged(null, (i) => i?.metadata.lastSignInTime)
      ),
      this.updates$.pipe(
        ofType(refreshIdTokenResult),
        mapTo(true),
        startWith(true)
      ),
    ]).pipe(

      mergeMap(([authState, forceRefreshToken]) => {
        if (!authState) {
          return of(null);
        } else {
          return from(authState.getIdTokenResult(forceRefreshToken)).pipe(
            first(),
            map((tokenResult: any) => {
              if (!tokenResult) {
                return null;
              } else {
                return {
                  payload: {
                    displayName: authState.displayName,
                    uid: authState.uid,
                    email: authState.email,
                    emailVerified: authState.emailVerified,
                    access: {
                      owner: tokenResult.claims?.owner,
                      member: tokenResult.claims?.member,
                      memberRestricted: tokenResult.claims?.memberRestricted,
                      memberSales: tokenResult.claims?.memberSales,
                      assignee: tokenResult.claims?.assignee,
                      customer: tokenResult.claims?.customer,
                      readonly: tokenResult.claims?.readonly,
                      agency: tokenResult.claims?.agency,
                    },
                  },
                };
              }
            })
          );
        }
      }),
      distinctUntilChanged(null, (d) => JSON.stringify(d)),
      map((tokenResultData) =>
        tokenResultData ? idTokenResult(tokenResultData) : idTokenResultGuest()
      ),
      share()
    );
  }

  refreshToken() {
    this.angularFireAuth.currentUser
      .then((user) => user?.getIdTokenResult(true))
      .then((t) => console.log('forced', t));
  }


  login(email: string, password: string): Observable<string> {
    return from(
      this.angularFireAuth
        .signInWithEmailAndPassword(email, password)
        .then(() => null)
        .catch((error) => {
          // var errorCode = error.code;
          return error.message;
        })
    );
  }

  logout(): Observable<boolean> {
    this.angularFireAuth.signOut();
    return of(true);
  }
  isSignInWithEmailLink(url: string): Observable<boolean> {
    return from(this.angularFireAuth.isSignInWithEmailLink(url).then(a => { console.log('aaa', a); return a; })).pipe(tap(r => console.log('rrr', r)));
  }

  signInWithEmailLink(email: string, url: string): Observable<boolean> {
    return from(
      this.angularFireAuth.signInWithEmailLink(email, url).then((_) => true)
    );
  }

  sendSignInLinkToEmail(email: string): Observable<boolean> {
    console.log(this.env.domainUrl + '/login');
    return from(
      this.angularFireAuth
        .sendSignInLinkToEmail(email, {
          url: this.env.domainUrl + '/login',
          handleCodeInApp: true,
        })
        .then((_) => true)
    );
  }
  sendResetPasswordEmail(email: string): Observable<boolean> {
    console.log(this.env.domainUrl + '/login');
    return from(
      this.angularFireAuth
        .sendPasswordResetEmail(email, {
          url: this.env.domainUrl + '/login',
          handleCodeInApp: true,
        })
        .then((_) => true)
    );
  }

  confirmPasswordReset(code: string, password: string): Observable<string> {
    console.log(this.env.domainUrl + '/login');
    return from(
      this.angularFireAuth
        .verifyPasswordResetCode(code)
        .then((email) =>
          this.angularFireAuth
            .confirmPasswordReset(code, password)
            .then(() => email)
        )
    );
  }
}
