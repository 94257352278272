<div fxLayout='row' fxLayoutGap="10px">
    <mat-form-field class="date-field">
        <mat-label class='color-warn'> Time-travel Date </mat-label>
        <input class='color-warn' matInput [formControl]="date1" readonly [matDatepicker]="picker1"
            (click)="picker1.open()">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="time-field">
        <mat-label class='color-warn'> Time </mat-label>
        <input class='color-warn' matInput [formControl]="time1" type="time">
    </mat-form-field>
</div>