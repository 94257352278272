import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { GoBackBaseComponent } from '@skylitup/flowergirl/ui';

@Component({
  selector: 'flrgl-go-back',
  templateUrl: 'go-back.component.html',
  styleUrls: ['go-back.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoBackComponent extends GoBackBaseComponent {
  @Input()
  path: string[] = ['..'];
  @Input()
  align = 'end';
  constructor() {
    super();
  }
}
