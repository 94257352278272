import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BudgetItemCalculationComponent } from '../budget-item-calculation/budget-item-calculation.component';
import { BudgetItemBaseComponent } from '@skylitup/flowergirl/features/budget';
import {
  EventItemService,
  calculationTypeLabel,
} from '@skylitup/flowergirl/core';

@Component({
  selector: 'flrgl-budget-item',
  templateUrl: 'budget-item.component.html',
  styleUrls: ['budget-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetItemComponent extends BudgetItemBaseComponent {
  @Input()
  editable = false;
  constructor(
    public dialog: MatDialog,
    private eventItemService: EventItemService
  ) {
    super();
  }
  onFocus(input: any) {
    input.select();
  }
  editCalculation() {
    this.dialog
      .open(BudgetItemCalculationComponent, {
        data: { item: this.item.data, customerEvent: this.item.event },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((d) => {
        if (d) {
          this.eventItemService.update({ ...d, meta: this.item.data.meta });
        }
      });
    // this.dialog.closeAll()
  }
  getCalculationLabel() {
    return calculationTypeLabel(this.item?.data);
  }
  getCalculationNumber() {
    return this.item?.calcNumber || 0;
  }
  getCalculationItemPrice() {
    return this.item?.calcItemPrice || 0;
  }
}
