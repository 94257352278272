import { Action, createReducer, on } from '@ngrx/store';
import { LoginResponse } from '../auth.model';
import {
  idTokenResult,
  logoutSuccess,
  idTokenResultGuest,
  loginSuccess,
  logout,
  sendSignInLinkToEmail,
  emailInStorage as emailInStorageAction,
  impersonateCustomer,
  timeTravel,
  authStorage
} from './auth.actions';
export interface AuthState extends LoginResponse {
  loggedIn: boolean;
  loggingOut: boolean;
  localstorageRead: false;
  emailInStorage: string;
  impersonatingCustomer: boolean;
  timeTravelling: boolean;
}

const initialState: AuthState = {
  loggedIn: null,
  loggingOut: false,
  displayName: null,
  uid: null,
  email: null,
  emailVerified: false,
  access: {
    owner: [],
    member: [],
    memberRestricted: [],
    memberSales: [],
    assignee: [],
    customer: [],
    readonly: [],
    agency: []
  },
  localstorageRead: false,
  emailInStorage: null,
  impersonatingCustomer: false,
  timeTravelling: false
};

const reducer = createReducer(
  initialState,
  on(sendSignInLinkToEmail, (state, { email }) => ({
    ...state,
    emailInStorage: email
  })),
  on(emailInStorageAction, (state, { email }) => ({
    ...state,
    emailInStorage: email
  })),
  on(idTokenResult, (state, { payload }) => ({
    loggedIn: true,
    ...payload,
    emailInStorage: payload.email
  })),
  on(impersonateCustomer, (state, { impersonatingCustomer }) => ({
    ...state,
    impersonatingCustomer
  })),
  on(timeTravel, (state, { timeTravelling }) => ({
    ...state,
    timeTravelling
  })),
  on(
    authStorage,
    (state, { impersonatingCustomer, emailInStorage, timeTravelling }) => ({
      ...state,
      localstorageRead: true,
      impersonatingCustomer,
      timeTravelling,
      emailInStorage
    })
  ),
  on(loginSuccess, state => ({ ...state, loggedIn: true })),
  on(idTokenResultGuest, state => ({
    ...initialState,
    loggedIn: false,
    emailInStorage: state.emailInStorage
  })),

  on(logout, state => ({
    ...initialState,
    loggingOut: true
  })),
  on(logoutSuccess, state => ({
    ...initialState,
    loggedIn: false,
    loggingOut: false
  }))
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return reducer(state, action);
}
