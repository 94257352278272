import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getErrorMessageHandler } from '@skylitup/base/util';


@Component({
    templateUrl: 'budget-research-item-notes-edit.html',
    styles: [''],
})
export class BudgetResearchItemNotesEdit {
    form = new FormGroup({ notes: new FormControl('', Validators.required) });
    get notes() {
        return this.form.get('notes');
    }

    getErrorMessage = getErrorMessageHandler;
    constructor(
        private dialogRef: MatDialogRef<BudgetResearchItemNotesEdit>,
        @Inject(MAT_DIALOG_DATA)
        data: { notes: string },
    ) {
        this.notes.setValue(data.notes);
    }
    keyUp(code: number) {
        if (code === 13) {
            this.dialogRef.close(this.notes.value || "");
        }
    }
}