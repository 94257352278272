import {
  EntityData,
  EntityKeys,
  Timestamp
} from '@skylitup/base/ngrx-data-fire';

export const USER_CARD_KEYS: EntityKeys = {
  name: 'user-card',
  plural: 'user-cards'
};

export type CustomerAccessType = 'full' | 'readonly';

export interface UserAccessCustomer {
  active: boolean; // if false it's blocked
  type: CustomerAccessType;
  vendorsMasked: boolean;
  expiryDate?: Date | Timestamp | null;
}
export interface UserCardData extends EntityData {
  authUId: string;
  username: string;
  email: string;

  createDate: Date | Timestamp;
  invitationDate?: Date | Timestamp;
  joinedDate?: Date | Timestamp;
  // agency-users concerns

  agencyScope: boolean;
  agencyReadLevel: 'full' | 'restricted' | 'sales';
  agencyActive: boolean;
  // customer-users concerns
  customerScope: string[];
  access: {
    [customerId: string]: UserAccessCustomer;
  };
  hasExpiry: boolean;

  // accessSerialized: {
  //   [customerId: string]: {
  //     active: boolean; // if false it's blocked
  //     type: CustomerAccessType;
  //     expiryDate?: Date | Timestamp;
  //   };
  // }
}
