import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '@skylitup/base/util';
import { EventItem } from '@skylitup/flowergirl/core';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class BudgetItemBaseComponent extends BaseComponent
  implements OnInit {
  @Input()
  item: EventItem;
  @Output()
  budgetChange: EventEmitter<number> = new EventEmitter();
  budgetControl = new FormControl();
  constructor() {
    super();
    this.budgetControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        debounceTime(1200)
      )
      .subscribe((v) => this.budgetChange.next(v));
  }

  ngOnInit() {
    this.budgetControl.setValue(this.item.data.budget, { emitEvent: false });
  }
  abstract editCalculation();
}
