// export const environment = {
//   production: false,
//   api_url: 'http://127.0.0.1:4000',
//   baseRoutePath: ''

import { InjectionToken } from '@angular/core';

// };
export const ENV = new InjectionToken<string>('ENV');

export interface Environment {
  production: boolean;
  domainUrl: string;
}
