<div class="chat-header" fxLayout="row">
    <button mat-button class="dialog-obj clickable1 underline1" (click)="navigateToLink()" fxLayoutAlign="center center"
        fxFlex="5 1 auto">
        <span class="text1x">{{text1()}}</span>
        <mat-icon class='m-l-8'>launch</mat-icon>
    </button>
    <mat-divider [vertical]="true"></mat-divider>
    <button mat-button class="media clickable1 underline1" (click)="navigateToMedia(false)"
        fxLayoutAlign="center center" fxFlex="none">
        <mat-icon>image</mat-icon>
        <span class="text1">Images</span>
    </button>
    <mat-divider [vertical]="true"></mat-divider>
    <button mat-button class="media clickable1 underline1" (click)="navigateToMedia(true)" fxLayoutAlign="center center"
        fxFlex="none">
        <mat-icon>description</mat-icon>
        <span class="text1">Docs
            <span *ngIf="docsNum$ | async as docsNum" class="docs-number"> ({{docsNum}})</span>
        </span>
    </button>
    <mat-divider [vertical]="true"></mat-divider>
</div>