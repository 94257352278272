import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AgencyService } from '../../domain-model/agency/agency.service';

@Injectable({
  providedIn: 'root',
})
export class SingleAgencyGuard implements CanActivate {
  constructor(private agencyService: AgencyService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.agencyService.agencies$.pipe(
      // tap(o=>console.log('pppppppp',o)),
      filter((o) => !!o && !!o.length), //
      // first(),
      map((agencies) => {
        if (agencies.length === 1) {
          return this.router.parseUrl(agencies[0].url);
        } else {
          return true;
        }
      })
    );
  }
}
