import { NgModule } from '@angular/core';
import { FlowergirlXplatWebUiModule } from '@skylitup/flowergirl/xplat/web/ui';
import { FlowergirlFeaturesMediaModule } from '@skylitup/flowergirl/features/media';
import { EditDocMetaPopupComponent, MediaComponent } from './components/media/media.component';
import { MediaPopupContainerComponent } from './components/media-popup-container/media-popup-container.component';

@NgModule({
  imports: [FlowergirlFeaturesMediaModule, FlowergirlXplatWebUiModule],
  declarations: [MediaComponent, MediaPopupContainerComponent, EditDocMetaPopupComponent],
  exports: [],
})
export class FlowergirlXplatWebFeaturesMediaModule { }
