import { Observable } from 'rxjs';
import { EffectNotification } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

export abstract class NotificationService {
  trackCompletion(resolvedEffects$: Observable<EffectNotification>) {
    return resolvedEffects$.pipe(
      tap(o => {
        if (o.notification.kind === 'C' || o.notification.kind === 'E') {
          this.warning(
            `${o.sourceName} - ${String(o.propertyName)}`,
            'EFFECT COMPLETED'
          );
        }
      })
    );
  }
  abstract warning(hint: string, msg: string): void;
  abstract error(hint: string, msg: string): void;
}
