import {
  EntityData,
  EntityKeys,
  Timestamp
} from '@skylitup/base/ngrx-data-fire';

export const MAIL_NOTIFICATION_KEYS: EntityKeys = {
  name: 'mail-notification-track',
  plural: 'mail-notifications-track'
};
export const MAIL_NOTIFICATION_LIST_LENGTH = 100;
export interface ChatSnapshotData {
  totalChats: number;
  chatsRead: number;
  lastEmailSentDate: Date | Timestamp;
}
export interface MailNotificationsTrackData extends EntityData {
  email: string,
  code: string,
  //   uId: string; authUid will be the same as the document
  chatSnapshot: {
    [eventId: string]: ChatSnapshotData;
  };
  history: MailNotification[];
}

export interface MailNotification {
  reason: string;
  date: Date | Timestamp
}