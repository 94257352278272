import { EntityData, EntityKeys, Timestamp } from '@skylitup/base/ngrx-data-fire';
export const USER_TRACK_SESSION_DURATION_MINS = 10;
export const SESSIONS_HISTORY_LIMIT = 300;
// ID IS ONE-TO-ONE WITH AUTH-UID (userCard.authUId)
export const USER_TRACK_KEYS: EntityKeys = {
    name: 'user-track',
    plural: 'users-track'
};

export interface UserTrackData extends EntityData {
    lastLogin?: Date | Timestamp;
    lastActive?: Date | Timestamp;
    currentSession?: UserTrackSessionData;
    history?: UserTrackSessionData[];
    name?: string;
}

export interface UserTrackSessionData {
    lastHeartBeat?: Date | Timestamp;
    start?: Date | Timestamp;
}