<div fxLayout="column">
    <div *ngIf="selectMode" class="selections">
        <div class="center">Selections</div>
        <div class="center empty" *ngIf="selectedMedia.length==0">No selections made yet</div>
        <div class="img-container p-y-15 " fxLayoutAlign="start stretch" fxLayout="row wrap" fxLayoutGap='2px'
            cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="reorderSelection($event)"
            lightbox-group>
            <div cdkDrag class="img-box m-x-10 p-b-4 "
                *ngFor="let media of selectedMedia;trackBy:trackByIdFn;let i=index" fxLayout="column" [matBadge]="i+1"
                matBadgeColor="primary" matBadgeOverlap="true" matBadgePosition="after below">
                <mat-checkbox checked="true" #c class="hover1" labelPosition="before"
                    (change)="mediaSelectionChanged(c.checked, media)">
                </mat-checkbox>
                <div fxLayout="column" fxLayoutAlign="start center" fxFlex="grow">
                    <img [src]="media.urlSmall" lightbox [fullImage]="{path:media.urlLarge}">
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
    </div>

    <ng-container *ngIf="selectMode;else tabsT">
        <ng-container *ngTemplateOutlet="imagesContainer"> </ng-container>
    </ng-container>
    <ng-template #tabsT>
        <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabChanged($event)"
            [selectedIndex]="mediaTabIndex$ | async">
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="m-r-15">image</mat-icon>
                    <span class="text1"> Images </span>
                </ng-template>
                <ng-template matTabContent>
                    <ng-container *ngTemplateOutlet="imagesContainer"> </ng-container>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="m-r-15">description</mat-icon>
                    <span class="text1"> Documents </span>
                </ng-template>
                <ng-template matTabContent>
                    <ng-container *ngTemplateOutlet="docsContainer"> </ng-container>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </ng-template>
</div>


<ng-template #docsContainer>
    <div class="top m-t-5" fxLayoutAlign="start start" fxLayout.lt-sm="row wrap"
        *ngLet="isRootMedia$ | async as isRootMedia">
        <flrgl-go-back align="end" [path]="['..']" fxFlex.gt-sm="none" fxFlex.lt-sm="grow"
            *ngIf="!selectMode && !isRootMedia">Back
        </flrgl-go-back>
        <div class="filters" fxLayout="row wrap" fxLayoutAlign="center start" fxFlex='grow'>
            <div fxLayoutAlign="start start" fxLayout="row wrap" fxLayoutGap="20px">
                <input autofocus="autofocus" style="display:none" />
                <button mat-icon-button class="clear-all" matTooltip="Clear All Filters" *ngIf="hasFilterDocs();"
                    (click)="clearAllFiltersDocs()">
                    <mat-icon>clear</mat-icon>
                </button>

                <mat-form-field class="select">
                    <mat-label>Document Type</mat-label>
                    <mat-select [formControl]="docType">
                        <mat-option *ngFor="let option of docTypeKeys" [value]="option[0]">
                            {{option[1]}}
                        </mat-option>
                    </mat-select>
                    <button mat-icon-button matSuffix *ngIf="docType.value" (click)="docType.setValue(null)">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <flrgl-entity-autocomplete [control]="catsAutocompleteDocs" (cleared)="clearVendorAndItemDocs()"
                    class="cats-filter">
                </flrgl-entity-autocomplete>
                <flrgl-entity-autocomplete [control]="vendorsAutocompleteDocs" (cleared)="clearItemDocs()"
                    class="vendors-filter">
                </flrgl-entity-autocomplete>
                <flrgl-entity-autocomplete [control]="itemsAutocompleteDocs" class="items-filter">
                </flrgl-entity-autocomplete>
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="doc-container" *ngIf="allDocs$ | async as docs">
        <ng-container *ngIf="catMap$ | async as catMap">
            <ng-container *ngIf="vendorMap$ | async as vendorMap">
                <ng-container *ngIf="eventItemMap$ | async as eventItemMap">
                    <!-- // -->
                    <table mat-table [dataSource]="allDocs$" class="mat-elevation-z1" class="docs-table">
                        <ng-container matColumnDef="icon">
                            <th mat-header-cell *matHeaderCellDef class="icon"></th>
                            <td mat-cell *matCellDef="let d; ">
                                <ng-container *ngIf="asDoc(d) as doc">
                                    <img [id]="'hidden-img-'+doc.id" [src]="doc.fileUrl"
                                        *ngIf="doc.renderType==='image'" lightbox #img6 hidden>
                                    <mat-icon class='doc-icon' fontSet="far" [fontIcon]="doc.iconCode"></mat-icon>
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef class="name">Name</th>
                            <td mat-cell *matCellDef="let d; ">
                                <ng-container *ngIf="asDoc(d) as doc">
                                    {{doc.name}}
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef class="type">Type</th>
                            <td mat-cell *matCellDef="let d; ">
                                <ng-container *ngIf="asDoc(d) as doc">
                                    {{doc.typeDescription}}
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="cat">
                            <th mat-header-cell *matHeaderCellDef class="cat no-mobile">Category</th>
                            <td mat-cell *matCellDef="let d; " class="no-mobile">
                                <ng-container *ngIf="asDoc(d) as doc">
                                    {{getDocCatName(catMap,doc)}}
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="vendor">
                            <th mat-header-cell *matHeaderCellDef class="vendor  no-mobile">Vendor</th>
                            <td mat-cell *matCellDef="let d; " class="no-mobile">
                                <ng-container *ngIf="asDoc(d) as doc">
                                    {{getDocVendorName(vendorMap,doc)}}
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="item">
                            <th mat-header-cell *matHeaderCellDef class="item  no-mobile ">Event-item</th>
                            <td mat-cell *matCellDef="let d; " class="no-mobile">
                                <ng-container *ngIf="asDoc(d) as doc">
                                    {{getDocItemName(eventItemMap,doc)}}
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="fileName">
                            <th mat-header-cell *matHeaderCellDef class="file no-mobile">Filename</th>
                            <td mat-cell *matCellDef="let d; " class="no-mobile">
                                <ng-container *ngIf="asDoc(d) as doc">
                                    {{doc.fileName}}
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef class="actions no-mobile"></th>
                            <td mat-cell *matCellDef="let d; " class="no-mobile">
                                <ng-container *ngIf="asDoc(d) as doc">
                                    <button [color]="" class="btn-actions" mat-icon-button *auth1="['planner']"
                                        [matMenuTriggerFor]="menu" (click)="$event.stopPropagation() || false">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="editDocMeta(doc)">
                                            <mat-icon>edit</mat-icon>
                                            Edit
                                        </button>
                                        <button mat-menu-item (click)="deleteDoc(doc)">
                                            <mat-icon>delete</mat-icon>
                                            Delete
                                        </button>
                                    </mat-menu>
                                </ng-container>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="docsTableColumns"></tr>
                        <tr mat-row *matRowDef="let doc; columns: docsTableColumns;" class="clickable1" #tr6
                            (click)="docRowClicked(doc,tr6)">
                        </tr>

                    </table>
                </ng-container>
            </ng-container>
        </ng-container>

        <div *ngIf="!docs.length" class="p-20" fxLayoutAlign="center center" fxLayout="column">
            <ng-container *ngIf="hasFilterDocs();else noDocsFoundT">
                <div class="p-20">No Document matches the current filters</div>
                <button mat-stroked-button class="clear-all" matTooltip="Clear All Filters"
                    (click)="clearAllFiltersDocs()">
                    <mat-icon>clear</mat-icon> Clear The Filters
                </button>
            </ng-container>
            <ng-template #noDocsFoundT>
                <div class="p-20">No Documents found</div>
            </ng-template>
        </div>
        <!-- 
        <div *ngFor="let d of docs">
            <ng-container *ngIf="asDoc(d) as doc">
                {{doc.data.fileName}} - {{doc.data.name}} - {{doc.data.type}}
            </ng-container>
        </div> -->



    </div>
</ng-template>


<ng-template #imagesContainer>
    <div class="top m-t-5" fxLayoutAlign="start start" fxLayout.lt-sm="row wrap"
        *ngLet="isRootMedia$ | async as isRootMedia">
        <flrgl-go-back align="end" [path]="['..']" fxFlex.gt-sm="none" fxFlex.lt-sm="grow"
            *ngIf="!selectMode && !isRootMedia">Back
        </flrgl-go-back>
        <div class="filters" fxLayout="row wrap" fxLayoutAlign="center start" fxFlex='grow'>
            <div fxLayoutAlign="start start" fxLayout="row wrap" fxLayoutGap="20px">
                <input autofocus="autofocus" style="display:none" />
                <button mat-icon-button class="clear-all" matTooltip="Clear All Filters" *ngIf="hasFilterMedia()"
                    (click)="clearAllFiltersMedia()">
                    <mat-icon>clear</mat-icon>
                </button>
                <flrgl-entity-autocomplete [control]="catsAutocompleteMedia" (cleared)="clearVendorAndItemMedia()"
                    class="cats-filter">
                </flrgl-entity-autocomplete>
                <flrgl-entity-autocomplete [control]="vendorsAutocompleteMedia" (cleared)="clearItemMedia()"
                    class="vendors-filter">
                </flrgl-entity-autocomplete>
                <flrgl-entity-autocomplete [control]="itemsAutocompleteMedia" class="items-filter">
                </flrgl-entity-autocomplete>
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="img-container" fxLayout="column">
        <ng-container *ngIf="(imageResultsNotEmpty$ | async);else noResultsT">
            <ng-container *ngTemplateOutlet="mediaContainer;context:{media: originMedia$ | async}"></ng-container>
            <ng-container *ngTemplateOutlet="mediaContainer;context:{media: showcaseMedia$ | async}"></ng-container>
            <ng-container *ngTemplateOutlet="mediaContainer;context:{media: media$ | async}"></ng-container>
        </ng-container>
        <ng-template #noResultsT>
            <div class="p-20" fxLayoutAlign="center center" fxLayout="column">
                <ng-container *ngIf="hasFilterMedia();else noMediaFoundT">
                    <div class="p-20">No Media matches the current filters</div>
                    <button mat-stroked-button class="clear-all" matTooltip="Clear All Filters"
                        (click)="clearAllFiltersMedia()">
                        <mat-icon>clear</mat-icon> Clear The Filters
                    </button>
                </ng-container>
                <ng-template #noMediaFoundT>
                    <div class="p-20">No Media found</div>
                </ng-template>
            </div>
        </ng-template>

        <ng-template #mediaContainer let-media='media'>
            <div fxFlex="grow" class="gal p-y-15" fxLayoutAlign="start stretch" fxLayout="row wrap" fxLayoutGap='2px'
                lightbox-group *ngIf="media?.length">
                <div class="img-box m-b-5 p-x-2 " *ngFor="let media of media;trackBy:trackByIdFn" fxLayout="column">
                    <mat-checkbox *ngIf="selectMode" #c class="hover1" labelPosition="before"
                        [checked]="mediaIsSelected(media)" (change)="mediaSelectionChanged(c.checked, media)">Select
                    </mat-checkbox>
                    <div fxLayout="column" fxLayoutAlign="start center" fxFlex="grow">
                        <button mat-icon-button class="img-more" [matMenuTriggerFor]="imgMenu" *auth1="['planner']">
                            <mat-icon>more_horiz</mat-icon>
                        </button>
                        <mat-menu #imgMenu="matMenu">
                            <a mat-menu-item (click)="deleteMedia(media)">Delete</a>
                        </mat-menu>

                        <img [src]="media.urlSmall" lightbox [fullImage]="{path:media.urlLarge}">
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
        </ng-template>
    </div>
</ng-template>