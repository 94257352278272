import { EntityProjected } from '@skylitup/base/ngrx-data-fire';
import { Upload } from '../upload.model';
import { MediaData } from './media.model.data';

export class Media extends EntityProjected<MediaData> implements Upload {
  constructor(data: MediaData) {
    super(data);
  }
  clone(): Media {
    return new Media(this.data).copy(this);
  }
  copy(from: Media): Media {
    return this as Media;
  }
  get urlSmall() {
    return this.data.urlSmall || this.data.urlFull;
  }
  get urlMedium() {
    return this.data.urlMedium || this.data.urlFull;
  }
  get urlLarge() {
    return this.data.urlLarge || this.data.urlFull;
  }
  get fileUrl(): string {
    return this.data.urlFull;
  }

}
