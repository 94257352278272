import { FormControl } from '@angular/forms';

import { BaseComponent } from '@skylitup/base/util';

import { createSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import {
  CustomerEvent,
  ContextService,
  EventItemService,
  CustomerEventService,
  AppState,
  EventItem,
} from '@skylitup/flowergirl/core';
export abstract class BudgetBaseComponent extends BaseComponent {
  customerEvent$: Observable<CustomerEvent>;
  focusCat$: Observable<string>;
  viewInitialized = false;
  private catGoalBudgetMap: { [catId: string]: FormControl } = {};
  constructor(
    public context: ContextService,
    public store: Store,
    public itemService: EventItemService,
    public customerEventService: CustomerEventService
  ) {
    super();
    this.customerEvent$ = context.customerEvent$;
    this.focusCat$ = this.store.select(
      createSelector(
        (s: AppState) => s.ui,
        (s) => s.focusCat
      )
    );
  }

  budgetChange(v: number, item: EventItem) {
    this.itemService
      .update({ budget: v, meta: item.data.meta })
      .pipe(first())
      .subscribe({ complete: () => console.log('updated budget') });
  }

  trackByIdFn(i, o) {
    return o.id;
  }
}
