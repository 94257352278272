import { createAction, props } from '@ngrx/store';

export const syncUserTrack = createAction(
  '[user-trackers - Sync]',
  props<{ sync: boolean }>()
);

export const doHeartBeat = createAction(
  '[user-trackers - Heartbeat]'
);
