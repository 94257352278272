import { entityDate, EntityProjected } from '@skylitup/base/ngrx-data-fire';
import { Auth } from '../auth/auth.model';
import { ChatTrackData } from './chat-track.model.data';
import * as moment from 'moment';

export function isChatNotificationsPaused(chatTrackData: ChatTrackData) {
    return chatTrackData.pauseNotifications && (entityDate(chatTrackData.pauseNotificationsUntil).getTime() > new Date().getTime())
}

export class ChatTrack extends EntityProjected<ChatTrackData> {
    public auth: Auth;
    get isPaused() {
        return isChatNotificationsPaused(this.data);
    }

    get pauseDuration() {
        return moment.duration(moment(entityDate(this.data.pauseNotificationsUntil)).diff(moment(), 'minutes'), 'minutes').humanize();
    }
    constructor(data: ChatTrackData) {
        super(data);
    }

    getReadBy(dialogId) {
        if (this.auth) {
            return (this.data.readBy[dialogId] || {})[this.auth.data.uid] || 0;
        } else {
            return null;
        }
    }

    getTotals(dialogId) {
        return this.data.totals[dialogId] || 0;
    }
    getUnread(dialogId) {
        if (this.auth) {
            return this.getTotals(dialogId) - this.getReadBy(dialogId);
        } else {
            return null;
        }
    }

    _getSumReadByUid(uId: string) {
        return this.data.sumReadBy[uId] || 0;
    }
    get sumReadBy() {
        if (this.auth) {
            return this._getSumReadByUid(this.auth.data.uid) || 0;
        } else {
            return null;
        }
    }
    get sumTotals() {
        return this.data.sumTotals;
    }
    get sumUnread() {
        if (this.auth) {
            return this.sumTotals - this.sumReadBy;
        } else {
            return null;
        }
    }
    clone(): ChatTrack {
        return new ChatTrack(this.data).copy(this);
    }
    copy(from: ChatTrack): ChatTrack {
        this.auth = from.auth;
        return this;
    }
}