<ng-container *ngIf="auth$ |async as auth">
    <div class="container">
        <div>
            <flrgl-vendor-card [withFilters]="withFilters" [vendor]="vendor" inShowCase="true" fxFlex="none"
                (focusSortClick)="focusSortClick.next($event)" (catclick)="catclick.next($event)" [editable]='editable'
                (locationClick)="locationClick.next($event)" [socialNames]="socialNames">
            </flrgl-vendor-card>
        </div>
        <div fxLayoutAlign="end" fxLayout="row" *ngIf="isUploading || uploadError">
            <div fxFlex="grow" *ngIf="isUploading">
                Uploading...
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div>
                <mat-expansion-panel *ngIf="uploadError">
                    <mat-expansion-panel-header>
                        <mat-panel-description fxLayoutAlign="end center">
                            <mat-icon class='m-r-10 error'>error</mat-icon> There's been an error during uploading
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    {{uploadError}}
                </mat-expansion-panel>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" *auth1="['planner']">
            <button mat-button fxFlex="none" matTooltip="Reorder Images" (click)="reorderImgs=!reorderImgs"
                *auth1="['planner']" [ngClass]="{'color-accent':reorderImgs}">
                <mat-icon> swap_vert</mat-icon> Reorder
            </button>
            <button mat-button (click)="fileUpload.click()" fxFlex="none" *auth1="['planner']">
                <mat-icon>attach_file</mat-icon> Upload Image
            </button>
            <input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*" hidden
                (change)="attachFile($event)" #fileUpload>
        </div>
        <div class="notes p-15 pointer1" (click)="showFullNotes=!showFullNotes" *ngIf="!empty(notes) && auth.isPlanner">
            <div [innerHTML]="notes"></div>
            <button mat-button *ngIf="vendor.data.notes?.length > this.excerptLength">
                <ng-container *ngIf="!showFullNotes;else less1"> More... </ng-container>
                <ng-template #less1>Less...</ng-template>
            </button>
            <div class="gradient1" *ngIf="!showFullNotes && vendor.data.notes?.length > this.excerptLength"></div>
        </div>
        <div *ngIf="!reorderImgs;else reorder" class="img-container" fxLayout="column">
            <div fxFlex="grow" class="gal" fxLayoutAlign="start stretch" fxLayout="row wrap" fxLayoutGap='2px'
                lightbox-group>
                <div class="img-box m-b-5 p-x-2 " *ngFor="let media of vendor.media;trackBy:trackByIdFn"
                    fxLayout="column">
                    <div fxLayout="column" fxLayoutAlign="start center" fxFlex="grow">
                        <button mat-icon-button class="img-more" [matMenuTriggerFor]="imgMenu" *auth1="['planner']">
                            <mat-icon>more_horiz</mat-icon>
                        </button>
                        <mat-menu #imgMenu="matMenu">
                            <a mat-menu-item (click)="deleteMedia(media)">Delete</a>
                        </mat-menu>
                        <img [src]="media.urlMedium" lightbox [fullImage]="{path:media.urlLarge}">
                    </div>
                </div>
            </div>
        </div>
        <ng-template #reorder>
            <div class="reorder1" [cdkDropListData]="vendor.media" (cdkDropListDropped)="dropImage($event,vendor)"
                fxLayoutGap="0px 5px" cdkDropList>
                <div class="img-box1  mat-elevation-z2" *ngFor="let media of vendor.media;trackBy:trackByIdFn"
                    fxLayout="row" fxLayoutAlign="start center" cdkDrag>
                    <mat-icon class="p-x-10 m-r-20">reorder</mat-icon>
                    <img [src]="media.urlMedium" lightbox [fullImage]="{path:media.urlLarge}">
                </div>
            </div>
        </ng-template>
    </div>
</ng-container>