import { Dictionary } from '@ngrx/entity';
import {
  EntityProjected,
  EntityProjectedBase
} from '@skylitup/base/ngrx-data-fire';
import {
  projectDictionary,
  projectList
} from '@skylitup/base/ngrx-data-fire';
import { EntityKeys, EntityData } from '@skylitup/base/ngrx-data-fire';

export const SYSTEM_KEYS: EntityKeys = {
  name: 'system',
  plural: 'system'
};
export {
  DATE_STRING_LONG_FORMAT,
  DATE_STRING_FORMAT
} from '@skylitup/base/ngrx-data-fire';

export interface SystemData extends EntityData {
  cats: CatData[];
}

export interface CatData extends EntityData {
  id: string;
  name: string;
}

export class System extends EntityProjected<SystemData> {
  categories: Category[];
  categoryMap: Dictionary<Category>;
  constructor(data) {
    super(data);
    this.categories = projectList<CatData, Category>(v => new Category(v))(
      this.data.cats
    );
    this.categoryMap = projectDictionary<Category>(this.categories);
  }
  clone(): System {
    return new System(this.data).copy(this);
  }
  copy(from: System): System {
    this.categories = [...from.categories];
    this.categoryMap = { ...from.categoryMap };
    return this;
  }
}

export class Category extends EntityProjectedBase<CatData> {
  constructor(data: CatData) {
    super(data);
  }
  get url(): string {
    return '#';
  }
  clone(): Category {
    return new Category(this.data).copy(this);
  }
  copy(from: Category): Category {
    return this;
  }
  get id() {
    return this.data.id;
  }
}
