import {
  DefaultPersistenceResultHandler,
  EntityAction,
  EntityOp,
  OP_SUCCESS,
  Logger,
  EntityActionFactory,
  DataServiceError,
  EntityActionDataServiceError,
} from "@ngrx/data";
import { Injectable } from "@angular/core";
import { Action, Store } from "@ngrx/store";
import { genericError } from "@skylitup/base/util";

export enum EntityOpSkipCache {
  SAVE_ADD_ONE_SUCCESS_SKIP_CACHE = "ngrx-data/save/add-one/success-skip-cache",
  SAVE_UPDATE_ONE_SUCCESS_SKIP_CACHE = "ngrx-data/save/update-one/success-skip-cache",
  UPDATE_ONE_SUCCESS_SKIP_CACHE = "ngrx-data/update-one/success-skip-cache",
  SAVE_ADD_ONE_ERROR_SKIP_CACHE = "ngrx-data/save/add-one/error-skip-cache",
  SAVE_UPDATE_ONE_ERROR_SKIP_CACHE = "ngrx-data/save/update-one/error-skip-cache",
  UPDATE_ONE_ERROR_SKIP_CACHE = "ngrx-data/update-one/error-skip-cache",
}

@Injectable({ providedIn: "root" })
export class PersistenceResultHandlerExtended extends DefaultPersistenceResultHandler {
  private entityActionFactoryRef: EntityActionFactory;
  constructor(
    logger: Logger,
    entityActionFactory: EntityActionFactory,
    private store: Store
  ) {
    super(logger, entityActionFactory);
    this.entityActionFactoryRef = entityActionFactory;
  }

  /** Handle successful result of persistence operation on an EntityAction */
  handleSuccess(action: EntityAction): (data: any) => Action {
    if (true) {
      return super.handleSuccess(action);
    }
    // needs more digging, see here for more : https://ngrx.io/guide/data/entity-change-tracker

    if (action.payload["DO_NOT_SKIP_CACHE__"]) {
      return super.handleSuccess(action);
    } else {
      if (action.payload.entityOp === EntityOp.SAVE_ADD_ONE) {
        let successOpIgnore;
        if (action.payload.entityOp === EntityOp.SAVE_ADD_ONE) {
          successOpIgnore = EntityOpSkipCache.SAVE_ADD_ONE_SUCCESS_SKIP_CACHE;
        } else if (action.payload.entityOp === EntityOp.SAVE_UPDATE_ONE) {
          successOpIgnore =
            EntityOpSkipCache.SAVE_UPDATE_ONE_SUCCESS_SKIP_CACHE;
        } else if (action.payload.entityOp === EntityOp.UPDATE_ONE) {
          successOpIgnore = EntityOpSkipCache.UPDATE_ONE_SUCCESS_SKIP_CACHE;
        }
        return (data: any) =>
          this.entityActionFactoryRef.create(
            action.payload.entityName,
            successOpIgnore,
            data
          );
      } else {
        return super.handleSuccess(action);
      }
    }
  }

  handleError(
    action: EntityAction
  ): (
    error: DataServiceError | Error
  ) => EntityAction<EntityActionDataServiceError> {
    if (
      action.payload.entityOp === EntityOp.SAVE_ADD_ONE ||
      action.payload.entityOp === EntityOp.SAVE_UPDATE_ONE
    ) {
      let errorOpIgnore;
      if (action.payload.entityOp === EntityOp.SAVE_ADD_ONE) {
        errorOpIgnore = EntityOpSkipCache.SAVE_ADD_ONE_ERROR_SKIP_CACHE;
      } else if (action.payload.entityOp === EntityOp.SAVE_UPDATE_ONE) {
        errorOpIgnore = EntityOpSkipCache.SAVE_UPDATE_ONE_ERROR_SKIP_CACHE;
      }
      return (data: any) => {
        const e = { msg: data, hint: errorOpIgnore };
        console.error(`${e.hint} - ${e.msg}`);
        this.store.dispatch(genericError(e));

        return this.entityActionFactoryRef.create({
          ...action.payload,
          entityOp: errorOpIgnore,
        });
      };
    } else {
      return super.handleError(action);
    }
  }
}
