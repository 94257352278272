import { Injectable } from '@angular/core';
import { firestoreRealtime, firestoreRealtimeNoSyncing } from '@skylitup/base/ngrx-data-fire';
import {
  Actions,
  createEffect,
  ofType,
  EffectNotification,
} from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { combineLatest, of, Observable, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { AgencyService } from '../agency/agency.service';
import { CustomerService } from '../customer/customer.service';
import { UserCardService } from './user-card.service';
import { AuthService } from '../auth/auth.service';
import { NotificationService } from '../../util/services/notification.service';
import { genericCatch$$ } from '@skylitup/base/util';
import { idTokenResult } from '../auth/state/auth.actions';
import { syncAgencyUserCards, syncCustomerUserCards } from './user-card.actions';

@Injectable()
export class UserCardEffects {

  signedInUserCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(idTokenResult),
        // filter(idToken => !!idToken.payload.uid),
        distinctUntilChanged(),
        switchMap(_ => combineLatest([
          this.authService.auth$,
          this.agencyService.entityIdInContext$
        ]).pipe(switchMap(([auth, agencyId]) =>
          (!!auth && !!agencyId) ?
            this.userCardService.syncSingleUserCard$(agencyId, auth.uid)
              .pipe(map(__ => firestoreRealtime(`agencies/${agencyId}/user-card | for single user ${auth.data.uid}`)))
            : of(firestoreRealtimeNoSyncing('agencies/{agencyId}/user-card | for single user'))
        ))
        )
      ),
    { dispatch: true }
  );

  syncAgencyUserCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncAgencyUserCards),
      distinctUntilChanged(null, (a) => a.sync),
      switchMap((action) =>
        this.agencyService.entityIdInContext$.pipe(
          switchMap((agencyId) =>
            action.sync && agencyId
              ? this.userCardService
                .syncAgencyUserCards$(agencyId)
                .pipe(
                  map((_) => firestoreRealtime(`agencies/${agencyId}/users-track`))
                )
              : of(firestoreRealtimeNoSyncing(`agencies/${agencyId}/users-track`))
          ),
          genericCatch$$('UserTrack - sync')
        )
      )
    )
  );

  syncCustomerUserCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncCustomerUserCards),
      distinctUntilChanged(null, (a) => a.sync),
      switchMap((action) =>
        combineLatest([
          this.agencyService.entityIdInContext$,
          this.customerService.entityIdInContext$,
        ]).pipe(
          switchMap(([agencyId, customerId]) =>
            (agencyId && customerId) ? this.userCardService
              .syncUserCardsForCustomer$(agencyId, customerId)
              .pipe(
                map((_) =>
                  firestoreRealtime(
                    `agencies/${agencyId}/user-cards | for customer ${customerId}`
                  )
                )
              )
              : of(
                firestoreRealtimeNoSyncing(
                  'agencies/{id}/user-cards | forCustomer'
                )
              )
          )
        )
      )
    ),
    { dispatch: false }
  );

  userJoinedDate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(idTokenResult),
        switchMap((a) =>
          of(a).pipe(
            debounceTime(60 * 1000),
            switchMap((_) =>
              this.authService.userCard$.pipe(
                filter((o) => !!o),
                filter(u => !u.data.joinedDate),
                first()
              )
            ),
            switchMap((userCard) =>
              this.userCardService.update({
                meta: userCard.data.meta,
                joinedDate: userCard.data.joinedDate
                  ? userCard.data.joinedDate
                  : new Date(),
              })
            ),
            genericCatch$$('usercard-joined$')
          )
        )
      ),
    { dispatch: false }
  );


  // userCards$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(routerNavigatedAction),
  //     switchMap((_) =>
  //       combineLatest([
  //         this.authService.auth$,
  //         this.agencyService.entityIdInContext$,
  //         this.customerService.entityIdInContext$,
  //       ])
  //     ),
  //     distinctUntilChanged(null, (a) => a.join('')),
  //     switchMap((a) =>
  //       of(a).pipe(
  //         switchMap(([auth, agencyId, customerId]) =>
  //           auth.isPlanner
  //             ? agencyId
  //               ? this.userCardService
  //                 .syncUserCards$(agencyId)
  //                 .pipe(
  //                   map((_) =>
  //                     firestoreRealtime(`agencies/${agencyId}/user-cards`)
  //                   )
  //                 )
  //               : of(firestoreRealtimeNoSyncing('agencies/{id}/user-cards'))
  //             : agencyId && customerId
  //               ? this.userCardService
  //                 .syncUserCardsForCustomer$(agencyId, customerId)
  //                 .pipe(
  //                   map((_) =>
  //                     firestoreRealtime(
  //                       `agencies/${agencyId}/user-cards | for customer`
  //                     )
  //                   )
  //                 )
  //               : of(
  //                 firestoreRealtimeNoSyncing(
  //                   'agencies/{id}/user-cards | forCustomer'
  //                 )
  //               )
  //         ),
  //         genericCatch$$('UserCardEffects')
  //       )
  //     )
  //   )
  // );



  constructor(
    private actions$: Actions,
    private userCardService: UserCardService,
    private customerService: CustomerService,
    private agencyService: AgencyService,
    private notifications: NotificationService,
    private authService: AuthService
  ) { }
  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.notifications.trackCompletion(resolvedEffects$);
  }
}
