import { ChecklistItem } from '../checklist-item/checklist-item.model';
import { CustomerEvent } from '../customer-event/customer-event.model';
import * as moment from 'moment';
import { Payment } from './payment.model';
import { Dictionary } from '@ngrx/entity';

export class ChecklistMonth {
  public items: ChecklistItem[] = [];
  payments: Payment[] = [];
  itemsExcludeDone: ChecklistItem[] = [];
  paymentsExcludeDone: Payment[] = [];

  public readonly id: string;
  public readonly idNum: number;
  public readonly title: string;
  constructor(
    // public event: CustomerEvent,
    public readonly year: number,
    public readonly month: number
  ) {
    this.idNum = this.year * 12 + this.month;
    this.id = '' + this.idNum;
    this.title = moment([year, month]).format('MMMM Y');
  }
  static updateMonth(
    item: ChecklistItem,
    checklistMonthMap: Dictionary<ChecklistMonth>,
    checklistMonths: ChecklistMonth[]
  ) {
    let checklistMonth = checklistMonthMap[item.monthId];
    if (!checklistMonth) {
      checklistMonth = new ChecklistMonth(item.year, item.month);
      checklistMonthMap[item.monthId] = checklistMonth;
      checklistMonths.push(checklistMonth);
    }
    checklistMonth.items.push(item);
    if (!item.done) {
      checklistMonth.itemsExcludeDone.push(item);
    }
    if (item.isPayment) {
      checklistMonth.payments.push(item as Payment);
      if (!item.done) {
        checklistMonth.paymentsExcludeDone.push(item as Payment);
      }
    }
    item.checklistMonth = checklistMonth;
  }
  clone() {
    return new ChecklistMonth(this.year, this.month).copy(this);
  }
  copy(from: ChecklistMonth): ChecklistMonth {
    this.items = [...from.items];
    this.payments = [...from.payments];
    this.itemsExcludeDone = [...from.itemsExcludeDone];
    this.paymentsExcludeDone = [...from.paymentsExcludeDone];
    return this;
  }
  itemsOrPayments(paymentsOnly: boolean, hideCompleted: boolean) {
    if (hideCompleted) {
      return paymentsOnly ? this.paymentsExcludeDone : this.itemsExcludeDone;
    } else {
      return paymentsOnly ? this.payments : this.items;
    }
  }
}
