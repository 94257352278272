import { Injectable } from '@angular/core';
import { FirebaseStorageDataService } from '@skylitup/base/ngrx-data-fire';
import { EntityBaseSelectors, EntityBaseService } from '@skylitup/base/ngrx-data-fire';
import {
  EntityCollectionServiceElementsFactory,
  EntityDataService
} from '@ngrx/data';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import * as moment from 'moment';
import { forkJoin, Observable, throwError } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { Media } from './media.model';
import { UploadIndex, projectUploadIndex } from '../upload.model.fn';
import { MediaData, MEDIA_KEYS } from './media.model.data';
import { AGENCY_KEYS } from '../../agency/agency.model.data';
import { CUSTOMER_KEYS } from '../../customer/customer.model.data';
import { UploadData, UploadFilesRequest } from '../upload.model.data';
import { UploadService } from '../upload.service';

// interface SelectorsProjected extends EntityBaseSelectors<MediaData, Media> {
//   selectMediaIndex: MemoizedSelector<any, UploadIndex>;
// }


@Injectable({ providedIn: 'root' })
export class MediaService extends EntityBaseService<MediaData, Media> {
  // public selectorsProjected: SelectorsProjected;
  constructor(
    entityServiceFactory: EntityCollectionServiceElementsFactory,
    entityDataServices: EntityDataService,
    private uploadService: UploadService
  ) {
    super(MEDIA_KEYS, entityServiceFactory, entityDataServices);
    this.init();
  }
  setupRxSelectors() {
    // Object.assign(this.selectorsProjected, { selectMediaIndex });
    return this._selectPlainEntityTuple;
  }
  setupRx$() {
  }

  protected newProjectedEntity(d: MediaData) {
    return new Media(d);
  }
  upload(m: UploadFilesRequest): Observable<MediaData[]> {
    return this.uploadService.upload(m).pipe(
      mergeMap(dd => this.createMedia(m.agency, m.customer, dd))
    );
  }
  createMedia(
    agencyId: string,
    customerId: string,
    dd: UploadData[]
  ): Observable<MediaData[]> {
    const collectionPath = customerId
      ? [
        AGENCY_KEYS.plural,
        agencyId,
        CUSTOMER_KEYS.plural,
        customerId,
        MEDIA_KEYS.plural
      ]
      : [AGENCY_KEYS.plural, agencyId, MEDIA_KEYS.plural];
    const data = dd.map(d => ({
      meta: {
        collectionPath,
        id: null
      },
      ...d
    }));
    return forkJoin(data.map(d => this.upsert(d as MediaData)));
  }

  syncEventMedia$(agencyId: string, customerId: string, eventId: string) {
    return this.syncQueryRealtime$(
      [
        AGENCY_KEYS.plural,
        agencyId,
        CUSTOMER_KEYS.plural,
        customerId,
        MEDIA_KEYS.plural
      ],
      {
        queryFn: query =>
          query.where('links.events', 'array-contains', eventId),
        replaceState: true
      }
    );
  }
  syncVendorShowcaseMedia$(agencyId: string, vendorId: string) {
    return this.syncQueryRealtime$(
      [AGENCY_KEYS.plural, agencyId, MEDIA_KEYS.plural],
      {
        queryFn: query => query.where('links.showcaseVendorId', '==', vendorId)
      }
    );
  }
}
