import { NgModule } from '@angular/core';
import { FlowergirlFeaturesNavigateModule } from '@skylitup/flowergirl/features/navigate';
import { FlowergirlXplatWebFeaturesOverviewModule } from '@skylitup/flowergirl/xplat/web/features/overview';
import { FlowergirlXplatWebFeaturesBudgetModule } from '@skylitup/flowergirl/xplat/web/features/budget';
import { FlowergirlXplatWebFeaturesChecklistModule } from '@skylitup/flowergirl/xplat/web/features/checklist';
import { FlowergirlXplatWebFeaturesMediaModule } from '@skylitup/flowergirl/xplat/web/features/media';
import { FlowergirlXplatWebUiModule } from '@skylitup/flowergirl/xplat/web/ui';
import { FlowergirlFeaturesNavigateModuleRoutingModule } from './flowergirl-xplat-web-features-navigate.routing.module';
import { CustomerComponent } from './components/agency/customer/customer.component';
import { VerifyEmailComponent } from './components/login/verify-email/verify-email.component';
import { NavigateComponent } from './components/navigate/navigate.component';
import { AgencyListComponent } from './components/agency-list/agency-list.component';
import { LoginComponent } from './components/login/login.component';
import {
  CustomerListComponent,
  CreateCustomerPopupComponent,
} from './components/agency/customer-list/customer-list.component';
import {
  CreateCustomerEventPopupComponent,
  CustomerEventListComponent,
} from './components/agency/customer/customer-event-list/customer-event-list.component';
import { AgencyComponent } from './components/agency/agency.component';
import { RouterModule } from '@angular/router';
import { CustomerEventComponent } from './components/agency/customer/customer-event/customer-event.component';
import { CommonModule } from '@angular/common';
import { EventSummaryComponent } from './components/agency/customer/customer-event/event-summary/event-summary.component';
@NgModule({
  imports: [
    FlowergirlXplatWebUiModule,
    FlowergirlFeaturesNavigateModule,
    FlowergirlFeaturesNavigateModuleRoutingModule,
    FlowergirlXplatWebFeaturesOverviewModule,
    FlowergirlXplatWebFeaturesBudgetModule,
    FlowergirlXplatWebFeaturesChecklistModule,
    FlowergirlXplatWebFeaturesMediaModule,
  ],
  declarations: [
    CustomerComponent,
    VerifyEmailComponent,
    NavigateComponent,
    AgencyListComponent,
    LoginComponent,
    CustomerListComponent,
    CustomerEventComponent,
    CustomerEventListComponent,
    CreateCustomerPopupComponent,
    CreateCustomerEventPopupComponent,
    AgencyComponent,
    EventSummaryComponent,
  ],
  exports: [],
})
export class FlowergirlXplatWebFeaturesNavigateModule { }
