<mat-drawer-container cdkDropListGroup *ngIf="customerEvent$|async as customerEvent">
    <mat-drawer-content>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="hide-toggle">
            <flrgl-go-back align="start" [path]="['..']" *ngIf="paymentsOnly;">Back To Budget</flrgl-go-back>
            <div fxFlex="grow" style="height:26px"></div>
            <mat-slide-toggle class="example-margin" color="primary" [formControl]="hideCompletedControl">
                Hide Completed
            </mat-slide-toggle>
            <button mat-icon-button *auth1="['planner']" (click)="drawer.toggle()">
                <mat-icon>date_range</mat-icon>
            </button>
        </div>
        <ng-container *ngFor="let checklist of customerEvent.checklistMonths;trackBy:trackByIdFn">
            <div class="container mat-elevation-z2"
                *ngIf="checklist.itemsOrPayments(paymentsOnly,hideCompletedControl.value).length">
                <div class="month-title title">{{checklist.title}}</div>
                <div *ngIf="checklist.items.length" #itemsList="cdkDropList" cdkDropList>
                    <div *ngFor="let item of checklist.itemsOrPayments(paymentsOnly, hideCompletedControl.value); trackBy:trackByIdFn"
                        [cdkDragDisabled]="!drawer.opened" (cdkDragEnded)="dateHover=null" [cdkDragData]="item" cdkDrag>
                        <ng-container *ngIf="customerEvent.getVendors(item.checklistCat.id) as vendors">
                            <flrgl-checklist-item [item]="item" (itemChange)="updateItem(customerEvent,$event,item)"
                                (dueDateClick)="drawer.toggle()" [bg2]="item.dueDate.getTime()<dateHover?.getTime()"
                                [showCat]="true" (deleted)="deleteItem(item)">
                            </flrgl-checklist-item>
                        </ng-container>
                        <div *cdkDragPreview>
                            <mat-card class="drag-preview" fxLayout="row">
                                <div fxFlex="grow" fxLayoutAlign="center">...</div>
                                <mat-icon fxFlex="none">today</mat-icon>
                            </mat-card>
                        </div>
                        <div class="placeholder1" *cdkDragPlaceholder></div>
                    </div>
                </div>
            </div>
        </ng-container>
    </mat-drawer-content>
    <mat-drawer #drawer mode='side' position='end' [opened]="false">
        <flrgl-checklist-calendar *ngIf="viewInitDone && drawer.opened" [customerEvent]="customerEvent"
            (dateSelected)="selectedDate($event,customerEvent)" #checklistCalendar (dateHover)="dateHover=$event"
            (closed)="drawer.close()">
        </flrgl-checklist-calendar>
    </mat-drawer>
</mat-drawer-container>