import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FlowergirlXplatWebFeaturesNavigateModule } from '@skylitup/flowergirl/xplat/web/features/navigate';
import { FlowergirlXplatWebUiModule } from '@skylitup/flowergirl/xplat/web/ui';
import { FlowergirlAppCoreModule } from './core/flowergirl-app-core.module';
@NgModule({
  declarations: [AppComponent],
  imports: [
    FlowergirlAppCoreModule,
    FlowergirlXplatWebUiModule,
    // features
    FlowergirlXplatWebFeaturesNavigateModule,
    AppRoutingModule,
    FontAwesomeModule,

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  bootstrap: [AppComponent],
  schemas: [],
})
export class AppModule { }
