<mat-dialog-content>
    <form [formGroup]="form">
        <div>
            <mat-form-field appearance="standard">
                <mat-label>Customer Event Name</mat-label>
                <input matInput formControlName="name" required (keyup)="keyUp($event.keyCode)">
                <mat-error *ngIf="name.invalid">{{getErrorMessage(name,'Customer Event Name')}}</mat-error>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button [disabled]="!form.valid" [mat-dialog-close]="name.value">Add</button>
    <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>