import { Component } from '@angular/core';

import { VendorResearchItemBaseComponent } from '@skylitup/flowergirl/features/vendor';

@Component({
  selector: 'flrgl-vendor-research-item',
  templateUrl: 'vendor-research-item.component.html'
})
export class VendorResearchItemComponent extends VendorResearchItemBaseComponent {
  constructor() {
    super();
  }
}
