import { EntityData, EntityKeys, Timestamp } from '@skylitup/base/ngrx-data-fire';
import { CustomerDataType } from '../customer/customer.model.data';
import { ChatSnapshotData } from '../mail-notifications-track/mail-notifications-track.model.data';
export const CHAT_TRACK_KEYS: EntityKeys = {
    name: 'chat-track',
    plural: 'chats-track'
};

export interface ChatTrackData extends EntityData {
    agencyId: string;
    customerType: CustomerDataType;
    readBy: { [dialogId: string]: { [uid: string]: number } };
    sumReadBy: { [uid: string]: number };
    readAllBy: { [uid: string]: Date | Timestamp };
    totals: { [dialogId: string]: number };
    sumTotals: number;
    snapshotEmails: { [uid: string]: ChatSnapshotData },
    pauseNotificationsUntil?: Date | Timestamp;
    pauseNotifications: boolean;
}

