import { EntityData, EntityKeys, Timestamp } from '@skylitup/base/ngrx-data-fire';
import { UploadFilesRequest } from '../upload/upload.model.data';

export const CHAT_RECENT_NUMBER = 30;

export const CHAT_KEYS: EntityKeys = {
  name: 'chat',
  plural: 'chats'
};
export type ChatLinkType =
  | 'category'
  | 'eventItem'
  | 'vendorCat'
  | 'general'
  | 'vendorResearch';
export interface ChatData extends EntityData {
  date: Timestamp | Date;
  msg: string;
  senderIsAgency: boolean;
  // agencyId:string;
  links: {
    cats: string[];
    eventItems: string[];
    vendorCats: { cat: string; vendor: string }[];
    vendorResearch: string[];
    event: string;
    originCompositeVendorId: string;
    originId: string;
    originType: ChatLinkType;
  };
  recent: boolean;
  readBy: { [uid: string]: boolean };
  attachment: {
    isAttachment: boolean;
    type: UploadFilesRequest['type'];
    mediaIds: string[];
    uploading: boolean;
    uploadError?: string;
  };
}


export function getOriginDialogId(chat: ChatData) {
  if (chat.links.originType === 'category') {
    return getCatDialogId(chat.links.originId);
  } else if (chat.links.originType === 'vendorCat') {
    return getVendorCatDialogId(
      chat.links.originId,
      chat.links.originCompositeVendorId
    );
  } else if (chat.links.originType === 'eventItem') {
    return getCustomerEventItemId(chat.links.originId);
  } else if (chat.links.originType === 'general') {
    return getGeneralDialogId();
  } else if (chat.links.originType === 'vendorResearch') {
    return getVendorResearchDialogId(chat.links.originId);
  } else {
    return null
  }
}

export function getCatDialogId(id: string) {
  return `category-${id}`;
}

export function getVendorCatDialogId(catId: string, vendorId: string) {
  return `vendor-${catId}-${vendorId}`;
}

export function getCustomerEventItemId(eventId: string) {
  return `eventItem-${eventId}`;
}

export function getVendorResearchDialogId(vendorResearchId: string) {
  return `vendorResearch-${vendorResearchId}`;
}

export function getGeneralDialogId() {
  return 'general'
}