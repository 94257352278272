import { Component, ChangeDetectionStrategy } from '@angular/core';

import { MatTabChangeEvent } from '@angular/material/tabs';
import { Store, createSelector } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CustomerEventBaseComponent } from '@skylitup/flowergirl/features/navigate';
import { AppState, ContextService, CustomerEvent, uiMainTabChanged } from '@skylitup/flowergirl/core';

@Component({
  selector: 'flrgl-customer-event',
  templateUrl: 'customer-event.component.html',
  styleUrls: ['customer-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerEventComponent extends CustomerEventBaseComponent {
  mainTabIndex$: Observable<number>;
  customerEvent$:Observable<CustomerEvent>;
  constructor(private store: Store, context:ContextService) {
    super();
    this.mainTabIndex$ = this.store.select(
      createSelector(
        (s: AppState) => s.ui,
        s => s.mainTabIndex
      )
    );
    this.customerEvent$ = context.customerEvent$;
  }
  tabChanged($event: MatTabChangeEvent) {
    this.store.dispatch(uiMainTabChanged({ index: $event.index }));
  }
}
