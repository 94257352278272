import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceElementsFactory,
  EntityDataService,
} from '@ngrx/data';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import {
  EntityBaseSelectors,
  EntityBaseService,
  EntityData,
} from '@skylitup/base/ngrx-data-fire';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { AGENCY_KEYS } from '../agency/agency.model.data';
import { AgencyService } from '../agency/agency.service';
import { AuthService } from '../auth/auth.service';
import { ChatService } from '../chat/chat.service';
import { ChecklistItem } from '../checklist-item/checklist-item.model';
import { ChecklistItemData } from '../checklist-item/checklist-item.model.data';
import { ChecklistItemService } from '../checklist-item/checklist-item.service';
import { CUSTOMER_KEYS } from '../customer/customer.model.data';
import { CustomerService } from '../customer/customer.service';
import { EventItem } from '../event-item/event-item.model';
import { EventItemData } from '../event-item/event-item.model.data';
import { EventItemService } from '../event-item/event-item.service';
import { SystemService } from '../system/system.service';
import { VendorService } from '../vendor/vendor.service';
import {
  indexChangeChecklistNextItem,
  indexChangeEventCategories,
  indexChangeEventVendors,
} from './customer-event-index.model.fn';
import { CustomerEvent } from './customer-event.model';
import {
  CustomerEventData,
  CUSTOMER_EVENT_KEYS,
} from './customer-event.model.data';
// import { projectLCustomerEvents } from './customerEvent.model.fn';
import {
  projectCustomerEventsCategories,
  projectCustomerEventsWithAgency,
  projectCustomerEventToCustomer,
  projectEventDialogs,
  projectEventoToChecklist as projectEventToChecklist,
  projectEventToItems,
  projectVendorCats,
} from './customer-event.model.fn';
import { VendorCat } from './vendor-cat.model';

interface SelectorsProjected
  extends EntityBaseSelectors<CustomerEventData, CustomerEvent> { }
@Injectable({ providedIn: 'root' })
export class CustomerEventService extends EntityBaseService<
CustomerEventData,
CustomerEvent
> {
  selectorsProjected: SelectorsProjected;
  customerEventMap$: Observable<Dictionary<CustomerEvent>>;
  customerEvents$: Observable<CustomerEvent[]>;
  customerEventInContext$: Observable<CustomerEvent>;
  customerEventsInContext$: Observable<CustomerEvent[]>;
  eventItemInContext$: Observable<EventItem>;
  constructor(
    entityServiceFactory: EntityCollectionServiceElementsFactory,
    entityDataServices: EntityDataService,
    private systemService: SystemService,
    private vendorService: VendorService,
    private eventItemService: EventItemService,
    private checklistItemService: ChecklistItemService,
    private chatService: ChatService,
    private authService: AuthService,
    private agencyService: AgencyService,
    private customerService: CustomerService,
  ) {
    super(CUSTOMER_EVENT_KEYS, entityServiceFactory, entityDataServices);
    this.init();
  }
  setupRxSelectors() {
    const withAgency = createSelector(
      this._selectPlainEntityTuple,
      this.agencyService.selectorsProjected.selectEntityMap,
      projectCustomerEventsWithAgency
    );

    const withCustomer = createSelector(
      withAgency,
      this.customerService.selectorsProjected.selectEntityMap,
      projectCustomerEventToCustomer
    );
    const withEventCategories = createSelector(
      withCustomer,
      this.systemService.selectorsProjected.selectCategoryMap,
      projectCustomerEventsCategories
    );
    const withVendorCats = createSelector(
      withEventCategories,
      this.vendorService.selectorsProjected.selectEntityMap,
      projectVendorCats
    );
    const withEventItems = createSelector(
      withVendorCats,
      this.eventItemService.selectorsProjected.selectEntities,
      projectEventToItems
    );

    const withChecklistItems = createSelector(
      withEventItems,
      this.checklistItemService.selectorsProjected.selectEntities,
      this.vendorService.selectorsProjected.selectEntityMap,
      projectEventToChecklist
    );
    // const selectCategoryVendors =
    const selectDialogs = createSelector(
      withChecklistItems,
      this.authService.selectUserId,
      this.chatService.selectorsProjected.selectEntities,
      projectEventDialogs
    );
    return selectDialogs;
  }
  setupRx$() {
    this.customerEventMap$ = this.entityMapProjected$;
    this.customerEvents$ = this.entitiesProjected$;
    this.customerEventInContext$ = this.entityInContext$;
    this.customerEventsInContext$ = combineLatest([
      this.customerEvents$,
      this.customerService.entityIdInContext$,
    ]).pipe(
      filter(([cc]) => !!cc),
      map(([events, customerId]) =>
        events.filter((c) => c._parentId === customerId)
      )
    );
    this.eventItemInContext$ = combineLatest([
      this.customerEventInContext$.pipe(filter((o) => !!o)),
      this.eventItemService.entityIdInContext$,
    ]).pipe(map(([customerEvent, itemId]) => customerEvent.itemMap[itemId]));
  }

  public eventsOfCustomer$(events: CustomerEvent[], customerId: string) {
    return events.filter((c) => c._parentId === customerId);
  }

  public syncCustomerEvents$(agency: string, customer: string) {
    return this.syncQueryRealtime$([
      AGENCY_KEYS.plural,
      agency,
      CUSTOMER_KEYS.plural,
      customer,
      CUSTOMER_EVENT_KEYS.plural,
    ]);
  }

  createEventItem(
    vendorCat: VendorCat,
    name: string,
    sortIndex: number
  ): Observable<EventItemData> {
    return this.eventItemService.createEventItem(vendorCat, name, sortIndex);
  }

  createChecklistItem(
    customerEvent: CustomerEvent,
    d: ChecklistItemData,
    isNext: boolean
  ): Observable<CustomerEventData> {
    return this.checklistItemService.createChecklistItem(customerEvent, d).pipe(
      mergeMap((item) => {
        if (!isNext) {
          return of(customerEvent.data);
        } else {
          return this.update(
            indexChangeChecklistNextItem(
              customerEvent.data,
              d.catId,
              item.meta.id
            )
          );
        }
      })
    );
  }
  updateChecklistItem(
    customerEvent: CustomerEvent,
    d: EntityData,
    checklistItem: ChecklistItem,
    isNext?: boolean
  ) {
    // console.log('www', checklistItem, d, isNext, customerEvent);
    let o: Observable<ChecklistItemData | EventItemData>;
    const data = { ...d, meta: checklistItem.meta };

    if (checklistItem.type !== 'eventItem') {
      o = this.checklistItemService.update(data);
    } else {
      o = this.eventItemService.update(data);
    }

    return o.pipe(
      mergeMap((item: ChecklistItemData | EventItemData) => {
        if (
          isNext === undefined ||
          (isNext === false &&
            customerEvent.getHighlightedChecklistItem(item.catId)?.id !==
            item.meta.id)
        ) {
          return of(customerEvent.data);
        } else {
          if (isNext) {
            return this.update(
              indexChangeChecklistNextItem(
                customerEvent.data,
                item.catId,
                item.meta.id
              )
            );
          } else {
            return this.update(
              indexChangeChecklistNextItem(customerEvent.data, item.catId, null)
            );
          }
        }
      })
    );
  }
  transferEventItem(
    eventItem: EventItem,
    src: VendorCat,
    dest: VendorCat
  ): Observable<CustomerEventData> {
    const srcItems = src.items.map((i) => i.id);
    const srcVendor = src.vendorId;
    const dstItems = dest.items.map((i) => i.id);
    const dstVendor = dest.vendorId;
    const catId = dest.id;
    const customerEvent = eventItem.event.data;
    const u: Partial<EventItemData> = {
      vendorId: dstVendor,
      meta: eventItem.data.meta,
    };
    throw Error('not ready');
    // return this.update(
    //   indexChangeEventItems(
    //     customerEvent,
    //     catId,
    //     srcVendor,
    //     srcItems,
    //     dstVendor,
    //     dstItems
    //   )
    // ).pipe(
    //   mergeMap(data =>
    //     of().pipe(
    //       mergeMap(_ => this.eventItemService.update(u)),
    //       map(_ => data)
    //     )
    //   )
    // );
  }

  updateIndexEventCategories(
    customerEvent: CustomerEventData,
    cats: string[]
  ): Observable<CustomerEventData> {
    return this.update(indexChangeEventCategories(customerEvent, cats));
  }

  public updateIndexEventVendors(
    customerEvent: CustomerEventData,
    category: string,
    vendors: string[]
  ): Observable<CustomerEventData> {
    return this.update(
      indexChangeEventVendors(customerEvent, category, vendors)
    );
  }

  public updateIndexEventItems(
    customerEvent: CustomerEventData,
    category: string,
    vendor: string,
    items: string[]
  ): Observable<CustomerEventData> {
    throw Error('not ready');
    // return this.update(
    //   indexChangeEventItems(customerEvent, category, vendor, items)
    // );
  }

  protected newProjectedEntity(d: CustomerEventData) {
    return new CustomerEvent(d);
  }

  syncBigPlan$(agencyId: string) {
    return this.syncQueryRealtimeGroupCollection$(CUSTOMER_EVENT_KEYS.plural, {
      queryFn: [
        (q) =>
          q
            .where('agencyId', '==', agencyId)
            .where('customerType', 'in', ['client', 'other']),
        (q) =>
          q
            .where('agencyId', '==', agencyId)
            .where('customerType', 'in', ['client', 'other']),
      ],
      replaceState: false,
    });
  }
  syncAllEvents$(agencyId: string) {
    return this.syncQueryRealtimeGroupCollection$(CUSTOMER_EVENT_KEYS.plural, {
      queryFn: [
        (q) =>
          q
            .where('agencyId', '==', agencyId),
        (q) =>
          q
            .where('agencyId', '==', agencyId)
      ],
      replaceState: false,
    });
  }
}
