<mat-dialog-content>
    <flrgl-event-item-spec-img [eventItem]="eventItem" noEdit="true">
    </flrgl-event-item-spec-img>
    <h2 mat-dialog-title>Specification for {{eventItem.name}}</h2>
    <mat-form-field *ngIf="editMode;else specView" class="full-width notes">
        <mat-label>Spec Details</mat-label>
        <textarea matInput placeholder="" rows=20 [formControl]="spec"></textarea>
    </mat-form-field>
    <ng-template #specView>
        <div class="spec-view" [innerHTML]="eventItem.spec">
        </div>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>
        <mat-icon>close</mat-icon> Close
    </button>
    <button *ngIf="!editMode && auth.isPlanner" mat-button (click)="toggleEdit(true)">
        <mat-icon>edit</mat-icon> Edit
    </button>
    <button *ngIf="editMode" mat-button [mat-dialog-close]="saveSpec()" type="submit">
        <mat-icon>save</mat-icon> Save
    </button>
</mat-dialog-actions>