import { FormControl } from '@angular/forms';
import { EntityProjectedBase } from '@skylitup/base/ngrx-data-fire';
import {
  combineLatest,
  ConnectableObservable,
  merge,
  Observable,
  Subject
} from 'rxjs';
import { filter, map, publishBehavior, startWith } from 'rxjs/operators';

interface EntityAutoCompleteSettings<T extends EntityProjectedBase<any>> {
  label: string;
  options$: Observable<T[]>;
  formControl: FormControl;
  displayFn?: (entity: T) => string;
  optionDisplayFn?: (entity: T) => string;
}
export class EntityAutocomplete<T extends EntityProjectedBase<any>> {
  filteredOptions$: Observable<T[]>;
  optionSelected$: Observable<T>;
  filterOverride$ = new Subject<string>();
  label: string;
  options$: Observable<T[]>;
  formControl: FormControl;
  displayFn: (entity: T) => string;
  optionDisplayFn: (entity: T) => string;

  constructor(settings: EntityAutoCompleteSettings<T>) {
    this.label = settings.label;
    this.options$ = settings.options$;
    this.formControl = settings.formControl;
    this.displayFn = settings.displayFn
      ? settings.displayFn
      : (entity: T) => (entity && entity.name ? entity.name : '');
    this.optionDisplayFn = settings.optionDisplayFn
      ? settings.optionDisplayFn
      : this.displayFn;
    this.filteredOptions$ = combineLatest([
      this.options$,
      merge(
        this.filterOverride$,
        this.formControl.valueChanges.pipe(
          startWith(''),
          map(value => (typeof value === 'string' ? value : value?.name || ''))
        )
      )
    ]).pipe(
      map(([options, v]) =>
        options.filter(
          option => option.name.toLowerCase().indexOf(v?.toLowerCase()) === 0
        )
      )
    );
    this.optionSelected$ = this.formControl.valueChanges.pipe(
      filter(o => typeof o !== 'string'),
      // distinctUntilChanged(),
      publishBehavior(null)
    );
    (<ConnectableObservable<T>>this.optionSelected$).connect();
  }
  clearFilter() {
    this.filterOverride$.next('');
  }
}
