import * as moment from "moment-timezone";
import { Dictionary } from "@ngrx/entity";
import { EntityData } from "./entity.model.data";

interface J<T extends EntityData> extends EntityProjectedBase<T> {}

export const DATE_STRING_LONG_FORMAT = "dddd, Do of MMMM, Y";
export const DATE_STRING_FORMAT = "LL";
export const TIMEZONE_LONDON = 'Europe/London';


export abstract class EntityProjectedBase<T extends EntityData> {
  constructor(public data: T) {
    if (!data.meta) {
      data = { ...data };
      data.meta = {} as any;
      this.data = data;
      Object.preventExtensions(this.data);
    }
    if (!data.meta.id && data["id"]) {
      data.meta.id = data["id"];
    }
  }
  get id(): string {
    return this.data.meta.id;
  }
  get name(): string {
    return this.data["name"] || this.id;
  }
  public static hashCode(s: string) {
    let hash = 0,
      chr;
    for (let i = 0; i < this.length; i++) {
      chr = s.charCodeAt(i);
      // tslint:disable-next-line: no-bitwise
      hash = (hash << 10) - hash + chr;
      // tslint:disable-next-line: no-bitwise
      hash |= 0; // Convert to 32bit integer
    }
    return "" + hash; //.substring(0, 4);
  }

  public static hashCode2(s: string) {
    const ss = s || "";
    return ss
      .split("")
      .map((o) => o.charCodeAt(0))
      .map((o) => o % 10)
      .slice(0, 4)
      .join("");
    // let hash = 0,
    //   chr;
    // for (let i = 0; i < this.length; i++) {
    //   chr = s.charCodeAt(i);
    //   // tslint:disable-next-line: no-bitwise
    //   hash = (hash << 10) - hash + chr;
    //   // tslint:disable-next-line: no-bitwise
    //   hash |= 0; // Convert to 32bit integer
    // }
    // return '' + hash; //.substring(0, 4);
  }
  // public static hashCode(s: string) {for(var i=h=0;i<s.length;i++)h=Math.imul(31,h)+s.charCodeAt(i)|0;return h;}
  abstract get url(): string;
  abstract clone(): J<T>;
  /*expected to return itself for convenient chaining */
  abstract copy(from: J<T>): J<T>;
}
export abstract class EntityProjected<
  T extends EntityData
> extends EntityProjectedBase<T> {
  constructor(data: T) {
    super(data);
  }

  get url(): string {
    return this.data.meta
      ? `/${this.data.meta.urlPath.join("/")}/${this.id}`
      : "#";
  }

  get _collectionPath(): string[] {
    return this.data?.meta?.collectionPath;
  }
  get _fullPath(): string[] {
    return this._collectionPath ? [...this._collectionPath, this.id] : null;
  }
  get _collection(): string {
    return this.data?.meta?.collection;
  }
  get _parentId(): string {
    return this.data?.meta?.parentId;
  }
  get _parentCollection(): string {
    return this.data?.meta?.parentCollection;
  }

  toString() {
    return JSON.stringify(this.data);
  }
}

export type EntityTuple<T> = [T[], Dictionary<T>];

export const dateStringLong = (date: Date, timezone: string) => {
  if (date) {
    return moment(date).tz(timezone).format(DATE_STRING_LONG_FORMAT);
  } else {
    console.log('ttttt', date, timezone);
    return "";
  }
};

export const dateString = (date: Date, timezone: string) => {
  if (date) {
    return moment(date).tz(timezone).format(DATE_STRING_FORMAT);
  } else {
    return "";
  }
};