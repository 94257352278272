import { createAction, props } from "@ngrx/store";
import { EntityData } from "../../entity-model/entity.model.data";

// interface T extends EntityData {};

export enum EntityOpsExtra {
  SAVE_BATCH_UPDATE = "ngrx/data/save/batch-update",
  SAVE_BATCH_UPDATE_SUCCESS = "ngrx/data/save/batch-update/success",
  SAVE_BATCH_UPDATE_ERROR = "ngrx/data/save/batch-update/error",
}
export function createBatchUpdate<T extends EntityData>(
  entityPluralName: string,
  updates: Partial<T>[]
) {
  const actionFn = createAction(
    `[${entityPluralName}] @${EntityOpsExtra.SAVE_BATCH_UPDATE}`,
    props<{
      payload: { entityName: string; entityOp: string; data: Partial<T>[] };
    }>()
  );
  const action = actionFn({
    payload: {
      entityName: entityPluralName,
      entityOp: EntityOpsExtra.SAVE_BATCH_UPDATE,
      data: updates,
    },
  });
  return action;
}

export const createBatchUpdateSuccess = (entityPluralName: string) => {
  const actionFn = createAction(
    `[${entityPluralName}] @${EntityOpsExtra.SAVE_BATCH_UPDATE_SUCCESS}`,
    props<{
      payload: { entityName: string; entityOp: string };
    }>()
  );
  const action = actionFn({
    payload: {
      entityName: entityPluralName,
      entityOp: EntityOpsExtra.SAVE_BATCH_UPDATE_SUCCESS,
    },
  });
  return action;
};
export const createBatchUpdateError = (
  entityPluralName: string,
  error: string
) => {
  const actionFn = createAction(
    `[${entityPluralName}] @${EntityOpsExtra.SAVE_BATCH_UPDATE_ERROR}`,
    props<{
      payload: {
        entityName: string;
        entityOp: string;
        data: { error: { message: string } };
      };
    }>()
  );
  const action = actionFn({
    payload: {
      entityName: entityPluralName,
      entityOp: EntityOpsExtra.SAVE_BATCH_UPDATE_ERROR,
      data: {
        error: {
          message: error,
        },
      },
    },
  });
  return action;
};
