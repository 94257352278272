import { Injectable, InjectionToken, Inject } from '@angular/core';
// import {
//   AngularFirestore,
//   QueryDocumentSnapshot,
// } from '@angular/fire/firestore';
import { QueryParams } from '@ngrx/data';
import { Update, Dictionary } from '@ngrx/entity';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap, first } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { idGen } from '../../util';
import {
  FirestoreEntityDataService,
  QueryFunction,
} from '../../firestore-entity.data.service';
import { FirestoreEntityDataServiceProvider } from '../../firestore-entity-data-service-provider.service';
import {
  EntityData,
  EntityKeys,
} from '../../../entity-model/entity.model.data';
import {
  createBatchUpdate,
  createBatchUpdateSuccess,
  createBatchUpdateError,
} from '../../state/firestore-batch.actions';
import { AngularFirestore } from '@angular/fire/firestore';

export const ENTITY_KEY_MAP_PLURAL = new InjectionToken<any>(
  'ENTITY_KEY_MAP_PLURAL'
);

@Injectable({ providedIn: 'root' })
export class WebFirestoreEntityDataServiceProvider extends FirestoreEntityDataServiceProvider {
  constructor(
    private firestore: AngularFirestore,
    private store: Store,
    @Inject(ENTITY_KEY_MAP_PLURAL)
    private entityKeyMapPlural: Dictionary<EntityKeys>
  ) {
    super();
  }
  getNewDataService(plural: string): FirestoreEntityDataService<any> {
    return new WebFirestoreEntityDataService(
      plural,
      this.firestore,
      this.store,
      this.entityKeyMapPlural
    );
  }
}

export class WebFirestoreEntityDataService<T extends EntityData>
  implements FirestoreEntityDataService<T> {
  public plural: string;
  constructor(
    public name: string,
    private firestore: AngularFirestore,
    private store: Store,
    private entityKeyMapPlural: Dictionary<EntityKeys>
  ) {
    this.plural = this.name;
  }

  add(entity: T): Observable<T> {
    console.error('Method not implemented.');
    throw new Error('Method not implemented.');
  }
  delete(id: string | number): Observable<string | number> {
    console.error('object deleted :' + id);
    return of(id);
  }
  getAll(path?: string[]): Observable<T[]> {
    const pathString = path.join('/');
    return of(getMetaFromPath(path, this.entityKeyMapPlural)).pipe(
      mergeMap((meta) =>
        this.firestore
          .collection<T>(pathString)
          .get()
          .pipe(
            map((docData) =>
              docData.docs.map(
                (s) =>
                  ({
                    meta: { ...meta, id: s.id },
                    ...s.data(),
                  } as T)
              )
            ),
            catchError((a) => {
              console.error('error at buildRealtimeQuery$ for ' + pathString);
              return throwError(a);
            })
          )
      )
    );
  }

  getById(id: any): Observable<T> {
    console.error('Method not implemented.');
    throw new Error('Method not implemented.');
  }
  getWithQuery(params: string | QueryParams): Observable<T[]> {
    console.error('Method not implemented.');
    throw new Error('Method not implemented.');
  }
  update(u: Update<T>): Observable<T> {
    if (!u.changes.meta || !u.changes.meta.collectionPath) {
      throw Error('changes need to include meta.path');
    }
    const changes = { ...u.changes, meta: { ...u.changes.meta } };

    const meta = getMetaFromPath(
      changes.meta.collectionPath,
      this.entityKeyMapPlural
    ) as EntityData['meta'];
    meta.id = u.id as string;

    delete changes.meta;

    const collectionPath = meta.collectionPath;
    const docRef = this.firestore
      .collection(collectionPath.join('/'))
      .doc<Partial<T>>(meta.id);
    return from(
      docRef.set(changes, { merge: true }).catch((error) => {
        const cEr = [...collectionPath, meta.id];
        console.error(error, cEr, u);
        throw error;
      })
    ).pipe(
      mergeMap((_) => docRef.get()),
      first(),
      map((d) => d.data() as T),
      catchError((error) => {
        const cEr = [...collectionPath, meta.id];
        console.error(error, cEr, u);
        return throwError(error);
      })
    );
  }

  updateBatch(updates: Partial<T>[]): Observable<any> {
    const batch = this.firestore.firestore.batch();
    for (const u of updates) {
      if (!u.meta || !u.meta.collectionPath) {
        throw Error('changes need to include meta.path');
      }
      const uNoMeta = { ...u };
      delete uNoMeta.meta;
      // console.log(JSON.stringify(u));
      // console.log('uuuuuu');
      const collectionPath = u.meta.collectionPath;
      const docRef = this.firestore.firestore
        .collection(collectionPath.join('/'))
        .doc(u.meta.id);
      batch.set(docRef, uNoMeta, { merge: true });
    }
    this.store.dispatch(createBatchUpdate<T>(this.plural, updates));
    return from(
      batch
        .commit()
        .then(() => {
          this.store.dispatch(createBatchUpdateSuccess(this.plural));
          return;
        })
        .catch((error) => {
          this.store.dispatch(createBatchUpdateError(this.plural, error));
          console.error(error);
          throw error;
        })
    ).pipe(
      catchError((error) => {
        // const cEr = [...collectionPath,meta.id];
        console.error(error, updates);
        return throwError(error);
      })
    );
  }

  upsert(t: T): Observable<T> {
    // console.log('yyyyyyy', t);
    if (!t.meta || !t.meta.collectionPath) {
      throw Error('entity does not have meta.path');
    }
    const entity = { ...t, meta: { ...t.meta } };
    const id = entity.meta.id ? entity.meta.id : idGen();
    const meta = getMetaFromPath(
      entity.meta.collectionPath,
      this.entityKeyMapPlural
    ) as EntityData['meta'];
    if (!meta.id) {
      meta.id = id;
    }
    delete entity.meta;

    const collectionPath = meta.collectionPath;
    // console.log('oooooo',collectionPath,id);
    return from(
      this.firestore
        .collection(collectionPath.join('/'))
        .doc(id)
        .set(entity, { merge: true })
        .catch((error) => {
          console.error(error);
          throw error;
        })
    ).pipe(
      first(),
      map((_) => ({
        meta,
        ...entity,
      })),
      catchError((error) => {
        const cEr = [...collectionPath, meta.id];
        console.error(error, cEr, entity);

        return throwError(error);
      })
    );
  }

  buildRealtimeQuery$(
    path: string[],
    queryFunction: QueryFunction
  ): Observable<T[]> {
    const pathString = path.join('/');

    const q = queryFunction
      ? (ref) => queryFunction(ref).where('_del', '==', null)
      : (query) => query.where('_del', '==', null);
    return of(getMetaFromPath(path, this.entityKeyMapPlural)).pipe(
      mergeMap((meta) =>
        this.firestore
          .collection<T>(pathString, q as any)
          .snapshotChanges()
          .pipe(
            map((ss: any) =>
              ss.map((s) => ({
                meta: { ...meta, id: s.payload.doc.id },
                ...s.payload.doc.data(),
              }))
            ),
            catchError((a) => {
              console.error('error at buildRealtimeQuery$ for ' + pathString);
              return throwError(a);
            })
          )
      )
    );
  }

  buildRealtimeCollectionGroupQuery$(
    collectionName: string,
    queryFunction: QueryFunction
  ) {
    const q = queryFunction
      ? (ref) => queryFunction(ref).where('_del', '==', null)
      : (query) => query.where('_del', '==', null);
    return this.firestore
      .collectionGroup<T>(collectionName, q as any)
      .snapshotChanges()
      .pipe(
        map((ss) =>
          ss.map((s) => ({
            meta: getMetaFromDoc(s.payload.doc as any, this.entityKeyMapPlural),
            ...s.payload.doc.data(),
          }))
        ),
        catchError((a) => {
          console.error(
            'error at buildRealtimeCollectionGroupQuery$ for ' + collectionName
          );
          return throwError(a);
        })
      );
  }

  getDocRealtime$(path: string[], id: string): Observable<T> {
    return of({
      ...getMetaFromPath(path, this.entityKeyMapPlural),
      id,
    }).pipe(
      mergeMap((meta) =>
        this.firestore
          .collection(path.join('/'))
          .doc(id)
          .snapshotChanges()
          .pipe(
            map((s) => ({
              meta,
              ...(s.payload.data() as any),
            })),
            catchError((a) => {
              console.error('error at getDocRealtime$ for ' + path);
              return throwError(a);
            })
          )
      )
    );
  }
  getAllRealtime$(path: string[]): Observable<T[]> {
    return of(getMetaFromPath(path, this.entityKeyMapPlural)).pipe(
      mergeMap((meta) =>
        this.firestore
          .collectionGroup<T>(this.plural)
          .snapshotChanges()
          .pipe(
            map((ss: any) =>
              ss.map((s) => {
                return {
                  meta: { ...meta, id: s.payload.doc.id },
                  ...s.payload.doc.data(),
                };
              })
            ),
            catchError((a) => {
              console.error('error at getAllRealtime$() for ' + this.plural);
              return throwError(a);
            })
          )
      )
    );
  }
  deleteEntity(entity: T): Observable<void> {
    if (!entity.meta || !entity.meta.collectionPath) {
      throw Error('changes need to include meta.path');
    }

    const meta = entity.meta;
    const u = { ...entity, _del: true };
    delete u.meta;

    const collectionPath = meta.collectionPath;
    const docRef = this.firestore
      .collection(collectionPath.join('/'))
      .doc<Partial<T>>(meta.id);
    return from(
      docRef.set(u, { merge: true }).catch((error) => {
        console.error(error, u);
        throw error;
      })
    );
  }
}

function getMetaFromDoc(
  doc: any, //DocumentSnapshot<any>,
  entityKeyMapPlural: Dictionary<EntityKeys>
) {
  const id = doc.ref.id;
  const collection = doc.ref.parent.id;
  const parentId = doc.ref.parent.parent?.id;
  const parentCollection = doc.ref.parent.parent?.parent?.id;
  let p: any = doc.ref.parent;
  const collectionPath = [];
  const urlPath = [];
  let i = 0;
  while (p) {
    collectionPath.unshift(p.id);
    if (!(i % 2)) {
      urlPath.unshift(
        entityKeyMapPlural[p.id] ? entityKeyMapPlural[p.id].name : p[p.id]
      );
    } else {
      urlPath.unshift(p.id);
    }
    i++;
    p = p.parent;
  }

  return {
    id,
    collection,
    parentCollection,
    parentId,
    collectionPath,
    urlPath,
  };
}
function getMetaFromPath(
  collectionPath: string[],
  entityKeyMapPlural: Dictionary<EntityKeys>
) {
  const p = [...collectionPath];
  const urlPath = [];
  for (let i = 0; i < p.length; i++) {
    if (!(i % 2)) {
      urlPath.push(
        entityKeyMapPlural[p[i]] ? entityKeyMapPlural[p[i]].name : p[i]
      );
    } else {
      urlPath.push(p[i]);
    }
  }

  const collection = p.pop();
  if (p.length) {
    const parentId = p.pop();
    const parentCollection = p.pop();
    return { collection, parentCollection, parentId, collectionPath, urlPath };
  } else {
    return { collection, collectionPath, urlPath };
  }
}

// function getMetaFromSnapshot(doc: DocumentSnapshot<DocumentData>) {
//   const id = doc.id;
//   const collection = doc.ref.parent.id;
//   let parentId = null;
//   let parentCollection = null;
//   if (doc.ref.parent.parent) {
//     parentId = doc.ref.parent.parent.id;
//     parentCollection = doc.ref.parent.parent.parent.id;
//   }
//   if (parentId) {
//     return { id, collection, parentId, parentCollection };
//   } else {
//     return { id, collection };
//   }
// }
