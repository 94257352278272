import { Input, Directive } from '@angular/core';
import { BaseComponent } from '@skylitup/base/util';
import { EventItem } from '@skylitup/flowergirl/core';

@Directive()
export abstract class OverviewEventItemBaseComponent extends BaseComponent {
  @Input()
  item: EventItem;
  constructor() {
    super();
  }
}
