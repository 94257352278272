import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  EffectNotification,
  ofType,
} from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import {
  firestoreRealtime,
  firestoreRealtimeNoSyncing,
} from '@skylitup/base/ngrx-data-fire';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { AGENCY_KEYS } from '../agency/agency.model.data';
import { AgencyService } from '../agency/agency.service';
import { CUSTOMER_EVENT_KEYS } from '../customer-event/customer-event.model.data';
import { CustomerEventService } from '../customer-event/customer-event.service';
import { CUSTOMER_KEYS } from '../customer/customer.model.data';
import { CustomerService } from '../customer/customer.service';
import { CHAT_KEYS } from './chat.model.data';
import { ChatService } from './chat.service';
import { NotificationService } from '../../util/services/notification.service';
import { genericCatch$$ } from '@skylitup/base/util';

@Injectable({ providedIn: 'root' })
export class ChatEffects {
  chatItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      switchMap((_) =>
        combineLatest([
          this.agencyService.entityIdInContext$,
          this.customerService.entityIdInContext$,
          this.customerEventService.entityIdInContext$,
        ])
      ),
      distinctUntilChanged(null, (a) => a.join('')),
      switchMap((a) =>
        of(a).pipe(
          switchMap(([agencyId, customerId, customerEventId]) =>
            agencyId && customerId && customerEventId
              ? this.chatItemService
                  .syncChats$(agencyId, customerId, customerEventId)
                  .pipe(
                    map((_) =>
                      firestoreRealtime(
                        `${AGENCY_KEYS.plural}/${agencyId}/${CUSTOMER_KEYS.plural}/${customerId}/${CUSTOMER_EVENT_KEYS.plural}/${customerEventId}/${CHAT_KEYS.plural}`
                      )
                    )
                  )
              : of(firestoreRealtimeNoSyncing('chat-items'))
          ),
          genericCatch$$('ChatEffects - customerEventContext')
        )
      )
    )
  );
  constructor(
    private actions$: Actions,
    private agencyService: AgencyService,
    private customerService: CustomerService,
    private customerEventService: CustomerEventService,
    private chatItemService: ChatService,
    private notifications: NotificationService
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.notifications.trackCompletion(resolvedEffects$);
  }
}
