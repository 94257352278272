import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule, ORIGIN, REGION } from '@angular/fire/functions';

// xplat load singletons from xplat and any config specific to this app (or group of apps if it's coming from xplat)
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { ENV } from '@skylitup/base/util';
import { FlowergirlCoreModule } from '@skylitup/flowergirl/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    FlowergirlCoreModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    StoreDevtoolsModule.instrument({
      maxAge: false,
      logOnly: environment.production,
    }),
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule, AngularFireFunctionsModule,

  ],
  exports: [],
  providers: [
    { provide: ENV, useValue: environment },
    // { provide: ORIGIN, useValue: environment.firebase.cloudfunctions}
  ],
})
export class FlowergirlAppCoreModule { }
