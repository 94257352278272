import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

import { Observable } from 'rxjs';

import { NgxGalleryPreviewComponent } from '@kolkov/ngx-gallery';
import { MatDialog } from '@angular/material/dialog';
import { ChatDialogBaseComponent } from '@skylitup/flowergirl/features/chat';
import { Dialog, Auth, Chat, Media, Doc } from '@skylitup/flowergirl/core';
import { LightboxComponent, PdfboxComponent } from '@skylitup/flowergirl/xplat/web/ui';

@Component({
  selector: 'flrgl-chat-dialog',
  templateUrl: 'chat-dialog.component.html',
  styleUrls: ['chat-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatDialogComponent extends ChatDialogBaseComponent
  implements OnChanges {
  @Input()
  dialog: Dialog;
  @Input()
  auth: Auth;
  @ViewChild('chatHistoryEl') history: ElementRef;
  @Output()
  msgDeleted = new EventEmitter();
  @Output()
  msgEditStart = new EventEmitter();

  constructor(private matDialog: MatDialog) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.dialog &&
      (<Dialog>changes.dialog?.currentValue)?.history?.length
    ) {
      this.scrollToBottom();
    }
  }
  isMySide(chat: Chat, auth: Auth) {
    return (
      (auth.isPlanner && chat.senderIsAgency) ||
      ((auth.isCustomer && !auth.isPlanner) && !chat.senderIsAgency)
    );
  }
  isNextSameSide(index) {
    const current = this.dialog?.history?.[index];
    const next = this.dialog?.history?.[index + 1];
    if (current && next && current.senderId === next?.senderId) {
      return true;
    } else {
      return false;
    }
  }
  scrollToBottom() {
    setTimeout(() => {
      this.history.nativeElement.scrollTop = this.history.nativeElement.scrollHeight;
    }, 0);
  }
  // showLightbox(media: Media) {
  //   this.lightboxPopup.open(LightboxComponent, {
  //     data: media.urlLarge,
  //     disableClose: false,
  //     panelClass: 'p-0',
  //   });
  // }

  trackByIdFn(i, o) {
    return o.id;
  }
  fullDate(chat: Chat) {
    return chat.dateStringFull;
  }
  showPdf(doc: Doc) {
    this.matDialog.open(PdfboxComponent, { data: doc.fileUrl, height: '80vh', width: '80vw' });
  }
  debug1(c) {
    console.log('ccc', c);
  }
}

// chatImageOptions = [{ height: '200px', image: false }];
// chatImageUrls(chat: Chat) {
//   if (chat?.data?.attachment?.media) {
//     return chat.data.attachment.media.map(m => ({
//       small: m.urlFull,
//       big: m.urlFull,
//       medium: m.urlFull
//     }));
//   } else return [];
// }
