import { EntityKeys } from '@skylitup/base/ngrx-data-fire';
import { UploadData } from '../upload.model.data';

export const DOC_KEYS: EntityKeys = {
    name: 'doc',
    plural: 'docs'
};
export interface DocData extends UploadData {
    type: 'contract' | 'invoice' | 'prices' | 'payment' | 'brochure' | 'other';
}

export const DOC_TYPE_OPTIONS = {
    'contract': "Contract",
    'invoice': "Invoice",
    'prices': "Prices",
    'payment': "Payment",
    'brochure': "Brochure",
    'transaction': "Transaction",
    'other': "Other"
}