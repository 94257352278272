import { BaseComponent } from '@skylitup/base/util';

 
export abstract class ChecklistItemEditBaseComponent extends BaseComponent {
  public text = 'ChecklistItemEdit';

  constructor() {
    super();
  }
}
