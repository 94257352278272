import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FormControl } from '@angular/forms';
import { EventItemSpecBaseComponent } from '@skylitup/flowergirl/features/overview';
import { EventItem, Auth } from '@skylitup/flowergirl/core';

@Component({
  selector: 'flrgl-event-item-spec',
  templateUrl: 'event-item-spec.component.html',
  styleUrls: ['event-item-spec.component.scss'],
})
export class EventItemSpecComponent extends EventItemSpecBaseComponent {
  editMode = false;
  spec = new FormControl();
  eventItem: EventItem;
  auth: Auth;
  constructor(
    @Inject(MAT_DIALOG_DATA) data: { item: EventItem; auth: Auth },
    private dialogRef: MatDialogRef<EventItemSpecComponent>
  ) {
    super();
    this.eventItem = data.item;
    this.auth = data.auth;
    if (!this.eventItem.data.spec && this.auth.isPlanner) {
      this.toggleEdit(true);
    } else {
      this.spec.setValue(this.eventItem.data.spec);
    }
  }
  saveSpec() {
    return { meta: this.eventItem.data.meta, spec: this.spec.value };
  }
  toggleEdit(edit: boolean) {
    if (!this.auth.isPlanner) {
      return;
    }
    this.editMode = edit;
    if (edit) {
      this.dialogRef.disableClose = true;
    } else {
      this.dialogRef.disableClose = false;
    }
  }
}
