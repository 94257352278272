import { EntityProjected } from '@skylitup/base/ngrx-data-fire';
import * as moment from 'moment';
import { entityDate } from '@skylitup/base/ngrx-data-fire';
import { DATE_STRING_FORMAT } from '../system/system.model';
import { CustomerAccessType, UserCardData } from './user-card.model.data';
export class UserCard extends EntityProjected<UserCardData> {
  // readonly createDateString: string;
  readonly createDate: Date;
  readonly invitationDate: Date;
  readonly joinedDate: Date;
  readonly lastLoginDate: Date;
  readonly lastEventDescription: string;

  isActive(customerId: string): boolean {
    return this.data?.access?.[customerId]?.active || false;
  }
  expires(customerId: string): boolean {
    return !!this.data?.access?.[customerId]?.expiryDate || false;
  }
  getExpiryDate(customerId: string): Date {
    return this.data?.access?.[customerId]?.expiryDate ? entityDate(this.data?.access?.[customerId]?.expiryDate) : null;
  }
  getExpiryDateFullDescription(customerId: string): string {
    return moment(this.getExpiryDate(customerId)).format("LLL");
  }
  getExpiryDateFromNow(customerId: string): string {
    return moment(this.getExpiryDate(customerId)).fromNow();
  }
  getType(customerId: string): CustomerAccessType {
    return this.data?.access?.[customerId]?.type || 'full';
  }
  isVendorsMasked(customerId: string): boolean {
    return this.data?.access?.[customerId]?.vendorsMasked || false;
  }
  get authUId() {
    return this.data.authUId;
  }

  get customerScope() {
    return this.data.customerScope || [];
  }
  get access() {
    return this.data.access || {};
  }

  constructor(data: UserCardData) {
    super(data);
    this.createDate = entityDate(this.data?.createDate);
    this.invitationDate = this.data?.invitationDate ? entityDate(this.data?.invitationDate) : null;
    this.joinedDate = this.data?.joinedDate ? entityDate(this.data?.joinedDate) : null;

    if (this.joinedDate) {
      this.lastEventDescription = `Joined on ${moment(this.joinedDate).format(DATE_STRING_FORMAT)}`;
    } else if (this.invitationDate) {
      this.lastEventDescription = `Invited on ${moment(this.invitationDate).format(DATE_STRING_FORMAT)}`;
    } else if (this.createDate) {
      this.lastEventDescription = `Created on ${moment(this.createDate).format(DATE_STRING_FORMAT)}`;
    }
  }
  get username() {
    return this.data.username;
  }
  get name() {
    return this.username;
  }
  get email() {
    return this.data.email;
  }
  clone(): UserCard {
    return new UserCard(this.data).copy(this);
  }

  copy(from: UserCard): UserCard {
    return this;
  }
}
