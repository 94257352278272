import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlowergirlXplatWebUiModule } from '@skylitup/flowergirl/xplat/web/ui';
import { EventItemSpecComponent } from './components/event-item-details/event-item-spec/event-item-spec.component';
import { OverviewComponent } from './components/overview/overview.component';
import { OverviewEventItemComponent } from './components/overview-event-item/overview-event-item.component';
import { EventItemDetailsComponent } from './components/event-item-details/event-item-details.component';
import { EventItemSpecImgComponent } from './components/event-item-details/event-item-spec-img/event-item-spec-img.component';
import { FlowergirlFeaturesOverviewModule } from '@skylitup/flowergirl/features/overview';
import { FlowergirlXplatWebFeaturesChatModule } from '@skylitup/flowergirl/xplat/web/features/chat';
@NgModule({
  imports: [
    FlowergirlFeaturesOverviewModule,
    FlowergirlXplatWebUiModule,
    FlowergirlXplatWebFeaturesChatModule,
  ],
  declarations: [
    EventItemSpecComponent,
    OverviewComponent,
    OverviewEventItemComponent,
    EventItemDetailsComponent,
    EventItemSpecImgComponent,
  ],
  exports: [],
})
export class FlowergirlXplatWebFeaturesOverviewModule {}
