<mat-dialog-content class="p-30">
    <form [formGroup]="form" fxLayoutAlign="center center" fxLayout="column">
        <div>
            <mat-form-field appearance="standard">
                <mat-label>Document Name</mat-label>
                <input matInput formControlName="name" required (keyup)="keyUp($event.keyCode)">
                <mat-error *ngIf="name.invalid">{{getErrorMessage(name,'Document Name')}}</mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="select">
                <mat-label>Document Type</mat-label>
                <mat-select formControlName="type" [required]='true'>
                    <mat-option *ngFor="let option of docTypeKeys" [value]="option[0]">
                        {{option[1]}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="type.invalid">{{getErrorMessage(type,'Document Type')}}</mat-error>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button [disabled]="!form.valid" [mat-dialog-close]="result()" #submit1>Select Files</button>
    <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>