import { EntityData, EntityKeys } from '@skylitup/base/ngrx-data-fire';

export const VENDOR_KEYS: EntityKeys = {
  name: 'vendor',
  plural: 'vendors'
};
export const GLOBAL_VENDOR_KEYS: EntityKeys = {
  name: 'global-vendor',
  plural: 'global-vendors'
};


export interface VendorParam {
  label: string;
  icon?: { fontSet: string; fontIcon: string };
  required?: boolean;
  type: 'bool' | 'select' | 'number';
  hideLabelInVal?: boolean;
}
export interface VendorParamSelect extends VendorParam {
  options: { code: string; name: string }[];
}
export interface VendorParamNumber extends VendorParam {
  min: number;
  max?: number;
  units: 'na' | 'euro' | 'days' | 'km' | 'mins';
}

export interface VendorContactData {
  name: string;
  surname: string;
  email: string;
  phone1: string;
  phone2: string;
  phone3: string;
}
export interface VendorData extends EntityData {
  name: string;
  contacts: VendorContactData[];
  showcaseMedia: string[];
  priceLevel: number;
  locations: string[]; // ids of locations where the vendor belongs (located).  
  coverage: string[]; // ids of locations where the vendor can be used (areas they cover).  
  customers: string[]; // where the vendor has been used
  cats: string[]; // relevant categories where it can be used
  globalVendor?: string; // id of global vendor card for future compatibility (i'll use redundancy and cloud functions to get the info in)
  links: {
    www: string;
    instagram: string;
    twitter: string;
    facebook: string;
    pinterest: string;
    youtube: string;
  };
  address: string;
  addressGeoCenter?: { lat: number; lng: number };
  mapCenter?: { lat: number; lng: number };
  mapZoom?: number;
  notes: string;
}

export interface GlobalVendorData extends EntityData {
  name: string;
  cats: string[]; // relevant categories where it can be used
}
