import { Component } from '@angular/core';
import { ChatMessageEditBaseComponent } from '@skylitup/flowergirl/features/chat';

@Component({
  selector: 'flrgl-chat-message-edit',
  templateUrl: 'chat-message-edit.component.html',
})
export class ChatMessageEditComponent extends ChatMessageEditBaseComponent {
  constructor() {
    super();
  }
}
