import { Component, Output, EventEmitter, Input } from '@angular/core';
import { RatingBaseComponent } from '@skylitup/flowergirl/ui';

 @Component({
  selector: 'flrgl-rating',
  templateUrl: 'rating.component.html',
  styleUrls: ['rating.component.scss']
})
export class RatingComponent extends RatingBaseComponent {
  rates = [1, 2, 3, 4, 5];
  hoverRating = -1;
  hovering = false;

  @Output()
  ratingChange = new EventEmitter<number>();
  @Input()
  rating = -1;
  @Input()
  readOnly = false;
  @Input()
  size = 'normal';
  constructor() {
    super();
  }
  mouseOver(i: number) {
    if (this.readOnly) {
      return;
    }
    this.hoverRating = i;
    this.hovering = true;
  }
  mouseOut(i: number) {
    if (this.readOnly) {
      return;
    }
    this.hoverRating = -1;
    this.hovering = false;
  }
  click(i) {
    if (this.readOnly) {
      return;
    }
    this.rating = i;
    this.ratingChange.next(i);
  }
}
