import {
  EntityTuple,
  EntityProjected
} from '@skylitup/base/ngrx-data-fire';
import { Vendor } from './vendor.model';
import { Dictionary } from '@ngrx/entity';
import { Category } from '../system/system.model';
import { cloneEntityTuple } from '@skylitup/base/ngrx-data-fire';
import { Media } from '../upload/media/media.model';
import { Agency } from '../agency/agency.model';
import { Auth } from '../auth/auth.model';

// export const projectVendorToReducedNames = (
//   t: EntityTuple<Vendor>,
//   auth: Auth
// ) => {
//   const result = cloneEntityTuple(t);
//   const [vendors] = result;
//   console.log('[[[[[[[',auth);
//   for (const a of vendors) {
//     if (auth.data.impersonatingCustomer) {
//       a.maskNames = true;
//       a.codeName = EntityProjected.hashCode2(a.id);
//     }
//   }
//   return result;
// };

export const projectVendortoCats = (
  t: EntityTuple<Vendor>,
  catMap: Dictionary<Category>
): EntityTuple<Vendor> => {
  const result = cloneEntityTuple(t, v => {
    v.cats = [];
  });
  if (catMap) {
    const [vendors] = result;
    for (const a of vendors) {
      a.cats = a.data.cats ? a.data.cats.map(cId => catMap[cId]) : [];
    }
  }
  return result;
};

export function projectVendorShowcaseMedia(
  t: EntityTuple<Vendor>,
  mediaMap: Dictionary<Media>
): EntityTuple<Vendor> {
  const result = cloneEntityTuple(t, c => (c.media = []));
  const [vendors, vendorMap] = result;
  for (const c of vendors) {
    if (c.data.showcaseMedia) {
      for (const mediaId of c.data.showcaseMedia) {
        const mediaObj = mediaMap[mediaId];
        if (mediaObj) {
          c.media.push(mediaObj);
        }
      }
    }
  }
  return result;
}

export function projectVendorLocations(t: EntityTuple<Vendor>, agency: Agency) {
  if (!agency) return t;
  const locStore = agency.locStore;
  const result = cloneEntityTuple(t, c => { c.locations = []; c.coverage = []; });
  const [vendors, vendorMap] = result;
  for (const c of vendors) {
    if (c.data.locations) {
      for (const locationId of c.data.locations) {
        const location = locStore.locationMap[locationId];
        if (location) {
          c.locations.push(location);
        }
      }
    }
    if (c.data.coverage) {
      for (const locationId of c.data.coverage) {
        const location = locStore.locationMap[locationId];
        if (location) {
          c.coverage.push(location);
        }
      }
    }
  }
  return result;
}
