import { Injectable } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { AppState } from '@skylitup/flowergirl/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CustomerEventService } from '../../customer-event/customer-event.service';
import { VendorResearchService } from '../../vendor-research/vendor-research.service';
import { DocService } from '../doc/doc.service';
import { Upload } from '../upload.model';
import { UploadFilters, projectUploadFilters, UploadIndex, projectUploadIndex } from '../upload.model.fn';
import { MediaService } from './media.service';


@Injectable({ providedIn: 'root' })
export class MediaLibraryService {
  currentEventMediaFilters$: Observable<UploadFilters>;
  currentEventDocFilters$: Observable<UploadFilters>;
  mediaIndex$: Observable<UploadIndex>;
  docIndex$: Observable<UploadIndex>;
  docsInItemScope$: Observable<Upload[]>;

  constructor(
    private mediaService: MediaService,
    private docService: DocService,
    private customerEventService: CustomerEventService,
    private vendorResearchService: VendorResearchService,
    private store: Store
  ) {

    const selectMediaIndex = createSelector(
      this.mediaService.selectorsProjected.selectEntityTuple,
      projectUploadIndex
    );

    const selectDocIndex = createSelector(
      this.docService.selectorsProjected.selectEntityTuple,
      projectUploadIndex
    );


    const selectProjectMediaFilters = createSelector(
      selectMediaIndex,
      this.customerEventService.selectorsProjected.selectEntityInContext,
      this.vendorResearchService.selectorsProjected.selectEntities,
      projectUploadFilters
    );

    const selectProjectDocFilters = createSelector(
      selectDocIndex,
      this.customerEventService.selectorsProjected.selectEntityInContext,
      this.vendorResearchService.selectorsProjected.selectEntities,
      projectUploadFilters
    );

    this.mediaIndex$ = this.store.select(selectMediaIndex);
    this.docIndex$ = this.store.select(selectDocIndex);
    this.currentEventMediaFilters$ = this.store.select(selectProjectMediaFilters);
    this.currentEventDocFilters$ = this.store.select(selectProjectDocFilters);

    this.docsInItemScope$ = combineLatest([
      this.docIndex$,
      this.store.select((s: AppState) => s.ui).pipe(map(ui => ui.focusItem), filter(o => !!o))
    ]).pipe(
      map(
        ([docsIndex, itemId]) => {
          return ([...(docsIndex.eventItemMap[itemId] || []), ...(docsIndex.originEventItemMap[itemId] || [])]);
        }
      ),
      filter(o => !!o));
  }
}
