import { FormControl, AbstractControl } from '@angular/forms';
import { EntityProjectedBase } from '@skylitup/base/ngrx-data-fire';
import { Dictionary } from '@ngrx/entity';
import {
  combineLatest,
  ConnectableObservable,
  merge,
  Observable,
  Subject
} from 'rxjs';
import {
  filter,
  map,
  publishBehavior,
  scan,
  startWith,
  takeUntil,
  tap
} from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';

interface EntityMultiAutoCompleteSettings<T extends EntityProjectedBase<any>> {
  label: string;
  options$: Observable<T[]>;
  formControl: FormControl;
  displayFn?: (entity: T) => string;
  groupFn?: (entity: T) => T[];
  groupOptionDisplayFn: (entity: T) => string;
  groupOptionIsRootFn: (entity: T) => boolean;
  filterFn: (options: T[], text: string, excludeMap?: Dictionary<T>) => T[];
  required: boolean;
}
export class EntityMultiAutocomplete<T extends EntityProjectedBase<any>> {
  filteredOptions$: Observable<T[]>;
  optionsSelected$: Observable<T[]>;
  optionSelectedMap$: Observable<Dictionary<T>>;
  optionSelectedTuple$: Observable<[T[], Dictionary<T>]>;
  filterOverride$ = new Subject<string>();
  label: string;
  options$: Observable<T[]>;
  formControl: FormControl;
  displayFn: (entity: T) => string;
  groupFn: (entity: T) => T[];
  groupOptionDisplayFn: (entity: T) => string;
  hasGroup = false;
  groupOptionIsRootFn: (entity: T) => boolean;
  cd: ChangeDetectorRef = null;
  _removeSelection$ = new Subject<T>();
  _clearAll$ = new Subject<T>();
  _replace$ = new Subject<T[]>();
  filterFn: (options: T[], text: string, excludeMap?: Dictionary<T>) => T[];
  required: boolean;

  constructor(settings: EntityMultiAutoCompleteSettings<T>) {
    this.label = settings.label;

    this.formControl = settings.formControl;
    this.displayFn = !!settings.displayFn
      ? settings.displayFn
      : (entity: T) => (entity && entity.name ? entity.name : '');
    this.groupFn = settings.groupFn ? settings.groupFn : (entity: T) => [];
    this.hasGroup = !!settings.groupFn;
    this.groupOptionDisplayFn = !!settings.groupOptionDisplayFn
      ? settings.groupOptionDisplayFn
      : (entity: T) => (entity && entity.name ? entity.name : '');
    this.groupOptionIsRootFn = !!settings.groupOptionIsRootFn
      ? settings.groupOptionIsRootFn
      : _ => false;
    this.required = !!settings.required;
    this.filterFn = !!settings.filterFn
      ? settings.filterFn
      : (options, text, excludeMap) =>
        options.filter(
          option =>
            !excludeMap[option.id] &&
            option.name.toLowerCase().indexOf(text?.toLowerCase()) === 0
        );

    const _addSelection = combineLatest([
      this.formControl.valueChanges.pipe(filter(o => !!o && typeof o !== 'string')),
      settings.options$
    ]).pipe(map(([o, options]) => options.find(a => a.id === o.id)))

    this.optionsSelected$ = merge(
      _addSelection.pipe(map(o => ['+', o])),
      this._removeSelection$.pipe(map(o => ['-', o])),
      this._replace$.pipe(map(o => ['=', o])),
      this._clearAll$.pipe(map(o => ['x', null]))
    ).pipe(
      // tap(o => console.log('===========', o)),
      scan((optionsSelected, [instruction, t]) => {
        if ('+' === instruction) {
          optionsSelected.push(t);
        } else if ('-' === instruction) {
          optionsSelected.splice(
            optionsSelected.findIndex(c => c.id === (t as T)?.id),
            1
          );
        } else if ('x' === instruction) {
          optionsSelected = [];
        } else if ('=' === instruction) {
          optionsSelected = t as T[];
        }
        return optionsSelected;
      }, []),
      publishBehavior([])
    );
    (<ConnectableObservable<T[]>>this.optionsSelected$).connect();

    this.optionSelectedTuple$ = this.optionsSelected$.pipe(
      map(ss => [ss, ss.reduce((r, s) => ((r[s.id] = s) && r) || r, {})])
    );
    this.optionSelectedMap$ = this.optionSelectedTuple$.pipe(map(t => t[1]));

    this.filteredOptions$ = combineLatest([
      settings.options$,
      merge(
        this.filterOverride$,
        this.formControl.valueChanges.pipe(
          startWith(''),
          map(value => (typeof value === 'string' ? value : value?.name || ''))
        )
      ),
      this.optionSelectedMap$
    ]).pipe(
      map(([options, text, selectedMap]) =>
        this.filterFn(options, text, selectedMap)
      )
    );
    // this.optionsSelected$.subscribe(o => { console.log('77777', o); this.formControl.setValue('') });
  }
  clearFilter() {
    this.filterOverride$.next('');
  }
  removeSingle(selection: T) {
    this._removeSelection$.next(selection);
  }
  removeAll() {
    this._clearAll$.next();
  }
  setValue(v: T[]) {
    this._replace$.next(v);
  }
}
