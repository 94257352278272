import { CustomerEventData } from '../customer-event/customer-event.model.data';
export type CalculationType =
  | 'none'
  | 'guests'
  | 'children'
  | 'adults'
  | 'custom';
export interface BudgetItemData {
  budget?: number;
  calcType?: CalculationType;
  calcItemPrice?: number;
  // calcCustomName?: string;
  calcCustomNumber?: number;
  notes?: string;
  na?: boolean;
}

export function getCalcNumber(
  eventData: CustomerEventData,
  itemData: BudgetItemData
) {
  const calculationType: CalculationType = itemData.calcType || 'none';
  if (eventData && calculationType === 'children') {
    return eventData.childrenGuests;
  } else if (eventData && calculationType === 'adults') {
    return eventData.adultGuests;
  } else if (eventData && calculationType === 'guests') {
    return eventData.adultGuests + eventData.childrenGuests;
  } else if (calculationType === 'custom') {
    return itemData.calcCustomNumber || 0;
  } else {
    return 0;
  }
}
export function calcBudget(eventData: CustomerEventData, itemData: BudgetItemData) {
  const calculationType: CalculationType = itemData.calcType || 'none';
  if (calculationType === 'none') {
    return itemData.budget || 0;
  } else {
    const itemPrice = itemData.calcItemPrice || 0;
    return getCalcNumber(eventData, itemData) * itemPrice;
  }
}

export function calculationTypeLabel(item: BudgetItemData) {
  if (item?.calcType === 'adults') {
    return 'Adults';
  } else if (item?.calcType === 'children') {
    return 'Children';
  } else if (item?.calcType === 'guests') {
    return 'Guests';
  } else {
    return '';
  }
}
