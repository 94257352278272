import { EntityTuple } from '@skylitup/base/ngrx-data-fire';
import { CustomerEvent } from '../customer-event/customer-event.model';
import { EventCategory, VendorCat } from '../customer-event/vendor-cat.model';
import { EventItem } from '../event-item/event-item.model';
import { Vendor } from '../vendor/vendor.model';
import { Dictionary } from '@ngrx/entity';
import { VendorResearch } from '../vendor-research/vendor-research.model';
import { Upload } from './upload.model';

export interface UploadIndex {
  catMap: { [cat: string]: Upload[] };
  vendorMap: { [k: string]: Upload[] };
  eventItemMap: { [k: string]: Upload[] };
  eventMap: { [k: string]: Upload[] };
  //-----------
  originCatMap: { [k: string]: Upload[] };
  originVendorMap: { [k: string]: Upload[] };
  originEventItemMap: { [k: string]: Upload[] };
  showcaseVendorMap: { [k: string]: Upload[] };
}

export interface UploadFilters {
  cats: EventCategory[];
  vendors: Vendor[];
  eventItems: EventItem[];
  events: CustomerEvent[];
}

export const projectUploadIndex = (t: EntityTuple<Upload>): UploadIndex => {
  const result: UploadIndex = {
    catMap: {},
    vendorMap: {},
    eventItemMap: {},
    eventMap: {},
    originCatMap: {},
    originVendorMap: {},
    originEventItemMap: {},
    showcaseVendorMap: {}
  };
  const [list] = t;
  for (const m of list) {
    for (const cat of m.data.links.cats) {
      if (!result.catMap[cat]) {
        result.catMap[cat] = [];
      }

      result.catMap[cat].push(m);
    }
    for (const vendor of m.data.links.vendors) {
      if (!result.vendorMap[vendor]) {
        result.vendorMap[vendor] = [];
      }
      result.vendorMap[vendor].push(m);
    }
    for (const eventItem of m.data.links.eventItems) {
      if (!result.eventItemMap[eventItem]) {
        result.eventItemMap[eventItem] = [];
      }
      result.eventItemMap[eventItem].push(m);
    }
    for (const event of m.data.links.events) {
      if (!result.eventMap[event]) {
        result.eventMap[event] = [];
      }
      result.eventMap[event].push(m);
    }

    //++++++++++++++

    if (m.data.links.originCat) {
      if (!result.originCatMap[m.data.links.originCat]) {
        result.originCatMap[m.data.links.originCat] = [];
      }
      result.originCatMap[m.data.links.originCat].push(m);
    }
    if (m.data.links.originVendor) {
      if (!result.originVendorMap[m.data.links.originVendor]) {
        result.originVendorMap[m.data.links.originVendor] = [];
      }
      result.originVendorMap[m.data.links.originVendor].push(m);
    }
    if (m.data.links.originItem) {
      if (!result.originEventItemMap[m.data.links.originItem]) {
        result.originEventItemMap[m.data.links.originItem] = [];
      }
      result.originEventItemMap[m.data.links.originItem].push(m);
    }
    if (m.data.links.showcaseVendorId) {
      if (!result.showcaseVendorMap[m.data.links.showcaseVendorId]) {
        result.showcaseVendorMap[m.data.links.showcaseVendorId] = [];
      }
      result.showcaseVendorMap[m.data.links.showcaseVendorId].push(m);
    }
  }
  return result;
};

export const projectUploadFilters = (
  index: UploadIndex,
  event: CustomerEvent,
  vrs: VendorResearch[]
): UploadFilters => {
  const result: {
    cats: Dictionary<EventCategory>;
    vendors: Dictionary<Vendor>;
    eventItems: Dictionary<EventItem>;
    events: Dictionary<CustomerEvent>;
  } = { cats: {}, vendors: {}, eventItems: {}, events: {} };
  if (event) {
    for (const cat of event.categories) {
      if (index.catMap[cat.id] || index.originCatMap[cat.id]) {
        result.cats[cat.id] = cat;
      }
    }
    for (const vendorCat of event.allVendorCats) {
      if (
        vendorCat.vendorId !== 'void' &&
        (index.vendorMap[vendorCat.vendorId] ||
          index.originVendorMap[vendorCat.id])
      ) {
        result.vendors[vendorCat.vendorId] = vendorCat.vendor;
      }
    }
    for (const item of event.items) {
      if (index.eventItemMap[item.id] || index.originEventItemMap[item.id]) {
        result.eventItems[item.id] = item;
      }
    }
  }
  for (const vr of vrs) {
    if (
      index.vendorMap[vr.data.vendorId] ||
      index.originVendorMap[vr.data.vendorId]
    ) {
      result.vendors[vr.data.vendorId] = vr.vendor;
    }
  }

  return {
    cats: Object.values(result.cats),
    vendors: Object.values(result.vendors),
    eventItems: Object.values(result.eventItems),
    events: Object.values(result.events)
  };
};

export const projectFilter = (
  mediaIndex: UploadIndex,
  filter: { event?: string; cat?: string; vendor?: string; eventItem?: string }
) => { };
