/**
 * read or write is controlled in the rules. this only associates the item with specific groups
 * in a rule we check the claims of the user and compare them with the memberships here.
 * the rules have a simple isOwner(...) which check the ownergroups of the user and compares them
 *
 * t and o contains Agency ids
 * c contains Customer group ids
 * as contains userids
 *
 * _a : {
 *  t:[teamId1]    // team this item relates to // probably not needed, implicit by subcollection
 *  o:[ownerGroupId1]  // ownerGroup this item belongs to // not needed, implicit by subcollection
 *  c:[customerAccountId1] // customerAccount this item might relate to // not needed, implicit by subcollection
 *  as: [assigneeId1, assigneeId2]  // ids of users assignees
 * }
 * _g: 1 // globally readable
 * _cUid: creatorUserId
 */
export interface EntityData {
  meta?: {
    collectionPath: string[];
    id?: string;
    collection?: string;
    urlPath?: string[];
    parentCollection?: string;
    parentId?: string;
  };
  _uid?: string;
  _del: boolean | null;
  importSrc?: string;
}

export interface EntityKeys {
  name: string;
  plural: string;
}

export interface Timestamp {
  toDate(): Date;
}

export function entityDate(d: Date | Timestamp | undefined): Date {
  if (d === undefined) { return new Date() };
  return (d as any)?.toDate ? (d as Timestamp)?.toDate() : (d as Date);
}