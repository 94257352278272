<mat-form-field>
    <mat-label>
        <ng-content></ng-content> {{control.label}}
    </mat-label>

    <!-- <div (click)="open()"> </div> -->
    <input type="text" matInput [formControl]="control.formControl" [matAutocomplete]="auto1"
        #auto1Trigger="matAutocompleteTrigger" (focus)="control.clearFilter()" #input1>
    <mat-autocomplete #auto1="matAutocomplete" [displayWith]="control.displayFn">
        <mat-option *ngFor="let option of control.filteredOptions$ | async" [value]="option">
            {{control.optionDisplayFn(option)}}
        </mat-option>
    </mat-autocomplete>
    <button mat-icon-button matSuffix *ngIf="control.formControl.value" (click)="clear(auto1Trigger)">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>