import { EntityProjected } from '@skylitup/base/ngrx-data-fire';
import { MailNotificationsTrackData } from './mail-notifications-track.model.data';

export class MailNotificationsTrack extends EntityProjected<MailNotificationsTrackData> {
    clone(): MailNotificationsTrack {
        return new MailNotificationsTrack(this.data).copy(this);
    }
    copy(from: MailNotificationsTrack): MailNotificationsTrack {
        return this;
    }
    constructor(data: MailNotificationsTrackData) { super(data); }
    get history() {
        return this.data.history || [];
    }
}