import { EntityProjected } from '@skylitup/base/ngrx-data-fire';
import { Upload } from '../upload.model';
import { UploadData } from '../upload.model.data';
import { DocData, DOC_TYPE_OPTIONS } from './doc.model.data';
export const IMAGE_EXTENSIONS_INDEX = ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp', 'bmp', 'ico', 'cur', 'tif', 'tiff']
    .reduce((r, e) => (!(r[e] = true) || r), {})
export class Doc extends EntityProjected<DocData> implements Upload {
    constructor(data: DocData) {
        super(data);
    }
    get fileUrl(): string {
        return this.data.urlFull;
    }
    get renderType(): 'image' | 'pdf' | 'default' {
        if (this.data?.ext === 'pdf') {
            return 'pdf';
        } else if (IMAGE_EXTENSIONS_INDEX[this.data?.ext]) {
            return 'image';
        } else {
            return 'default';
        }
    }
    clone(): Doc {
        return new Doc(this.data).copy(this);
    }
    copy(from: Doc): Doc {
        return this;
    }
    // .xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf
    get iconCode() {
        switch (this.data.ext) {
            case 'doc': return 'fa-file-word';
            case 'docx': return 'fa-file-word';
            case 'pdf': return 'fa-file-pdf';
            case 'xls': return 'fa-file-excel';
            case 'xlsx': return 'fa-file-excel';
            case 'ppt': return 'fa-file-powerpoint';
            case 'pptx': return 'fa-file-powerpoint';
        }
        if (IMAGE_EXTENSIONS_INDEX[this.data.ext]) {
            return 'fa-file-image';
        }
        return 'fa-file-alt';

    }
    get fileName(): string {
        return this.data.fileName;
    }
    get typeDescription(): string {
        return DOC_TYPE_OPTIONS[this.data.type] || '';
    }
}
