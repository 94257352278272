import { Observable } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { Dictionary } from '@ngrx/entity';
import { filter, takeUntil } from 'rxjs/operators';
import { Loc, projectLocStore } from '@skylitup/flowergirl/core';
import { EntityMultiAutocomplete } from '@skylitup/base/xplat/web/ui/material';

export class LocationsAutocomplete extends EntityMultiAutocomplete<Loc> {
  constructor(
    locations$: Observable<Loc[]>,
    public destroy$: Observable<any>,
    label: string,
    required?: boolean,
  ) {
    super({
      label,
      options$: locations$.pipe(filter((o) => !!o)),
      formControl: new FormControl(),
      groupFn: (loc) => loc.descendants,
      groupOptionIsRootFn: (loc) => loc.isRoot,
      groupOptionDisplayFn: (loc) =>
        loc.id === 'anywhere' ? 'Anywhere' : `Anywhere in ${loc.name}`,
      // displayFn: loc => (loc.id === 'anywhere' ? '-' : loc.name),
      filterFn: (locations: Loc[], text: string, excludeMap: Dictionary<Loc>) =>
        projectLocStore(
          locations.map((l) => l.data),
          {
            filterFn: (location) =>
              !excludeMap[location.id] &&
              !excludeMap[location.root?.id] &&
              location.name.toLowerCase().includes(text.toLowerCase()),
            unshiftSelfInTreeRoots: true,
          }
        ).locationTree,
      required,
    });
    this.optionSelectedTuple$
      .pipe(takeUntil(this.destroy$))
      .subscribe(([selectedLocs, selectedLocMap]) => {
        // this.formControl.setValue('');
        setTimeout((_) => {
          const result = [];
          let madeChange = false;
          for (const loc of selectedLocs) {
            let removeThis = false;
            try {
              for (const a of loc.ancestors) {
                if (selectedLocMap[a.id]) {
                  removeThis = true;
                  madeChange = true;
                }
              }
            } catch (ex) {
              console.log(loc);
              throw ex;
            }
            if (!removeThis) {
              result.push(loc);
            }
          }
          if (madeChange) {
            this.setValue(result);
          }
        }, 0);
      });
  }
}
