import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { FormControl } from '@angular/forms';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { ThemeSelectorBaseComponent } from '@skylitup/flowergirl/ui';
import { THEME_NAMES, uiSetTheme, AppState } from '@skylitup/flowergirl/core';

@Component({
  selector: 'flrgl-theme-selector',
  templateUrl: 'theme-selector.component.html',
})
export class ThemeSelectorComponent extends ThemeSelectorBaseComponent {
  theme$: Observable<string>;
  themes = THEME_NAMES;
  control = new FormControl();
  constructor(
    @Inject(MAT_DIALOG_DATA) public debug: boolean,
    public dialModalRef: MatDialogRef<ThemeSelectorComponent>,
    private store: Store
  ) {
    super();
    this.theme$ = this.store.select((s: AppState) => s.ui.theme);
    this.theme$
      .pipe(takeUntil(this.destroy$))
      .subscribe((theme) => this.control.setValue(theme));
    this.control.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((theme) => this.store.dispatch(uiSetTheme({ theme })));
  }
}
