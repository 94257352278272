<div fxLayout="row" fxLayoutAlign="start center" [ngClass]="{'no-numbers':!showNumbers}">
    <div fxFlex="grow" class="name1">
        <ng-content></ng-content>
        {{name}}
    </div>

    <div *ngIf="showNumbers" fxLayout="column" fxLayoutAlign="start end" class='payments-container'>
        <!-- AAAAAAA -->
        <div>
            <div fxFlex="none" class="budget-item pending"
                [ngClass]="{'pending':actualBudget!== paymentsPaid, 'paid':actualBudget=== paymentsPaid}">
                <div class="label" *ngIf="withLabels">Pending (total)</div>
                <div class="value">
                    {{actualBudget - paymentsPaid  | currency:'EUR':'symbol'}}
                </div>
            </div>
            <div fxFlex="none" class="budget-item paid">
                <div class="label" *ngIf="withLabels">Paid (total)</div>
                <div class="value">
                    {{paymentsPaid | currency:'EUR':'symbol'}}
                </div>
            </div>
        </div>
        <ng-container *auth1="['planner']">
            <mat-hint align="end" *ngIf="paymentsTotal !== actualBudget">
                Payments! {{paymentsTotal | currency:'EUR':'symbol':'1.0-0'}}
                ( <span class="goal-below" *ngIf="paymentsTotal > actualBudget"> €
                    {{digits2( paymentsTotal - actualBudget) }}
                    more than the Budget</span>
                <span class="goal-below" *ngIf="actualBudget > paymentsTotal"> €
                    {{digits2(  actualBudget - paymentsTotal) }}
                    less than the Budget</span> )
            </mat-hint>
        </ng-container>
    </div>
    <div fxFlex="1 1 20%"></div>
    <div *ngIf="showNumbers" fxFlex="none" class="budget-item ">
        <div class="label" *ngIf="withLabels">Goal (total)</div>
        <div class="value">
            {{goalBudget | currency:'EUR':'symbol'}}
        </div>
    </div>
    <div *ngIf="showNumbers" fxFlex="none" class="budget-item" [ngClass]="getCatActualColor()">
        <div class="label" *ngIf="withLabels">Actual (total)</div>
        <div class="value">
            {{actualBudget | currency:'EUR':'symbol'}}
        </div>
    </div>
</div>