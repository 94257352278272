import { cloneEntityTuple, EntityTuple } from '@skylitup/base/ngrx-data-fire';
import { Auth } from '../auth/auth.model';
import { ChatTrack } from './chat-track.model'

export function projectChatTrackswithAuth(t: EntityTuple<ChatTrack>, auth: Auth): EntityTuple<ChatTrack> {
    const result = cloneEntityTuple(t);
    const [chatTracks] = result;
    for (const chatTrack of chatTracks) {
        chatTrack.auth = auth;
    }
    return result;
}